import axios from "axios";
import store from "../store";
import router from "@/router";
import {Element, Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
const instance = axios.create({
    baseURL: '/main', timeout: 30000
    // baseURL: 'http://127.0.0.1:8080/main', timeout: 10000
});


instance.interceptors.request.use(config => {
    // 页面切换的时候取消上一个路由的所有请求
    config.cancelToken = new axios.CancelToken(cancel => {
        window._axiosPromiseArr.push({ cancel })
    })

    return config
}, error => {
    return Promise.reject(new Error(error))
})

instance.interceptors.response.use(response => {
    const res = response.data
    if (res.code !== 200) {
        Message.error(res.message);
        // return Promise.reject(res.message)
        return Promise.reject(new Error(res.message))
    } else {
        return res;
    }
}, async error => {
    if (error.response) {
        // console.log(error.response)
        switch (error.response.status) {
            case 401:
                localStorage.removeItem('userInfo')
                store.commit('changeUserInfo',{})
                if(router.app._route.name!='EmailActive'&&router.app._route.name!='forget'&&router.app._route.name!='passwordReset'&&router.app._route.name!='helpCenter'&&router.app._route.name!='loginSuccess'&&router.app._route.name!='loginFail'&&JSON.parse(localStorage.getItem('guide')).some(obj=>obj=='index')){
                    // Message.closeAll()
                    // Message.error();
                    await router.replace({
                        path: '/login'
                    }, () => {
                    })
                }
                break;
            case 400:
                Message.error(store.state.language.PARAM_ERROR);
                break;
            case 500:
                Message.closeAll()
                Message.error(store.state.language.SYS_ERROR);
                // setTimeout(()=>{
                //     window.location.reload()
                // },500)
                break;
            // Message.error(error);
            case 404:
                Message.closeAll()
                Message.error(store.state.language.SYS_ERROR);
                break;
            default:
                Message.closeAll()
                Message.error(store.state.language.SYS_ERROR);
            // Message.error(error);
            // case 404:
            //     localStorage.removeItem('userInfo')
            //     // await store.dispatch('user/resetToken')
            //     await router.replace({
            //         path: '/login'
            //     })
        }
    }

    return error;
    // return Promise.reject(error) // 返回接口返回的错误信息
})

export default instance

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/fonts/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { web } from './utils/web'
import 'flag-icon-css/css/flag-icons.css';
import VueLazyload from 'vue-lazyload';
import locale from 'element-ui/lib/locale';
import en from 'element-ui/lib/locale/lang/en';
import zh from 'element-ui/lib/locale/lang/zh-CN';
import de from 'element-ui/lib/locale/lang/de';
import es from 'element-ui/lib/locale/lang/es';
import {behavior, filterCountryCodeLowerCase} from "@/utils/publicFunction";
import VueGtag from 'vue-gtag';
import VueAxios from 'vue-axios'
import axios from 'axios'


Vue.use(VueAxios, axios.create({
  http2: true // 开启HTTP2
}))

// 设置Intercom的基本配置
window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "nkcr4sm1",
};

// 加载Intercom聊天窗口的代码
(function() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function() { i.c(arguments); };
    i.q = [];
    i.c = function(args) { i.q.push(args); };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/nkcr4sm1';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();


Vue.use(VueGtag, {
  config: {
    id: 'G-7SEDQ87G54'
  }
}, router)

Vue.use(VueLazyload);
// 设置为英文
if(localStorage.getItem('languageIndex')==0){
  locale.use(zh);
}else if(localStorage.getItem('languageIndex')==1){
  locale.use(en);
}else if(localStorage.getItem('languageIndex')==2){
  locale.use(de);
}else if(localStorage.getItem('languageIndex')==3){
  locale.use(es);
}

Vue.prototype.$behavior = behavior;

// 设置Intercom的基本配置
window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "nkcr4sm1",
  // user_id: user.id, // 重要：用于捕获用户ID的变量
  // name: user.name, // 重要：用于捕获用户姓名的变量
  // email: user.email, // 重要：用于捕获用户电子邮件地址的变量
  // created_at: user.createdAt, // 重要：用于捕获用户注册日期的变量
};

// 加载Intercom聊天窗口的代码
(function() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function() { i.c(arguments); };
    i.q = [];
    i.c = function(args) { i.q.push(args); };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/nkcr4sm1';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'error.png',
//   loading: 'loading-spinner.gif',
//   attempt: 1
// });
// Vue.config.errorHandler = function (err, vm, info) {
//   // 处理错误，例如发送到后端服务器，记录日志等
//   console.error('Vue errorHandler:', err, info);
//   // 可选择的操作：停止传播错误，避免控制台输出错误信息
//   // 如果你不希望Vue停止传播错误，可以去掉下面这行代码
//   // throw err;
// };
// import { encrypt, decrypt } from '/utils/jsencrypt'//rememberMe-password加密
web()
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

<template>
  <div class="bottom-container">
    <div style="padding-bottom: 20px;box-sizing: border-box;width: 100%;display: flex;justify-content: center;bottom: 0;position:sticky;">
      <div class="bottom-container-box">
        <div class="bottom-box-arr">
          <div class="bottom-arr" v-for="(item,index) in bottomArr">
            <div class="bottom-title">{{item.name}}</div>
            <div class="bottom-content" v-for="(item1,index1) in item.valueArr" @click="linkToHelpCenter(item1.articleId,item1.url)">
              {{item1.content}}
            </div>
          </div>
          <div class="bottom-arr">
            <div>
              {{$store.state.language.MORE_NEWS}}
            </div>
            <div class="bottom-circle-box">
              <div v-for="(item,index) in bottomIcon" class="bottom-circle" @click="toOtherUrl(item)">
                <img style="width: 26px" :src="item.image" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div>
        {{$store.state.language.BEIAN }}
      </div>
      <div>
       {{$store.state.language.BEIAN_RIGHT}}
      </div>
    </div>
  </div>
</template>

<script>
import {filterCountryCode} from "../utils/publicFunction";

export default {
  name: "BottomContainer.vue",
  data() {
    return {
      bottomIcon:[
        {
          image:require('../../public/images/Facebook.png'),
          url:'https://www.facebook.com/tendabuy.official/'
        },
        {
          image: require('../../public/images/Youtube.png'),
          url:'https://www.youtube.com/watch?v=SRcnCLUnL30&t=7s'
        },
        {
          image: require('../../public/images/Discord.png'),
          url:'https://discord.com/invite/xGCuZFNrDd'
        },
        {
          image:  require('../../public/images/Reddit.png'),
          url:'https://reddit.com/r/TendabuyShoppingAgent'
        },
      ],
      bottomArr: [{
        name: this.$store.state.language.NEWBIE_GUIDE,
        nameEn:'Starter Guide',
        valueArr: [{
          content: this.$store.state.language.MEMBER_GIFT,
          contentEn: 'New Member Gift Voucher',
          articleId: 430
        }, {
          content: this.$store.state.language.PAYMENT_DEPOSIT,
          contentEn: 'Payment & Deposit'
        },  {
          content: this.$store.state.language.TUTORIAL,
          contentEn: 'Tutorial',
          articleId: 8
        }, {
          content: this.$store.state.language.SERVICE_FEES,
          contentEn: 'Service & Fees',
          articleId: 6
        }, {
          content: this.$store.state.language.RETURNS_REFUNDS,
          contentEn: 'Returns & Refunds',
          articleId: 4
        }]
      },
        {
          name: this.$store.state.language.CUSTOMER_SERVICE,
          nameEn:'Customer Service',
          valueArr: [{
            content:this.$store.state.language.HELP_CENTER_INDEX,
            contentEn: 'Help Center'
          },{
            content: this.$store.state.language.STORAGE1,
            contentEn: 'Commodity Storage',
            articleId: 133
          }, {
            content: this.$store.state.language.INSPECTION_RULES,
            contentEn: 'Inspection Rules',
            articleId: 5
          }, {
            content: this.$store.state.language.INSURANCE_COM,
            contentEn: 'Insurance & Compensation',
            articleId: 130
          },{
            content:this.$store.state.language.CONTACT_US,
            contentEn: 'Contact Us',
          }]
        },
        {
          name: this.$store.state.language.DELIVERY,
          nameEn:'Delivery',
          valueArr: [{
            content: this.$store.state.language.CHARGES,
            contentEn: 'Freight',
            articleId: 77
          }, {
            content: this.$store.state.language.PACKAGING,
            contentEn: 'Packing',
            articleId: 95
          }, {
            content: this.$store.state.language.CUSTOMS_TAX,
            contentEn: 'Customs & Tax',
            articleId: 99
          }, {
            content: this.$store.state.language.RECEIPT_INFORMATION,
            contentEn: 'Signing Instructions',
            articleId: 123
          }, {
            content: this.$store.state.language.LOGISTICS_TRACKING,
            contentEn: 'Logistics Tracking'
          }]
        },
        {
          name: this.$store.state.language.ABOUT_US,
          nameEn:'About Us',
          valueArr: [{
            content: 'Tendabuy',
            contentEn: 'Tendabuy',
            url:'/guidance?id=1'
          }, {
            content: this.$store.state.language.TERMS_OF_USE,
            contentEn: 'Terms of Use',
            articleId: 132
          }, {
            content: this.$store.state.language.PRIVACY_SECURITY,
            contentEn: 'Privacy & Security',
            articleId: 134
          }, {
            content: this.$store.state.language.LEGAL_NOTICE,
            contentEn: 'Legal Notice'
          }, {
            content: this.$store.state.language.BLOG,
            contentEn: 'Blog',
            articleId: 135,
            url:'http://blog.tendabuy.com/'
          }]
        }
      ]
    }
  },
  methods: {
    toOtherUrl(item){
      window.open(item.url,'_blank')
    },
    filterCountryCode,
    linkToHelpCenter(id,url){
      if(url){
        var path = this.$router.resolve({
          path:url
        }).href
        window.open(path, '_blank');
      }else{
        localStorage.removeItem('OutterHelpCenterId')
        localStorage.removeItem('OutterHelpCenterChar')
        localStorage.removeItem('InnerHelpCenterId')
        localStorage.removeItem('InnerHelpCenterName')
        window.open('/helpCenter/frequentQuestion' +(id? `?articleId=${id}`:''), '_blank');
        window.scrollTo({
          top: 0,
        });
      }
    }
  }
}
</script>

<style scoped>
* {
  font-size: 14px;
  color: white;
}
.right-box{
  display: flex;
  flex-direction:column;
  align-items: center;
  margin-top: 20px;
  width: 1200px;
}
.bottom-circle-box{
  display: flex;
  margin-top: 15px;

}
.bottom-circle{
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #004f9a;
  cursor: pointer;
  .iconfont{
    color: #004f9a;
    font-size: 24px;
    margin-top: 2px;
  }
}
.bottom-arr{
  width: 245px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bottom-box-arr{
  display: flex;
}
.bottom-container-box {
  display: flex;
  border-bottom: 2px solid #2083DD;
  padding-bottom:40px;
  box-sizing: border-box;
}

.bottom-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.bottom-content {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.top {
  display: flex;
  width: 1200px;
}

.top-item {
  width: 300px;
  height: 120px;
  display: flex;
}

.bottom-container, .top-container, .bottom-container {
  width: 100%;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-container {
  background: #F6F6F6;
}

.bottom-container-box {
  width: 1200px;
}

.bottom-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.bottom-container {
  background: #004F9A;
  padding: 30px 0;
  box-sizing: border-box;
}
</style>

import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
// 密钥对生成 http://web.chacuo.net/netrsakeypair; 把下面生成的公钥、私钥换成自己生成的即可
// const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0meu97HPe/maITDiC/R6'+
// 'wtLZzng1d / hpH1QvAv35rl500L07Pk1MpP7TJqpmsg00MrNwtR1NhmvZP2QP5ESw'+
// 'bSn / o2FRzRST9S6peq7wpcH41x / WPklSRxYVU2jdxJK + OKCgHGVR84TwkT4IAQVg'+
// 'molt7VbfF6yeSDeP1yOwpH5wF6yM5tJ4Fkr0ZzfjzZFDKbztrV2BLTK + hrLl8hYU'+
// 'u9KaZvBRYKxocuduLlGg7VOugXy9PEveSidOkgfg0BBBUmQ3cmptKbFr2Hrcxz4V'+
// 'Ax0cZcisgKORksURJKKEKKOAR9gmM5CC0ca / hZ14vMJOti43aX7KRxQwRiZSIoFD'+
// 'dwIDAQAB'//生成的公钥
const privateKey = 'MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDSZ673sc97+Zoh'+
'MOIL9HrC0tnOeDV3+GkfVC8C/fmuXnTQvTs+TUyk/tMmqmayDTQys3C1HU2Ga9k/'+
'ZA/kRLBtKf+jYVHNFJP1Lql6rvClwfjXH9Y+SVJHFhVTaN3Ekr44oKAcZVHzhPCR'+
'PggBBWCaiW3tVt8XrJ5IN4/XI7CkfnAXrIzm0ngWSvRnN+PNkUMpvO2tXYEtMr6G'+
'suXyFhS70ppm8FFgrGhy524uUaDtU66BfL08S95KJ06SB+DQEEFSZDdyam0psWvY'+
'etzHPhUDHRxlyKyAo5GSxREkooQoo4BH2CYzkILRxr+FnXi8wk62LjdpfspHFDBG'+
'JlIigUN3AgMBAAECggEBALumLvzHv0VRQe4SG7gPzaCNziT/PqiFirOfL+JaQmEJ'+
'6uLzsa+J3y5T+ltEpBxt/DyvnnhY7vhQnUuU4TtzK2ngiBbdR8hAbwi/GfXJqeQb'+
'T+QEh927JL8zacefAhI70ZzNXJ6WmnU1/ht7GEZY1mRc5wZCcOs7T1sW+pkkQnkg'+
'j0OQ8+t4hy3l3xGeWYnNkdnFdyJoHVI9+mrkfPQhD9gy+ipd9FvyByPkty73W422'+
'o1EQ9CFT61LwLcJZk3nhOU9p0oexsKo9QYEVK4exOIpasFdgvyL/JzcLUZRKiik+'+
'19IYWIHvuPYBYb4TkX9pN0mOAblyyAZFc3YF2FmG/dECgYEA/H4NWOdiBHDq9iu4'+
'iIZ6kdsbiTsOuBNlF/aq8WVU6GvFhrANXiKsg3Qz9c0vIiogb/bQFS+wzX/U9/9h'+
'vchWW371PVm63Fa8YKl3JX+MP5TWpjbw1YY6DQ61YMVTCyjU+baltRasUqBQV+sZ'+
'ey0G9jpP1sj6tj+yzyd1rvsl+08CgYEA1VP0V3N9vMrpDrLaGS4eUcsgmQuN9TWF'+
'tmV/l7GKivp6RlXrcIieNsJNlKGPfaHWde05n0rozkPMytkHJLFKWHiV1wUg7uHm'+
'10EVPdbsJGJsGW8oMN9Bb7XlzkVoQsXWw5Dt2+ONoiVa4fHn9pfYOTrg7OHTmyyk'+
'cyL8PcVXi1kCgYBC0hk2LgYCpt+HxopPl0dY0mO3Lan3jSpz7RPQTsDpwZvEyVLA'+
'VuzQ3S/jHSocIIn1F5x5/uULfYkfGLcFQr+X90Fvuw94wHuaGT8u7VJwgTNNSbzy'+
'xVMXoYzez1nq2iOC8pNoGVgBheu9WLqlnoT5JPV8IjQ3hyQLLqY2aMDkgQKBgQCq'+
'OxFrS4zVv7HUeeJSX5vwN0CvACpqtygZUctMeG+O0awLPnrfVRWzqr+P0JCZdzCU'+
'8BWgSH2YQ0BDyoXJwPQLv0YKzFvoZDn6IURbEYtkyWlrrmkK/szP4P4d6QL27bqg'+
'WqGEa4RVFKv+SzSIcckoehSM0vQyCqC8A/n3LOfcGQKBgEc2BCWc6wv05Pii3l5/'+
'XXFHKlwl2HjS1Ryh3Vjyn4mFfU6WKMd/eP2M9YAPb2us1RGhGaAkqmAJscGoyT2J'+
'I0Skh0mQITWUEQ+5Z5wGbcmGSzVhls6du7+0INtV9hr3qJl7EyyA8uJBzopUzSWn'+
'T1fVJu8QWMcGDeGtU9v6SaHa'

// 加密
export function encrypt(txt,publicKey) {
    const encryptor = new JSEncrypt()
    
    encryptor.setPublicKey(publicKey) // 设置公钥
    return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
    const encryptor = new JSEncrypt()
    encryptor.setPrivateKey(privateKey) // 设置私钥
    return encryptor.decrypt(txt) // 对数据进行解密
}

<template>
  <div>
    <div class="left-menu">
      <div @click="toLink('https://world.taobao.com/')" class="left-menu-item">
        <div class="left-menu-img-box">
          <img class="left-menu-img" src="https://oss.tendabuy.com/static/logo/taobao.webp" alt="">
        </div>
        <div class="left-menu-span">
          Taobao
        </div>
      </div>
      <div class="left-menu-line">
        <img class="left-menu-line-img" src="../../public/images/under-line.png" alt="">
      </div>
      <div @click="toLink('https://www.1688.com/')" class="left-menu-item">
        <div class="left-menu-img-box">
          <img class="left-menu-img" src="https://oss.tendabuy.com/static/logo/1688.webp" alt="">
        </div>
        <div class="left-menu-span">
          1688
        </div>
      </div>
      <div class="left-menu-line">
        <img class="left-menu-line-img" src="../../public/images/under-line.png" alt="">
      </div>
      <div @click="toLink('https://www.tmall.com/')" class="left-menu-item">
        <div class="left-menu-img-box">
          <img class="left-menu-img" src="https://oss.tendabuy.com/static/logo/tmail.webp" alt="">
        </div>
        <div class="left-menu-span">
          Tmall
        </div>
      </div>
    </div>
    <div class="right-menu">
      <div class="right-menu-box">
<!--        <div class="right-zone">-->
<!--          <div>{{ zone }}</div>-->
<!--          <div>{{ zoneMonthDay }}</div>-->
<!--        </div>-->
<!--        <div class="right-zone-time">-->
<!--          {{ zoneTime }}-->
<!--        </div>-->
        <div class="right-zone-icon">
          <div style="margin-top: -4px;">
            <i class="iconfont icon-whatsapp" style="color:#00e676;margin-left: 3px;font-size: 29px;" @click="toWhatsApp()"></i>
          </div>
          <div>
            <el-popover
                placement="right"
                width="250"
                trigger="hover"
                padding="0"
                class="right-time-popover"
                v-model="rightMenuCartPopover"
                style="z-index: 4000;height: 600px;padding: 0 !important;">
              <div class="right-menu-goods">
                <div>
                  <div class="right-menu-cart-title">{{
                      $store.state.language.SHOPPING_CART
                    }}（{{ $store.state.cartQuantity }}）
                  </div>
                  <div class="right-menu-cart-box" v-if="$store.state.cartQuantity!=0">
                    <div class="right-menu-cart-box-item" v-for="(item,index) in rightMenuCart">
                      <div><img style="width: 60px;height: 60px;border-radius: 4px" :src="filterGoodsUrl(item.goodsUrl)"
                                alt=""></div>
                      <div style="margin-left: 10px;">
                        <div class="right-menu-subject"><span v-html="item.subject"></span></div>
                        <div class="right-menu-style">{{ item.style }}</div>
                        <div class="right-menu-quantity">
                          <el-input-number v-model="item.quantity" aria-valuenow="3" @change="numHandleChange(item)"
                                           :min="1"
                                           :label="$store.state.language.DESCRIPTION_TEXT"></el-input-number>
                        </div>
                        <div class="right-menu-price">
                          <span>{{ $store.state.currency.symbol }}{{ filterPrice(item.price) }}</span><span><i
                            class="el-icon-delete delete-icon" @click="removeCart(item.id)"></i></span></div>
                      </div>
                    </div>
                  </div>
                  <el-empty style="height: 450px" v-if="$store.state.cartQuantity==0"
                            :description="$store.state.language.CART_EMPTY"></el-empty>
                  <div class="right-menu-check-box">
                    <div class="order-amount">
                    <span
                        style="display: inline-block;padding-bottom: 4px;box-sizing: border-box;">{{
                        $store.state.language.Total_Order_Price
                      }}：</span>`
                      <div class="order-amount-span">
                        {{
                          $store.state.currency.symbol
                        }}{{ $store.state.cartQuantity != 0 ? filterPrice(orderAmount()) : (0).toFixed(2) }}
                      </div>
                    </div>
                    <div class="right-menu-check-button" @click="toCartCheckOut()">
                      {{ $store.state.language.Proceed_To_Checkout }}
                    </div>
                  </div>
                </div>
              </div>
              <el-button slot="reference">
                <div>
                  <el-badge :value="$store.state.cartQuantity" :max="99" v-if="$store.state.cartQuantity!=0" class="item">
                    <i class="el-icon-shopping-cart-2" @click="toCartCheckOut()"></i>
                  </el-badge>
                  <i v-else class="el-icon-shopping-cart-2" @click="toCartCheckOut()"></i>
                </div>
              </el-button>
            </el-popover>
          </div>
          <div>
            <i class="el-icon-service"></i>
          </div>
          <div>
            <i class="el-icon-star-off" @click="$router.push({path:'/person/collection'})"></i>
          </div>
        </div>
      </div>
      <div class="top-icon" v-show="top" @click="toTop">
        <img width="28px" src="../../public/images/top.png" alt="">
      </div>
    </div>
  </div>

</template>
<script>
import {cartgoodsDelete, cartgoodsGet, cartgoodsModify} from "@/api/cargoods";
import {exchangeRate, filterGoodsUrl, filterPrice} from "@/utils/publicFunction";
import {right} from "core-js/internals/array-reduce";
import {getUserProfile} from "@/common/common";

export default {
  name: 'RightMenu',
  data() {
    return {
      zoneTime: '00:00:00',
      zone: 'UTC+8',
      zoneMonthDay: 'April 15th',
      rightMenuCart: [],
      rightMenuCartPopover: false,
      top: false
    }
  },
  watch: {
    rightMenuCartPopover(newVal) {
      if(newVal){
        var that = this;
        exchangeRate(function () {
          cartgoodsGet().then(res => {
            that.rightMenuCart = res.data
          })
        })
      }
    },
    rightMenuCart(newVal) {
      if (newVal) {
        this.$store.state.cartQuantity = newVal.length
      } else {
        this.$store.state.cartQuantity = 0
      }
    }
  },
  created() {
    this.getLocalTime()
    var timer = setInterval(() => {
      this.getLocalTime()
    }, 1000)
    var that = this;
    if(getUserProfile()){
      exchangeRate(function () {
        that.initCreated()
      })
    }else{
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },

  methods: {
    toLink(url){
      window.open(url,'_blank');
    },
    right,
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 200) {
        this.top = true;
      } else {
        this.top = false;
      }
    },
    //回到顶部
    toTop() {
      let Top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 设置计时器，实现滚动效果
      document.documentElement.scrollTop = 0
    }
    ,
    initCreated() {
      cartgoodsGet().then(res => {
        this.rightMenuCart = res.data ? res.data : []
      })
    }
    ,
    filterPrice,
    toWhatsApp() {
      window.open('https://api.whatsapp.com/send?phone=8615060457682', '_blank')
    }
    ,
    filterGoodsUrl,
    removeCart(id) {
      //// console.log(id)
      var data = {
        id
      }
      cartgoodsDelete(data).then(res => {
        //// console.log(res)
        if (this.$route.path == '/person/shop/cart') {
          // location.reload()

          this.$message.success(this.$store.state.language.DELETE_SUCCESSFUL)
        }
        cartgoodsGet().then(res => {
          this.rightMenuCart = res.data
          //// console.log(res)
        })
      })
    }
    ,
    toCartCheckOut() {
      if (this.$route.path != '/person/shop/cart') {
        this.$router.push({
          path: '/person/shop/cart'
        })
      }

    }
    ,
    orderAmount() {
      var orderAmount = 0
      this.rightMenuCart.filter(item => {
        orderAmount += item.price * 1 * item.quantity * 1
      })
      return orderAmount.toFixed(2)
    }
    ,
    numHandleChange(value) {

      this.isToOrderConfirm = false
      if (value.quantity) {
        var params = {
          id: value.id,
          num: value.quantity,
          totalPrice: (value.price * 100 * value.quantity * 1 / 100).toFixed(2)
        }
        cartgoodsModify(params).then(res => {
          this.isToOrderConfirm = true
          this.$message.closeAll()

          this.$message.success(this.$store.state.language.SUCCESS)
          if (this.$route.path == '/person/shop/cart') {

            // location.reload()
          }
          //// console.log(res)
        })
      } else {

      }
    }
    ,
    getLocalTime() {
      // 获取当前时间
      var now = new Date();
      // 获取当前时区与 UTC 的分钟差值
      var offsetMinutes = now.getTimezoneOffset();
      // 计算时区偏移量（小时）
      var offsetHours = offsetMinutes / 60;
      // 获取时区名称
      var timezone = 'UTC' + (offsetHours > 0 ? '-' : '+') + Math.abs(offsetHours);
      // 定义英文月份数组
      var monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      // 定义英文日期后缀数组
      var dateSuffixes = ["st", "nd", "rd", "th"];
      // 获取年、月、日、小时、分钟和秒
      var year = now.getFullYear();
      var month = monthNames[now.getMonth()]; // 从月份数组中获取对应的英文月份
      var day = now.getDate();
      var dateSuffix = dateSuffixes[Math.min(day - 1, 3)] || dateSuffixes[3]; // 获取日期后缀
      var hours = String(now.getHours()).padStart(2, '0');
      var minutes = String(now.getMinutes()).padStart(2, '0');
      var seconds = String(now.getSeconds()).padStart(2, '0');
      this.zone = timezone
      this.zoneTime = hours + ":" + minutes + ":" + seconds
      this.zoneMonthDay = month + " " + day + dateSuffix
    }
  }
}
</script>
<style lang="scss" scoped>
.left-menu-line-img{
  width: 60px;

}
.left-menu-line{
  margin-top: -5px;
}
.left-menu-span{
  font-weight: 700;
}
.left-menu-img:hover{
  transform: scale(1.0);
  transition: 0.1s;
}
.left-menu-img{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:4px;
  width: 100%;
  height: 100%;
  transform: scale(0.8);
}
.left-menu-img-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 1px;
}
.left-menu-item{
  display: flex;
  flex-direction:column;
  align-items: center;
  cursor: pointer;
}
.top-icon {
  width: 58px;
  border-top: 1px #f2f2f2 solid;
  min-height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;
}

::v-deep .el-badge__content.is-fixed {
  right: 17px;
  text-align: right;
}

.delete-icon {
  margin-right: 10px;
  display: none;
}

.right-menu-cart-box-item:hover .delete-icon {
  display: block;
}

.order-amount-span {
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  box-sizing: border-box;
  color: black;
}

.right-menu-check-button {
  height: 30px;
  width: 100%;
  background-color: #0071DC;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.order-amount {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 12px;
  color: #999;
  width: 100%;
  margin-bottom: 9px;

}

.right-menu-check-box {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.right-menu-cart-title {
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  box-sizing: border-box;
}

.right-menu-price {
  margin-top: 2px;
  font-size: 12px;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-menu-subject, .right-menu-style {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}

.right-menu-subject {
  font-size: 12px;
  color: #333;
}

.right-menu-style {
  font-size: 12px;
  color: #999;
}

.right-menu-cart-box-item {
  padding: 10px;
  display: flex;
  padding-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.right-menu-cart-box-item:hover {
  background: #F8F8F8;
}

.right-time-popover .el-popper {
  z-index: 5555 !important;
}

.right-menu-goods .el-popper {
  margin-right: 2px;
  padding: 0;
}

.right-menu-quantity ::v-deep .el-input-number {
  width: 100px;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;

}

.right-menu-quantity {
  height: 22px;
}

.right-menu-quantity ::v-deep .el-input-number span {
  height: 22px;
  width: 22px;
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  background: #F2F2F2;
}

.right-menu-quantity ::v-deep .el-input {
  height: 22px;
  font-size: 12px;
}

.right-menu-quantity ::v-deep .el-input-number span i {
  font-weight: 700 !important;
}

//.cart-body-num ::v-deep
.right-menu-quantity ::v-deep .el-input__inner {
  height: 22px !important;
  text-align: center;
  background: #F2F2F2;
}

.right-menu-quantity ::v-deep .el-input {
  height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  background: #F2F2F2;

  margin: 2px 0;
}

.right-menu-quantity ::v-deep .el-input input {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 12px !important;
}

.right-menu-quantity[data-v-672a901b] ::v-deep .el-input-number .el-input__inner {
  border: none;
}

.right-menu-quantity ::v-deep .el-input-number .el-input__inner {
  padding: 0;
  font-size: 13px;
}

.right-menu-goods {
  width: 100%;
  height: 580px;
  box-sizing: border-box;
  border: 4px;
  margin-right: 10px;
  box-sizing: border-box;
  //margin-right: ;
}

.right-menu-cart-box {
  overflow-y: scroll;
  height: 450px;
  overflow-x: hidden;
  box-sizing: border-box;
}

::v-deep .el-popover {
  width: 250px;
  margin: 0;
  margin-right: 10px;
  padding: 0 !important;
  z-index: 9999 !important; /* 使用一个足够高的值，并确保有 !important 以覆盖默认样式 */
}

::v-deep .el-popover .popper__inner {
  /* 修改内容区域的样式 */
  padding: 0px;
  color: #666;
}

.right-menu .el-button {
  border: none;
  background: white !important;
  padding: 0;
}

.right-zone-icon i {
  font-size: 28px;
  color: #666;
  cursor: pointer;
}

.right-zone-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 200px;
  background: white;
}

.right-zone-time {
  background: #F8F8F8;
  padding: 7px 0;
  font-family: DINPro-Medium;
  font-weight: 700;
  color: #333;
  font-size: 13px;
  box-sizing: border-box;
}

.right-zone {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #999;
  background: #F8F8F8;
  border-bottom: 1px solid #eaeaea;
  font-size: 12px;
  margin-top: 7px;
}


.left-menu{
  position: fixed;
  left: 15px;
  bottom: 200px;
  padding:15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
  //z-index: 2000;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .2);
  word-break: break-word !important;
  flex-direction: column;
  z-index: 1900;
  background: #ffffffcc;
}
.right-menu {
  position: fixed;
  right: 15px;
  bottom: 200px;
  width: 58px;
  border-radius: 4px;
  //z-index: 2000;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .2);
  word-break: break-word !important;
  z-index: 1900;
  background: #F8F8F8;
}

.right-menu-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Microsoft YaHei';
}

</style>
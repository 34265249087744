import request from "../utils/request";
var url = '/exchangeRate';

// 人民币汇率转换
export function exchangeRateConvert(params){
    return request({
        url: url+'/convert',
        method: 'GET',
        params
    })
}

// 查询汇率列表
export function exchangeRateQuery(params){
    return request({
        url: url+'/query',
        method: 'get',
        params
    })
}

// 保存更新汇率
export function exchangeRateSave(params){
    return request({
        url: url+'/save',
        method: 'get',
        params
    })
}


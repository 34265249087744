var countryArr = [
    {
        "country": "埃及",
        "countryEn": "Egypt",
        "id": 1,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            31.14,
            30.01
        ],
        "code": "eg"
    },
    {
        "country": "利比亚",
        "countryEn": "Libya",
        "id": 2,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            13.07,
            32.49
        ],
        "code": "ly"
    },
    {
        "country": "突尼斯",
        "countryEn": "Tunisia",
        "id": 3,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            10.11,
            36.50
        ],
        "code": "tn"
    },
    {
        "country": "阿尔及利亚",
        "countryEn": "Algeria",
        "id": 4,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            3.08,
            36.42
        ],
        "code": "dz"
    },
    {
        "country": "摩洛哥",
        "countryEn": "Morocco",
        "id": 5,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -7.09,
            31.79
        ],
        "code": "ma"
    },
    {
        "country": "西撒哈拉",
        "countryEn": "Western Sahara",
        "id": 6,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -12.88,
            24.21
        ],
        "code": "eh"
    },
    {
        "country": "毛里塔尼亚",
        "countryEn": "Mauritania",
        "id": 7,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            57.30,
            -20.10
        ],
        "code": "mr"
    },
    {
        "country": "塞内加尔",
        "countryEn": "Senegal",
        "id": 8,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -17.29,
            14.34
        ],
        "code": "sn"
    },
    {
        "country": "佛得角",
        "countryEn": "Cape Verde",
        "id": 9,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -23.34,
            15.02
        ],
        "code": "cv"
    },
    {
        "country": "冈比亚",
        "countryEn": "Gambia",
        "id": 10,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -16.40,
            13.28
        ],
        "code": "gm"
    },
    {
        "country": "几内亚比绍",
        "countryEn": "Guinea-Bissau",
        "id": 11,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -15.45,
            11.45
        ],
        "code": "gw"
    },
    {
        "country": "几内亚",
        "countryEn": "Guinea",
        "id": 12,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -13.49,
            9.29
        ],
        "code": "gn"
    },
    {
        "country": "马里",
        "countryEn": "Mali",
        "id": 13,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -7.55,
            12.34
        ],
        "code": "ml"
    },
    {
        "country": "布基纳法索",
        "countryEn": "Burkina Faso",
        "id": 14,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -1.30,
            12.15
        ],
        "code": "bf"
    },
    {
        "country": "尼日尔",
        "countryEn": "Niger",
        "id": 15,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            2.06,
            13.27
        ],
        "code": "ne"
    },
    {
        "country": "乍得",
        "countryEn": "Chad",
        "id": 16,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            14.59,
            12.10
        ],
        "code": "td"
    },
    {
        "country": "苏丹",
        "countryEn": "Sudan",
        "id": 17,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            32.35,
            15.31
        ],
        "code": "sd"
    },
    {
        "country": "厄立特里亚",
        "countryEn": "Eritrea",
        "id": 18,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            38.55,
            15.19
        ],
        "code": "er"
    },
    {
        "country": "吉布提",
        "countryEn": "Djibouti",
        "id": 19,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            42.20,
            11.08
        ],
        "code": "dj"
    },
    {
        "country": "索马里",
        "countryEn": "Somalia",
        "id": 20,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            45.25,
            2.02
        ],
        "code": "so"
    },
    {
        "country": "埃塞俄比亚",
        "countryEn": "Ethiopia",
        "id": 21,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            38.42,
            9.02
        ],
        "code": "et"
    },
    {
        "country": "中非",
        "countryEn": "Central Africa",
        "id": 22,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            18.35,
            4.23
        ],
        "code": "cf"
    },
    {
        "country": "喀麦隆",
        "countryEn": "Cameroon",
        "id": 23,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            11.35,
            3.50
        ],
        "code": "cm"
    },
    {
        "country": "尼日利亚",
        "countryEn": "Nigeria",
        "id": 24,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            7.32,
            9.05
        ],
        "code": "ng"
    },
    {
        "country": "贝宁",
        "countryEn": "Benin",
        "id": 25,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            2.42,
            6.23
        ],
        "code": "bj"
    },
    {
        "country": "多哥",
        "countryEn": "Togo",
        "id": 26,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            1.20,
            6.09
        ],
        "code": "tg"
    },
    {
        "country": "加纳",
        "countryEn": "Ghana",
        "id": 27,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -0.06,
            5.35
        ],
        "code": "gh"
    },
    {
        "country": "科特迪瓦（象牙海岸）",
        "countryEn": "Cote d’lvoire（Ivory Coast）",
        "id": 28,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -5.17,
            6.49
        ],
        "code": "ci"
    },
    {
        "country": "塞拉利昂",
        "countryEn": "Sierra Leone",
        "id": 29,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -13.17,
            8.30
        ],
        "code": "sl"
    },
    {
        "country": "利比里亚",
        "countryEn": "Liberia",
        "id": 30,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -10.47,
            6.18
        ],
        "code": "lr"
    },
    {
        "country": "赤道几内亚",
        "countryEn": "Equatorial Guinea",
        "id": 31,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            8.50,
            3.45
        ],
        "code": "gq"
    },
    {
        "country": "圣多美和普林西比",
        "countryEn": "Sao Tome and Principe",
        "id": 32,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            6.39,
            0.10
        ],
        "code": "st"
    },
    {
        "country": "加蓬",
        "countryEn": "Gabon",
        "id": 33,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            9.26,
            0.25
        ],
        "code": "ga"
    },
    {
        "country": "刚果",
        "countryEn": "Congo",
        "id": 34,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            15.12,
            -4.09
        ],
        "code": "cg"
    },
    {
        "country": "刚果（扎伊尔）",
        "countryEn": "The Democratic Republic Of Congo （Zaire）",
        "id": 35,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            15.15,
            -4.20
        ],
        "code": "cd"
    },
    {
        "country": "乌干达",
        "countryEn": "Uganda",
        "id": 36,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            32.30,
            0.20
        ],
        "code": "ug"
    },
    {
        "country": "卢旺达",
        "countryEn": "Rwanda",
        "id": 37,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            30.04,
            -1.59
        ],
        "code": "rw"
    },
    {
        "country": "布隆迪",
        "countryEn": "Burundi",
        "id": 38,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            29.18,
            -3.16
        ],
        "code": "bi"
    },
    {
        "country": "肯尼亚",
        "countryEn": "Kenya",
        "id": 39,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            36.48,
            -1.17
        ],
        "code": "ke"
    },
    {
        "country": "坦桑尼亚",
        "countryEn": "Tanzania",
        "id": 40,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            35.45,
            -6.08
        ],
        "code": "tz"
    },
    {
        "country": "莫桑比克",
        "countryEn": "Mozambique",
        "id": 41,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            32.32,
            -25.58
        ],
        "code": "mz"
    },
    {
        "country": "马拉维",
        "countryEn": "Malawi",
        "id": 42,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            33.48,
            -14.00
        ],
        "code": "mw"
    },
    {
        "country": "赞比亚",
        "countryEn": "Zambia",
        "id": 43,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            28.16,
            -15.28
        ],
        "code": "zm"
    },
    {
        "country": "安哥拉",
        "countryEn": "Angola",
        "id": 44,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            13.15,
            -8.50
        ],
        "code": "ao"
    },
    {
        "country": "纳米比亚",
        "countryEn": "Namibia",
        "id": 45,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            17.04,
            -22.35
        ],
        "code": "na"
    },
    {
        "country": "博茨瓦纳",
        "countryEn": "Botswana",
        "id": 46,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            25.57,
            -24.45
        ],
        "code": "bw"
    },
    {
        "country": "津巴布韦",
        "countryEn": "Zimbabwe",
        "id": 47,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            31.02,
            -17.43
        ],
        "code": "zw"
    },
    {
        "country": "南非",
        "countryEn": "South Africa",
        "id": 48,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            22.93,
            -30.55
        ],
        "code": "za"
    },
    {
        "country": "斯威士兰",
        "countryEn": "Swaziland",
        "id": 49,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            31.06,
            -26.18
        ],
        "code": "sz"
    },
    {
        "country": "莱索托",
        "countryEn": "Lesotho",
        "id": 50,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            27.30,
            -29.18
        ],
        "code": "ls"
    },
    {
        "country": "塞舌尔",
        "countryEn": "Seychelles",
        "id": 51,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            55.28,
            -4.40
        ],
        "code": "sc"
    },
    {
        "country": "科摩罗",
        "countryEn": "Comoro",
        "id": 52,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            43.16,
            -11.40
        ],
        "code": "km"
    },
    {
        "country": "马达加斯加",
        "countryEn": "Madagascar",
        "id": 53,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            47.31,
            -18.55
        ],
        "code": "mg"
    },
    {
        "country": "毛里求斯",
        "countryEn": "Mauritius",
        "id": 54,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            57.50,
            -20.00
        ],
        "code": "mu"
    },
    {
        "country": "中国",
        "countryEn": "China",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "蒙古",
        "countryEn": "Mongolia",
        "id": 56,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            106.92,
            47.92
        ],
        "code": "mn"
    },
    {
        "country": "朝鲜",
        "countryEn": "Korea",
        "id": 57,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            125.30,
            39.09
        ],
        "code": "kp"
    },
    {
        "country": "韩国",
        "countryEn": "South Korea",
        "id": 58,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            126.58,
            37.31
        ],
        "code": "kr"
    },
    {
        "country": "日本",
        "countryEn": "Japan",
        "id": 59,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            139.44,
            35.41
        ],
        "code": "jp"
    },
    {
        "country": "哈萨克斯坦",
        "countryEn": "Kazakhstan",
        "id": 60,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            71.30,
            51.10
        ],
        "code": "kz"
    },
    {
        "country": "土库曼斯坦",
        "countryEn": "Turkmenistan",
        "id": 61,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            57.50,
            38.00
        ],
        "code": "tm"
    },
    {
        "country": "乌兹别克斯坦",
        "countryEn": "Uzbekistan",
        "id": 62,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            69.10,
            41.20
        ],
        "code": "uz"
    },
    {
        "country": "吉尔吉斯斯坦",
        "countryEn": "Kyrgyzstan",
        "id": 63,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            74.46,
            42.54
        ],
        "code": "kg"
    },
    {
        "country": "塔吉克斯坦",
        "countryEn": "Tajikistan",
        "id": 64,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            68.48,
            38.33
        ],
        "code": "tj"
    },
    {
        "country": "格鲁吉亚",
        "countryEn": "Georgia",
        "id": 65,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            44.50,
            41.43
        ],
        "code": "ge"
    },
    {
        "country": "亚美尼亚",
        "countryEn": "Armenia",
        "id": 66,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            44.31,
            40.10
        ],
        "code": "am"
    },
    {
        "country": "阿塞拜疆",
        "countryEn": "Azerbaijan",
        "id": 67,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            49.56,
            40.29
        ],
        "code": "az"
    },
    {
        "country": "土耳其",
        "countryEn": "Turkey",
        "id": 68,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            32.54,
            39.57
        ],
        "code": "tr"
    },
    {
        "country": "塞浦路斯",
        "countryEn": "Cyprus",
        "id": 69,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            33.25,
            35.10
        ],
        "code": "cy"
    },
    {
        "country": "叙利亚",
        "countryEn": "Syria",
        "id": 70,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            36.18,
            33.30
        ],
        "code": "sy"
    },
    {
        "country": "黎巴嫩",
        "countryEn": "Lebanon",
        "id": 71,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            35.31,
            33.53
        ],
        "code": "lb"
    },
    {
        "country": "伊拉克",
        "countryEn": "Iraq",
        "id": 72,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            44.30,
            33.20
        ],
        "code": "iq"
    },
    {
        "country": "科威特",
        "countryEn": "Kuwait",
        "id": 73,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            48.00,
            29.30
        ],
        "code": "kw"
    },
    {
        "country": "约旦",
        "countryEn": "Jordan",
        "id": 74,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            35.52,
            31.57
        ],
        "code": "jo"
    },
    {
        "country": "巴勒斯坦",
        "countryEn": "Palestine",
        "id": 75,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            34.28,
            31.30
        ],
        "code": "ps"
    },
    {
        "country": "以色列",
        "countryEn": "Israel",
        "id": 76,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            35.12,
            31.47
        ],
        "code": "il"
    },
    {
        "country": "沙特阿拉伯",
        "countryEn": "Saudi Arabia",
        "id": 77,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            46.42,
            24.41
        ],
        "code": "sa"
    },
    {
        "country": "巴林",
        "countryEn": "Bahrain",
        "id": 78,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            50.30,
            26.10
        ],
        "code": "bh"
    },
    {
        "country": "卡塔尔",
        "countryEn": "Qatar",
        "id": 79,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            51.35,
            25.15
        ],
        "code": "qa"
    },
    {
        "country": "阿拉伯联合酋长国",
        "countryEn": "United Arab Emirates",
        "id": 80,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            54.22,
            24.28
        ],
        "code": "ae"
    },
    {
        "country": "也门",
        "countryEn": "Yemen",
        "id": 81,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            48.51,
            15.55
        ],
        "code": "ye"
    },
    {
        "country": "阿曼",
        "countryEn": "Oman",
        "id": 82,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            58.36,
            23.37
        ],
        "code": "om"
    },
    {
        "country": "伊朗",
        "countryEn": "Iran",
        "id": 83,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            51.30,
            35.44
        ],
        "code": "ir"
    },
    {
        "country": "阿富汗",
        "countryEn": "Afghanistan",
        "id": 84,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            69.11,
            34.28
        ],
        "code": "af"
    },
    {
        "country": "巴基斯坦",
        "countryEn": "Pakistan",
        "id": 85,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            73.10,
            33.40
        ],
        "code": "pk"
    },
    {
        "country": "印度",
        "countryEn": "India",
        "id": 86,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            77.13,
            28.37
        ],
        "code": "in"
    },
    {
        "country": "斯里兰卡",
        "countryEn": "Sri Lanka",
        "id": 87,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            80.77,
            7.87
        ],
        "code": "lk"
    },
    {
        "country": "马尔代夫",
        "countryEn": "Maldives",
        "id": 88,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            73.28,
            4.00
        ],
        "code": "mv"
    },
    {
        "country": "尼泊尔",
        "countryEn": "Nepal",
        "id": 89,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            85.20,
            27.45
        ],
        "code": "np"
    },
    {
        "country": "不丹",
        "countryEn": "Bhutan",
        "id": 90,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            89.45,
            27.31
        ],
        "code": "bt"
    },
    {
        "country": "孟加拉国",
        "countryEn": "Bangladesh",
        "id": 91,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            90.26,
            23.43
        ],
        "code": "bd"
    },
    {
        "country": "缅甸",
        "countryEn": "Burma",
        "id": 92,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            96.20,
            16.45
        ],
        "code": "mm"
    },
    {
        "country": "泰国",
        "countryEn": "Thailand",
        "id": 93,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            100.35,
            13.45
        ],
        "code": "th"
    },
    {
        "country": "老挝",
        "countryEn": "Laos",
        "id": 94,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            102.36,
            17.58
        ],
        "code": "la"
    },
    {
        "country": "柬埔寨",
        "countryEn": "Kampuchea Cambodia",
        "id": 95,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            104.55,
            11.33
        ],
        "code": "kh"
    },
    {
        "country": "越南",
        "countryEn": "Vietnam",
        "id": 96,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            105.55,
            21.05
        ],
        "code": "vn"
    },
    {
        "country": "马来西亚",
        "countryEn": "Malaysia",
        "id": 97,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            101.41,
            3.09
        ],
        "code": "my"
    },
    {
        "country": "新加坡",
        "countryEn": "Singapore",
        "id": 98,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            103.51,
            1.18
        ],
        "code": "sg"
    },
    {
        "country": "文莱",
        "countryEn": "Brunei",
        "id": 99,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            115.00,
            4.52
        ],
        "code": "bn"
    },
    {
        "country": "菲律宾",
        "countryEn": "Philippines",
        "id": 100,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            121.03,
            14.40
        ],
        "code": "ph"
    },
    {
        "country": "印度尼西亚",
        "countryEn": "Indonesia",
        "id": 101,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            106.49,
            -6.09
        ],
        "code": "id"
    },
    {
        "country": "东帝汶",
        "countryEn": "East Timor",
        "id": 102,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            125.34,
            -8.29
        ],
        "code": "tl"
    },
    {
        "country": "格陵兰岛",
        "countryEn": "Greenland",
        "id": 103,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -51.43,
            64.10
        ],
        "code": "gl"
    },
    {
        "country": "加拿大",
        "countryEn": "Canada",
        "id": 104,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -75.42,
            45.27
        ],
        "code": "ca"
    },
    {
        "country": "圣皮埃尔和密克隆",
        "countryEn": "St. Pierre and Miquelon",
        "id": 105,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -56.12,
            46.46
        ],
        "code": "pm"
    },
    {
        "country": "美国",
        "countryEn": "United States of America",
        "id": 106,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -77.02,
            39.91
        ],
        "code": "us"
    },
    {
        "country": "墨西哥",
        "countryEn": "Mexico",
        "id": 107,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -99.10,
            19.20
        ],
        "code": "mx"
    },
    {
        "country": "危地马拉",
        "countryEn": "Guatemala",
        "id": 108,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -90.22,
            14.40
        ],
        "code": "gt"
    },
    {
        "country": "伯利兹",
        "countryEn": "Belize",
        "id": 109,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -88.30,
            17.18
        ],
        "code": "bz"
    },
    {
        "country": "萨尔瓦多",
        "countryEn": "El Salvador",
        "id": 110,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -89.10,
            13.40
        ],
        "code": "sv"
    },
    {
        "country": "洪都拉斯",
        "countryEn": "Honduras",
        "id": 111,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -87.14,
            14.05
        ],
        "code": "hn"
    },
    {
        "country": "尼加拉瓜",
        "countryEn": "Nicaragua",
        "id": 112,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -86.20,
            12.06
        ],
        "code": "ni"
    },
    {
        "country": "哥斯达黎加",
        "countryEn": "Costa Rica",
        "id": 113,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -84.02,
            9.55
        ],
        "code": "cr"
    },
    {
        "country": "百慕大",
        "countryEn": "Bermuda",
        "id": 114,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -64.75,
            32.30
        ],
        "code": "bm"
    },
    {
        "country": "巴拿马",
        "countryEn": "Panama",
        "id": 115,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -79.25,
            9.00
        ],
        "code": "pa"
    },
    {
        "country": "巴哈马",
        "countryEn": "Bahamas",
        "id": 116,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -77.20,
            25.05
        ],
        "code": "bs"
    },
    {
        "country": "特克斯和凯科斯群岛",
        "countryEn": "Turks and Caicos Islands ",
        "id": 117,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -71.08,
            21.28
        ],
        "code": "tc"
    },
    {
        "country": "古巴",
        "countryEn": "Cuba",
        "id": 118,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -82.22,
            23.08
        ],
        "code": "cu"
    },
    {
        "country": "开曼群岛",
        "countryEn": "Cayman Islands",
        "id": 119,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -81.24,
            19.20
        ],
        "code": "ky"
    },
    {
        "country": "牙买加",
        "countryEn": "Jamaica",
        "id": 120,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -76.50,
            18.00
        ],
        "code": "jm"
    },
    {
        "country": "海地",
        "countryEn": "Haiti",
        "id": 121,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -72.20,
            18.40
        ],
        "code": "ht"
    },
    {
        "country": "多米尼加",
        "countryEn": "Dominican Republic",
        "id": 122,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.24,
            15.20
        ],
        "code": "do"
    },
    {
        "country": "波多黎各",
        "countryEn": "Porto Rico",
        "id": 123,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -66.07,
            18.28
        ],
        "code": "pr"
    },
    {
        "country": "美属维尔京群岛",
        "countryEn": "The United States Virgin Islands ",
        "id": 124,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -64.56,
            18.21
        ],
        "code": "vi"
    },
    {
        "country": "英属维尔京群岛",
        "countryEn": "The British Virgin Islands ",
        "id": 125,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -64.37,
            18.27
        ],
        "code": "vg"
    },
    {
        "country": "安圭拉",
        "countryEn": "Anguilla",
        "id": 126,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -63.05,
            18.22
        ],
        "code": "ai"
    },
    {
        "country": "蒙特塞拉特",
        "countryEn": "Montserrat",
        "id": 127,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -62.13,
            16.47
        ],
        "code": "ms"
    },
    {
        "country": "安提瓜和巴布达",
        "countryEn": "Antigua and Barbuda ",
        "id": 128,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.48,
            17.20
        ],
        "code": "ag"
    },
    {
        "country": "瓜德罗普",
        "countryEn": "Guadeloupe",
        "id": 129,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.44,
            16.00
        ],
        "code": "gp"
    },
    {
        "country": "多米尼克",
        "countryEn": "Dominica",
        "id": 130,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.50,
            15.50
        ],
        "code": "dm"
    },
    {
        "country": "马提尼克",
        "countryEn": "Martinique",
        "id": 131,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.02,
            14.36
        ],
        "code": "mq"
    },
    {
        "country": "圣卢西亚",
        "countryEn": "St. Lucia ",
        "id": 132,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -60.58,
            14.02
        ],
        "code": "lc"
    },
    {
        "country": "圣文森特和格林纳丁斯",
        "countryEn": "St. Vincent and the Grenadines ",
        "id": 133,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.10,
            13.10
        ],
        "code": "vc"
    },
    {
        "country": "阿鲁巴",
        "countryEn": "Aruba",
        "id": 134,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -70.02,
            12.32
        ],
        "code": "aw"
    },
    {
        "country": "巴巴多斯",
        "countryEn": "Barbados",
        "id": 135,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -59.30,
            13.05
        ],
        "code": "bb"
    },
    {
        "country": "格林纳达",
        "countryEn": "Grenada",
        "id": 136,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.44,
            12.04
        ],
        "code": "gd"
    },
    {
        "country": "特立尼达和多巴哥",
        "countryEn": "Trinidad and Tobago ",
        "id": 137,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -60.32,
            11.18
        ],
        "code": "tt"
    },
    {
        "country": "安的列斯",
        "countryEn": "Antilles",
        "id": 138,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -69.00,
            12.05
        ],
        "code": "xx"
    },
    {
        "country": "圣其茨和尼维斯",
        "countryEn": "St. Kitts & Nevis",
        "id": 139,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -62.43,
            17.18
        ],
        "code": "kn"
    },
    {
        "country": "委内瑞拉",
        "countryEn": "Venezuela",
        "id": 140,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -66.55,
            10.30
        ],
        "code": "ve"
    },
    {
        "country": "哥伦比亚",
        "countryEn": "Columbia",
        "id": 141,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -74.00,
            4.34
        ],
        "code": "co"
    },
    {
        "country": "圭亚那",
        "countryEn": "Guyana",
        "id": 142,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -58.12,
            6.50
        ],
        "code": "gy"
    },
    {
        "country": "苏里南",
        "countryEn": "Suriname",
        "id": 143,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -55.10,
            5.50
        ],
        "code": "sr"
    },
    {
        "country": "法属圭亚那",
        "countryEn": "French Guiana",
        "id": 144,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -52.18,
            5.05
        ],
        "code": "gf"
    },
    {
        "country": "厄瓜多尔",
        "countryEn": "Ecuador",
        "id": 145,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -78.35,
            -0.15
        ],
        "code": "ec"
    },
    {
        "country": "秘鲁",
        "countryEn": "Peru",
        "id": 146,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -77.00,
            -12.00
        ],
        "code": "pe"
    },
    {
        "country": "巴西",
        "countryEn": "Brazil",
        "id": 147,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -47.55,
            -15.47
        ],
        "code": "br"
    },
    {
        "country": "玻利维亚",
        "countryEn": "Bolivia",
        "id": 148,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -68.10,
            -16.20
        ],
        "code": "bo"
    },
    {
        "country": "巴拉圭",
        "countryEn": "Paraguay",
        "id": 149,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -57.30,
            -25.10
        ],
        "code": "py"
    },
    {
        "country": "智利",
        "countryEn": "Chile",
        "id": 150,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -70.40,
            -33.24
        ],
        "code": "cl"
    },
    {
        "country": "阿根廷",
        "countryEn": "Argentina",
        "id": 151,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -60.00,
            -36.30
        ],
        "code": "ar"
    },
    {
        "country": "乌拉圭",
        "countryEn": "Uruguay",
        "id": 152,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -56.11,
            -34.50
        ],
        "code": "uy"
    },
    {
        "country": "马尔维纳斯群岛",
        "countryEn": "Falkland Islands",
        "id": 153,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -59.51,
            -51.40
        ],
        "code": "fk"
    },
    {
        "country": "福克兰群岛",
        "countryEn": "Falkland Islands",
        "id": 154,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -59.51,
            -51.40
        ],
        "code": "fk"
    },
    {
        "country": "冰岛",
        "countryEn": "Iceland",
        "id": 155,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -21.57,
            64.10
        ],
        "code": "is"
    },
    {
        "country": "法罗群岛（丹）",
        "countryEn": "Faeroe Islands",
        "id": 156,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -6.56,
            62.05
        ],
        "code": "fo"
    },
    {
        "country": "挪威",
        "countryEn": "Norway",
        "id": 157,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            10.45,
            59.55
        ],
        "code": "no"
    },
    {
        "country": "瑞典",
        "countryEn": "Sweden",
        "id": 158,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            18.03,
            59.20
        ],
        "code": "se"
    },
    {
        "country": "芬兰",
        "countryEn": "Finland",
        "id": 159,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            25.03,
            60.15
        ],
        "code": "fi"
    },
    {
        "country": "爱尔兰",
        "countryEn": "Ireland",
        "id": 160,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -6.15,
            53.21
        ],
        "code": "ie"
    },
    {
        "country": "英国",
        "countryEn": "United Kingdom",
        "id": 161,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -0.05,
            51.36
        ],
        "code": "gb"
    },
    {
        "country": "丹麦",
        "countryEn": "Denmark",
        "id": 162,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.34,
            55.41
        ],
        "code": "dk"
    },
    {
        "country": "爱沙尼亚",
        "countryEn": "Estonia",
        "id": 163,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            24.48,
            59.22
        ],
        "code": "ee"
    },
    {
        "country": "拉脱维亚",
        "countryEn": "Latvia",
        "id": 164,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            24.08,
            56.53
        ],
        "code": "lv"
    },
    {
        "country": "立陶宛",
        "countryEn": "Lithuania",
        "id": 165,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            25.19,
            54.38
        ],
        "code": "lt"
    },
    {
        "country": "白俄罗斯",
        "countryEn": "White Russia",
        "id": 166,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            27.30,
            53.52
        ],
        "code": "by"
    },
    {
        "country": "俄罗斯",
        "countryEn": "Russia",
        "id": 167,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            37.35,
            55.45
        ],
        "code": "ru"
    },
    {
        "country": "乌克兰",
        "countryEn": "Ukraine",
        "id": 168,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            30.28,
            50.30
        ],
        "code": "ua"
    },
    {
        "country": "波兰",
        "countryEn": "Poland",
        "id": 169,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            21.00,
            52.13
        ],
        "code": "pl"
    },
    {
        "country": "德国",
        "countryEn": "Germany",
        "id": 170,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            13.25,
            52.30
        ],
        "code": "de"
    },
    {
        "country": "荷兰",
        "countryEn": "Holland",
        "id": 171,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            4.54,
            52.23
        ],
        "code": "nl"
    },
    {
        "country": "比利时",
        "countryEn": "Belgium",
        "id": 172,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            4.21,
            50.51
        ],
        "code": "be"
    },
    {
        "country": "卢森堡",
        "countryEn": "Luxembourg",
        "id": 173,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            6.09,
            49.37
        ],
        "code": "lu"
    },
    {
        "country": "法国",
        "countryEn": "France",
        "id": 174,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            2.20,
            48.50
        ],
        "code": "fr"
    },
    {
        "country": "摩纳哥",
        "countryEn": "Monaco",
        "id": 175,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            7.25,
            43.44
        ],
        "code": "mc"
    },
    {
        "country": "捷克",
        "countryEn": "Czech",
        "id": 176,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            14.22,
            50.05
        ],
        "code": "cz"
    },
    {
        "country": "斯洛伐克",
        "countryEn": "Slovakia",
        "id": 177,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            17.07,
            48.10
        ],
        "code": "sk"
    },
    {
        "country": "奥地利",
        "countryEn": "Austria",
        "id": 178,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            16.22,
            48.12
        ],
        "code": "at"
    },
    {
        "country": "列支敦士登",
        "countryEn": "Liechtenstein",
        "id": 179,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            9.31,
            47.08
        ],
        "code": "li"
    },
    {
        "country": "圣马力诺",
        "countryEn": "San Marino",
        "id": 180,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.30,
            43.55
        ],
        "code": "sm"
    },
    {
        "country": "意大利",
        "countryEn": "Italy",
        "id": 181,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.29,
            41.54
        ],
        "code": "it"
    },
    {
        "country": "梵蒂冈",
        "countryEn": "Vatican",
        "id": 182,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.27,
            41.54
        ],
        "code": "va"
    },
    {
        "country": "瑞士",
        "countryEn": "Switzerland",
        "id": 183,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            7.28,
            46.57
        ],
        "code": "ch"
    },
    {
        "country": "匈牙利",
        "countryEn": "Hungary",
        "id": 184,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            19.05,
            47.29
        ],
        "code": "hu"
    },
    {
        "country": "斯洛文尼亚",
        "countryEn": "Slovenia",
        "id": 185,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            14.33,
            46.04
        ],
        "code": "si"
    },
    {
        "country": "摩尔多瓦",
        "countryEn": "Moldova",
        "id": 186,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            28.50,
            47.02
        ],
        "code": "md"
    },
    {
        "country": "罗马尼亚",
        "countryEn": "Romania",
        "id": 187,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            26.10,
            44.27
        ],
        "code": "ro"
    },
    {
        "country": "塞尔维亚和黑山（塞黑）",
        "countryEn": "Serbia and Montenegro",
        "id": 188,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            21.00,
            44.01
        ],
        "code": "rs"
    },
    {
        "country": "克罗地亚",
        "countryEn": "Croatia",
        "id": 189,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            15.58,
            45.50
        ],
        "code": "hr"
    },
    {
        "country": "波斯尼亚和黑塞哥维那（波黑）",
        "countryEn": "Bosnia and Herzegovina",
        "id": 190,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            18.26,
            43.52
        ],
        "code": "ba"
    },
    {
        "country": "保加利亚",
        "countryEn": "Bulgaria",
        "id": 191,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            23.20,
            42.45
        ],
        "code": "bg"
    },
    {
        "country": "马其顿",
        "countryEn": "Macedonia",
        "id": 192,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            21.26,
            42.01
        ],
        "code": "mk"
    },
    {
        "country": "阿尔巴尼亚",
        "countryEn": "Albania",
        "id": 193,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            19.49,
            41.18
        ],
        "code": "al"
    },
    {
        "country": "希腊",
        "countryEn": "Greece",
        "id": 194,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            23.46,
            37.58
        ],
        "code": "gr"
    },
    {
        "country": "安道尔",
        "countryEn": "Andorra",
        "id": 195,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            1.32,
            42.31
        ],
        "code": "ad"
    },
    {
        "country": "西班牙",
        "countryEn": "Spain",
        "id": 196,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -3.45,
            40.25
        ],
        "code": "es"
    },
    {
        "country": "葡萄牙",
        "countryEn": "Portugal",
        "id": 197,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -9.10,
            38.42
        ],
        "code": "pt"
    },
    {
        "country": "马耳他",
        "countryEn": "Malta",
        "id": 198,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            14.31,
            35.54
        ],
        "code": "mt"
    },
    {
        "country": "澳大利亚",
        "countryEn": "Australia",
        "id": 199,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            149.08,
            -35.15
        ],
        "code": "au"
    },
    {
        "country": "新西兰",
        "countryEn": "New Zealand",
        "id": 200,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            174.46,
            -41.19
        ],
        "code": "nz"
    },
    {
        "country": "巴布亚新几内亚",
        "countryEn": "Papua new guinea",
        "id": 201,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            147.08,
            -9.24
        ],
        "code": "pg"
    },
    {
        "country": "所罗门群岛",
        "countryEn": "Solomon",
        "id": 202,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            159.57,
            -9.27
        ],
        "code": "sb"
    },
    {
        "country": "瓦努阿图",
        "countryEn": "Vanuatu",
        "id": 203,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            168.18,
            -17.45
        ],
        "code": "vu"
    },
    {
        "country": "新喀里多尼亚",
        "countryEn": "New Caledonia",
        "id": 204,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            166.30,
            -22.17
        ],
        "code": "nc"
    },
    {
        "country": "斐济群岛",
        "countryEn": "Fiji",
        "id": 205,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            178.30,
            -18.06
        ],
        "code": "fj"
    },
    {
        "country": "基里巴斯",
        "countryEn": "Kiribati",
        "id": 206,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            173.00,
            1.30
        ],
        "code": "ki"
    },
    {
        "country": "瑙鲁",
        "countryEn": "Nauru",
        "id": 207,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            166.55,
            -0.33
        ],
        "code": "nr"
    },
    {
        "country": "密克罗尼西亚",
        "countryEn": "Micronesia",
        "id": 208,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            158.09,
            6.55
        ],
        "code": "fm"
    },
    {
        "country": "马绍尔群岛",
        "countryEn": "Marshall Islands",
        "id": 209,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            171.12,
            7.09
        ],
        "code": "mh"
    },
    {
        "country": "帕劳",
        "countryEn": "Palau",
        "id": 210,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            134.28,
            7.20
        ],
        "code": "pw"
    },
    {
        "country": "北马里亚纳群岛",
        "countryEn": "Northern Mariana Islands",
        "id": 211,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            145.45,
            15.12
        ],
        "code": "mp"
    },
    {
        "country": "关岛",
        "countryEn": "Guam",
        "id": 212,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            144.45,
            13.29
        ],
        "code": "gu"
    },
    {
        "country": "图瓦卢",
        "countryEn": "Tuvalu",
        "id": 213,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            179.13,
            -8.31
        ],
        "code": "tv"
    },
    {
        "country": "瓦利斯及富图纳",
        "countryEn": "Wallis Islands and Futuna Islands",
        "id": 214,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -176.10,
            -13.20
        ],
        "code": "wf"
    },
    {
        "country": "西萨摩亚",
        "countryEn": "Western Samoa",
        "id": 215,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -171.50,
            -13.50
        ],
        "code": "ws"
    },
    {
        "country": "美属萨摩亚",
        "countryEn": "American Samoa",
        "id": 216,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -170.43,
            -14.16
        ],
        "code": "as"
    },
    {
        "country": "纽埃",
        "countryEn": "Niue",
        "id": 217,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -169.55,
            -19.03
        ],
        "code": "nu"
    },
    {
        "country": "托克劳",
        "countryEn": "Tokelau",
        "id": 218,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -172.50,
            -9.50
        ],
        "code": "tk"
    },
    {
        "country": "库克群岛",
        "countryEn": "Cook Islands",
        "id": 219,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -159.45,
            -21.12
        ],
        "code": "ck"
    },
    {
        "country": "汤加",
        "countryEn": "Tonga",
        "id": 220,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -174.00,
            -21.10
        ],
        "code": "to"
    },
    {
        "country": "法属波利尼西亚",
        "countryEn": "French Polynesia",
        "id": 221,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -149.34,
            -17.32
        ],
        "code": "pf"
    },
    {
        "country": "皮特凯恩群岛",
        "countryEn": "Pitcairn Islands",
        "id": 222,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -130.06,
            -25.04
        ],
        "code": "pn"
    },
    {
        "country": "中国香港",
        "countryEn": "Hongkong",
        "id": 223,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            114.15,
            22.15
        ],
        "code": "hk"
    },
    {
        "country": "中国澳门",
        "countryEn": "Macao",
        "id": 224,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            113.50,
            22.20
        ],
        "code": "mo"
    },
    {
        "country": "中国台湾",
        "countryEn": "Taiwan",
        "id": 225,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            121.31,
            25.03
        ],
        "code": "tw"
    }
]

countryArr.filter(item=>{
    item.fullName=item.country+'-'+item.countryEn+'-'+item.code
})

countryArr.sort((a, b) => {
    const nameA = a.countryEn.toLowerCase();
    const nameB = b.countryEn.toLowerCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
});

var countryArrSort = [
    {
        "country": "阿富汗",
        "countryEn": "Afghanistan",
        "id": 84,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            69.11,
            34.28
        ],
        "code": "af"
    },
    {
        "country": "阿尔巴尼亚",
        "countryEn": "Albania",
        "id": 193,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            19.49,
            41.18
        ],
        "code": "al"
    },
    {
        "country": "阿尔及利亚",
        "countryEn": "Algeria",
        "id": 4,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            3.08,
            36.42
        ],
        "code": "dz"
    },
    {
        "country": "美属萨摩亚",
        "countryEn": "American Samoa",
        "id": 216,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -170.43,
            -14.16
        ],
        "code": "as"
    },
    {
        "country": "安道尔",
        "countryEn": "Andorra",
        "id": 195,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            1.32,
            42.31
        ],
        "code": "ad"
    },
    {
        "country": "安哥拉",
        "countryEn": "Angola",
        "id": 44,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            13.15,
            -8.5
        ],
        "code": "ao"
    },
    {
        "country": "安圭拉",
        "countryEn": "Anguilla",
        "id": 126,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -63.05,
            18.22
        ],
        "code": "ai"
    },
    {
        "country": "安提瓜和巴布达",
        "countryEn": "Antigua and Barbuda ",
        "id": 128,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.48,
            17.2
        ],
        "code": "ag"
    },
    {
        "country": "安的列斯",
        "countryEn": "Antilles",
        "id": 138,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -69,
            12.05
        ],
        "code": "xx"
    },
    {
        "country": "阿根廷",
        "countryEn": "Argentina",
        "id": 151,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -60,
            -36.3
        ],
        "code": "ar"
    },
    {
        "country": "亚美尼亚",
        "countryEn": "Armenia",
        "id": 66,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            44.31,
            40.1
        ],
        "code": "am"
    },
    {
        "country": "阿鲁巴",
        "countryEn": "Aruba",
        "id": 134,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -70.02,
            12.32
        ],
        "code": "aw"
    },
    {
        "country": "澳大利亚",
        "countryEn": "Australia",
        "id": 199,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            149.08,
            -35.15
        ],
        "code": "au"
    },
    {
        "country": "奥地利",
        "countryEn": "Austria",
        "id": 178,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            16.22,
            48.12
        ],
        "code": "at"
    },
    {
        "country": "阿塞拜疆",
        "countryEn": "Azerbaijan",
        "id": 67,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            49.56,
            40.29
        ],
        "code": "az"
    },
    {
        "country": "巴哈马",
        "countryEn": "Bahamas",
        "id": 116,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -77.2,
            25.05
        ],
        "code": "bs"
    },
    {
        "country": "巴林",
        "countryEn": "Bahrain",
        "id": 78,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            50.3,
            26.1
        ],
        "code": "bh"
    },
    {
        "country": "孟加拉国",
        "countryEn": "Bangladesh",
        "id": 91,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            90.26,
            23.43
        ],
        "code": "bd"
    },
    {
        "country": "巴巴多斯",
        "countryEn": "Barbados",
        "id": 135,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -59.3,
            13.05
        ],
        "code": "bb"
    },
    {
        "country": "比利时",
        "countryEn": "Belgium",
        "id": 172,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            4.21,
            50.51
        ],
        "code": "be"
    },
    {
        "country": "伯利兹",
        "countryEn": "Belize",
        "id": 109,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -88.3,
            17.18
        ],
        "code": "bz"
    },
    {
        "country": "贝宁",
        "countryEn": "Benin",
        "id": 25,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            2.42,
            6.23
        ],
        "code": "bj"
    },
    {
        "country": "百慕大",
        "countryEn": "Bermuda",
        "id": 114,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -64.75,
            32.3
        ],
        "code": "bm"
    },
    {
        "country": "不丹",
        "countryEn": "Bhutan",
        "id": 90,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            89.45,
            27.31
        ],
        "code": "bt"
    },
    {
        "country": "玻利维亚",
        "countryEn": "Bolivia",
        "id": 148,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -68.1,
            -16.2
        ],
        "code": "bo"
    },
    {
        "country": "波斯尼亚和黑塞哥维那（波黑）",
        "countryEn": "Bosnia and Herzegovina",
        "id": 190,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            18.26,
            43.52
        ],
        "code": "ba"
    },
    {
        "country": "博茨瓦纳",
        "countryEn": "Botswana",
        "id": 46,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            25.57,
            -24.45
        ],
        "code": "bw"
    },
    {
        "country": "巴西",
        "countryEn": "Brazil",
        "id": 147,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -47.55,
            -15.47
        ],
        "code": "br"
    },
    {
        "country": "文莱",
        "countryEn": "Brunei",
        "id": 99,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            115,
            4.52
        ],
        "code": "bn"
    },
    {
        "country": "保加利亚",
        "countryEn": "Bulgaria",
        "id": 191,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            23.2,
            42.45
        ],
        "code": "bg"
    },
    {
        "country": "布基纳法索",
        "countryEn": "Burkina Faso",
        "id": 14,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -1.3,
            12.15
        ],
        "code": "bf"
    },
    {
        "country": "缅甸",
        "countryEn": "Burma",
        "id": 92,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            96.2,
            16.45
        ],
        "code": "mm"
    },
    {
        "country": "布隆迪",
        "countryEn": "Burundi",
        "id": 38,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            29.18,
            -3.16
        ],
        "code": "bi"
    },
    {
        "country": "喀麦隆",
        "countryEn": "Cameroon",
        "id": 23,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            11.35,
            3.5
        ],
        "code": "cm"
    },
    {
        "country": "加拿大",
        "countryEn": "Canada",
        "id": 104,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -75.42,
            45.27
        ],
        "code": "ca"
    },
    {
        "country": "佛得角",
        "countryEn": "Cape Verde",
        "id": 9,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -23.34,
            15.02
        ],
        "code": "cv"
    },
    {
        "country": "开曼群岛",
        "countryEn": "Cayman Islands",
        "id": 119,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -81.24,
            19.2
        ],
        "code": "ky"
    },
    {
        "country": "中非",
        "countryEn": "Central Africa",
        "id": 22,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            18.35,
            4.23
        ],
        "code": "cf"
    },
    {
        "country": "乍得",
        "countryEn": "Chad",
        "id": 16,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            14.59,
            12.1
        ],
        "code": "td"
    },
    {
        "country": "智利",
        "countryEn": "Chile",
        "id": 150,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -70.4,
            -33.24
        ],
        "code": "cl"
    },
    {
        "country": "中国",
        "countryEn": "China",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.2,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "哥伦比亚",
        "countryEn": "Columbia",
        "id": 141,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -74,
            4.34
        ],
        "code": "co"
    },
    {
        "country": "科摩罗",
        "countryEn": "Comoro",
        "id": 52,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            43.16,
            -11.4
        ],
        "code": "km"
    },
    {
        "country": "刚果",
        "countryEn": "Congo",
        "id": 34,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            15.12,
            -4.09
        ],
        "code": "cg"
    },
    {
        "country": "库克群岛",
        "countryEn": "Cook Islands",
        "id": 219,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -159.45,
            -21.12
        ],
        "code": "ck"
    },
    {
        "country": "哥斯达黎加",
        "countryEn": "Costa Rica",
        "id": 113,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -84.02,
            9.55
        ],
        "code": "cr"
    },
    {
        "country": "科特迪瓦（象牙海岸）",
        "countryEn": "Cote d’lvoire（Ivory Coast）",
        "id": 28,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -5.17,
            6.49
        ],
        "code": "ci"
    },
    {
        "country": "克罗地亚",
        "countryEn": "Croatia",
        "id": 189,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            15.58,
            45.5
        ],
        "code": "hr"
    },
    {
        "country": "古巴",
        "countryEn": "Cuba",
        "id": 118,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -82.22,
            23.08
        ],
        "code": "cu"
    },
    {
        "country": "塞浦路斯",
        "countryEn": "Cyprus",
        "id": 69,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            33.25,
            35.1
        ],
        "code": "cy"
    },
    {
        "country": "捷克",
        "countryEn": "Czech",
        "id": 176,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            14.22,
            50.05
        ],
        "code": "cz"
    },
    {
        "country": "丹麦",
        "countryEn": "Denmark",
        "id": 162,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.34,
            55.41
        ],
        "code": "dk"
    },
    {
        "country": "吉布提",
        "countryEn": "Djibouti",
        "id": 19,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            42.2,
            11.08
        ],
        "code": "dj"
    },
    {
        "country": "多米尼克",
        "countryEn": "Dominica",
        "id": 130,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.5,
            15.5
        ],
        "code": "dm"
    },
    {
        "country": "多米尼加",
        "countryEn": "Dominican Republic",
        "id": 122,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.24,
            15.2
        ],
        "code": "do"
    },
    {
        "country": "东帝汶",
        "countryEn": "East Timor",
        "id": 102,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            125.34,
            -8.29
        ],
        "code": "tl"
    },
    {
        "country": "厄瓜多尔",
        "countryEn": "Ecuador",
        "id": 145,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -78.35,
            -0.15
        ],
        "code": "ec"
    },
    {
        "country": "埃及",
        "countryEn": "Egypt",
        "id": 1,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            31.14,
            30.01
        ],
        "code": "eg"
    },
    {
        "country": "萨尔瓦多",
        "countryEn": "El Salvador",
        "id": 110,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -89.1,
            13.4
        ],
        "code": "sv"
    },
    {
        "country": "赤道几内亚",
        "countryEn": "Equatorial Guinea",
        "id": 31,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            8.5,
            3.45
        ],
        "code": "gq"
    },
    {
        "country": "厄立特里亚",
        "countryEn": "Eritrea",
        "id": 18,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            38.55,
            15.19
        ],
        "code": "er"
    },
    {
        "country": "爱沙尼亚",
        "countryEn": "Estonia",
        "id": 163,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            24.48,
            59.22
        ],
        "code": "ee"
    },
    {
        "country": "埃塞俄比亚",
        "countryEn": "Ethiopia",
        "id": 21,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            38.42,
            9.02
        ],
        "code": "et"
    },
    {
        "country": "法罗群岛（丹）",
        "countryEn": "Faeroe Islands",
        "id": 156,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -6.56,
            62.05
        ],
        "code": "fo"
    },
    {
        "country": "马尔维纳斯群岛",
        "countryEn": "Falkland Islands",
        "id": 153,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -59.51,
            -51.4
        ],
        "code": "fk"
    },
    {
        "country": "福克兰群岛",
        "countryEn": "Falkland Islands",
        "id": 154,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -59.51,
            -51.4
        ],
        "code": "fk"
    },
    {
        "country": "斐济群岛",
        "countryEn": "Fiji",
        "id": 205,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            178.3,
            -18.06
        ],
        "code": "fj"
    },
    {
        "country": "芬兰",
        "countryEn": "Finland",
        "id": 159,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            25.03,
            60.15
        ],
        "code": "fi"
    },
    {
        "country": "法国",
        "countryEn": "France",
        "id": 174,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            2.2,
            48.5
        ],
        "code": "fr"
    },
    {
        "country": "法属圭亚那",
        "countryEn": "French Guiana",
        "id": 144,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -52.18,
            5.05
        ],
        "code": "gf"
    },
    {
        "country": "法属波利尼西亚",
        "countryEn": "French Polynesia",
        "id": 221,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -149.34,
            -17.32
        ],
        "code": "pf"
    },
    {
        "country": "加蓬",
        "countryEn": "Gabon",
        "id": 33,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            9.26,
            0.25
        ],
        "code": "ga"
    },
    {
        "country": "冈比亚",
        "countryEn": "Gambia",
        "id": 10,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -16.4,
            13.28
        ],
        "code": "gm"
    },
    {
        "country": "格鲁吉亚",
        "countryEn": "Georgia",
        "id": 65,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            44.5,
            41.43
        ],
        "code": "ge"
    },
    {
        "country": "德国",
        "countryEn": "Germany",
        "id": 170,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            13.25,
            52.3
        ],
        "code": "de"
    },
    {
        "country": "加纳",
        "countryEn": "Ghana",
        "id": 27,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -0.06,
            5.35
        ],
        "code": "gh"
    },
    {
        "country": "希腊",
        "countryEn": "Greece",
        "id": 194,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            23.46,
            37.58
        ],
        "code": "gr"
    },
    {
        "country": "格陵兰岛",
        "countryEn": "Greenland",
        "id": 103,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -51.43,
            64.1
        ],
        "code": "gl"
    },
    {
        "country": "格林纳达",
        "countryEn": "Grenada",
        "id": 136,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.44,
            12.04
        ],
        "code": "gd"
    },
    {
        "country": "瓜德罗普",
        "countryEn": "Guadeloupe",
        "id": 129,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.44,
            16
        ],
        "code": "gp"
    },
    {
        "country": "关岛",
        "countryEn": "Guam",
        "id": 212,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            144.45,
            13.29
        ],
        "code": "gu"
    },
    {
        "country": "危地马拉",
        "countryEn": "Guatemala",
        "id": 108,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -90.22,
            14.4
        ],
        "code": "gt"
    },
    {
        "country": "几内亚",
        "countryEn": "Guinea",
        "id": 12,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -13.49,
            9.29
        ],
        "code": "gn"
    },
    {
        "country": "几内亚比绍",
        "countryEn": "Guinea-Bissau",
        "id": 11,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -15.45,
            11.45
        ],
        "code": "gw"
    },
    {
        "country": "圭亚那",
        "countryEn": "Guyana",
        "id": 142,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -58.12,
            6.5
        ],
        "code": "gy"
    },
    {
        "country": "海地",
        "countryEn": "Haiti",
        "id": 121,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -72.2,
            18.4
        ],
        "code": "ht"
    },
    {
        "country": "荷兰",
        "countryEn": "Holland",
        "id": 171,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            4.54,
            52.23
        ],
        "code": "nl"
    },
    {
        "country": "洪都拉斯",
        "countryEn": "Honduras",
        "id": 111,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -87.14,
            14.05
        ],
        "code": "hn"
    },
    {
        "country": "中国香港",
        "countryEn": "Hongkong",
        "id": 223,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            114.15,
            22.15
        ],
        "code": "hk"
    },
    {
        "country": "匈牙利",
        "countryEn": "Hungary",
        "id": 184,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            19.05,
            47.29
        ],
        "code": "hu"
    },
    {
        "country": "冰岛",
        "countryEn": "Iceland",
        "id": 155,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -21.57,
            64.1
        ],
        "code": "is"
    },
    {
        "country": "印度",
        "countryEn": "India",
        "id": 86,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            77.13,
            28.37
        ],
        "code": "in"
    },
    {
        "country": "印度尼西亚",
        "countryEn": "Indonesia",
        "id": 101,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            106.49,
            -6.09
        ],
        "code": "id"
    },
    {
        "country": "伊朗",
        "countryEn": "Iran",
        "id": 83,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            51.3,
            35.44
        ],
        "code": "ir"
    },
    {
        "country": "伊拉克",
        "countryEn": "Iraq",
        "id": 72,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            44.3,
            33.2
        ],
        "code": "iq"
    },
    {
        "country": "爱尔兰",
        "countryEn": "Ireland",
        "id": 160,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -6.15,
            53.21
        ],
        "code": "ie"
    },
    {
        "country": "以色列",
        "countryEn": "Israel",
        "id": 76,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            35.12,
            31.47
        ],
        "code": "il"
    },
    {
        "country": "意大利",
        "countryEn": "Italy",
        "id": 181,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.29,
            41.54
        ],
        "code": "it"
    },
    {
        "country": "牙买加",
        "countryEn": "Jamaica",
        "id": 120,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -76.5,
            18
        ],
        "code": "jm"
    },
    {
        "country": "日本",
        "countryEn": "Japan",
        "id": 59,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            139.44,
            35.41
        ],
        "code": "jp"
    },
    {
        "country": "约旦",
        "countryEn": "Jordan",
        "id": 74,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            35.52,
            31.57
        ],
        "code": "jo"
    },
    {
        "country": "柬埔寨",
        "countryEn": "Kampuchea Cambodia",
        "id": 95,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            104.55,
            11.33
        ],
        "code": "kh"
    },
    {
        "country": "哈萨克斯坦",
        "countryEn": "Kazakhstan",
        "id": 60,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            71.3,
            51.1
        ],
        "code": "kz"
    },
    {
        "country": "肯尼亚",
        "countryEn": "Kenya",
        "id": 39,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            36.48,
            -1.17
        ],
        "code": "ke"
    },
    {
        "country": "基里巴斯",
        "countryEn": "Kiribati",
        "id": 206,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            173,
            1.3
        ],
        "code": "ki"
    },
    {
        "country": "朝鲜",
        "countryEn": "Korea",
        "id": 57,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            125.3,
            39.09
        ],
        "code": "kp"
    },
    {
        "country": "科威特",
        "countryEn": "Kuwait",
        "id": 73,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            48,
            29.3
        ],
        "code": "kw"
    },
    {
        "country": "吉尔吉斯斯坦",
        "countryEn": "Kyrgyzstan",
        "id": 63,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            74.46,
            42.54
        ],
        "code": "kg"
    },
    {
        "country": "老挝",
        "countryEn": "Laos",
        "id": 94,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            102.36,
            17.58
        ],
        "code": "la"
    },
    {
        "country": "拉脱维亚",
        "countryEn": "Latvia",
        "id": 164,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            24.08,
            56.53
        ],
        "code": "lv"
    },
    {
        "country": "黎巴嫩",
        "countryEn": "Lebanon",
        "id": 71,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            35.31,
            33.53
        ],
        "code": "lb"
    },
    {
        "country": "莱索托",
        "countryEn": "Lesotho",
        "id": 50,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            27.3,
            -29.18
        ],
        "code": "ls"
    },
    {
        "country": "利比里亚",
        "countryEn": "Liberia",
        "id": 30,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -10.47,
            6.18
        ],
        "code": "lr"
    },
    {
        "country": "利比亚",
        "countryEn": "Libya",
        "id": 2,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            13.07,
            32.49
        ],
        "code": "ly"
    },
    {
        "country": "列支敦士登",
        "countryEn": "Liechtenstein",
        "id": 179,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            9.31,
            47.08
        ],
        "code": "li"
    },
    {
        "country": "立陶宛",
        "countryEn": "Lithuania",
        "id": 165,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            25.19,
            54.38
        ],
        "code": "lt"
    },
    {
        "country": "卢森堡",
        "countryEn": "Luxembourg",
        "id": 173,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            6.09,
            49.37
        ],
        "code": "lu"
    },
    {
        "country": "中国澳门",
        "countryEn": "Macao",
        "id": 224,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            113.5,
            22.2
        ],
        "code": "mo"
    },
    {
        "country": "马其顿",
        "countryEn": "Macedonia",
        "id": 192,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            21.26,
            42.01
        ],
        "code": "mk"
    },
    {
        "country": "马达加斯加",
        "countryEn": "Madagascar",
        "id": 53,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            47.31,
            -18.55
        ],
        "code": "mg"
    },
    {
        "country": "马拉维",
        "countryEn": "Malawi",
        "id": 42,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            33.48,
            -14
        ],
        "code": "mw"
    },
    {
        "country": "马来西亚",
        "countryEn": "Malaysia",
        "id": 97,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            101.41,
            3.09
        ],
        "code": "my"
    },
    {
        "country": "马尔代夫",
        "countryEn": "Maldives",
        "id": 88,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            73.28,
            4
        ],
        "code": "mv"
    },
    {
        "country": "马里",
        "countryEn": "Mali",
        "id": 13,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -7.55,
            12.34
        ],
        "code": "ml"
    },
    {
        "country": "马耳他",
        "countryEn": "Malta",
        "id": 198,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            14.31,
            35.54
        ],
        "code": "mt"
    },
    {
        "country": "马绍尔群岛",
        "countryEn": "Marshall Islands",
        "id": 209,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            171.12,
            7.09
        ],
        "code": "mh"
    },
    {
        "country": "马提尼克",
        "countryEn": "Martinique",
        "id": 131,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.02,
            14.36
        ],
        "code": "mq"
    },
    {
        "country": "毛里塔尼亚",
        "countryEn": "Mauritania",
        "id": 7,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            57.3,
            -20.1
        ],
        "code": "mr"
    },
    {
        "country": "毛里求斯",
        "countryEn": "Mauritius",
        "id": 54,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            57.5,
            -20
        ],
        "code": "mu"
    },
    {
        "country": "墨西哥",
        "countryEn": "Mexico",
        "id": 107,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -99.1,
            19.2
        ],
        "code": "mx"
    },
    {
        "country": "密克罗尼西亚",
        "countryEn": "Micronesia",
        "id": 208,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            158.09,
            6.55
        ],
        "code": "fm"
    },
    {
        "country": "摩尔多瓦",
        "countryEn": "Moldova",
        "id": 186,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            28.5,
            47.02
        ],
        "code": "md"
    },
    {
        "country": "摩纳哥",
        "countryEn": "Monaco",
        "id": 175,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            7.25,
            43.44
        ],
        "code": "mc"
    },
    {
        "country": "蒙古",
        "countryEn": "Mongolia",
        "id": 56,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            106.92,
            47.92
        ],
        "code": "mn"
    },
    {
        "country": "蒙特塞拉特",
        "countryEn": "Montserrat",
        "id": 127,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -62.13,
            16.47
        ],
        "code": "ms"
    },
    {
        "country": "摩洛哥",
        "countryEn": "Morocco",
        "id": 5,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -7.09,
            31.79
        ],
        "code": "ma"
    },
    {
        "country": "莫桑比克",
        "countryEn": "Mozambique",
        "id": 41,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            32.32,
            -25.58
        ],
        "code": "mz"
    },
    {
        "country": "纳米比亚",
        "countryEn": "Namibia",
        "id": 45,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            17.04,
            -22.35
        ],
        "code": "na"
    },
    {
        "country": "瑙鲁",
        "countryEn": "Nauru",
        "id": 207,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            166.55,
            -0.33
        ],
        "code": "nr"
    },
    {
        "country": "尼泊尔",
        "countryEn": "Nepal",
        "id": 89,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            85.2,
            27.45
        ],
        "code": "np"
    },
    {
        "country": "新喀里多尼亚",
        "countryEn": "New Caledonia",
        "id": 204,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            166.3,
            -22.17
        ],
        "code": "nc"
    },
    {
        "country": "新西兰",
        "countryEn": "New Zealand",
        "id": 200,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            174.46,
            -41.19
        ],
        "code": "nz"
    },
    {
        "country": "尼加拉瓜",
        "countryEn": "Nicaragua",
        "id": 112,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -86.2,
            12.06
        ],
        "code": "ni"
    },
    {
        "country": "尼日尔",
        "countryEn": "Niger",
        "id": 15,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            2.06,
            13.27
        ],
        "code": "ne"
    },
    {
        "country": "尼日利亚",
        "countryEn": "Nigeria",
        "id": 24,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            7.32,
            9.05
        ],
        "code": "ng"
    },
    {
        "country": "纽埃",
        "countryEn": "Niue",
        "id": 217,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -169.55,
            -19.03
        ],
        "code": "nu"
    },
    {
        "country": "北马里亚纳群岛",
        "countryEn": "Northern Mariana Islands",
        "id": 211,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            145.45,
            15.12
        ],
        "code": "mp"
    },
    {
        "country": "挪威",
        "countryEn": "Norway",
        "id": 157,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            10.45,
            59.55
        ],
        "code": "no"
    },
    {
        "country": "阿曼",
        "countryEn": "Oman",
        "id": 82,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            58.36,
            23.37
        ],
        "code": "om"
    },
    {
        "country": "巴基斯坦",
        "countryEn": "Pakistan",
        "id": 85,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            73.1,
            33.4
        ],
        "code": "pk"
    },
    {
        "country": "帕劳",
        "countryEn": "Palau",
        "id": 210,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            134.28,
            7.2
        ],
        "code": "pw"
    },
    {
        "country": "巴勒斯坦",
        "countryEn": "Palestine",
        "id": 75,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            34.28,
            31.3
        ],
        "code": "ps"
    },
    {
        "country": "巴拿马",
        "countryEn": "Panama",
        "id": 115,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -79.25,
            9
        ],
        "code": "pa"
    },
    {
        "country": "巴布亚新几内亚",
        "countryEn": "Papua new guinea",
        "id": 201,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            147.08,
            -9.24
        ],
        "code": "pg"
    },
    {
        "country": "巴拉圭",
        "countryEn": "Paraguay",
        "id": 149,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -57.3,
            -25.1
        ],
        "code": "py"
    },
    {
        "country": "秘鲁",
        "countryEn": "Peru",
        "id": 146,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -77,
            -12
        ],
        "code": "pe"
    },
    {
        "country": "菲律宾",
        "countryEn": "Philippines",
        "id": 100,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            121.03,
            14.4
        ],
        "code": "ph"
    },
    {
        "country": "皮特凯恩群岛",
        "countryEn": "Pitcairn Islands",
        "id": 222,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -130.06,
            -25.04
        ],
        "code": "pn"
    },
    {
        "country": "波兰",
        "countryEn": "Poland",
        "id": 169,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            21,
            52.13
        ],
        "code": "pl"
    },
    {
        "country": "波多黎各",
        "countryEn": "Porto Rico",
        "id": 123,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -66.07,
            18.28
        ],
        "code": "pr"
    },
    {
        "country": "葡萄牙",
        "countryEn": "Portugal",
        "id": 197,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -9.1,
            38.42
        ],
        "code": "pt"
    },
    {
        "country": "卡塔尔",
        "countryEn": "Qatar",
        "id": 79,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            51.35,
            25.15
        ],
        "code": "qa"
    },
    {
        "country": "罗马尼亚",
        "countryEn": "Romania",
        "id": 187,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            26.1,
            44.27
        ],
        "code": "ro"
    },
    {
        "country": "俄罗斯",
        "countryEn": "Russia",
        "id": 167,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            37.35,
            55.45
        ],
        "code": "ru"
    },
    {
        "country": "卢旺达",
        "countryEn": "Rwanda",
        "id": 37,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            30.04,
            -1.59
        ],
        "code": "rw"
    },
    {
        "country": "圣马力诺",
        "countryEn": "San Marino",
        "id": 180,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.3,
            43.55
        ],
        "code": "sm"
    },
    {
        "country": "圣多美和普林西比",
        "countryEn": "Sao Tome and Principe",
        "id": 32,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            6.39,
            0.1
        ],
        "code": "st"
    },
    {
        "country": "沙特阿拉伯",
        "countryEn": "Saudi Arabia",
        "id": 77,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            46.42,
            24.41
        ],
        "code": "sa"
    },
    {
        "country": "塞内加尔",
        "countryEn": "Senegal",
        "id": 8,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -17.29,
            14.34
        ],
        "code": "sn"
    },
    {
        "country": "塞尔维亚和黑山（塞黑）",
        "countryEn": "Serbia and Montenegro",
        "id": 188,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            21,
            44.01
        ],
        "code": "rs"
    },
    {
        "country": "塞舌尔",
        "countryEn": "Seychelles",
        "id": 51,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            55.28,
            -4.4
        ],
        "code": "sc"
    },
    {
        "country": "塞拉利昂",
        "countryEn": "Sierra Leone",
        "id": 29,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -13.17,
            8.3
        ],
        "code": "sl"
    },
    {
        "country": "新加坡",
        "countryEn": "Singapore",
        "id": 98,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            103.51,
            1.18
        ],
        "code": "sg"
    },
    {
        "country": "斯洛伐克",
        "countryEn": "Slovakia",
        "id": 177,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            17.07,
            48.1
        ],
        "code": "sk"
    },
    {
        "country": "斯洛文尼亚",
        "countryEn": "Slovenia",
        "id": 185,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            14.33,
            46.04
        ],
        "code": "si"
    },
    {
        "country": "所罗门群岛",
        "countryEn": "Solomon",
        "id": 202,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            159.57,
            -9.27
        ],
        "code": "sb"
    },
    {
        "country": "索马里",
        "countryEn": "Somalia",
        "id": 20,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            45.25,
            2.02
        ],
        "code": "so"
    },
    {
        "country": "南非",
        "countryEn": "South Africa",
        "id": 48,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            22.93,
            -30.55
        ],
        "code": "za"
    },
    {
        "country": "韩国",
        "countryEn": "South Korea",
        "id": 58,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            126.58,
            37.31
        ],
        "code": "kr"
    },
    {
        "country": "西班牙",
        "countryEn": "Spain",
        "id": 196,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -3.45,
            40.25
        ],
        "code": "es"
    },
    {
        "country": "斯里兰卡",
        "countryEn": "Sri Lanka",
        "id": 87,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            80.77,
            7.87
        ],
        "code": "lk"
    },
    {
        "country": "圣其茨和尼维斯",
        "countryEn": "St. Kitts & Nevis",
        "id": 139,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -62.43,
            17.18
        ],
        "code": "kn"
    },
    {
        "country": "圣卢西亚",
        "countryEn": "St. Lucia ",
        "id": 132,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -60.58,
            14.02
        ],
        "code": "lc"
    },
    {
        "country": "圣皮埃尔和密克隆",
        "countryEn": "St. Pierre and Miquelon",
        "id": 105,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -56.12,
            46.46
        ],
        "code": "pm"
    },
    {
        "country": "圣文森特和格林纳丁斯",
        "countryEn": "St. Vincent and the Grenadines ",
        "id": 133,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -61.1,
            13.1
        ],
        "code": "vc"
    },
    {
        "country": "苏丹",
        "countryEn": "Sudan",
        "id": 17,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            32.35,
            15.31
        ],
        "code": "sd"
    },
    {
        "country": "苏里南",
        "countryEn": "Suriname",
        "id": 143,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -55.1,
            5.5
        ],
        "code": "sr"
    },
    {
        "country": "斯威士兰",
        "countryEn": "Swaziland",
        "id": 49,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            31.06,
            -26.18
        ],
        "code": "sz"
    },
    {
        "country": "瑞典",
        "countryEn": "Sweden",
        "id": 158,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            18.03,
            59.2
        ],
        "code": "se"
    },
    {
        "country": "瑞士",
        "countryEn": "Switzerland",
        "id": 183,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            7.28,
            46.57
        ],
        "code": "ch"
    },
    {
        "country": "叙利亚",
        "countryEn": "Syria",
        "id": 70,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            36.18,
            33.3
        ],
        "code": "sy"
    },
    {
        "country": "中国台湾",
        "countryEn": "Taiwan",
        "id": 225,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            121.31,
            25.03
        ],
        "code": "tw"
    },
    {
        "country": "塔吉克斯坦",
        "countryEn": "Tajikistan",
        "id": 64,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            68.48,
            38.33
        ],
        "code": "tj"
    },
    {
        "country": "坦桑尼亚",
        "countryEn": "Tanzania",
        "id": 40,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            35.45,
            -6.08
        ],
        "code": "tz"
    },
    {
        "country": "泰国",
        "countryEn": "Thailand",
        "id": 93,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            100.35,
            13.45
        ],
        "code": "th"
    },
    {
        "country": "英属维尔京群岛",
        "countryEn": "The British Virgin Islands ",
        "id": 125,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -64.37,
            18.27
        ],
        "code": "vg"
    },
    {
        "country": "刚果（扎伊尔）",
        "countryEn": "The Democratic Republic Of Congo （Zaire）",
        "id": 35,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            15.15,
            -4.2
        ],
        "code": "cd"
    },
    {
        "country": "美属维尔京群岛",
        "countryEn": "The United States Virgin Islands ",
        "id": 124,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -64.56,
            18.21
        ],
        "code": "vi"
    },
    {
        "country": "多哥",
        "countryEn": "Togo",
        "id": 26,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            1.2,
            6.09
        ],
        "code": "tg"
    },
    {
        "country": "托克劳",
        "countryEn": "Tokelau",
        "id": 218,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -172.5,
            -9.5
        ],
        "code": "tk"
    },
    {
        "country": "汤加",
        "countryEn": "Tonga",
        "id": 220,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -174,
            -21.1
        ],
        "code": "to"
    },
    {
        "country": "特立尼达和多巴哥",
        "countryEn": "Trinidad and Tobago ",
        "id": 137,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -60.32,
            11.18
        ],
        "code": "tt"
    },
    {
        "country": "突尼斯",
        "countryEn": "Tunisia",
        "id": 3,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            10.11,
            36.5
        ],
        "code": "tn"
    },
    {
        "country": "土耳其",
        "countryEn": "Turkey",
        "id": 68,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            32.54,
            39.57
        ],
        "code": "tr"
    },
    {
        "country": "土库曼斯坦",
        "countryEn": "Turkmenistan",
        "id": 61,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            57.5,
            38
        ],
        "code": "tm"
    },
    {
        "country": "特克斯和凯科斯群岛",
        "countryEn": "Turks and Caicos Islands ",
        "id": 117,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -71.08,
            21.28
        ],
        "code": "tc"
    },
    {
        "country": "图瓦卢",
        "countryEn": "Tuvalu",
        "id": 213,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            179.13,
            -8.31
        ],
        "code": "tv"
    },
    {
        "country": "乌干达",
        "countryEn": "Uganda",
        "id": 36,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            32.3,
            0.2
        ],
        "code": "ug"
    },
    {
        "country": "乌克兰",
        "countryEn": "Ukraine",
        "id": 168,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            30.28,
            50.3
        ],
        "code": "ua"
    },
    {
        "country": "阿拉伯联合酋长国",
        "countryEn": "United Arab Emirates",
        "id": 80,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            54.22,
            24.28
        ],
        "code": "ae"
    },
    {
        "country": "英国",
        "countryEn": "United Kingdom",
        "id": 161,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            -0.05,
            51.36
        ],
        "code": "gb"
    },
    {
        "country": "乌拉圭",
        "countryEn": "Uruguay",
        "id": 152,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -56.11,
            -34.5
        ],
        "code": "uy"
    },
    {
        "country": "美国",
        "countryEn": "United States of America",
        "id": 106,
        "region": "北美洲",
        "regionEn": "North America",
        "regionId": "3",
        "coordinate": [
            -77.02,
            39.91
        ],
        "code": "us"
    },
    {
        "country": "乌兹别克斯坦",
        "countryEn": "Uzbekistan",
        "id": 62,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            69.1,
            41.2
        ],
        "code": "uz"
    },
    {
        "country": "瓦努阿图",
        "countryEn": "Vanuatu",
        "id": 203,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            168.18,
            -17.45
        ],
        "code": "vu"
    },
    {
        "country": "梵蒂冈",
        "countryEn": "Vatican",
        "id": 182,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            12.27,
            41.54
        ],
        "code": "va"
    },
    {
        "country": "委内瑞拉",
        "countryEn": "Venezuela",
        "id": 140,
        "region": "南美洲",
        "regionEn": "South America",
        "regionId": "4",
        "coordinate": [
            -66.55,
            10.3
        ],
        "code": "ve"
    },
    {
        "country": "越南",
        "countryEn": "Vietnam",
        "id": 96,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            105.55,
            21.05
        ],
        "code": "vn"
    },
    {
        "country": "瓦利斯及富图纳",
        "countryEn": "Wallis Islands and Futuna Islands",
        "id": 214,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -176.1,
            -13.2
        ],
        "code": "wf"
    },
    {
        "country": "西撒哈拉",
        "countryEn": "Western Sahara",
        "id": 6,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            -12.88,
            24.21
        ],
        "code": "eh"
    },
    {
        "country": "西萨摩亚",
        "countryEn": "Western Samoa",
        "id": 215,
        "region": "大洋洲",
        "regionEn": "Oceania",
        "regionId": "6",
        "coordinate": [
            -171.5,
            -13.5
        ],
        "code": "ws"
    },
    {
        "country": "白俄罗斯",
        "countryEn": "White Russia",
        "id": 166,
        "region": "欧洲",
        "regionEn": "Europe",
        "regionId": "2",
        "coordinate": [
            27.3,
            53.52
        ],
        "code": "by"
    },
    {
        "country": "也门",
        "countryEn": "Yemen",
        "id": 81,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            48.51,
            15.55
        ],
        "code": "ye"
    },
    {
        "country": "赞比亚",
        "countryEn": "Zambia",
        "id": 43,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            28.16,
            -15.28
        ],
        "code": "zm"
    },
    {
        "country": "津巴布韦",
        "countryEn": "Zimbabwe",
        "id": 47,
        "region": "非洲",
        "regionEn": "Africa",
        "regionId": "5",
        "coordinate": [
            31.02,
            -17.43
        ],
        "code": "zw"
    }
]

var ChinaCountryArr = [
    {
    "country": "其他省市",
    "countryEn": "Other",
    "id": 55,
    "region": "亚洲",
    "regionEn": "Asia",
    "regionId": "1",
    "coordinate": [
        116.20,
        39.55
    ],
    "code": "cn"
},
    {
        "country": "广东",
        "countryEn": "Guangdong",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "新疆",
        "countryEn": "Xinjiang",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "广西",
        "countryEn": "Guangxi",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "福建",
        "countryEn": "Fujian",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "湖北",
        "countryEn": "Hubei",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "陕西",
        "countryEn": "Shaanxi",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "四川",
        "countryEn": "Sichuan",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "重庆",
        "countryEn": "Chongqing",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "河南",
        "countryEn": "Henan",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "安徽",
        "countryEn": "Anhui",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "江西",
        "countryEn": "Jiangxi",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "山西",
        "countryEn": "Shanxi",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "云南",
        "countryEn": "Yunnan",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "贵州",
        "countryEn": "Guizhou",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "西藏",
        "countryEn": "Xizang",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "江苏",
        "countryEn": "Jiangsu",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "浙江",
        "countryEn": "Zhejiang",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "上海",
        "countryEn": "Shanghai",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "北京",
        "countryEn": "Beijing",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "天津",
        "countryEn": "Tianjin",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "河北",
        "countryEn": "Hebei",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "辽宁",
        "countryEn": "Liaoning",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "山东",
        "countryEn": "Shandong",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "甘肃",
        "countryEn": "Gansu",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "青海",
        "countryEn": "Qinghai",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "内蒙古",
        "countryEn": "Neimenggu",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "宁夏",
        "countryEn": "Ningxia",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "黑龙江",
        "countryEn": "Heilongjiang",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "吉林",
        "countryEn": "Jilin",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "湖南",
        "countryEn": "Hunan",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    },
    {
        "country": "海南",
        "countryEn": "Hainan",
        "id": 55,
        "region": "亚洲",
        "regionEn": "Asia",
        "regionId": "1",
        "coordinate": [
            116.20,
            39.55
        ],
        "code": "cn"
    }]

export function getAllCountry(params) {
    return countryArrSort
}

export function getAllCountry1(params) {
    countryArrSort = [...countryArrSort, ...ChinaCountryArr]
    countryArrSort.filter(item => {
        item.value = item.country + '-' + item.countryEn+'-'+item.code
    })
    return countryArrSort
}

export function getAllProduct() {
    var productArr = [

            {
                name: "鞋类",
                type: [
                    {
                        name: "男鞋",
                        products: [
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                        ],
                    },
                    {
                        name: "女鞋",
                        products: [
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                            {
                                name: "运动鞋",
                                url: require("../../public/images/nanxie_yundongxie.jpg"),
                            },
                        ],
                    },
                ],
            },
            {
                name: "男装",
                type: [
                    {
                        name: "外套",
                        products: [
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                        ],
                    },
                    {
                        name: "上衣",
                        products: [
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },

                        ],
                    },
                    {
                        name: "裤装",
                        products: [
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            }
                        ],
                    },
                    {
                        name: "运动健身",
                        products: [
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                            {
                                name: "棒球服",
                                url: require("../../public/images/waitao_bangqiufu.jpg"),
                            },
                        ],
                    },
                ],
            },
            {
                name: "女装",
                type: [
                    {
                        name: "外套",
                        products: [
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                        ],
                    },
                    {
                        name: "上衣",
                        products: [
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                        ],
                    },
                    {
                        name: "裙装",
                        products: [
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                        ],
                    },
                    {
                        name: "裤装",
                        products: [
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                        ],
                    },
                    {
                        name: "运动健身",
                        products: [
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                        ],
                    },
                    {
                        name: "三坑服饰",
                        products: [
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                            {
                                name: "夹克",
                                url: require("../../public/images/waitao_jiake.jpg"),
                            },
                        ],
                    },
                ],
            },
            {
                name: "包装",
                type: [
                    {
                        name: "出行背包",
                        products: [
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                        ],
                    },
                    {
                        name: "时尚手袋",
                        products: [
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                        ],
                    },
                    {
                        name: "旅行系列",
                        products: [
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                        ],
                    },
                    {
                        name: "小型包袋",
                        products: [
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                        ],
                    },
                    {
                        name: "箱包配件",
                        products: [
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            },
                            {
                                name: "洗漱包",
                                url: require("../../public/images/xishubao.jpg"),
                            }
                        ],
                    },
                ],
            },
            {
                name: "首饰配饰",
                type: [
                    {
                        name: "首饰",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                    {
                        name: "腰带",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                    {
                        name: "眼镜",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                    {
                        name: "帽子",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                    {
                        name: "围巾",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                    {
                        name: "配件",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                    {
                        name: "发饰",
                        products: [
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                            {
                                name: "腰链",
                                url: require("../../public/images/yaodai_yaolian.jpg"),
                            },
                        ],
                    },
                ],
            },

        {
            name: "鞋类",
            type: [
                {
                    name: "男鞋",
                    products: [
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                    ],
                },
                {
                    name: "女鞋",
                    products: [
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                        {
                            name: "运动鞋",
                            url: require("../../public/images/nanxie_yundongxie.jpg"),
                        },
                    ],
                },
            ],
        },
        {
            name: "男装",
            type: [
                {
                    name: "外套",
                    products: [
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                    ],
                },
                {
                    name: "上衣",
                    products: [
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },

                    ],
                },
                {
                    name: "裤装",
                    products: [
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        }
                    ],
                },
                {
                    name: "运动健身",
                    products: [
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                        {
                            name: "棒球服",
                            url: require("../../public/images/waitao_bangqiufu.jpg"),
                        },
                    ],
                },
            ],
        },
        {
            name: "女装",
            type: [
                {
                    name: "外套",
                    products: [
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                    ],
                },
                {
                    name: "上衣",
                    products: [
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                    ],
                },
                {
                    name: "裙装",
                    products: [
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                    ],
                },
                {
                    name: "裤装",
                    products: [
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                    ],
                },
                {
                    name: "运动健身",
                    products: [
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                    ],
                },
                {
                    name: "三坑服饰",
                    products: [
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                        {
                            name: "夹克",
                            url: require("../../public/images/waitao_jiake.jpg"),
                        },
                    ],
                },
            ],
        },
        {
            name: "包装",
            type: [
                {
                    name: "出行背包",
                    products: [
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                    ],
                },
                {
                    name: "时尚手袋",
                    products: [
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                    ],
                },
                {
                    name: "旅行系列",
                    products: [
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                    ],
                },
                {
                    name: "小型包袋",
                    products: [
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                    ],
                },
                {
                    name: "箱包配件",
                    products: [
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        },
                        {
                            name: "洗漱包",
                            url: require("../../public/images/xishubao.jpg"),
                        }
                    ],
                },
            ],
        },
        {
            name: "首饰配饰",
            type: [
                {
                    name: "首饰",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
                {
                    name: "腰带",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
                {
                    name: "眼镜",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
                {
                    name: "帽子",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
                {
                    name: "围巾",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
                {
                    name: "配件",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
                {
                    name: "发饰",
                    products: [
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                        {
                            name: "腰链",
                            url: require("../../public/images/yaodai_yaolian.jpg"),
                        },
                    ],
                },
            ],
        },
    ]
    return productArr
}

export function getAllCountryByGroup() {
    var countryByGroup = [
        {
            "name": "HOT",
            "valueArr": [
                {
                    "country": "美国",
                    "countryEn": "United States of America",
                    "id": 106,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -77.02,
                        39.91
                    ],
                    "code": "us"
                },
                {
                    "country": "英国",
                    "countryEn": "United Kingdom",
                    "id": 161,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -0.05,
                        51.36
                    ],
                    "code": "gb"
                },
                {
                    "country": "加拿大",
                    "countryEn": "Canada",
                    "id": 104,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -75.42,
                        45.27
                    ],
                    "code": "ca"
                },
                {
                    "country": "澳大利亚",
                    "countryEn": "Australia",
                    "id": 199,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        149.08,
                        -35.15
                    ],
                    "code": "au"
                },
                {
                    "country": "德国",
                    "countryEn": "Germany",
                    "id": 170,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        13.25,
                        52.3
                    ],
                    "code": "de"
                },
                {
                    "country": "法国",
                    "countryEn": "France",
                    "id": 174,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        2.2,
                        48.5
                    ],
                    "code": "fr"
                },
                {
                    "country": "荷兰",
                    "countryEn": "Holland",
                    "id": 171,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        4.54,
                        52.23
                    ],
                    "code": "nl"
                },
                {
                    "country": "波兰",
                    "countryEn": "Poland",
                    "id": 169,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        21,
                        52.13
                    ],
                    "code": "pl"
                },
                {
                    "country": "西班牙",
                    "countryEn": "Spain",
                    "id": 196,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -3.45,
                        40.25
                    ],
                    "code": "es"
                },
                {
                    "country": "爱尔兰",
                    "countryEn": "Ireland",
                    "id": 160,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -6.15,
                        53.21
                    ],
                    "code": "ie"
                },
                {
                    "country": "意大利",
                    "countryEn": "Italy",
                    "id": 181,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        12.29,
                        41.54
                    ],
                    "code": "it"
                },
                {
                    "country": "新西兰",
                    "countryEn": "New Zealand",
                    "id": 200,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        174.46,
                        -41.19
                    ],
                    "code": "nz"
                },
                {
                    "country": "瑞典",
                    "countryEn": "Sweden",
                    "id": 158,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        18.03,
                        59.2
                    ],
                    "code": "se"
                },
                {
                    "country": "挪威",
                    "countryEn": "Norway",
                    "id": 157,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        10.45,
                        59.55
                    ],
                    "code": "no"
                },
                {
                    "country": "比利时",
                    "countryEn": "Belgium",
                    "id": 172,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        4.21,
                        50.51
                    ],
                    "code": "be"
                },
                {
                    "country": "墨西哥",
                    "countryEn": "Mexico",
                    "id": 107,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -99.1,
                        19.2
                    ],
                    "code": "mx"
                },
                {
                    "country": "日本",
                    "countryEn": "Japan",
                    "id": 59,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        139.44,
                        35.41
                    ],
                    "code": "jp"
                },
                {
                    "country": "巴西",
                    "countryEn": "Brazil",
                    "id": 147,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -47.55,
                        -15.47
                    ],
                    "code": "br"
                },
                {
                    "country": "以色列",
                    "countryEn": "Israel",
                    "id": 76,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        35.12,
                        31.47
                    ],
                    "code": "il"
                },
                {
                    "country": "丹麦",
                    "countryEn": "Denmark",
                    "id": 162,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        12.34,
                        55.41
                    ],
                    "code": "dk"
                },
                {
                    "country": "瑞士",
                    "countryEn": "Switzerland",
                    "id": 183,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        7.28,
                        46.57
                    ],
                    "code": "ch"
                },
                {
                    "country": "罗马尼亚",
                    "countryEn": "Romania",
                    "id": 187,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        26.1,
                        44.27
                    ],
                    "code": "ro"
                }
            ]
        },
        {
            "name": "ABC",
            "valueArr": [
                {
                    "country": "阿富汗",
                    "countryEn": "Afghanistan",
                    "id": 84,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        69.11,
                        34.28
                    ],
                    "code": "af",
                    "value": "阿富汗-Afghanistan"
                },
                {
                    "country": "阿尔巴尼亚",
                    "countryEn": "Albania",
                    "id": 193,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        19.49,
                        41.18
                    ],
                    "code": "al",
                    "value": "阿尔巴尼亚-Albania"
                },
                {
                    "country": "阿尔及利亚",
                    "countryEn": "Algeria",
                    "id": 4,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        3.08,
                        36.42
                    ],
                    "code": "dz",
                    "value": "阿尔及利亚-Algeria"
                },
                {
                    "country": "美属萨摩亚",
                    "countryEn": "American Samoa",
                    "id": 216,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -170.43,
                        -14.16
                    ],
                    "code": "as",
                    "value": "美属萨摩亚-American Samoa"
                },
                {
                    "country": "安道尔",
                    "countryEn": "Andorra",
                    "id": 195,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        1.32,
                        42.31
                    ],
                    "code": "ad",
                    "value": "安道尔-Andorra"
                },
                {
                    "country": "安哥拉",
                    "countryEn": "Angola",
                    "id": 44,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        13.15,
                        -8.5
                    ],
                    "code": "ao",
                    "value": "安哥拉-Angola"
                },
                {
                    "country": "安圭拉",
                    "countryEn": "Anguilla",
                    "id": 126,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -63.05,
                        18.22
                    ],
                    "code": "ai",
                    "value": "安圭拉-Anguilla"
                },
                {
                    "country": "安提瓜和巴布达",
                    "countryEn": "Antigua and Barbuda ",
                    "id": 128,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.48,
                        17.2
                    ],
                    "code": "ag",
                    "value": "安提瓜和巴布达-Antigua and Barbuda "
                },
                {
                    "country": "安的列斯",
                    "countryEn": "Antilles",
                    "id": 138,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -69,
                        12.05
                    ],
                    "code": "xx",
                    "value": "安的列斯-Antilles"
                },
                {
                    "country": "阿根廷",
                    "countryEn": "Argentina",
                    "id": 151,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -60,
                        -36.3
                    ],
                    "code": "ar",
                    "value": "阿根廷-Argentina"
                },
                {
                    "country": "亚美尼亚",
                    "countryEn": "Armenia",
                    "id": 66,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        44.31,
                        40.1
                    ],
                    "code": "am",
                    "value": "亚美尼亚-Armenia"
                },
                {
                    "country": "阿鲁巴",
                    "countryEn": "Aruba",
                    "id": 134,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -70.02,
                        12.32
                    ],
                    "code": "aw",
                    "value": "阿鲁巴-Aruba"
                },
                {
                    "country": "澳大利亚",
                    "countryEn": "Australia",
                    "id": 199,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        149.08,
                        -35.15
                    ],
                    "code": "au",
                    "value": "澳大利亚-Australia"
                },
                {
                    "country": "奥地利",
                    "countryEn": "Austria",
                    "id": 178,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        16.22,
                        48.12
                    ],
                    "code": "at",
                    "value": "奥地利-Austria"
                },
                {
                    "country": "阿塞拜疆",
                    "countryEn": "Azerbaijan",
                    "id": 67,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        49.56,
                        40.29
                    ],
                    "code": "az",
                    "value": "阿塞拜疆-Azerbaijan"
                },
                {
                    "country": "巴哈马",
                    "countryEn": "Bahamas",
                    "id": 116,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -77.2,
                        25.05
                    ],
                    "code": "bs",
                    "value": "巴哈马-Bahamas"
                },
                {
                    "country": "巴林",
                    "countryEn": "Bahrain",
                    "id": 78,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        50.3,
                        26.1
                    ],
                    "code": "bh",
                    "value": "巴林-Bahrain"
                },
                {
                    "country": "孟加拉国",
                    "countryEn": "Bangladesh",
                    "id": 91,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        90.26,
                        23.43
                    ],
                    "code": "bd",
                    "value": "孟加拉国-Bangladesh"
                },
                {
                    "country": "巴巴多斯",
                    "countryEn": "Barbados",
                    "id": 135,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -59.3,
                        13.05
                    ],
                    "code": "bb",
                    "value": "巴巴多斯-Barbados"
                },
                {
                    "country": "比利时",
                    "countryEn": "Belgium",
                    "id": 172,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        4.21,
                        50.51
                    ],
                    "code": "be",
                    "value": "比利时-Belgium"
                },
                {
                    "country": "伯利兹",
                    "countryEn": "Belize",
                    "id": 109,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -88.3,
                        17.18
                    ],
                    "code": "bz",
                    "value": "伯利兹-Belize"
                },
                {
                    "country": "贝宁",
                    "countryEn": "Benin",
                    "id": 25,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        2.42,
                        6.23
                    ],
                    "code": "bj",
                    "value": "贝宁-Benin"
                },
                {
                    "country": "百慕大",
                    "countryEn": "Bermuda",
                    "id": 114,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -64.75,
                        32.3
                    ],
                    "code": "bm",
                    "value": "百慕大-Bermuda"
                },
                {
                    "country": "不丹",
                    "countryEn": "Bhutan",
                    "id": 90,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        89.45,
                        27.31
                    ],
                    "code": "bt",
                    "value": "不丹-Bhutan"
                },
                {
                    "country": "玻利维亚",
                    "countryEn": "Bolivia",
                    "id": 148,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -68.1,
                        -16.2
                    ],
                    "code": "bo",
                    "value": "玻利维亚-Bolivia"
                },
                {
                    "country": "波斯尼亚和黑塞哥维那（波黑）",
                    "countryEn": "Bosnia and Herzegovina",
                    "id": 190,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        18.26,
                        43.52
                    ],
                    "code": "ba",
                    "value": "波斯尼亚和黑塞哥维那（波黑）-Bosnia and Herzegovina"
                },
                {
                    "country": "博茨瓦纳",
                    "countryEn": "Botswana",
                    "id": 46,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        25.57,
                        -24.45
                    ],
                    "code": "bw",
                    "value": "博茨瓦纳-Botswana"
                },
                {
                    "country": "巴西",
                    "countryEn": "Brazil",
                    "id": 147,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -47.55,
                        -15.47
                    ],
                    "code": "br",
                    "value": "巴西-Brazil"
                },
                {
                    "country": "文莱",
                    "countryEn": "Brunei",
                    "id": 99,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        115,
                        4.52
                    ],
                    "code": "bn",
                    "value": "文莱-Brunei"
                },
                {
                    "country": "保加利亚",
                    "countryEn": "Bulgaria",
                    "id": 191,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        23.2,
                        42.45
                    ],
                    "code": "bg",
                    "value": "保加利亚-Bulgaria"
                },
                {
                    "country": "布基纳法索",
                    "countryEn": "Burkina Faso",
                    "id": 14,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -1.3,
                        12.15
                    ],
                    "code": "bf",
                    "value": "布基纳法索-Burkina Faso"
                },
                {
                    "country": "缅甸",
                    "countryEn": "Burma",
                    "id": 92,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        96.2,
                        16.45
                    ],
                    "code": "mm",
                    "value": "缅甸-Burma"
                },
                {
                    "country": "布隆迪",
                    "countryEn": "Burundi",
                    "id": 38,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        29.18,
                        -3.16
                    ],
                    "code": "bi",
                    "value": "布隆迪-Burundi"
                },
                {
                    "country": "喀麦隆",
                    "countryEn": "Cameroon",
                    "id": 23,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        11.35,
                        3.5
                    ],
                    "code": "cm",
                    "value": "喀麦隆-Cameroon"
                },
                {
                    "country": "加拿大",
                    "countryEn": "Canada",
                    "id": 104,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -75.42,
                        45.27
                    ],
                    "code": "ca",
                    "value": "加拿大-Canada"
                },
                {
                    "country": "佛得角",
                    "countryEn": "Cape Verde",
                    "id": 9,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -23.34,
                        15.02
                    ],
                    "code": "cv",
                    "value": "佛得角-Cape Verde"
                },
                {
                    "country": "开曼群岛",
                    "countryEn": "Cayman Islands",
                    "id": 119,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -81.24,
                        19.2
                    ],
                    "code": "ky",
                    "value": "开曼群岛-Cayman Islands"
                },
                {
                    "country": "中非",
                    "countryEn": "Central Africa",
                    "id": 22,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        18.35,
                        4.23
                    ],
                    "code": "cf",
                    "value": "中非-Central Africa"
                },
                {
                    "country": "乍得",
                    "countryEn": "Chad",
                    "id": 16,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        14.59,
                        12.1
                    ],
                    "code": "td",
                    "value": "乍得-Chad"
                },
                {
                    "country": "智利",
                    "countryEn": "Chile",
                    "id": 150,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -70.4,
                        -33.24
                    ],
                    "code": "cl",
                    "value": "智利-Chile"
                },
                {
                    "country": "中国",
                    "countryEn": "China",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn",
                    "value": "中国-China"
                },
                {
                    "country": "哥伦比亚",
                    "countryEn": "Columbia",
                    "id": 141,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -74,
                        4.34
                    ],
                    "code": "co",
                    "value": "哥伦比亚-Columbia"
                },
                {
                    "country": "科摩罗",
                    "countryEn": "Comoro",
                    "id": 52,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        43.16,
                        -11.4
                    ],
                    "code": "km",
                    "value": "科摩罗-Comoro"
                },
                {
                    "country": "刚果",
                    "countryEn": "Congo",
                    "id": 34,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        15.12,
                        -4.09
                    ],
                    "code": "cg",
                    "value": "刚果-Congo"
                },
                {
                    "country": "库克群岛",
                    "countryEn": "Cook Islands",
                    "id": 219,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -159.45,
                        -21.12
                    ],
                    "code": "ck",
                    "value": "库克群岛-Cook Islands"
                },
                {
                    "country": "哥斯达黎加",
                    "countryEn": "Costa Rica",
                    "id": 113,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -84.02,
                        9.55
                    ],
                    "code": "cr",
                    "value": "哥斯达黎加-Costa Rica"
                },
                {
                    "country": "科特迪瓦（象牙海岸）",
                    "countryEn": "Cote d’lvoire（Ivory Coast）",
                    "id": 28,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -5.17,
                        6.49
                    ],
                    "code": "ci",
                    "value": "科特迪瓦（象牙海岸）-Cote d’lvoire（Ivory Coast）"
                },
                {
                    "country": "克罗地亚",
                    "countryEn": "Croatia",
                    "id": 189,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        15.58,
                        45.5
                    ],
                    "code": "hr",
                    "value": "克罗地亚-Croatia"
                },
                {
                    "country": "古巴",
                    "countryEn": "Cuba",
                    "id": 118,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -82.22,
                        23.08
                    ],
                    "code": "cu",
                    "value": "古巴-Cuba"
                },
                {
                    "country": "塞浦路斯",
                    "countryEn": "Cyprus",
                    "id": 69,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        33.25,
                        35.1
                    ],
                    "code": "cy",
                    "value": "塞浦路斯-Cyprus"
                },
                {
                    "country": "捷克",
                    "countryEn": "Czech",
                    "id": 176,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        14.22,
                        50.05
                    ],
                    "code": "cz",
                    "value": "捷克-Czech"
                }
            ]
        },
        {
            "name": "DEF",
            "valueArr": [
                {
                    "country": "丹麦",
                    "countryEn": "Denmark",
                    "id": 162,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        12.34,
                        55.41
                    ],
                    "code": "dk",
                    "value": "丹麦-Denmark"
                },
                {
                    "country": "吉布提",
                    "countryEn": "Djibouti",
                    "id": 19,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        42.2,
                        11.08
                    ],
                    "code": "dj",
                    "value": "吉布提-Djibouti"
                },
                {
                    "country": "多米尼克",
                    "countryEn": "Dominica",
                    "id": 130,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.5,
                        15.5
                    ],
                    "code": "dm",
                    "value": "多米尼克-Dominica"
                },
                {
                    "country": "多米尼加",
                    "countryEn": "Dominican Republic",
                    "id": 122,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.24,
                        15.2
                    ],
                    "code": "do",
                    "value": "多米尼加-Dominican Republic"
                },
                {
                    "country": "东帝汶",
                    "countryEn": "East Timor",
                    "id": 102,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        125.34,
                        -8.29
                    ],
                    "code": "tl",
                    "value": "东帝汶-East Timor"
                },
                {
                    "country": "厄瓜多尔",
                    "countryEn": "Ecuador",
                    "id": 145,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -78.35,
                        -0.15
                    ],
                    "code": "ec",
                    "value": "厄瓜多尔-Ecuador"
                },
                {
                    "country": "埃及",
                    "countryEn": "Egypt",
                    "id": 1,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        31.14,
                        30.01
                    ],
                    "code": "eg",
                    "value": "埃及-Egypt"
                },
                {
                    "country": "萨尔瓦多",
                    "countryEn": "El Salvador",
                    "id": 110,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -89.1,
                        13.4
                    ],
                    "code": "sv",
                    "value": "萨尔瓦多-El Salvador"
                },
                {
                    "country": "赤道几内亚",
                    "countryEn": "Equatorial Guinea",
                    "id": 31,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        8.5,
                        3.45
                    ],
                    "code": "gq",
                    "value": "赤道几内亚-Equatorial Guinea"
                },
                {
                    "country": "厄立特里亚",
                    "countryEn": "Eritrea",
                    "id": 18,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        38.55,
                        15.19
                    ],
                    "code": "er",
                    "value": "厄立特里亚-Eritrea"
                },
                {
                    "country": "爱沙尼亚",
                    "countryEn": "Estonia",
                    "id": 163,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        24.48,
                        59.22
                    ],
                    "code": "ee",
                    "value": "爱沙尼亚-Estonia"
                },
                {
                    "country": "埃塞俄比亚",
                    "countryEn": "Ethiopia",
                    "id": 21,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        38.42,
                        9.02
                    ],
                    "code": "et",
                    "value": "埃塞俄比亚-Ethiopia"
                },
                {
                    "country": "法罗群岛（丹）",
                    "countryEn": "Faeroe Islands",
                    "id": 156,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -6.56,
                        62.05
                    ],
                    "code": "fo",
                    "value": "法罗群岛（丹）-Faeroe Islands"
                },
                {
                    "country": "马尔维纳斯群岛",
                    "countryEn": "Falkland Islands",
                    "id": 153,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -59.51,
                        -51.4
                    ],
                    "code": "fk",
                    "value": "马尔维纳斯群岛-Falkland Islands"
                },
                {
                    "country": "福克兰群岛",
                    "countryEn": "Falkland Islands",
                    "id": 154,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -59.51,
                        -51.4
                    ],
                    "code": "fk",
                    "value": "福克兰群岛-Falkland Islands"
                },
                {
                    "country": "斐济群岛",
                    "countryEn": "Fiji",
                    "id": 205,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        178.3,
                        -18.06
                    ],
                    "code": "fj",
                    "value": "斐济群岛-Fiji"
                },
                {
                    "country": "芬兰",
                    "countryEn": "Finland",
                    "id": 159,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        25.03,
                        60.15
                    ],
                    "code": "fi",
                    "value": "芬兰-Finland"
                },
                {
                    "country": "法国",
                    "countryEn": "France",
                    "id": 174,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        2.2,
                        48.5
                    ],
                    "code": "fr",
                    "value": "法国-France"
                },
                {
                    "country": "法属圭亚那",
                    "countryEn": "French Guiana",
                    "id": 144,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -52.18,
                        5.05
                    ],
                    "code": "gf",
                    "value": "法属圭亚那-French Guiana"
                },
                {
                    "country": "法属波利尼西亚",
                    "countryEn": "French Polynesia",
                    "id": 221,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -149.34,
                        -17.32
                    ],
                    "code": "pf",
                    "value": "法属波利尼西亚-French Polynesia"
                }
            ]
        },
        {
            "name": "GHI",
            "valueArr": [
                {
                    "country": "加蓬",
                    "countryEn": "Gabon",
                    "id": 33,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        9.26,
                        0.25
                    ],
                    "code": "ga",
                    "value": "加蓬-Gabon"
                },
                {
                    "country": "冈比亚",
                    "countryEn": "Gambia",
                    "id": 10,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -16.4,
                        13.28
                    ],
                    "code": "gm",
                    "value": "冈比亚-Gambia"
                },
                {
                    "country": "格鲁吉亚",
                    "countryEn": "Georgia",
                    "id": 65,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        44.5,
                        41.43
                    ],
                    "code": "ge",
                    "value": "格鲁吉亚-Georgia"
                },
                {
                    "country": "德国",
                    "countryEn": "Germany",
                    "id": 170,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        13.25,
                        52.3
                    ],
                    "code": "de",
                    "value": "德国-Germany"
                },
                {
                    "country": "加纳",
                    "countryEn": "Ghana",
                    "id": 27,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -0.06,
                        5.35
                    ],
                    "code": "gh",
                    "value": "加纳-Ghana"
                },
                {
                    "country": "希腊",
                    "countryEn": "Greece",
                    "id": 194,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        23.46,
                        37.58
                    ],
                    "code": "gr",
                    "value": "希腊-Greece"
                },
                {
                    "country": "格陵兰岛",
                    "countryEn": "Greenland",
                    "id": 103,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -51.43,
                        64.1
                    ],
                    "code": "gl",
                    "value": "格陵兰岛-Greenland"
                },
                {
                    "country": "格林纳达",
                    "countryEn": "Grenada",
                    "id": 136,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.44,
                        12.04
                    ],
                    "code": "gd",
                    "value": "格林纳达-Grenada"
                },
                {
                    "country": "瓜德罗普",
                    "countryEn": "Guadeloupe",
                    "id": 129,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.44,
                        16
                    ],
                    "code": "gp",
                    "value": "瓜德罗普-Guadeloupe"
                },
                {
                    "country": "关岛",
                    "countryEn": "Guam",
                    "id": 212,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        144.45,
                        13.29
                    ],
                    "code": "gu",
                    "value": "关岛-Guam"
                },
                {
                    "country": "危地马拉",
                    "countryEn": "Guatemala",
                    "id": 108,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -90.22,
                        14.4
                    ],
                    "code": "gt",
                    "value": "危地马拉-Guatemala"
                },
                {
                    "country": "几内亚",
                    "countryEn": "Guinea",
                    "id": 12,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -13.49,
                        9.29
                    ],
                    "code": "gn",
                    "value": "几内亚-Guinea"
                },
                {
                    "country": "几内亚比绍",
                    "countryEn": "Guinea-Bissau",
                    "id": 11,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -15.45,
                        11.45
                    ],
                    "code": "gw",
                    "value": "几内亚比绍-Guinea-Bissau"
                },
                {
                    "country": "圭亚那",
                    "countryEn": "Guyana",
                    "id": 142,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -58.12,
                        6.5
                    ],
                    "code": "gy",
                    "value": "圭亚那-Guyana"
                },
                {
                    "country": "海地",
                    "countryEn": "Haiti",
                    "id": 121,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -72.2,
                        18.4
                    ],
                    "code": "ht",
                    "value": "海地-Haiti"
                },
                {
                    "country": "荷兰",
                    "countryEn": "Holland",
                    "id": 171,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        4.54,
                        52.23
                    ],
                    "code": "nl",
                    "value": "荷兰-Holland"
                },
                {
                    "country": "洪都拉斯",
                    "countryEn": "Honduras",
                    "id": 111,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -87.14,
                        14.05
                    ],
                    "code": "hn",
                    "value": "洪都拉斯-Honduras"
                },
                {
                    "country": "中国香港",
                    "countryEn": "Hongkong",
                    "id": 223,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        114.15,
                        22.15
                    ],
                    "code": "hk",
                    "value": "中国香港-Hongkong"
                },
                {
                    "country": "匈牙利",
                    "countryEn": "Hungary",
                    "id": 184,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        19.05,
                        47.29
                    ],
                    "code": "hu",
                    "value": "匈牙利-Hungary"
                },
                {
                    "country": "冰岛",
                    "countryEn": "Iceland",
                    "id": 155,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -21.57,
                        64.1
                    ],
                    "code": "is",
                    "value": "冰岛-Iceland"
                },
                {
                    "country": "印度",
                    "countryEn": "India",
                    "id": 86,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        77.13,
                        28.37
                    ],
                    "code": "in",
                    "value": "印度-India"
                },
                {
                    "country": "印度尼西亚",
                    "countryEn": "Indonesia",
                    "id": 101,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        106.49,
                        -6.09
                    ],
                    "code": "id",
                    "value": "印度尼西亚-Indonesia"
                },
                {
                    "country": "伊朗",
                    "countryEn": "Iran",
                    "id": 83,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        51.3,
                        35.44
                    ],
                    "code": "ir",
                    "value": "伊朗-Iran"
                },
                {
                    "country": "伊拉克",
                    "countryEn": "Iraq",
                    "id": 72,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        44.3,
                        33.2
                    ],
                    "code": "iq",
                    "value": "伊拉克-Iraq"
                },
                {
                    "country": "爱尔兰",
                    "countryEn": "Ireland",
                    "id": 160,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -6.15,
                        53.21
                    ],
                    "code": "ie",
                    "value": "爱尔兰-Ireland"
                },
                {
                    "country": "以色列",
                    "countryEn": "Israel",
                    "id": 76,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        35.12,
                        31.47
                    ],
                    "code": "il",
                    "value": "以色列-Israel"
                },
                {
                    "country": "意大利",
                    "countryEn": "Italy",
                    "id": 181,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        12.29,
                        41.54
                    ],
                    "code": "it",
                    "value": "意大利-Italy"
                }
            ]
        },
        {
            "name": "JKL",
            "valueArr": [
                {
                    "country": "牙买加",
                    "countryEn": "Jamaica",
                    "id": 120,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -76.5,
                        18
                    ],
                    "code": "jm",
                    "value": "牙买加-Jamaica"
                },
                {
                    "country": "日本",
                    "countryEn": "Japan",
                    "id": 59,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        139.44,
                        35.41
                    ],
                    "code": "jp",
                    "value": "日本-Japan"
                },
                {
                    "country": "约旦",
                    "countryEn": "Jordan",
                    "id": 74,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        35.52,
                        31.57
                    ],
                    "code": "jo",
                    "value": "约旦-Jordan"
                },
                {
                    "country": "柬埔寨",
                    "countryEn": "Kampuchea Cambodia",
                    "id": 95,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        104.55,
                        11.33
                    ],
                    "code": "kh",
                    "value": "柬埔寨-Kampuchea Cambodia"
                },
                {
                    "country": "哈萨克斯坦",
                    "countryEn": "Kazakhstan",
                    "id": 60,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        71.3,
                        51.1
                    ],
                    "code": "kz",
                    "value": "哈萨克斯坦-Kazakhstan"
                },
                {
                    "country": "肯尼亚",
                    "countryEn": "Kenya",
                    "id": 39,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        36.48,
                        -1.17
                    ],
                    "code": "ke",
                    "value": "肯尼亚-Kenya"
                },
                {
                    "country": "基里巴斯",
                    "countryEn": "Kiribati",
                    "id": 206,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        173,
                        1.3
                    ],
                    "code": "ki",
                    "value": "基里巴斯-Kiribati"
                },
                {
                    "country": "朝鲜",
                    "countryEn": "Korea",
                    "id": 57,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        125.3,
                        39.09
                    ],
                    "code": "kp",
                    "value": "朝鲜-Korea"
                },
                {
                    "country": "科威特",
                    "countryEn": "Kuwait",
                    "id": 73,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        48,
                        29.3
                    ],
                    "code": "kw",
                    "value": "科威特-Kuwait"
                },
                {
                    "country": "吉尔吉斯斯坦",
                    "countryEn": "Kyrgyzstan",
                    "id": 63,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        74.46,
                        42.54
                    ],
                    "code": "kg",
                    "value": "吉尔吉斯斯坦-Kyrgyzstan"
                },
                {
                    "country": "老挝",
                    "countryEn": "Laos",
                    "id": 94,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        102.36,
                        17.58
                    ],
                    "code": "la",
                    "value": "老挝-Laos"
                },
                {
                    "country": "拉脱维亚",
                    "countryEn": "Latvia",
                    "id": 164,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        24.08,
                        56.53
                    ],
                    "code": "lv",
                    "value": "拉脱维亚-Latvia"
                },
                {
                    "country": "黎巴嫩",
                    "countryEn": "Lebanon",
                    "id": 71,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        35.31,
                        33.53
                    ],
                    "code": "lb",
                    "value": "黎巴嫩-Lebanon"
                },
                {
                    "country": "莱索托",
                    "countryEn": "Lesotho",
                    "id": 50,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        27.3,
                        -29.18
                    ],
                    "code": "ls",
                    "value": "莱索托-Lesotho"
                },
                {
                    "country": "利比里亚",
                    "countryEn": "Liberia",
                    "id": 30,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -10.47,
                        6.18
                    ],
                    "code": "lr",
                    "value": "利比里亚-Liberia"
                },
                {
                    "country": "利比亚",
                    "countryEn": "Libya",
                    "id": 2,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        13.07,
                        32.49
                    ],
                    "code": "ly",
                    "value": "利比亚-Libya"
                },
                {
                    "country": "列支敦士登",
                    "countryEn": "Liechtenstein",
                    "id": 179,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        9.31,
                        47.08
                    ],
                    "code": "li",
                    "value": "列支敦士登-Liechtenstein"
                },
                {
                    "country": "立陶宛",
                    "countryEn": "Lithuania",
                    "id": 165,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        25.19,
                        54.38
                    ],
                    "code": "lt",
                    "value": "立陶宛-Lithuania"
                },
                {
                    "country": "卢森堡",
                    "countryEn": "Luxembourg",
                    "id": 173,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        6.09,
                        49.37
                    ],
                    "code": "lu",
                    "value": "卢森堡-Luxembourg"
                }
            ]
        },
        {
            "name": "MNO",
            "valueArr": [
                {
                    "country": "中国澳门",
                    "countryEn": "Macao",
                    "id": 224,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        113.5,
                        22.2
                    ],
                    "code": "mo",
                    "value": "中国澳门-Macao"
                },
                {
                    "country": "马其顿",
                    "countryEn": "Macedonia",
                    "id": 192,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        21.26,
                        42.01
                    ],
                    "code": "mk",
                    "value": "马其顿-Macedonia"
                },
                {
                    "country": "马达加斯加",
                    "countryEn": "Madagascar",
                    "id": 53,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        47.31,
                        -18.55
                    ],
                    "code": "mg",
                    "value": "马达加斯加-Madagascar"
                },
                {
                    "country": "马拉维",
                    "countryEn": "Malawi",
                    "id": 42,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        33.48,
                        -14
                    ],
                    "code": "mw",
                    "value": "马拉维-Malawi"
                },
                {
                    "country": "马来西亚",
                    "countryEn": "Malaysia",
                    "id": 97,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        101.41,
                        3.09
                    ],
                    "code": "my",
                    "value": "马来西亚-Malaysia"
                },
                {
                    "country": "马尔代夫",
                    "countryEn": "Maldives",
                    "id": 88,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        73.28,
                        4
                    ],
                    "code": "mv",
                    "value": "马尔代夫-Maldives"
                },
                {
                    "country": "马里",
                    "countryEn": "Mali",
                    "id": 13,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -7.55,
                        12.34
                    ],
                    "code": "ml",
                    "value": "马里-Mali"
                },
                {
                    "country": "马耳他",
                    "countryEn": "Malta",
                    "id": 198,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        14.31,
                        35.54
                    ],
                    "code": "mt",
                    "value": "马耳他-Malta"
                },
                {
                    "country": "马绍尔群岛",
                    "countryEn": "Marshall Islands",
                    "id": 209,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        171.12,
                        7.09
                    ],
                    "code": "mh",
                    "value": "马绍尔群岛-Marshall Islands"
                },
                {
                    "country": "马提尼克",
                    "countryEn": "Martinique",
                    "id": 131,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.02,
                        14.36
                    ],
                    "code": "mq",
                    "value": "马提尼克-Martinique"
                },
                {
                    "country": "毛里塔尼亚",
                    "countryEn": "Mauritania",
                    "id": 7,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        57.3,
                        -20.1
                    ],
                    "code": "mr",
                    "value": "毛里塔尼亚-Mauritania"
                },
                {
                    "country": "毛里求斯",
                    "countryEn": "Mauritius",
                    "id": 54,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        57.5,
                        -20
                    ],
                    "code": "mu",
                    "value": "毛里求斯-Mauritius"
                },
                {
                    "country": "墨西哥",
                    "countryEn": "Mexico",
                    "id": 107,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -99.1,
                        19.2
                    ],
                    "code": "mx",
                    "value": "墨西哥-Mexico"
                },
                {
                    "country": "密克罗尼西亚",
                    "countryEn": "Micronesia",
                    "id": 208,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        158.09,
                        6.55
                    ],
                    "code": "fm",
                    "value": "密克罗尼西亚-Micronesia"
                },
                {
                    "country": "摩尔多瓦",
                    "countryEn": "Moldova",
                    "id": 186,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        28.5,
                        47.02
                    ],
                    "code": "md",
                    "value": "摩尔多瓦-Moldova"
                },
                {
                    "country": "摩纳哥",
                    "countryEn": "Monaco",
                    "id": 175,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        7.25,
                        43.44
                    ],
                    "code": "mc",
                    "value": "摩纳哥-Monaco"
                },
                {
                    "country": "蒙古",
                    "countryEn": "Mongolia",
                    "id": 56,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        106.92,
                        47.92
                    ],
                    "code": "mn",
                    "value": "蒙古-Mongolia"
                },
                {
                    "country": "蒙特塞拉特",
                    "countryEn": "Montserrat",
                    "id": 127,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -62.13,
                        16.47
                    ],
                    "code": "ms",
                    "value": "蒙特塞拉特-Montserrat"
                },
                {
                    "country": "摩洛哥",
                    "countryEn": "Morocco",
                    "id": 5,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -7.09,
                        31.79
                    ],
                    "code": "ma",
                    "value": "摩洛哥-Morocco"
                },
                {
                    "country": "莫桑比克",
                    "countryEn": "Mozambique",
                    "id": 41,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        32.32,
                        -25.58
                    ],
                    "code": "mz",
                    "value": "莫桑比克-Mozambique"
                },
                {
                    "country": "纳米比亚",
                    "countryEn": "Namibia",
                    "id": 45,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        17.04,
                        -22.35
                    ],
                    "code": "na",
                    "value": "纳米比亚-Namibia"
                },
                {
                    "country": "瑙鲁",
                    "countryEn": "Nauru",
                    "id": 207,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        166.55,
                        -0.33
                    ],
                    "code": "nr",
                    "value": "瑙鲁-Nauru"
                },
                {
                    "country": "尼泊尔",
                    "countryEn": "Nepal",
                    "id": 89,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        85.2,
                        27.45
                    ],
                    "code": "np",
                    "value": "尼泊尔-Nepal"
                },
                {
                    "country": "新喀里多尼亚",
                    "countryEn": "New Caledonia",
                    "id": 204,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        166.3,
                        -22.17
                    ],
                    "code": "nc",
                    "value": "新喀里多尼亚-New Caledonia"
                },
                {
                    "country": "新西兰",
                    "countryEn": "New Zealand",
                    "id": 200,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        174.46,
                        -41.19
                    ],
                    "code": "nz",
                    "value": "新西兰-New Zealand"
                },
                {
                    "country": "尼加拉瓜",
                    "countryEn": "Nicaragua",
                    "id": 112,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -86.2,
                        12.06
                    ],
                    "code": "ni",
                    "value": "尼加拉瓜-Nicaragua"
                },
                {
                    "country": "尼日尔",
                    "countryEn": "Niger",
                    "id": 15,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        2.06,
                        13.27
                    ],
                    "code": "ne",
                    "value": "尼日尔-Niger"
                },
                {
                    "country": "尼日利亚",
                    "countryEn": "Nigeria",
                    "id": 24,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        7.32,
                        9.05
                    ],
                    "code": "ng",
                    "value": "尼日利亚-Nigeria"
                },
                {
                    "country": "纽埃",
                    "countryEn": "Niue",
                    "id": 217,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -169.55,
                        -19.03
                    ],
                    "code": "nu",
                    "value": "纽埃-Niue"
                },
                {
                    "country": "北马里亚纳群岛",
                    "countryEn": "Northern Mariana Islands",
                    "id": 211,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        145.45,
                        15.12
                    ],
                    "code": "mp",
                    "value": "北马里亚纳群岛-Northern Mariana Islands"
                },
                {
                    "country": "挪威",
                    "countryEn": "Norway",
                    "id": 157,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        10.45,
                        59.55
                    ],
                    "code": "no",
                    "value": "挪威-Norway"
                },
                {
                    "country": "阿曼",
                    "countryEn": "Oman",
                    "id": 82,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        58.36,
                        23.37
                    ],
                    "code": "om",
                    "value": "阿曼-Oman"
                }
            ]
        },
        {
            "name": "PQR",
            "valueArr": [
                {
                    "country": "巴基斯坦",
                    "countryEn": "Pakistan",
                    "id": 85,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        73.1,
                        33.4
                    ],
                    "code": "pk",
                    "value": "巴基斯坦-Pakistan"
                },
                {
                    "country": "帕劳",
                    "countryEn": "Palau",
                    "id": 210,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        134.28,
                        7.2
                    ],
                    "code": "pw",
                    "value": "帕劳-Palau"
                },
                {
                    "country": "巴勒斯坦",
                    "countryEn": "Palestine",
                    "id": 75,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        34.28,
                        31.3
                    ],
                    "code": "ps",
                    "value": "巴勒斯坦-Palestine"
                },
                {
                    "country": "巴拿马",
                    "countryEn": "Panama",
                    "id": 115,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -79.25,
                        9
                    ],
                    "code": "pa",
                    "value": "巴拿马-Panama"
                },
                {
                    "country": "巴布亚新几内亚",
                    "countryEn": "Papua new guinea",
                    "id": 201,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        147.08,
                        -9.24
                    ],
                    "code": "pg",
                    "value": "巴布亚新几内亚-Papua new guinea"
                },
                {
                    "country": "巴拉圭",
                    "countryEn": "Paraguay",
                    "id": 149,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -57.3,
                        -25.1
                    ],
                    "code": "py",
                    "value": "巴拉圭-Paraguay"
                },
                {
                    "country": "秘鲁",
                    "countryEn": "Peru",
                    "id": 146,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -77,
                        -12
                    ],
                    "code": "pe",
                    "value": "秘鲁-Peru"
                },
                {
                    "country": "菲律宾",
                    "countryEn": "Philippines",
                    "id": 100,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        121.03,
                        14.4
                    ],
                    "code": "ph",
                    "value": "菲律宾-Philippines"
                },
                {
                    "country": "皮特凯恩群岛",
                    "countryEn": "Pitcairn Islands",
                    "id": 222,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -130.06,
                        -25.04
                    ],
                    "code": "pn",
                    "value": "皮特凯恩群岛-Pitcairn Islands"
                },
                {
                    "country": "波兰",
                    "countryEn": "Poland",
                    "id": 169,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        21,
                        52.13
                    ],
                    "code": "pl",
                    "value": "波兰-Poland"
                },
                {
                    "country": "波多黎各",
                    "countryEn": "Porto Rico",
                    "id": 123,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -66.07,
                        18.28
                    ],
                    "code": "pr",
                    "value": "波多黎各-Porto Rico"
                },
                {
                    "country": "葡萄牙",
                    "countryEn": "Portugal",
                    "id": 197,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -9.1,
                        38.42
                    ],
                    "code": "pt",
                    "value": "葡萄牙-Portugal"
                },
                {
                    "country": "卡塔尔",
                    "countryEn": "Qatar",
                    "id": 79,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        51.35,
                        25.15
                    ],
                    "code": "qa",
                    "value": "卡塔尔-Qatar"
                },
                {
                    "country": "罗马尼亚",
                    "countryEn": "Romania",
                    "id": 187,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        26.1,
                        44.27
                    ],
                    "code": "ro",
                    "value": "罗马尼亚-Romania"
                },
                {
                    "country": "俄罗斯",
                    "countryEn": "Russia",
                    "id": 167,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        37.35,
                        55.45
                    ],
                    "code": "ru",
                    "value": "俄罗斯-Russia"
                },
                {
                    "country": "卢旺达",
                    "countryEn": "Rwanda",
                    "id": 37,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        30.04,
                        -1.59
                    ],
                    "code": "rw",
                    "value": "卢旺达-Rwanda"
                }
            ]
        },
        {
            "name": "STU",
            "valueArr": [
                {
                    "country": "圣马力诺",
                    "countryEn": "San Marino",
                    "id": 180,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        12.3,
                        43.55
                    ],
                    "code": "sm",
                    "value": "圣马力诺-San Marino"
                },
                {
                    "country": "圣多美和普林西比",
                    "countryEn": "Sao Tome and Principe",
                    "id": 32,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        6.39,
                        0.1
                    ],
                    "code": "st",
                    "value": "圣多美和普林西比-Sao Tome and Principe"
                },
                {
                    "country": "沙特阿拉伯",
                    "countryEn": "Saudi Arabia",
                    "id": 77,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        46.42,
                        24.41
                    ],
                    "code": "sa",
                    "value": "沙特阿拉伯-Saudi Arabia"
                },
                {
                    "country": "塞内加尔",
                    "countryEn": "Senegal",
                    "id": 8,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -17.29,
                        14.34
                    ],
                    "code": "sn",
                    "value": "塞内加尔-Senegal"
                },
                {
                    "country": "塞尔维亚和黑山（塞黑）",
                    "countryEn": "Serbia and Montenegro",
                    "id": 188,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        21,
                        44.01
                    ],
                    "code": "rs",
                    "value": "塞尔维亚和黑山（塞黑）-Serbia and Montenegro"
                },
                {
                    "country": "塞舌尔",
                    "countryEn": "Seychelles",
                    "id": 51,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        55.28,
                        -4.4
                    ],
                    "code": "sc",
                    "value": "塞舌尔-Seychelles"
                },
                {
                    "country": "塞拉利昂",
                    "countryEn": "Sierra Leone",
                    "id": 29,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -13.17,
                        8.3
                    ],
                    "code": "sl",
                    "value": "塞拉利昂-Sierra Leone"
                },
                {
                    "country": "新加坡",
                    "countryEn": "Singapore",
                    "id": 98,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        103.51,
                        1.18
                    ],
                    "code": "sg",
                    "value": "新加坡-Singapore"
                },
                {
                    "country": "斯洛伐克",
                    "countryEn": "Slovakia",
                    "id": 177,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        17.07,
                        48.1
                    ],
                    "code": "sk",
                    "value": "斯洛伐克-Slovakia"
                },
                {
                    "country": "斯洛文尼亚",
                    "countryEn": "Slovenia",
                    "id": 185,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        14.33,
                        46.04
                    ],
                    "code": "si",
                    "value": "斯洛文尼亚-Slovenia"
                },
                {
                    "country": "所罗门群岛",
                    "countryEn": "Solomon",
                    "id": 202,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        159.57,
                        -9.27
                    ],
                    "code": "sb",
                    "value": "所罗门群岛-Solomon"
                },
                {
                    "country": "索马里",
                    "countryEn": "Somalia",
                    "id": 20,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        45.25,
                        2.02
                    ],
                    "code": "so",
                    "value": "索马里-Somalia"
                },
                {
                    "country": "南非",
                    "countryEn": "South Africa",
                    "id": 48,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        22.93,
                        -30.55
                    ],
                    "code": "za",
                    "value": "南非-South Africa"
                },
                {
                    "country": "韩国",
                    "countryEn": "South Korea",
                    "id": 58,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        126.58,
                        37.31
                    ],
                    "code": "kr",
                    "value": "韩国-South Korea"
                },
                {
                    "country": "西班牙",
                    "countryEn": "Spain",
                    "id": 196,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -3.45,
                        40.25
                    ],
                    "code": "es",
                    "value": "西班牙-Spain"
                },
                {
                    "country": "斯里兰卡",
                    "countryEn": "Sri Lanka",
                    "id": 87,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        80.77,
                        7.87
                    ],
                    "code": "lk",
                    "value": "斯里兰卡-Sri Lanka"
                },
                {
                    "country": "圣其茨和尼维斯",
                    "countryEn": "St. Kitts & Nevis",
                    "id": 139,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -62.43,
                        17.18
                    ],
                    "code": "kn",
                    "value": "圣其茨和尼维斯-St. Kitts & Nevis"
                },
                {
                    "country": "圣卢西亚",
                    "countryEn": "St. Lucia ",
                    "id": 132,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -60.58,
                        14.02
                    ],
                    "code": "lc",
                    "value": "圣卢西亚-St. Lucia "
                },
                {
                    "country": "圣皮埃尔和密克隆",
                    "countryEn": "St. Pierre and Miquelon",
                    "id": 105,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -56.12,
                        46.46
                    ],
                    "code": "pm",
                    "value": "圣皮埃尔和密克隆-St. Pierre and Miquelon"
                },
                {
                    "country": "圣文森特和格林纳丁斯",
                    "countryEn": "St. Vincent and the Grenadines ",
                    "id": 133,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -61.1,
                        13.1
                    ],
                    "code": "vc",
                    "value": "圣文森特和格林纳丁斯-St. Vincent and the Grenadines "
                },
                {
                    "country": "苏丹",
                    "countryEn": "Sudan",
                    "id": 17,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        32.35,
                        15.31
                    ],
                    "code": "sd",
                    "value": "苏丹-Sudan"
                },
                {
                    "country": "苏里南",
                    "countryEn": "Suriname",
                    "id": 143,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -55.1,
                        5.5
                    ],
                    "code": "sr",
                    "value": "苏里南-Suriname"
                },
                {
                    "country": "斯威士兰",
                    "countryEn": "Swaziland",
                    "id": 49,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        31.06,
                        -26.18
                    ],
                    "code": "sz",
                    "value": "斯威士兰-Swaziland"
                },
                {
                    "country": "瑞典",
                    "countryEn": "Sweden",
                    "id": 158,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        18.03,
                        59.2
                    ],
                    "code": "se",
                    "value": "瑞典-Sweden"
                },
                {
                    "country": "瑞士",
                    "countryEn": "Switzerland",
                    "id": 183,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        7.28,
                        46.57
                    ],
                    "code": "ch",
                    "value": "瑞士-Switzerland"
                },
                {
                    "country": "叙利亚",
                    "countryEn": "Syria",
                    "id": 70,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        36.18,
                        33.3
                    ],
                    "code": "sy",
                    "value": "叙利亚-Syria"
                },
                {
                    "country": "中国台湾",
                    "countryEn": "Taiwan",
                    "id": 225,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        121.31,
                        25.03
                    ],
                    "code": "tw",
                    "value": "中国台湾-Taiwan"
                },
                {
                    "country": "塔吉克斯坦",
                    "countryEn": "Tajikistan",
                    "id": 64,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        68.48,
                        38.33
                    ],
                    "code": "tj",
                    "value": "塔吉克斯坦-Tajikistan"
                },
                {
                    "country": "坦桑尼亚",
                    "countryEn": "Tanzania",
                    "id": 40,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        35.45,
                        -6.08
                    ],
                    "code": "tz",
                    "value": "坦桑尼亚-Tanzania"
                },
                {
                    "country": "泰国",
                    "countryEn": "Thailand",
                    "id": 93,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        100.35,
                        13.45
                    ],
                    "code": "th",
                    "value": "泰国-Thailand"
                },
                {
                    "country": "英属维尔京群岛",
                    "countryEn": "The British Virgin Islands ",
                    "id": 125,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -64.37,
                        18.27
                    ],
                    "code": "vg",
                    "value": "英属维尔京群岛-The British Virgin Islands "
                },
                {
                    "country": "刚果（扎伊尔）",
                    "countryEn": "The Democratic Republic Of Congo （Zaire）",
                    "id": 35,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        15.15,
                        -4.2
                    ],
                    "code": "cd",
                    "value": "刚果（扎伊尔）-The Democratic Republic Of Congo （Zaire）"
                },
                {
                    "country": "美属维尔京群岛",
                    "countryEn": "The United States Virgin Islands ",
                    "id": 124,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -64.56,
                        18.21
                    ],
                    "code": "vi",
                    "value": "美属维尔京群岛-The United States Virgin Islands "
                },
                {
                    "country": "多哥",
                    "countryEn": "Togo",
                    "id": 26,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        1.2,
                        6.09
                    ],
                    "code": "tg",
                    "value": "多哥-Togo"
                },
                {
                    "country": "托克劳",
                    "countryEn": "Tokelau",
                    "id": 218,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -172.5,
                        -9.5
                    ],
                    "code": "tk",
                    "value": "托克劳-Tokelau"
                },
                {
                    "country": "汤加",
                    "countryEn": "Tonga",
                    "id": 220,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -174,
                        -21.1
                    ],
                    "code": "to",
                    "value": "汤加-Tonga"
                },
                {
                    "country": "特立尼达和多巴哥",
                    "countryEn": "Trinidad and Tobago ",
                    "id": 137,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -60.32,
                        11.18
                    ],
                    "code": "tt",
                    "value": "特立尼达和多巴哥-Trinidad and Tobago "
                },
                {
                    "country": "突尼斯",
                    "countryEn": "Tunisia",
                    "id": 3,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        10.11,
                        36.5
                    ],
                    "code": "tn",
                    "value": "突尼斯-Tunisia"
                },
                {
                    "country": "土耳其",
                    "countryEn": "Turkey",
                    "id": 68,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        32.54,
                        39.57
                    ],
                    "code": "tr",
                    "value": "土耳其-Turkey"
                },
                {
                    "country": "土库曼斯坦",
                    "countryEn": "Turkmenistan",
                    "id": 61,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        57.5,
                        38
                    ],
                    "code": "tm",
                    "value": "土库曼斯坦-Turkmenistan"
                },
                {
                    "country": "特克斯和凯科斯群岛",
                    "countryEn": "Turks and Caicos Islands ",
                    "id": 117,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -71.08,
                        21.28
                    ],
                    "code": "tc",
                    "value": "特克斯和凯科斯群岛-Turks and Caicos Islands "
                },
                {
                    "country": "图瓦卢",
                    "countryEn": "Tuvalu",
                    "id": 213,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        179.13,
                        -8.31
                    ],
                    "code": "tv",
                    "value": "图瓦卢-Tuvalu"
                },
                {
                    "country": "乌干达",
                    "countryEn": "Uganda",
                    "id": 36,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        32.3,
                        0.2
                    ],
                    "code": "ug",
                    "value": "乌干达-Uganda"
                },
                {
                    "country": "乌克兰",
                    "countryEn": "Ukraine",
                    "id": 168,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        30.28,
                        50.3
                    ],
                    "code": "ua",
                    "value": "乌克兰-Ukraine"
                },
                {
                    "country": "阿拉伯联合酋长国",
                    "countryEn": "United Arab Emirates",
                    "id": 80,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        54.22,
                        24.28
                    ],
                    "code": "ae",
                    "value": "阿拉伯联合酋长国-United Arab Emirates"
                },
                {
                    "country": "英国",
                    "countryEn": "United Kingdom",
                    "id": 161,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        -0.05,
                        51.36
                    ],
                    "code": "gb",
                    "value": "英国-United Kingdom"
                },
                {
                    "country": "乌拉圭",
                    "countryEn": "Uruguay",
                    "id": 152,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -56.11,
                        -34.5
                    ],
                    "code": "uy",
                    "value": "乌拉圭-Uruguay"
                },
                {
                    "country": "美国",
                    "countryEn": "United States of America",
                    "id": 106,
                    "region": "北美洲",
                    "regionEn": "North America",
                    "regionId": "3",
                    "coordinate": [
                        -77.02,
                        39.91
                    ],
                    "code": "us",
                    "value": "美国-United States of America"
                },
                {
                    "country": "乌兹别克斯坦",
                    "countryEn": "Uzbekistan",
                    "id": 62,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        69.1,
                        41.2
                    ],
                    "code": "uz",
                    "value": "乌兹别克斯坦-Uzbekistan"
                }
            ]
        },
        {
            "name": "VWY",
            "valueArr": [
                {
                    "country": "瓦努阿图",
                    "countryEn": "Vanuatu",
                    "id": 203,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        168.18,
                        -17.45
                    ],
                    "code": "vu",
                    "value": "瓦努阿图-Vanuatu"
                },
                {
                    "country": "梵蒂冈",
                    "countryEn": "Vatican",
                    "id": 182,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        12.27,
                        41.54
                    ],
                    "code": "va",
                    "value": "梵蒂冈-Vatican"
                },
                {
                    "country": "委内瑞拉",
                    "countryEn": "Venezuela",
                    "id": 140,
                    "region": "南美洲",
                    "regionEn": "South America",
                    "regionId": "4",
                    "coordinate": [
                        -66.55,
                        10.3
                    ],
                    "code": "ve",
                    "value": "委内瑞拉-Venezuela"
                },
                {
                    "country": "越南",
                    "countryEn": "Vietnam",
                    "id": 96,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        105.55,
                        21.05
                    ],
                    "code": "vn",
                    "value": "越南-Vietnam"
                },
                {
                    "country": "瓦利斯及富图纳",
                    "countryEn": "Wallis Islands and Futuna Islands",
                    "id": 214,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -176.1,
                        -13.2
                    ],
                    "code": "wf",
                    "value": "瓦利斯及富图纳-Wallis Islands and Futuna Islands"
                },
                {
                    "country": "西撒哈拉",
                    "countryEn": "Western Sahara",
                    "id": 6,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        -12.88,
                        24.21
                    ],
                    "code": "eh",
                    "value": "西撒哈拉-Western Sahara"
                },
                {
                    "country": "西萨摩亚",
                    "countryEn": "Western Samoa",
                    "id": 215,
                    "region": "大洋洲",
                    "regionEn": "Oceania",
                    "regionId": "6",
                    "coordinate": [
                        -171.5,
                        -13.5
                    ],
                    "code": "ws",
                    "value": "西萨摩亚-Western Samoa"
                },
                {
                    "country": "白俄罗斯",
                    "countryEn": "White Russia",
                    "id": 166,
                    "region": "欧洲",
                    "regionEn": "Europe",
                    "regionId": "2",
                    "coordinate": [
                        27.3,
                        53.52
                    ],
                    "code": "by",
                    "value": "白俄罗斯-White Russia"
                },
                {
                    "country": "也门",
                    "countryEn": "Yemen",
                    "id": 81,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        48.51,
                        15.55
                    ],
                    "code": "ye",
                    "value": "也门-Yemen"
                }
            ]
        },
        {
            "name": "Z",
            "valueArr": [
                {
                    "country": "赞比亚",
                    "countryEn": "Zambia",
                    "id": 43,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        28.16,
                        -15.28
                    ],
                    "code": "zm",
                    "value": "赞比亚-Zambia"
                },
                {
                    "country": "津巴布韦",
                    "countryEn": "Zimbabwe",
                    "id": 47,
                    "region": "非洲",
                    "regionEn": "Africa",
                    "regionId": "5",
                    "coordinate": [
                        31.02,
                        -17.43
                    ],
                    "code": "zw",
                    "value": "津巴布韦-Zimbabwe"
                }
            ]
        },
        {
            "name": "China",
            "valueArr": [
                {
                    "country": "其他省市",
                    "countryEn": "Other",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "广东",
                    "countryEn": "Guangdong",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "新疆",
                    "countryEn": "Xinjiang",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "广西",
                    "countryEn": "Guangxi",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "福建",
                    "countryEn": "Fujian",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "湖北",
                    "countryEn": "Hubei",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "陕西",
                    "countryEn": "Shaanxi",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "四川",
                    "countryEn": "Sichuan",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "重庆",
                    "countryEn": "Chongqing",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "河南",
                    "countryEn": "Henan",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "安徽",
                    "countryEn": "Anhui",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "江西",
                    "countryEn": "Jiangxi",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "山西",
                    "countryEn": "Shanxi",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "云南",
                    "countryEn": "Yunnan",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "贵州",
                    "countryEn": "Guizhou",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "西藏",
                    "countryEn": "Xizang",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "江苏",
                    "countryEn": "Jiangsu",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "浙江",
                    "countryEn": "Zhejiang",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "上海",
                    "countryEn": "Shanghai",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "北京",
                    "countryEn": "Beijing",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "天津",
                    "countryEn": "Tianjin",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "河北",
                    "countryEn": "Hebei",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "辽宁",
                    "countryEn": "Liaoning",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "山东",
                    "countryEn": "Shandong",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "甘肃",
                    "countryEn": "Gansu",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "青海",
                    "countryEn": "Qinghai",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "内蒙古",
                    "countryEn": "Neimenggu",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "宁夏",
                    "countryEn": "Ningxia",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "黑龙江",
                    "countryEn": "Heilongjiang",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "吉林",
                    "countryEn": "Jilin",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "湖南",
                    "countryEn": "Hunan",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                },
                {
                    "country": "海南",
                    "countryEn": "Hainan",
                    "id": 55,
                    "region": "亚洲",
                    "regionEn": "Asia",
                    "regionId": "1",
                    "coordinate": [
                        116.2,
                        39.55
                    ],
                    "code": "cn"
                }
            ]
        }
    ]
    countryByGroup.filter(item=>{
        item.valueArr.filter(item1=>{
            item1.value = item1.country+'-'+item1.countryEn+'-'+item1.code

        })
    })
    return countryByGroup
}


import request from "@/utils/request";
import {getAccount} from "@/api/user";
import store from '@/store/index'

export function getUserProfile() {
    let profile = localStorage.getItem('userInfo');
    if (profile && JSON.parse(profile)&&JSON.parse(profile).employeeCode) {
        store.commit('changeUserInfo', JSON.parse(profile));
        if (localStorage.getItem('googleLogin')) {
            localStorage.removeItem('googleLogin')
            getUserProfile()
        }
        return JSON.parse(profile);
    } else {
        profile = getAccount();
        if (localStorage.getItem('googleLogin')) {
            var i = 0
            var timer = setInterval(() => {
                localStorage.removeItem('googleLogin')
                if (getUserProfile()&&getUserProfile().employeeCode) {
                    clearInterval(timer)
                }
                if (i >20) {
                    clearInterval(timer)
                }
                i++;
            }, 500)
            if (i >20) {
                clearInterval(timer)
            }
        }

    }
    if (!profile || !JSON.parse(profile).employeeCode) {
        localStorage.removeItem('userInfo');
    }
    return profile;
}

import {encrypt} from "../utils/jsenscrypt";
import request from "../utils/request";
import axios from 'axios';

var userUrl = '/account'

// 获得登录验证码
export function getLoginCode() {

    return request({
        url: userUrl + '/login/code',
        method: 'get',
    })
}

// 请求用户信息
export function getAccount() {
    let userProfile = null;
    request({
        synchronous: true,
        url: userUrl + '/get',
        method: 'get',
    }).then(res => {
        if (res.code == 200) {
            if (res.data && res.data.authorized && res.data.employeeCode) {
                userProfile = res.data;
                localStorage.setItem('userInfo', JSON.stringify(res.data))
            }
        }
    });
    return userProfile;
}

// 请求用户信息
export function getAccountCopy() {
   return request({
        url: userUrl + '/get',
        method: 'get',
    })
}

// 激活邮箱接口
export function sendMail(data) {
    return request({
        url: userUrl + '/send/mail',
        method: 'post',
        data
    })
}

// 获取公钥
export function getPublicKey() {
    return request({
        url: userUrl + '/get/publicKey',
        method: 'get'

    })
}

// 获取验证码
export function sendCaptcha() {
    return request({
        url: userUrl + '/sendCaptcha',
        method: 'get'

    })
}

// 获取验证码
export function loginWithCaptcha(captcha) {
    return request({
        url: userUrl + '/loginWithCaptcha',
        method: 'get',
        params: {
            captcha: captcha
        }
    })
}

// 登录
export function login(data, publicKey) {
    var password = encrypt(data.password, publicKey)
    var user = {}
    data.password = password
    user.email = data.email
    user.password = password
    user.verificationCode = data.verificationCode
    user.verification = data.verification
    return request({
        url: userUrl + '/login',
        method: 'post',
        data
    })
}


// 注册
export function register(data, publicKey) {
    var password = encrypt(data.password, publicKey)
    var user = {}
    user.email = data.email
    user.password = password
    user.country = data.country
    user.invitationCode = data.invitationCode == '' ? null : data.invitationCode
    user.verificationCode = data.verificationCode
    user.verification = data.verification
    data.password = password
    data.confrimPassword = password
    return request({
        url: userUrl + '/register',
        method: 'post',
        data: user
    })
}

// Google关联
export function renderPath(source) {

    return request({
        url: userUrl + '/render/' + source,
        method: 'get'
    })
}

// 获得注册验证码
export function getRegisterCode() {

    return request({
        url: userUrl + '/register/code',
        method: 'get',
    })
}

// 用户登出
export function logout() {
    return request({
        url: userUrl + '/logout',
        method: 'get'
    })
}

// 忘记密码验证码
export function getForgetCode() {

    return request({
        url: userUrl + '/forget/code',
        method: 'get',
    })
}

// 激活邮件验证
export function activeEmail() {
    return request({
        url: userUrl + '/activemail',
        method: 'get'
    })
}

// 获得激活邮箱验证码
export function getActiveCode() {
    return request({
        url: userUrl + '/active/code',
        method: 'get',
    })
}

// 获得Google登录
export function getGoogleLogin() {

    return request({
        url: userUrl + '/render/google',
        method: 'get',
    })
}

// 获得google登录后的用户信息
export function getGoogleLoginUserInfo(url) {

    return request({
        url,
        method: 'get',
    })
}


// 重置密码
export function accountForgetPassword(data,publicKey){
    data.password = encrypt(data.password, publicKey)
    return request({
        url:userUrl+'/forget/password',
        method:'post',
        data
    })
}

// 发送激活邮件
export function accountSendEmailActive(data){
    return request({
        url:userUrl+'/send/mail/active',
        method:'post',
        data
    })
}

// 忘记密码验证码
export function accountForgetCode(){
    return request({
        url:userUrl+'/forget/code',
        method:'get'
    })
}

// 激活验证码
export function accountActiveCode(){
    return request({
        url:userUrl+'/active/code',
        method:'get'

    })
}

// 激活账户
export function accountActive(params){
    return request({
        url:userUrl+'/active',
        method:'get',
        params
    })
}

// 判断用户邮箱是否激活
export function accountGetActive(){
    return request({
        url: '/account/getActive',
        method: 'get'
    })
}
export function languageMore(index) {
    var language = {
        'language1': {
            'HOME_PAGE': '首页',
            'SHOPPING_CART': '购物车',
            'FAVORITE_LIST': '收藏夹',
            'MY_ORDERS': '我的订单',
            'MY_WAREHOUSE': '我的仓库',
            'MY_ASSETS': '我的资产',
            'MY_INQUIRY': '我的咨询',
            'MESSAGING_CENTER': '站内信',
            'ACCOUNT_BALANCE': '账户余额(¥)',
            'CUSTOM_FEATURES': '自定义功能',
            'AFFILIATE_MARKETING': '推广联盟',
            'HELP_CENTER': '帮助中心',
            'PRODUCT_INFORMATION': '商品信息',
            'ACCOUNT_BALANCE2': '余额',
            'PRODUCT_NOTES': '商品备注信息',
            'SPECIAL_PURCHASE_REQUIREMENT_MESSAGE': '仅当您有特殊采购要求时才写消息',
            'ACCOUNT_SETTINGS': '账户设置',
            'ACCOUNT_SETTINGS2': '账户',
            'UNIT_PRICE_LABEL': '单价',
            'QUANTITY_LABEL': '数量',
            'AMOUNT_LABEL': '金额',
            'ACTION_LABEL': '操作',
            'TAGS_LABEL': '标签',
            'DELETE_BUTTON': '删除',
            'KIND_REMINDER': '提示',
            'CONFIRM_DELETE_PRODUCT': '确定删除商品？',
            'CANCEL_1': '取消',
            'CONFIRM_1': '确定 ',
            'ADD_TO_CART': '加入购物车',
            'REMOVE_SELECTED_ITEMS': '删除选中的商品',
            'PRODUCT_SELECTED_1_NO_FREIGHT': '已选中商品 1 件 （不含运费）',
            'ESTIMATE_SHIPPING_COST': '预估国际运费',
            'PROCEED_TO_CHECKOUT': '结算',
            'SHOP': '店铺',
            'PRODUCTS': '商品',
            'SEARCH_BY_NOTE_OR_STORE_NAME': '搜索备注名/店铺名',
            'CHOOSE_PLATFORM': '选择平台',
            'TAOBAO': '淘宝',
            'TMALL': '天猫',
            'ALIBABA_1688': 1688.0,
            'ENTER_PRODUCT': '请输入商品',
            'FAVORITE_ITEM_KEYWORDS': '输入收藏商品关键词',
            'ADD_TO_CART2': '加入购物车',
            'VIEW_WAREHOUSE_IMAGES': '查看仓库照片',
            'NAVIGATE_TO': '前往',
            'SHOW_ALL': '全部',
            'AWAITING_PAYMENT': '待付款',
            'SORT_BY_DATE': '时间排序',
            'ORDER_RECYCLE_BIN': '订单回收站',
            'ITEM_DETAILS_HEADER': '名称/主订单/商品单号',
            'SIMPLIFY_FILTERS': '精简筛选条件',
            'ORDER_STATUS': '订单状态',
            'UNPAID_ORDERS': '待付款',
            'PAYMENT_COMPLETED': '已付款',
            'PENDING_ORDERS': '采购中',
            'AWAITING_CONFIRMATION': '待确认',
            'SHIPPED_ORDERS': '已发货',
            'DELIVERED_ORDERS': '订单已送达',
            'CANCEL_ORDER_ACTION': '已取消',
            'START_DATE_RANGE': '开始日期',
            'END_DATE_RANGE': '结束日期',
            'SEARCH_ACTION': '搜索',
            'AFTER_SALES_SERVICE_1': '售后',
            'UNIT_PRICE_FIELD': '单价',
            'QUANTITY_FIELD': '数量',
            'STATUS_OF_ORDER': '订单状态',
            'ADDITIONAL_SERVICES': '附加服务',
            'AFTER_SALES_SERVICE2': '售后服务',
            'ORDER_TOTAL': '订单总额',
            'ADDITIONAL_ACTIONS': '其他操作',
            'ORDER_NUMBER_LABEL': '订单编号',
            'CREATION_TIMESTAMP': '创建时间',
            'WAREHOUSE_NAME_TENDBUY': '仓库: Tendabuy仓库',
            'UNPAID_STATUS': '未付款',
            'PROCEED_TO_PAYMENT': '去支付',
            'PRODUCT_NOTE_COLON': '商品备注信息：',
            'SELECT_ALL_2': '全选',
            'PAGE_NAVIGATION': '页',
            'ITEM_IDENTIFIER_HEADER': '名称/主订单/商品单号',
            'FILTER_COLLAPSE': '精简筛选条件',
            'DATE_RANGE_START': '开始日期',
            'DATE_RANGE_END': '结束日期',
            'SEARCH_QUERY': '搜索',
            'ORDER_SUCCESS_SHIPMENT_PROCESS': '订单成功订购后，卖家将商品寄送至仓库，仓库收到商品后，您可以在本页面查看、操作商品',
            'IDENTIFICATION_COLOR': '标识颜色',
            'PRODUCT_LABEL': '商品标签',
            'SELECT_ALL_ITEMS': '全选',
            'TENDABUY_WAREHOUSE': 'Tendabuy仓库',
            'SHIPPING_COUNTRY': '寄送国家',
            'ESTIMATED_DEPOSIT_WEIGHT': '预估押金(实重)',
            'SELECTED_ITEMS_COUNT': '选中商品 * 件 ( 共 * 件商品)',
            'PREVIEW_PACKAGING': '预演包装',
            'PREPACKING_SERVICE_INFO': '预演服务不是真实包事，仅是预打包服务，如需提包请返回仓库进行“提交运送”',
            'SUBMIT_SHIPMENT': '提交运送',
            'SEARCH_PR_PO_PI': '输入PR/PO/PI查找',
            'PACKAGE_PREVIEW_INFO': '预演包裹信息',
            'INTENDED_ROUTE': '意向线路',
            'WEIGHT_GRAMS': '重量',
            'DIMENSIONS_CM': '尺寸',
            'VALUE_ADDED': '增值',
            'ALL_OPTIONS': '全部 ',
            'OPERATIONS': '操作',
            'UNPROCESSED_STATUS': '未处理',
            'PRINT_OUTBOUND_ORDER': '打印出库单',
            'SHIPPED_STATUS': '已出库',
            'WEIGHT_CALCULATED': '重量已计算',
            'SHIPPED_OUT': '已寄送',
            'EXPIRED_STATUS': '已失效',
            'CANCELLED_STATUS': '已取消',
            'PACKAGING_WEIGHT_VOLUME_HINT': '您的商品在打包前，系统无法获得准确的重量、体积。因此你可以在【我的仓库】页面通过购买预演包裹获取包裹的准确重量、体积。',
            'AFTER_SALES_SERVICE': '售后服务',
            'RECHARGE': '充值',
            'ARREARS': '欠款',
            'SETTLE_ARREARS': '偿还欠款',
            'AFTER_SALES_SERVICE_2': '售后服务',
            'START_DATE': '开始日期',
            'END_DATE': '结束日期',
            'TRANSACTION_TYPE': '类型',
            'WITHDRAWAL_DEDUCTION': '提现扣款',
            'ORDER_REFUND': '订单退款',
            'VIEW_BILL': '查看账单',
            'TXN_TIME': '交易时间',
            'INCOME_EXPENSE': '收入/支出(¥)',
            'ALL_MESSAGES': '全部消息',
            'TXN_ID': '交易编号',
            'ALL_MESSAGES2': '全部消息',
            'NO_DATA_AVAILABLE': '暂无数据',
            'INPUT_CONTENT': '请输入商品链接或名称',
            'CONTENT_TYPE': '类型',
            'AVAILABLE_BALANCE': '可用余额 :',
            'ENQUIRY_CONTENT': '咨询内容',
            'AVAILABLE_BALANCE2': '可用余额',
            'BALANCE_DETAIL': '余额明细',
            'ALL_INQUIRIES': '全部',
            'ORDER_INQUIRY': '订单咨询',
            'PACKAGE_INQUIRY': '包裹咨询',
            'AFTER_SALE_SERVICE': '包裹售后',
            'OTHER_QUERIES': '其他咨询',
            'PACKAGE_ORDER_NO': '包裹号/订单号',
            'ASSOCIATED_NO': '关联单号',
            'CONSULT_CONTENT': '咨询内容',
            'ENQUIRY_SUBMIT_TIME': '提交时间',
            'ALL_ITEMS': ' 全部 ',
            'BALANCE_DETAIL2': '余额明细',
            'PACKAGE_ORDER_PRODUCT_NO': '包裹号/订单号/商品编号',
            'TITLE': '标题',
            'CREATION_TIME': '创建时间',
            'OPERATION': '操作',
            'EMPTY_DATA': '暂无数据',
            'SELECT_ALL_MESSAGES': '全选',
            'BIRTHDAY': '生日',
            'SYSTEM_NOTIFICATION': '系统消息',
            'MANUAL_MESSAGE': '人工消息',
            'VIEW_DETAILS': '查看详情',
            'PERSONAL_INFO': '个人信息',
            'EDIT_PROFILE': '编辑',
            'BIRTHDAY2': '生日',
            'BUYING_GUIDELINES': '代购须知',
            'PHONE_NUMBER': '手机',
            'BUYING_GUIDELINES2': '代购须知',
            'VOICE': '语言',
            'CANCEL_BUTTON': '取消',
            'GROWTH_POINTS': '成长值',
            'VIEW_GROWTH_HISTORY': '查看成长值记录',
            'NEED_3000_MORE_POINTS_FOR_V1': '距离V1只需要3000个成长值',
            'SHIPPING_ADDRESS': '收货地址',
            'ADD_SHIPPING_ADDRESS': '新增收货地址',
            'ZERO_ADDRESSES_CREATED_LEFT': '您已创建',
            'ZERO_ADDRESSES_CREATED_RIGHT': ' 收货地址',
            'PROMPT_ADD_ADDRESS': '您还没有添加收货地址，请添加收货地址',
            'ACCOUNT_SECURITY': '账户安全',
            'USER_NAME': '用户名',
            'EMAIL_ADDRESS': '邮箱',
            'SECURITY_LEVEL': '安全级别',
            'LAST_LOGIN': '最近登录',
            'AUTHORIZED_BINDINGS': '授权绑定',
            'MODIFY_EMAIL_IF_ISSUE': '建议仅在邮箱设置错误或出现问题时修改',
            'LOW_SECURITY_SET_PAYMENT_PW': '您的安全级别为 "低" 为了保障您的账户安全 请设置支付密码',
            'BINDING_ACTION': '绑定',
            'CHANGE_LOGIN_PW': '修改登录密码',
            'STRONG_PW_RECOMMENDED': '安全性高的密码可以使账号更安全。建议您定期更换密码， 且设置一个包含数字和字母，并长度超过6位以上的密码。',
            'PAYMENT_PASSWORD': '支付密码',
            'SET_PW_FOR_SECURE_PAYMENTS': '使用 Tendabuy 余额支付时需要输入的密码，设置支付密码以保障支付安全',
            'UPDATE_PASSWORD': '修改密码',
            'CREDIT_CARD_BILLING_ADDRESS': '信用卡账单地址',
            'SET_CARD_BILLING_ADDRESS_FOR_PAYMENTS': '当您使用信用卡支付时，需要设置信用卡账单地址。',
            'ADD_BILLING_ADDRESS': '添加账单地址',
            'TOP_UP_AUTHORIZATION': '补款授权',
            'ENABLE_SMALL_TOPUP_CONFIRMATION': '开启这个功能，免去小金额补款确认，有效提高效率。',
            'AUTHORIZATION_TYPE': '授权类型',
            'CANCEL_BUTTON2': '取消',
            'DAILY_CAP': '每日封顶',
            'REMAINING_LIMIT': '剩余额度',
            'CONFIRM_BUTTON': '确定',
            'ACTION': '操作',
            'DOMESTIC_SHIPPING_AUTH': '国内运费授权',
            'EDIT_AUTH': '修改',
            'ORDER_TOPUP_AUTH': '订单补款授权',
            'INTERNATIONAL_SHIPPING_AUTH': '国际运费授权',
            'ORDER_TAG_MANAGEMENT': '订单标识管理',
            'ENABLE_CUSTOMER_MANAGEMENT': '开启这个功能，管理您的客户。',
            'CREATE_TAG': '创建标识',
            'TAG_NAME': '标识名称',
            'TAG_COLOR': '标识颜色',
            'TAG_REMARK': '标识备注',
            'CONFIRM_BUTTON2': '确定',
            'COUNTRY': '国家',
            'TAG': '标识',
            'NOTE': '备注',
            'EDIT_TAG': '编辑',
            'DELETE_TAG': '删除',
            'CUSTOM_PHOTO_TEMPLATE': '定制拍照模板',
            'LEARN_CUSTOM_PHOTO': '学会使用这个功能，填写[定制拍照]内容的时候，会变的更加容易',
            'ADD_TEMPLATE': '新增模版',
            'TEMPLATE_NOT_FOUND': '没有找到您查找的定制拍照模板',
            'ADD_PHOTO_TEMPLATE': '去添精细照片模板',
            'CUSTOM_PHOTO': '定制拍照模板',
            'TEMPLATE_NAME': '模板名称',
            'PHOTO_COUNT': '照片数量',
            'PHOTO_REQUIREMENTS': '拍照要求',
            'TEMPLATE_NAME2 ': '请输入模板名称',
            'ENTER_REMARKS': '请输入备注内容',
            'PHOTO_REQUIREMENTS2': '请注明您的照片要求，每张照片有一个要求，您可以上传样本照片以供参考。',
            'EMAIL_APP_NOTIFICATIONS': '电子邮件推送通知',
            'SELECT_NOTIFICATIONS': '勾选我们应该通知你的内容',
            'CUSTOMIZE_PHOTO': '自定义照片',
            'WATERMARK_ENABLED': '开启水印则您收到的拍照会添加您选择的水印样式',
            'ADD_FORWARDING_ORDER': '添加转运订单',
            'WAREHOUSE_ADDRESS': 'Tendabuy仓库地址',
            'RETURN_ORDERS': '返回我的订单',
            'ORDER_REMARK2': '\n订单备注',
            'SEARCH_COURIER': '搜索快递单号',
            'HISTORY_FORWARDING': '历史转运',
            'RECIPIENT_ADDRESS': '仓库地址',
            'CONTACT_ADDRESS': '收件人',
            'ADDDRESS': '收件地址',
            'POST_CODE': '邮编',
            'CONTACT_PHONE': '联系电话',
            'WARNING_PACKAGE_SECURITY': '如果您将包裹寄送到tendabuy仓库,请不要把快递号透露任何人。快递号是证明包裹属于您的唯一标识,',
            'SHIPPING_INFO_Tendabuy': '寄送到Tendabuy仓库的快递信息',
            'TRACKING_NUMBER': '快递单号',
            'TRACKING_NUMBER_REQUEST': '您可以询问发件人',
            'PARCEL_NAME': '产品名称',
            'ADD_TRACKING_NUMBER': '添加快递单号',
            'TRACK_PACKAGE_STATUS': '查看快递状态',
            'CANCEL_ACTION': '取消',
            'SUBMIT_ACTION': '提交',
            'DISCLAIMER': '免责声明',
            'SUBMIT_PRODUCT_INFO': '填写并提交产品信息',
            'PAYMENT_SHIPPING_FEES': '支付产品和运输费用',
            'WAITING_MERCHANT_SHIPMENT': '等待商家发货',
            'COUNTRY2': '国家',
            'REQUIRED_FIELDS': '必填项',
            'PRODUCT_LINK': '商品链接',
            'GENDER': '性别',
            'GENDER2': '性别',
            'INPUT_SHIPPING_COST': '请填写卖家到Tendadabuy仓库的运费',
            'UPLOAD_PICTURE': '上传图片',
            'PICTURE_SIZE_LIMIT': '单张图片大小限制在3M以内（支持gif、jpg、png格式）',
            'PRODUCT_REMARK': '商品备注信息',
            'COST': '费用',
            'UNIT_PRICE': '单价',
            'FILL_IN_RMB_1_FOR_UNKNOWN_PRICE': '如果商品价格未知，可填写1元下单后等待采购补款。',
            'DOMESTIC_SHIPPING_COST': '到仓运费',
            'TOTAL_PRICE': '总价',
            'INTERNATIONAL_SHIPPING_EXTRA': '不包含国际运费，国际运费需另计',
            'ESTIMATE_SHIPPING': '估算运费',
            'THIRD_PARTY_LINK_DISCLAIMER': '您在此页面提交的链接属于第三方商品链接（如：闲鱼、小红书、Bilibili、yupoo等），Tendabuy仅可以帮助您寻找该链接商家进行商品咨询、代购以及安排第三方物流寄送服务。Tendabuy无法辨别商品质量及真伪，需要您承担相关风险，才可进行下单。',
            'BUYING_AGENT_GUIDE': '代购须知',
            'PRODUCT_DETAILS': '商品详情',
            'AGREE': '我同意',
            'BUY_NOW': '立即购买',
            'CUSTOM_ORDER_REQUIRED': '定制订购 （*必填项）',
            'UPLOAD_DESCRIBE_PRODUCT_1': '1. 您可以上传图片，或者描述您想购买的商品。',
            'UPLOAD_DESCRIBE_PRODUCT_2': '2. 买手会根据您提供的要求尽可能寻找符合的产品，买手会在24小时内与您进行信息确认。',
            'UPLOAD_DESCRIBE_PRODUCT_3': '3. 如果买手找到商品但您不想要，服务费无法退还。',
            'PRODUCT_IMAGE': '商品图片',
            'ORDER_REMARK': '备注',
            'PRICE_RANGE': '价格范围',
            'ENTER_PROD_DESC': '请输入商品描述',
            'QUANTITY': '数量',
            'WAREHOUSE_SHIPPING_FEE_CNY_10': '将寄往Tendabuy仓库;服务费CNY 10/每单',
            'BUYER_QUALITY_DISCLAIMER': '免责声明： 买手无法辨别商品质量、真伪，对于商品的售后，我们会尽力替您与商家协商，但我们无法保证商品的售后。如果您想要使用此服务，您需要自行承担风险，否则我们无法替您继续购买。',
            'CUSTOMIZE_NOW': '立即定制',
            'PRODUCT_IMAGE2': '商品图片',
            'CONFIRM_AGREE': '我同意',
            'PRODUCT_NAME': '商品名称',
            'PRODUCT_NAME2': '产品名称',
            'PRODUCT_SPECIFICATIONS': '商品详情',
            '': '已使用',
            'PRODUCT_SPECIFICATIONS2': '商品规格',
            'TRANSACTION_TIME': '交易时间',
            'VIEW_STATEMENT': '查看账单',
            'TRANSACTION_ID': '交易编号',
            'REMARKS': '备注',
            'NO_DATA_FOUND': '暂无数据',
            'PARCEL': '包裹',
            'PRIORITY_SHIPPING': '优先配送',
            'RECHARGE_REQUIRED': '需要充值',
            'IN_TRANSIT': '在路上',
            'RECEIVED': '已收货',
            'CANCELLED': '已取消',
            'REVIEWABLE_PRODUCTS': '可评论的产品',
            'POSTED_REVIEWS': '已发布的评论',
            'EMPTY_FIELD': '空',
            'SEARCH_ORDER_NUMBER': '搜索订单编号',
            'RELATED_ORDER_NO': '关联单号',
            'COUPONS_SECTION': '优惠券',
            'AVAILABLE_COUPONS': '可用优惠券',
            'USED_COUPONS': '已使用优惠券',
            'COUPON_EXPIRED': '优惠券已过期',
            'NO_ACTIVE_COUPONS': '您还没有优惠券',
            'MY_POINTS': '我的积分',
            'POINTS_THIS_MONTH': '本月积分兑换',
            'REDEMPTION_DETAILS': '兑换详情',
            'REDEMPTION_RECORD': '兑换记录',
            'RELATED_ORDER_NO2': '关联单号',
            'RELATED_NUMBER': '相关编号',
            'REDEMPTION_METHOD': '兑换方式',
            'TENDABUY_POINTS': '腾达币',
            'STORAGE_FEES': '仓储费用',
            'REDEMPTION_DATE': '兑换日期',
            'REMARK': '备注',
            'REDEMPTION_ACTION': '操作',
            'REDEEMED_ITEMS': '兑换物品',
            'ORDER_INQUIRIES': '订单咨询',
            'PARCEL_INQUIRIES': '包裹咨询',
            'AFTER_SALE_INQUIRIES': '售后包裹',
            'OTHER_ENQUIRY': '其他查询',
            'QUERY_TYPE': '类型',
            'REMARK2': '备注',
            'QUERY_CONTENT': '咨询内容',
            'SELECT_ALL': '全选',
            'QUERY_STATUS': '状态',
            'WELCOME_NO_QUERIES_MSG': '"欢迎来到“查询”页面，目前没有任何查询，\n\n您可以随时点击右侧的“客服”按钮给我们留言。"',
            'SELECT_ALL2': '全选',
            'SYSTEM_MESSAGES': '系统消息',
            'MANUAL_MESSAGES': '手动消息',
            'STATUS': '状态',
            'NO_UNREAD_INBOX_MESSAGES': '您还没有任何未读的站内消息',
            'STATUS2': '状态',
            'STATUS3': '状态',
            'PHONE': '电话',
            'SUBMISSION_TIME': '提交时间',
            'LANGUAGE': '语言',
            'SUBMISSION_TIME2': '提交时间',
            'VIEW_GROWTH_RECORDS': '查看成长值记录',
            'POINTS_TO_LEVEL_UP': '获取3000积分即可升级到V1级别',
            'SIZE_INFORMATION': '我的尺码信息',
            'MY_PRIVILEGES': '我的特权',
            'LEVEL_BENEFITS': '等级特权',
            'INTERNATIONAL_SHIPPING_DISCOUNT': '国际运费折扣',
            'INSURANCE_DISCOUNT': '保险折扣',
            'ADDED_SERVICE_DISCOUNT': '增值服务折扣',
            'RETURN_EXCHANGE': '免退换货操作费',
            'FEE_USED_AVAIL': '已用/可用(月)',
            'CUSTOMIZED_PHOTO_USED_MONTHLY': '定制拍照',
            'MY_RANK': '我的等级',
            'LEVEL_VALUE': '价值',
            'AUTO_PAY_AUTHORIZATIONS': '自动支付授权，启用小额自动支付，订单/包裹处理',
            'ENABLE_MICRO_PAYMENTS': '授权类型',
            'AUTHORIZATION_TYPES': '国内运输',
            'DOMESTIC_SHIPPING': '欠付',
            'UNPAID_TRANSPORT': '国际运输',
            'TRANSACTION_LIMIT': '单笔限额',
            'DAILY_LIMIT': '每日限额',
            'AVAILABLE_LIMIT': '可用配额',
            'STATUS_OPERATIONS': '状态',
            'ORDER_TAGGING': '操作',
            'TAGGING_FOR_EFFICIENCY': '修改',
            'TRANSACTION_LIMIT2': '每笔交易限额',
            'TAG_NOT_FOUND': '未找到您正在寻找的标签',
            'PRODUCT_TAGS_EXPLANATION': '什么是产品标签？\n产品标签是一种更灵活、更有趣的分类物品的方式，通过为订单中的每个物品添加一个或多个标签来实现。',
            'BENEFITS_OF_TAGS': '添加标签的好处\n通过在“购物车”、“订单”和“仓库”页面上添加自定义标签，您可以更高效地筛选和管理您的订单。',
            'HOW_TO_APPLY_TAGS': '如何添加标签？\n您可以在“购物车”、“订单”和“仓库”页面上添加自定义标签。',
            'HIGH_RES_TEMPLATES': '高清照片模板 保存额外的照片模板，并通过简单的点击应用模板',
            'ADVANCED_PHOTO_TEMPLATES_USAGE': '详细照片模板是如何工作的？\n在结账、我的订单和我的仓库页面中添加详细照片模板，然后仓库工作人员可以在商品到达仓库后按照模板要求拍摄产品照片。',
            'DETAILS_PHOTO_TEMPLATES_WORKFLOW': '可以添加到详细照片模板的信息\n编辑完照片模板后，只需选择模板，仓库工作人员将按照模板拍摄您的物品照片，包括水印、照片数量、拍摄角度和细节等。',
            'INFO_IN_DETAIL_TEMPLATES': '如何添加详细照片模板？\n您可以在“购物车”、“订单”和“仓库”页面上添加自定义标签。',
            'ADDING_DETAIL_TEMPLATES': '邮件和应用程序通知管理\n勾选我们应该通知您的内容。',
            'UNREAD_MESSAGES': '未读',
            'AUTO_PAY_ACTIVE': '自动支付功能已启用',
            'COUPON_CLAIMED': '已领取优惠券',
            'COUPON_EXPIRING_SOON': '优惠券即将过期',
            'COUPON_USED': '优惠券已成功使用',
            'OPEN_BOX_REWARD_GRANTED': '开箱奖励申请成功！',
            'OPEN_BOX_REJECTED': '开箱奖励申请被拒绝',
            'FINANCIAL_OPERATIONS': '财务',
            'RECHARGE_COMPLETED': '充值成功！',
            'WITHDRAWAL_SUCCESS': '提现成功',
            'ORDER_AUTO_PAYMENTS': '订单自动支付',
            'PARCELS': '包裹',
            'PARCEL_SHORTAGE_REFUND': '包裹差额退款',
            'REHEARSAL_DONE': '排练完成',
            'PARCEL_SUBMITTED': '包裹已提交!',
            'RISK_CONTROL': '包裹进入风控',
            'PARCEL_CANCELLED': '包裹取消',
            'SHIPPED_PARCEL': '包裹已发货',
            'PARCEL_UNPAID': '包裹欠费',
            'PARCEL_PAID': '包裹已支付',
            'PARCEL_RECHARGED': '包裹已充值',
            'REHEARSAL_CANCELED': '排练取消',
            'INSURANCE_CLAIM_APPROVED': '保险理赔已批准',
            'INSURANCE_CLAIM_DENIED': '保险理赔被拒绝',
            'CLAIM_RECEIVED': '您的保险理赔申请已收到',
            'ORDER_SUBMITTED': '订单',
            'ORDER_UNDER_REVIEW': '订单进入风控',
            'DOMESTIC_SHIPPING_UNPAID': '国内运费欠费',
            'ORDER_PAYMENT_PENDING': '订单欠费',
            'CONFIRMATION_NEEDED': '需要确认',
            'ORDER_STORED': '订单已存储',
            'PARTIAL_SHIPPING': '分开发货',
            'RETURN_REQUEST_ACCEPTED': '退货请求已被接受',
            'RETURN_REQUEST_CANCELED': '退货请求已取消',
            'EXCHANGE_REQUEST_ACCEPTED': '换货请求已被接受',
            'EXCHANGE_REQUEST_CANCELED': '换货请求已取消',
            'ORDER_RETURNED_SUCCESSFULLY': '订单成功退回',
            'ORDER_EXPIRING': '订单即将过期',
            'ORDER_OVERDUE': '订单已逾期',
            'PAID_PHOTO_COMPLETE': '付费照片已完成',
            'ORDER_RECHARGED': '订单已充值',
            'DOMESTIC_SHIPPING_RECHARGED': '订单的国内运费已充值',
            'SHIPPING_REFUND_DONE': '订单运费已退款',
            'ORDER_RETRACTED': '订单已撤回',
            'ORDER_CANCELED': '订单已取消',
            'ORDER_PAID': '订单已支付',
            'QUALITY_CHECK_ALERT': '质量检验提醒',
            'AGENT_ORDER_WARNING': '代理商订单提醒',
            'OVERDUE_ORDER_DELETION_IMMINENT': '逾期订单即将被销毁',
            'ORDER_DELETED_OVERDUE': '订单已逾期并已销毁',
            'QUICK_WATERMARK_SETUP': '质检照片水印\n立即设置，以便您的质检照片带有您选择的水印。',
            'WATERMARK_SYSTEM': '系统水印',
            'NO_WATERMARK': '无水印',
            'SOURCE_PRODUCT': '本商品来源',
            'SOURCE_PLATFORM': 'alibaba平台',
            'SOURCE_ALIBABA_TENDABUY': 'Tendabuy可委托采购商为您代购',
            'CERTIFIED_WAREHOUSE': 'Tendabuy认证仓库',
            'YOUR_LOCATION': '你的地址',
            'INTERNATIONAL_SHIPPING_ESTIMATE': '估算国际运费',
            'BUYING_HOURS_09_TO_18_UTC8': '支付后，采购商会在09:00-18:00（UTC+8）为您进行代购服务',
            'REPORT_ISSUE': '举报',
            'FORCE_REFRESH_PAGE': '强制刷新',
            'MIN_ORDER_QUANTITY': '最低起批数量为',
            'ORDER_FOLLOW_LINK': 'Tendabuy会严格按照下单链接去替您购买，如果您对订单有疑问，请随时联系在线客服。',
            'CONTACT_SUPPORT_ANYTIME': '展示商品均来自第三方平台，Tendabuy只提供信息搜索、代购、寄送服务。Tendabuy无法鉴定商品质量、真伪，使用Tendabuy代购服务即默认接受以上风险。',
            'UNREAD_MESSAGES2': '未读',
            'SPECIFICATIONS': '规格参数',
            'USERNAME': '用户名',
            'USERNAME2': '用户名',
            'FIND_SIMILAR': '找相似',
            'affiLIATE_SHARE': '推广分享',
            'FAVORITE': '收藏商品',
            'MONTHLY_SALES': '月销量',
            'ENTER_STORE': '进入店铺',
            'CATEGORY': '商品分类',
            'MORE_SERVICES': '更多服务',
            'ADDITIONAL_FEATURES': '更多功能',
            'RESTRICTED_ITEMS_TITLE': '禁运',
            'RESTRICTED_ITEMS_CONTENT': '·烟、酒、违禁品不承运',
            'RESTRICTED_ITEMS': '违禁品包括但不限于以下商品：瑞士军刀，全套厨房刀具，菜刀，裁纸/切纸/拆信刀,园艺/刀/剪/锯,拆线器/拆线刀,纯果蔬汁,充气打火机,葡萄酒,油灰刀,芝士/西式奶酪,大闸蟹,竹剑/木刀,军刀卡/万能刀卡,冰刀鞋,其他户外刀具,磁性元器件,瑞士军刀,立铣刀,雕刻刀,烟标/烟卡,金刚铃/金刚杵／钺刀，种子等。',
            'LIMITED_GOODS_NOTICE_TITLE': '限购',
            'LIMITED_GOODS_NOTICE': '限购\n·药品、液体、膏状货物、化妆品粉末属于中国海关限制出口货物。订购前请咨询 【客服】',
            'CONSULT_BEFORE_ORDER_TITLE': '温馨提示',
            'CONSULT_BEFORE_ORDER': '【关于海关物流】由于各国依据各国海关政策差异，部分国家或地区对特殊商品较为敏感，所以会出现禁运、限购物品、物流线路限制及体积重量限制的情况。',
            'BEIAN_RIGHT': 'Copyright @ 2024 Tendabuy.com All rights reserved',
            'BEIAN': '专门为全球用户提供淘宝、1688、微店等中国购物网站产品，提供书籍、特产、化妆品等各类产品的代产品，支持PayPal、支付宝等支付方式\n',
            'GOODS_DETAIL_TIP1': '您购买的商品，会寄送到TendaBuy仓库，到达仓库后，我们会替您检查商品、并且拍摄商品照片。',
            'GOODS_DETAIL_TIP2': '您可以在[我的仓库]查看、管理商品',
            'GOODS_DETAIL_TIP3': '如果您对到达TendaBuy仓库的商品不满意，可以在到达仓库5天内申请退换货，采购会代您跟商家协商处理售后',
            'TIP2': '给采购的留言，没有特殊采购要求则不写。',
            'SERVICE_ONE_WEBSITE': '一站式服务',
            'PURCHASE_ORDERS': '订单购买',
            'PURCHASE_ORDERS_CONTENT': '我们将帮助您购买中国所有线上、线下渠道的商品，您只需粘贴商品链接完成下单，我们将提供全方位的代购服务保障。',
            'SHIP_TO_WAREHOUSE': '商家发货至仓库',
            'SHIP_TO_WAREHOUSE_CONTENT': '您可以通过我们购买不同商家的商品，这些商品都会汇聚至Tendabuy中国仓库。',
            'QUALITY_CHECK': '质检称重',
            'QUALITY_CHECK_CONTENT': '您的商品到达仓库后，我们将会为您的商品进行验收质检并将结果反馈给您，您将在Tendabuy得到商品售后服务保障。',
            'GLOBAL_SHIPPING_CONTENT': '您可以将寄存在仓库里的商品自由选择拼装成一个包裹，通过选择Tendabuy提供的可靠的物流商寄送到您的家里。',
            'HOT_LIST': '热门榜单',
            'ESTIMATION': '运费估算',
            'FORWARDING': '转运订单',
            'FILL_BUY': '手动填单',
            'FIND_BUY': '定制订购 ',
            'BEGINNERS_GUIDE': '新手指引',
            'HELP_CENTER_INDEX': '帮助中心',
            'USER_CENTER_INDEX': '个人中心',
            'LOGOUT_INDEX': '登出',
            'GUIDE_USING_IMAGE_SEARCH': '以图搜物使用指南',
            'MY_BALANCE': '我的余额',
            'POINTS': '积分',
            'My_COUPONS': '我的优惠券',
            'MY_GROWTH_POINTS': '我的成长值',
            'WITHDRAW': '提现',
            'PENDING_PAYMENT': '待支付',
            'RECHARGE_AMOUNT': '充值金额',
            'ENTER_THE_AMOUNT': '请输入金额',
            'DELETE_SUCCESSFUL': '删除成功',
            'SHOP_CART_NULL': '您的购物车当前是空的，去购物犒劳一下下自己',
            'ORDER_DETAILS2': '订单详情',
            'IN_PROGRESS': '处理中',
            'ORDERED': '已订购',
            'SENT': '已发送',
            'ARRIVED': '已到货',
            'STORAGE': '已入库',
            'CONTACT': '咨询',
            'TOTAL_PRICE2': '总价',
            'SEND': '发送',
            'ORDER_CONSULT_TIP': '采购员只能处理本订单相关业务，其他业务咨询请联系',
            'ONLINE_CUSTOMER': '在线客服！',
            'PRODUCT_AMOUNT': '商品金额',
            'WAREHOUSE_DELIVERY_FEE': '到仓运费',
            'STORAGE_TIME': '入库时间',
            'PRODUCT_TYPE': '商品类型',
            'BUSINESS_TYPE': '业务类型',
            'TRANSACTION_MODE': '交易方式',
            'Arrears_Info': '欠款指的是您未支付/未补款但我们优先帮您处理以免耽误时效的包裹费用、订单退换货运费或者是货到付款等费用。请及时偿还欠款，否则下次无法交易。',
            'Profile_Picture': '头像',
            'Modify_User_Info': '修改用户信息',
            'Female': '女',
            'Male': '男',
            'Currency': '币种',
            'Shopping_Cart': '购物车',
            'Total_Order_Price': '订单总价',
            'Proceed_To_Checkout': '去购物车结算',
            'Recipient': '收件人',
            'First_Name': '名',
            'Last_Name': '姓',
            'Region': '所在地区',
            'Detailed_Address': '详细地址',
            'ZIP_Code': '邮编',
            'Address_Info': '请输入公寓门牌号，街道地址等详细地址信息。 （请勿重复填写邮编，城市等信息）',
            'Phone_Number': '手机号',
            'Address_Label': '地址标签',
            'Home': '家',
            'Work': '工作',
            'Default_Address': '默认地址',
            'Login_Password': '登录密码',
            'Enter_Old_Password': '请输入原登录密码',
            'New_Password': '新的密码',
            'Enter_Password': '请输入密码',
            'Repeat_Password': '重复密码',
            'Reenter_Password': '请再次输入密码',
            'Verification_Code': '验证码',
            'Send_Code': '发送验证码',
            'Submit': '提交',
            'Custom_Template': '模版',
            'Popular_Searches': '最近大家都在搜索',
            'Homepage': '首页',
            'FAQs': '常见问题',
            'Service_Quality': '服务质量',
            'Service_Guarantee': '服务保障',
            'Sort_By_Comprehensive': '综合排序',
            'Rebuy_Rate_30Days': '近30天复购率',
            'Sort_By_Price': '价格排序',
            'Min': '最低',
            'Max': '最高',
            'Certified_Factory': '认证工厂',
            'Experience_Score': '综合体验分',
            'Five_Star': '5星',
            'FourPointFive_Five_Stars': '4.5星-5星',
            'Four_FourPointFive_Stars': '4星-4.5星',
            'Below_Four_Stars': '4星以下',
            'Pickup_Rate_24H': '24小时揽收率',
            'Pickup_Rate_48H': '48小时揽收率',
            'Same_Day_Shipping': '当日发货',
            'Shipping_24H': '24小时发货',
            'Shipping_48H': '48小时发货',
            'Seven_Day_Return': '7天无理由',
            'Free_Shipping': '支持包邮',
            'Reset': '重置',
            'Collapse': '收起',
            'More_Filters': '更多筛选条件',
            'Accepted': '已同意',
            'Terms': '《搜索服务条款》',
            'Search_Service_Terms': '搜索的商品均来自第三方平台，Tendabuy只提供信息搜索、代购、寄送服务。Tendabuy无法鉴定商品质量、真伪。',
            'Product_Info_Details': '同意《搜索服务条款》则为您展示搜索结果。',
            'Close_Page': '关 闭',
            'Merchant_Info': '商家',
            'Shipping_Fee': '运费',
            'Certified_Warehouse': 'Tendabuy认证仓库',
            'User_Message': '留言',
            'Sales_Volume_30Days': '该商品在近30天的商品销量值。部分商品可能存在误差，此数据来源第三方，可用来购买参考。',
            'Report_Reason': '举报原因',
            'Infringement_Report': '侵权举报',
            'Report_Record': '举报记录',
            'Next_Step': '下一步',
            'Select_Report_Reason': '请选择举报原因',
            'Previous_Step': '上一步',
            'Report_Description': '举报描述',
            'Image_Evidence': '图片凭证',
            'Submit_Report': '提交',
            'Select_Category': '请选择种类',
            'Agree_And_Proceed': '请阅读并同意后操作',
            'TendaBuy_Warehouse': 'TendaBuy仓库',
            'Country_for_Shipping': '请填写将要寄送的国家，Tendabuy采购员会根据填写国家，判断您的商品是否可以寄送。',
            'Shipping_Country': '选择寄送国家',
            'Order_Confirmation': '确认订单',
            'Store_Total': '店铺合计',
            'Payment_Total_Excl_SH_Fee': '待支付总价(国际运费需另计)',
            'Prohibited_Items': '《禁购商品声明》',
            'Service_Terms_Management': '《服务条款和用户管理》',
            'Return_Exchange': '《退换货服务》',
            'Disclaimer': '《免责声明》',
            'Read_and_Agree': '我已阅读并同意',
            'Shipping_Destination': '寄送至',
            'Sender_Information': '寄件人',
            'Return_to_Cart_for_Modification': '返回购物车修改',
            'Product_Information_Confirmation': '确认商品信息',
            'Subtotal': '小计',
            'Submit_Order': '提交订单',
            'Warehouse_Arrival_Notification': '温馨提示：订单提交并且支付成功后，请耐心等待商品入库。入库后可提交寄送商品。',
            'Payment_Due_By': '收银台',
            'Checkout_Successful': '订单提交成功！',
            'Available_Payment_Methods': '请在 之前支付，超时则将自动取消订单',
            'Current_Country': '当前国家',
            'Open_Payment_Channels': '已开放的支付渠道',
            'Balance_Recharge_Needed': '您的余额不足，请先充值后再进行支付',
            'Euro_Payment_Exchange': '当前订单结算币种为欧元。支持本地币种支付，支付银行会按照银行汇率进行币种兑换',
            'Global_Currency_Support': '覆盖202个国家或地区 支持25种货币，非美元币种支付时会产生货币兑换和手续费 当地合作银行的借记卡和贷记卡',
            'Total_Price': '总价',
            'Amount_Due': '应付款 ',
            'Handling_Fee': '手续费',
            'Settlement_RMB_Only': '只支持中国人民币结算',
            'Purchase_Amount_Discount': '商品满减优惠',
            'Negotiated_Price': '与商家协商后的价格',
            'Shipping_Fee_Discount': '运费优惠',
            'Mobile_Exclusive_Price': '手机端优惠价',
            'Incorrect_Price_Displayed': '页面价格显示有误',
            'Others': '其他原因',
            'Login': '或登录',
            'LOGIN_1': '登录',
            'Forgot_Password': '忘记密码？',
            'Sign_Up': '注册',
            'Email_Input': '请输入邮箱地址',
            'Password_Input': '请输入密码',
            'Verification_Code_Input': '请输入验证码',
            'TendaBuy_Reg_Agreement': '《TendaBuy 用户注册协议》',
            'AND': '和',
            'Privacy_Statement': '《隐私申明》',
            'Account_Existence': '已有账号？',
            'Password_Confirmation': '请再次输入密码',
            'Country_Selection': '请选择国家',
            'Email_Address_Input': '邮箱（请填写真实可用的邮箱）',
            'Invitation_Code_Optional': '请输入邀请码（选填）',
            'MY_PACKAGE': '我的包裹',
            'PENDING': '待处理',
            'PROCESSING': '处理中',
            'PROCESSED': '处理结束',
            'DAILY_GOODS': '日用品普货',
            'SPECIAL_GOODS': '非普货',
            'PAID': '已付款',
            'WAIT_PACK': '等待打包',
            'PACKED': '包裹已打包',
            'READY_SHIP': '待发货',
            'PROVINCE': '省份',
            'CITY': '城市',
            'ENTER_NAME': '请输入名字',
            'ENTER_AREA': '请输入地区',
            'ADDR_REQUIRED': '地址不能为空',
            'ZIP_REQUIRED': '邮编不能为空',
            'PHONE_REQUIRED': '手机号不能为空',
            'PHONE_FMT_WRONG': '手机号格式不正确',
            'COUNTRY_ADDR_REQ': '国家地址不能为空',
            'FILES': '文件',
            'PHOTO_TEMPLATE': '拍照模板',
            'ENTER_REMARK': '请输入备注描述',
            'SINGLE_PHOTO_REQ': '仅支持输入1个拍照要求，例如：拍照logo近照，测量手链尺寸之类的。',
            'PHOTO': '照片',
            'PHOTO_TIP': '温馨提示：一张照片仅提供拍摄一件商品的一个细节要求',
            'FREE_PHOTOS_FEE': '本月剩余免费定制拍照 0 张 服务费：',
            'SERVICE': '服务',
            'EDIT_SUCCESS': '修改成功',
            'SHIPPING_FEE': '运输费用',
            'SHIP_TIME': '运输时效',
            'CHARGE_WEIGHT': '计费重量',
            'FEATURES': '特点',
            'FEE_ITEM': '收费项目',
            'OPER_FEE': '操作费',
            'SETTLE_STD': '结算标准',
            'TOTAL_FEE_DET': '总费用详情',
            'PAY_TOTAL': '支付总金额',
            'MAIL_LIMIT': '邮寄限制',
            'WEIGHT_LIMIT': '重量上限',
            'MIN_LIMIT': '最低下限',
            'MAX_LENGTH_LIMIT': '最长边限制',
            'AFTERSALE_PERIOD': '售后查询周期',
            'COMP_BASIS': '赔偿依据',
            'FUEL_SURCHARGE': '燃油附加费',
            'AFTERSALE_SUPPORT': '是否支持售后查询',
            'COMP_STD': '赔偿标准',
            'COMP_PERIOD': '赔偿周期',
            'NO_DATA': '暂无数据',
            'ENTER_WEIGHT': '请输入重量',
            'NO_ROUTE_CFG': '还未开通该配置下的线路',
            'PLEASE_SELECT': '请选择',
            'HISTORY_SEARCH': '历史搜索',
            'TRIGGER_WIDTH_IS': '触发器的宽度是',
            'IMG_COMPRESS_FAIL': '图片压缩失败',
            'NO_STORE_FOUND': '未找到该店铺，请输入正确的店铺链接',
            'CREATE_NEW_TAG': '创建新标签',
            'DOMAIN_REQUIRED': '域名不能为空',
            'ENTER_TAG_NAME': '请输入标签名称',
            'CART_EMPTY': '购物车没有商品，赶快去添加吧！',
            'SUCCESS': '成功',
            'LIMIT_5_FILES': '当前限制选择 5 个文件',
            'SHIPPING_NOW': '发货中',
            'AWAIT_CUSTOMS': '等待海关验收',
            'ENROUTE_INTL': '国际途中',
            'ARRIVED_CNTRY': '已到目标国',
            'DOMESTIC_TRNSP': '目标国内运输',
            'DELIVERED': '已送达',
            'IMG_FMT_LIMIT': '上传图片只能是 JPG、JPEG、gif、bmp、PNG 格式!',
            'IMG_SIZE_10MB': '上传图片大小不能超过 10MB!',
            'PARAM_ERROR': '参数错误，请联系管理员',
            'SYS_ERROR': '系统错误，请联系管理员',
            'ENTER_PHONE': '请输入正确的手机号',
            'COPY': '复制',
            'SET_DEFAULT': '设置默认',
            'EDIT_ADDRESS': '修改地址',
            'PERMANENT_DELETE_ADDR': '此操作将永久删除该地址, 是否继续?',
            'DEL_ADDR_CONF': '删除收货地址',
            'ADDR_DELETED': '已取消删除',
            'V1_GROWTH_VAL': '距离V1-只需要-2个成长值',
            'INT_FEE_DISCNT': '国际运费减免',
            'INSURE_DIS_AMT': '保险优惠金额',
            'VALUE_ADD_DISC': '增值服务优惠',
            'UPLOAD_DESPER': '拼命上传中',
            'SELECT_DATE': '选择日期',
            'IMG_FORMAT_PNG': '上传图片只能是 JPG/PNG 格式!',
            'IMG_MAX_20MB': '上传图片大小不能超过 20MB!',
            'LIMIT_1_FILE': '当前限制选择 1 个文件',
            'VIEW_RECORDS': '查看记录',
            'TOPUP_AMOUNT': '请输入充值金额',
            'BALANCE_TOPUP': '余额充值',
            'MY_BILL': '我的账单',
            'SELLER_1': '卖家',
            'COPY_ADDRESS': '复制地址',
            'ZHU_JIAXUAN': '朱加旋',
            'PKG_NAME': '包裹名称',
            'COPY_SUCCESS': '复制成功',
            'COPY_FAIL': '复制失败',
            'FUJIAN_ADDR': '福建省 泉州市 丰泽区 齐云路152号华丰创业园三楼出电梯右手边',
            'ADD_TRACK_NUM': '请添加快递单号',
            'TRANSIT_ORDER': '转运单提交成功',
            'SEARCH_TRACK': '搜索快递单号',
            'SEARCH_KEYWORD': '搜索关键字',
            'RESULT': '结果',
            'READ': '已读',
            'SELECT_DATA': '请选择数据',
            'PKG_DETAILS': '包裹详情',
            'RECV_INFO': '收货信息',
            'DECLARE_NAME': '申报名称',
            'CURRENCY_1': '货币',
            'DECLARED_PRICE': '申报单价',
            'DECLARED_QTY': '申报数量',
            'TOTAL_DECLARED_VALUE': '申报总价值',
            'TOTAL': '总计',
            'TOTAL_VALUE': '总价值',
            'DECLARATION_INFO': '申报信息(自主申报)',
            'LOGISTICS_INFO': '物流信息',
            'PACKAGE_ID': '包裹编号',
            'DETAILS': '详情',
            'SHIPPED': '已发货',
            'CHOOSE_RECEIVING_ADDR': '选择收货地址',
            'CHOOSE_LOGISTICS_ROUTE': '选择物流线路',
            'AUTH_DEDUCT_DIFF': '授权扣除差额',
            'READ_AGREE_DISCLAIMER': '我已阅读并同意 "Tendabuy免责声明"',
            'ADDRESS': '地址',
            'SELECT': '选择',
            'CHOOSE_SERVICE_ROUTE': '选择包裹服务和路线',
            'MAIL_LIMIT_1': '邮限',
            'VOLUME': '体积',
            'ADD_SERVICE': '添加服务',
            'PRODUCT_PRICE': '商品价格',
            'CHOOSE_PACKAGE_SERVICE': '请选择包裹服务',
            'DECLARATION_ADVICE': '由于各物流路线都对申报品名及项目数量有限制，请根据系统提示进行选择、填写。如您对申报有疑问，可咨询在线客服',
            'DECL_VAL_UPPER_LIMIT': '申报总价值上限',
            'DECL_VAL_LOWER_LIMIT': '申报总价值下限',
            'DECL_ITEM_UPPER_LIMIT': '申报项目上限',
            'DECL_ITEM_LOWER_LIMIT': '申报项目下限',
            'ADD_DECLARATION_ITEM': '新增申报项目',
            'ENTER': '请输入',
            'TOTAL_QUANTITY': '总量',
            'BUY_INSURANCE': '购买保价',
            'INSURANCE_NOTICE': '保价须知',
            'INSURED_AMOUNT': '保价金额',
            'PREMIUM': '保费',
            'SERVICE_FEE': '服务费',
            'CHECK_URL_OR_HOME': '请检查您输入的网址是否正确，或单击下面的按钮返回主页。',
            'GO_HOME': '返回主页',
            'PAGE_NOT_FOUND': '找不到网页...',
            'BANNED_RESTRICTED_ITEMS': '【禁运和限购物品】',
            'TRACK_LOGISTICS': '查询物流',
            'PRODUCT_ISSUE': '商品异常',
            'PACKAGE_LOST': '包裹丢失',
            'REQUIRED_FIELD': '（*必填项）',
            'UPLOAD_LIMIT_5_2MB': '最多可上传5张,单张图片大小限制在2M以内(支持jpg、png格式)',
            'MIN_PRICE': '最低单价',
            'MAX_PRICE': '最高单价',
            'SHIP_TO_TENDABUY': '请寄往Tendabuy仓库;服务费CNY 10/每单',
            'ENTER_MAX_PRICE': '请输入最高单价',
            'MAX_GT_MIN_PRICE': '最高单价不能低于最低单价',
            'ENTER_MIN_PRICE': '请输入最低单价',
            'MIN_LT_MAX_PRICE': '最低单价不能高于最高单价',
            'AVATAR_JPG_ONLY': '上传头像图片只能是 JPG 格式!',
            'ADD_SUCCESS': '添加成功',
            'HAVE_ACCOUNT': '已有账号，',
            'BACK_TO_TENDABUY': '返回TENDABUY',
            'ACTIVATE_ACCOUNT': '激活账号',
            'EMAIL_ACTIVATED': '邮箱激活成功',
            'PASSWORD_REQUIREMENT': '密码限6-20字符，必须包含字母和数字',
            'PASSWORDS_NOT_MATCH': '两次输入密码不一致!',
            'VALID_EMAIL_REQUIRED': '请输入正确的邮箱地址',
            'CODE_REQUEST_FAST': '验证码请求过快',
            'LOGIN_SUCCESS': '登录成功',
            'PARTNER_LOW_COST': '携手国际众多快递公司，运费超低折扣',
            'LENGTH': '长',
            'WIDTH': '宽',
            'HEIGHT': '高',
            'SEARCH': '查询',
            'THIRD_PARTY_SHIPPING': '所有非TendaBuy自营线路的物流服务均由第三方物流商提供，且他们会根据市场行情调整运费价格，如有价格变动TendaBuy会提前通知。为提供极致的服务体验，如您在物流服务上遇到问题，TendaBuy可代您与第三方物流商协商沟通。',
            'LOGISTICS_NOTICE': '物流服务说明',
            'QUERY_SHIPPING': '请输入对应参数，查询运费！',
            'TO_TENDABUY_WAREHOUSE': 'Tendabuy 仓库寄往',
            'SEARCHING': '正在搜索',
            'FOUND_PRODUCTS': ',为你找到以下商品',
            'COMPREHENSIVE': '综合',
            'NO_PRODUCTS': '暂无商品',
            'LOADING': '加载中...',
            'NO_MORE': '没有更多了...',
            'FINAL_PRICE': '请您填写最终价格',
            'COUPON_RULES_EFFECT': '受购物券领取规则影响，领券或满减优惠可能无法享受，建议您修改的价格与卖家实际销售价格一致！',
            'PRICE_CHANGE_ADVICE': '请选择修改价格原因',
            'PRODUCT_RISK_NOTICE': '产品风险提示',
            'PRODUCT_RISK_ALERT': '您提交的产品可能存在-1-项风险，建议您仔细阅读风险后再考虑是否购买~',
            'BRAND_PRODUCT': '品牌产品',
            'BRAND_AUTHENTICITY': '本产品为-1-品牌产品，请注意辨别真伪',
            'UNDERSTOOD': '我明白了',
            'REPORT_DESC': '请输入举报描述',
            'MINIMUM_QUANTITY_ERROR': '商品数量不能小于最低起批数量',
            'FREE': '免费',
            'CANCEL_SUCCESS': '取消商品成功',
            'CANCEL_FAIL': '取消商品失败',
            'SAVE_SUCCESS': '收藏商品成功',
            'SAVE_FAIL': '收藏商品失败',
            'PASSWORD': '密码',
            'OR_CONTINUE': '或继续',
            'BACK_TO_PASSWORD_LOGIN': '返回密码登录',
            'OPEN_APP_HOME': '打开Tendabuy APP首页',
            'CONFIRM_PASSWORD': '确认密码',
            'YOUR_EMAIL': '您的邮箱',
            'EMAIL_INACTIVE': '未激活，想要继续使用请点击下方按钮，重新发送激活邮件',
            'RESEND_ACTIVATION': '重新发送激活邮件',
            'CHECK_ACTIVATION_EMAIL': '请前往您的邮箱查看激活邮件并验证你的账户。',
            'NEW_LOCATION_LOGIN': '您的帐户正在从新位置登录，请输入电子邮件中的验证码以继续。',
            'OPEN_LINK_ACTIVATE': '请查看邮件并打开链接来激活您的Tendabuy账户',
            'REGISTER_SUCCESS': '注册成功',
            'WINDOW_FAIL': '窗口打开失败',
            'LOGIN_SUCCESSFUL': '登录成功',
            'ACCOUNT_NOT_ACTIVE': '账号未激活',
            'READ_AGREE': '请阅读并同意说明',
            'ORDER_IDENTIFICATION': '订单标识',
            'DESCRIPTION_TEXT': '描述文字',
            'CUSTOM_PHOTO_SERV': '定制拍照是一项Tendabuy增值服务，每张收费1CNY。可根据自定义要求拍摄特定角度的商品照片，例如，你可以购买定制拍照，备注请帮我测量鞋垫长度、我需要查看衣服logo细节图、请测量卫衣胸围等要求',
            'REMAINING_FREE_PHOTO': '本月剩余免费定制拍照 -1- 张 服务费：',
            'PHOTO_QUALITY_PROMISE': '定制拍照-质量承诺',
            'TOTAL_PRODUCT_PRICE': '商品总价',
            'VALUE_ADDED_FEE': '增值服务费',
            'EDIT_REMARK': '修改备注信息',
            'SEND_1': '发 送',
            'CANCEL_PAYMENT': '取消支付',
            'CANCEL_PAYMENT_1': '取消支付？',
            'TRANSIT_SELF_SERVICE': '转运订单自助服务',
            'SERVICE_1': '【服务】',
            'FILE_SELECT': '选取文件',
            'HISTORY_MESSAGES': '历史消息',
            'COLLAPSE_MESSAGES': '收起消息',
            'EDIT_LABEL': '编辑标签',
            'ENTER_LABEL_COLOR': '请输入标识颜色',
            'MODIFY_LABEL': '修改标签',
            'DELETE_LABEL': '删除标签',
            'CANCEL_EDIT': '取消编辑',
            'SELF_CHECK_REMINDER': '自检提醒',
            'ORDER_REMINDER': '订单提醒',
            'SERVICE_2': '服务',
            'UPLOAD_FAIL': '文件上传失败:',
            'JPG_PNG_500KB_LIMIT': '只能上传jpg/png文件，且不超过500kb',
            'DELETE_LABEL_CONFIRM': '确认永久删除标签？',
            'WARNING': '警告',
            'CHOOSE_PRODUCT': '请选择商品',
            'CHOOSE_COLOR': '请选择标识颜色',
            'ADD_SERVICE_1': '添加服务',
            'STRENGTHEN_FRAME': '固脚+边框',
            'CALC_WEIGHT': '计算重量(g)',
            'PACKAGE_DECLARATION_ALERT': '包裹申报提醒',
            'NEW_DECLARATION_ITEM': '新增申报项目',
            'INSURANCE_PURCHASE': '购买保价',
            'INSURANCE_APPLICATION': '保价申请',
            'INSURANCE_SUGGESTION': '建议：保价金额=商品总价+物流费用',
            'SELECT_SERVICE': '选择服务',
            'BOX_STRENGTHENING': '固脚箱子包装',
            'ASSOCIATED_EMAIL': '请输入您账号的关联邮箱',
            'ENTER_ASSOCIATED_EMAIL': '请输入您绑定的邮箱',
            'SENT_EMAIL_SUCCESS': '邮件发送成功，请及时查看邮件',
            'PAYMENT_CANCEL': '支付取消',
            'CLOSE_1': '关闭',
            'SHIPPING_PAYPAL': '请保持商品实际寄送地址和paypal收货地址一致，否则交易会被审核，影响处理时效。',
            'PAYMENT_TIP': '温馨提示：请在打开的新页面进行支付操作，支付未完成，请勿关闭此窗口',
            'COMPLETED_PAYMENT': '若已经成功付款，请点击已完成支付',
            'PAYMENT_PROBLEM': '若支付遇到问题，您可以重新支付或点击反馈',
            'PAYMENT_FAIL_FEEDBACK': '支付失败反馈',
            'PAYMENT_SUCCESS': '已付款',
            'PAYMENT_FAIL': '支付失败',
            'ORDER_PROCESS_6_HOURS': '你的订单将在 6 工作小时内处理 (部分第三方平台除外)',
            'ATTENTION_ITEMS': '请注意以下事项：',
            'PACKAGE_SUBMIT_SELF': '商品不会寄送给您，您需自主提交包裹',
            'AFTER_STORAGE_SUBMIT': '商品入库后，可在 "用户中心-我的仓库" 中选中商品，提交包裹，选择物流支付国际运费。',
            'WATCH_INTERNAL_INFO': '关注站内信息',
            'COMMUNICATE_ISSUES': '订单处理过程中，有任何问题，我们会及时通过站内消息与您沟通协商。',
            'CONTINUE_SHOPPING': '继续购物',
            'VIEW_ORDER': '查看订单',
            'RECHARGE_NOT_EMPTY': '充值金额不能为空',
            'SIMULATE_PACKAGE': '预演包裹',
            'MY_PARCEL': '我的包裹',
            'TENDABUY_AUDIT_7DAY': 'Tendabuy将在7个工作内审核，感谢你的反馈',
            'REPORT_ID': '举报编号',
            'REPORT_TIME': '举报时间',
            'REPORT_TYPE': '举报类型',
            'REPORT_PRODUCT': '举报商品',
            'REPORT_PROGRESS': '举报进度',
            'STORE_FAVORITES': '店铺收藏',
            'SORT': '排序',
            'PRICE': '价格',
            'REORDER_RATE': '复购率',
            'FAVORITE_SUCCESS': '收藏成功',
            'UNFAVORITE_SUCCESS': '取消收藏成功',
            'EXTEND_STORAGE': '延长保管',
            'STORAGE_EXTRA_DAYS': '您的商品在仓库的保管时间将会延长（30*N）天，到期时间为',
            'CANCEL': '取 消',
            'CONFIRM': '确 定',
            'MONTHS': '个月',
            'SERVICES': '服务',
            'SELECT_STORAGE_TIME': '请选择保管期',
            'ENTER_PRODUCT_LINK': '请输入商品链接',
            'ENTER_PRODUCT_NAME': '请输入商品名称',
            'ENTER_PRODUCT_SPEC': '请输入商品规格',
            'IMAGE_LIMIT_2MB': '单张图片大小限制在2M以内（支持jpg、png格式）',
            'PRICE_UNKNOWN_INPUT': 'Tendabuy无法辨别第三方平台商品价格，需要您在下方输入价格，如果您也不清楚商品的价格，可以填写1元下单，Tendabuy买手接到订单后，会与商家取得联系，再向您发起商品补款。',
            'SELLER_SHIP_FEE': '请填写卖家到 tendabuy仓库的运费',
            'TOTAL_AMOUNT_RMB': '总计金额 = 商品费用 + 到仓运费 换算标准（以人民币为基准）',
            'ENTER_UNIT_PRICE': '请输入单价',
            'PRODUCT_NAME_100CHAR': '请输入商品名称（限100字符）',
            'SELLER_FEE_TENDABUY': '请填写卖家到Tandabuy仓库的运费',
            'HTTP_LINK_REQUIRED': '请输入商品链接，并以http://或https://开头',
            'SPECIFY_COLOR_SIZE': '为了更准确地为你购买商品，请在此处留下颜色/尺码等规格信息。',
            'CLICK_COUNT': '点击数',
            'RECORD': '记录',
            'VIEW': '查看',
            'SIGNED': '已签收',
            'PAID1': '已付款',
            'ITEM_MISSING': '找不到该商品',
            'SECURITY_SET_2FA': '您的安全级别为"-1-"为了保障您的账户安全 请设置双重认证',
            'SECURITY_LOW': '您的安全级别为 "-1-"',
            'CHANGE_PWD': '修改支付密码',
            'OLD_PWD': '原支付密码',
            'PWD_MIN_6': '密码要大于等于6位',
            'ENTER_OLD_PWD': '请输入原支付密码',
            'LOGIN_PWD_OK': '登录密码修改成功',
            'PAY_PWD_OK': '支付密码修改成功',
            'VALUE_ADD': '增值服务',
            'ORDER_AMOUNT': '订单金额',
            'REAUTH': '是否补款授权',
            'ITEMS_QTY': '商品数量',
            'PARCEL_INFO': '包裹信息',
            'EST_SHIPPING': '预估运费',
            'EST_WEIGHT': '预估重量',
            'EST_VOLUME': '预估体积',
            'ACT_SHIPPING': '实际运费',
            'ACT_WEIGHT': '实际重量',
            'ACT_VOLUME': '实际体积',
            'RECIPIENT': '收货人',
            'CUBIC_CM': '立方厘米',
            'TOTAL_WEIGHT': '总重量',
            'TOTAL_VOLUME': '总体积',
            'LOG_ROUTE': '物流路线',
            'TRACK_NO': '物流单号',
            'LOG_STATUS': '物流状态',
            'ACT_AMOUNT': '实付金额',
            'SELF_DECLAR': '自主申报',
            'PARCEL_SERVICE': '包裹服务',
            'ORDER_SERVICE': '订单服务',
            'AGREE_TNC': '请先同意协议声明',
            'DECLAR_EXCEED': '申报项目过多',
            'FILL_ITEMS': '请填写申报项目',
            'SELECT_ROUTE': '请选择路线',
            'PRE_FEE': '预演操作费',
            'IMG_2MB': '上传头像图片大小不能超过 2MB!',
            'MODIFY_PRICE': '修改价格',
            'STOCK': '库存',
            '待删除': '扫一扫登录',
            'ACTIVATE_EMAIL': '激活邮箱',
            'EMAIL_SENT': '邮件发送成功',
            'TEMPLATE': '模板',
            'PENDING_CON': '待确认',
            'WEIGHT_MAX': '重量上限',
            'WEIGHT_MIN': '重量下限',
            'AFTER_SALES_DE': '售后服务描述',
            '与上面重复': '账号安全',
            'INVALID_PARAMS': '参数错误',
            'PWD_RESET_SU': '密码重置成功',
            'PAY_BE_TIMEOUT': '请在-1-之前支付，超时则将自动取消订单。',
            'CON_PAYMENT': '确认付款',
            'PAY_ISSUE': '支付遇到问题',
            'RE_DETAILS': '举报详情',
            'LOGISTICS_LABEL': '此标签只保留在Tendabuy系统，用于识别物流线路限制，商品或包裹上不会有此类信息。',
            'NEWBIE_GUIDE': '新手教程',
            'MEMBER_GIFT': '800元新人注册礼券',
            '删掉': '注册指南',
            'TUTORIAL': '代购演示',
            'SERVICE_FEES': '服务与费用',
            'RETURNS_REFUNDS': '退换货服务',
            'CUSTOMER_SERVICE': '客户服务',
            'PAYMENT_DEPOSIT': '支付与充值说明',
            'STORAGE1': '商品保管期',
            'INSPECTION_RULES': '验货规则',
            'INSURANCE_COM': '保险及赔偿',
            'DELIVERY': '配送相关',
            'CHARGES': '物流运费',
            'PACKAGING': '集中打包',
            'CUSTOMS_TAX': '海关及税项',
            'RECEIPT_INFORMATION': '收货注意事项',
            'LOGISTICS_TRACKING': '物流查询',
            'ABOUT_US': '关于我们',
            'TENDABUY': 'Tendabuy',
            'TERMS_OF_USE': '用户协议',
            'PRIVACY_SECURITY': '隐私声明',
            'LEGAL_NOTICE': '法律声明',
            'CONTACT_US': '联系我们',
            'TRACK_INFO': '如何查询包裹的物流信息？',
            'DELIVERY_AWARENESS': '包裹收货注意事项有哪些？',
            'DAMAGED_ITEM': '易碎品如果损坏怎么办？',
            'PROBLEMATIC_PRODUCT': '我在收到包裹后才发现代购的商品有问题怎么办？',
            'WAREHOUSE_PROBLEM': '商品到达Tendabuy仓库后发现有问题怎么办？',
            '上面已经有了': '我同意',
            'MULTIPLE_CHINA': 'Multiples Shopping Platform From China',
            'COMPETITIVE_ITEMS': 'Offer competitive prices on millions of online & offline items',
            'COMBINE_DELIVER': 'Combine items from various sellers into one package & deliver to you',
            'COVERING_SAFE': 'Covering more than 200 countries worldwide, safe and reliable',
            'PURCHASE_TENDABUY': '提交代购订单',
            'CHECK_IN': '签收验货入库',
            'SEND_PKG': '提交国际包裹',
            'VIEW_MORE': 'View More',
            'MORE_NEWS': 'STAY CONNECTED',
            '购物车支付界面': 'Item Category',
            'GOODS_INTER_CONTENT': '给采购的留言，没有特殊采购要求则不写。',
            '收藏夹页面': '请输入内容',
            'OFF_500': '满￥500.00减￥50.00',
            'COUPONS': '运费券',
            'NO-THRESHOLD_COUPON': '￥20.00无门槛',
            'OVER_500': '满￥500.00可用',
            '20%_OFF': '8折',
            'APPLICABLE_COUNTRY': '使用国家',
            'APPLICABLE_LINE': '使用线路',
            'ALL_COUNTRIES': '全部国家',
            'USAGE_RULES': '使用规则',
            'USE': '立即使用',
            'CONVERT': '兑换',
            'ENTER_REDEEM': '请输入兑换码',
            'AVAILABLE_COUPON': '可使用优惠券',
            'USED_COUPONS0': '已使用优惠券',
            'EXPIRED_COUPON': '已过期优惠券',
            'ANY_COUPONS': '您还没有优惠券',
            'DO_YOU_DELETE': '是否确认删除?',
            'COLLECTED_PRODUCTS': '还没有收藏任何商品，快去收藏',
            'COLLECTED_SHOP': '还没有收藏任何店铺，快去收藏',
            'ORDER_DETAIL': '订单信息',
            'PACKAGE_NUMBER': '预演包裹编号',
            'SUBMIT_TIME': '提交时间',
            'VALUE_ADD_SERVICE_FEE': '增值服务费',
            '前面有': '订单金额',
            'GOODS_QUANTITY': '商品数量',
            'PARCEL_INFO2': '包裹信息',
            'ESTIMATED_FREIGHT': '预估运费',
            'ESTIMATED_WEIGHT': '预估重量',
            'ESTIMATED_VOLUME': '预估体积',
            'FINAL_FREIGHT': '实际运费',
            'EXACT_WEIGHT': '实际重量',
            'EXACT_VOLUME': '实际体积',
            'DETAILED_COSTS': '费用明细',
            'PRE-PACKAGING_OPERATION_FEE': '预演操作费',
            'RECIPIENTS_INFO': '收货信息',
            'RECIPIENT2': '收货人',
            'MOBILE_PHONE': '电话',
            'SHIPPING_ADDRESS2': '收货地址',
            'SHIPPING_LINE': '物流路线',
            'SHIPPING_ID': '物流单号',
            'SHIPPING_STATUS': '物流状态',
            'SHIPPING_INFO': '物流信息',
            'STATUS1': '状态',
            'PARCEL_PAGE': '包裹订单支付成功后订单将进入“我的包裹”页面，我们会安排运送您的商品',
            'SELECT_LOGISTICS': '请选择物流线路',
            'NO_SELECTED': '未选择路线',
            'INTENTIONAL_ROUTE': '意向物流',
            'SELECT_ROUTE1': '选择路线',
            'SUCCESSFULLY_CANCELED': '取消成功',
            'TOTAL0': '总费用',
            'ENQUIRY_PAGE': '欢迎来到我的咨询页面，您目前没有任何咨询问题，您可以随时点击右侧客服按钮给我们留言',
            'UNDER_2MB': '上传图片大小不能超过 2MB!',
            'TRACK_PARCEL': '如何查询包裹的物流信息？',
            'NOTICES_RECEIVING': '包裹收货注意事项有哪些？',
            'PRODUCTS_DAMAGED': '易碎品如果损坏怎么办？',
            'ISSUE_PRODUCT': '我在收到包裹后才发现代购的商品有问题怎么办？',
            'APPLY_AFTERSALES': '商品到达Tendabuy仓库后发现有问题怎么办？',
            'LEAVE_INFO': '请留下商品描述、规格备注等信息',
            'AMOUNT_LESS': '输入的最高单价不能小于0',
            'AMOUNT_MORE': '输入的最低单价不能小于0',
            'COMMON_BRAND': '普通品牌',
            'INTERNATIONAL_BRAND': '奢侈品牌',
            'COMMON_BATTERY': '普通品牌（带电）',
            'COMMON_WITHOUT_BATT': '普通品牌（不带电）',
            'REMOVE_ORDER': '删除订单',
            'COMBINE_PAYMENT': '合并付款',
            'GO_PLACE': '去下单',
            'PLACE_AN_ORDER': '还没有任何订单，去下单',
            'ORDERS_UNPAID': '请检查订单是否全部为未付款',
            'RESET_SUCCESSFUL': '重置密码成功',
            'WRONG_PAYMENT': '支付遇到问题',
            'WITHDRAWAL_AMOUNT': '提现金额',
            'MODIFY_REMARK': '修改备注',
            'SELECT_DESTINATION': '请选择目的地',
            'LESS_THAN': '输入的单价不能小于0',
            'LESS_THAN2': '输入的到仓运费不能小于0',
            'PARCEL_NO': '包裹编号',
            'ORDER_SERVER_FEE': '订单服务费用',
            'NO_THRESHOLD': '无门槛',
            'UP_20OFF': '最多可优惠￥20.00',
            '50_OFF': '5折券,最多可优惠￥20.00',
            'ALL_LINES': '国际快递-全部线路',
            'IN_PACKING': '已到仓',
            'CONFIRM_REFUND': '确定需要删除订单吗？该商品不会退回给卖家来得到退款',
            'REMINDER': '提示',
            'QUALITY_PHOTOS': '普通拍照',
            'ENTER_PARCEL': '请备注名称',
            'SHIPPING_COMPANY': '物流公司',
            'SHIPPING_EXPRESS_NO': '单号为',
            'NO_STATUS': '暂无1111的快递状态',
            'REFUND': '退款',
            'ORDER_ID': '订单号',
            'REQUEST_REFUND': '申请退款',
            'REASON': '申请理由',
            'SELECT_REASON': '请选择申请理由',
            'DESCRIPTION': '描述',
            'IN_DESCRIPTION': '请输入原因',
            'REFUNDED': '申请退款成功',
            'REVIEWS': '我的评论',
            'REDEMPTION': '兑换成功',
            'BLOG': '博客',
            'COMPLETED': '已完成',
            'AFTERSALES': '退款中',
            'REFUNDED2': '已退款',
            'NO_LONGER': '不想要了',
            'PRICE_CHANGE': '价格变化',
            'OUT_OF_STOCK': '暂时缺货',
            'WRONG_SIZE': '买错尺码、颜色',
            'LATE_DELIVERY': '一直未发货',
            'QUALITY_ISSUES': '严重质量问题',
            'NOT_SAME': '与描述不一致',
            'INCORRECT': '收到错误商品',
            'YES': '是',
            'NO': '否',
            'BUY_SHIP': '购中国好物 达全球门户',
            'SHOP_CHINA': 'Shop China',
            'CHOOSE_TENDABUY': 'Choose Tendabuy',
            'NOT_FOUND': '没有找到该商品',
            'PARTNERS': 'Partners',
            'VALUE_MONEY': 'Value-For-Money',
            'ADDED_SERVICE': 'Value-Added Service',
            'LOGISTICS': 'Logistics Provider',
            'DESTINATION': '请选择地区',
            'REFUND_RETURN': '1.您的退款/退货申请,买手会在24小时内受理并与卖家沟通协商处理。(请您根据实际情况选择原因,如您选择的原因与实际原因不致,将以商家最终核实的责任为准。)',
            'RETURN_TIME': '2.能否退货,以及退货退款时间取决于卖家,大约7-15个工作日。',
            'CLOSE_SERVICE': '3.商品入库超过5天卖家将拒绝无理由退货,入库超过10天卖家将关团售后入口,无法发起退货申请。',
            'APPLYING_RETURN': '4.申请退货前,请您仔细阅读→点击 ',
            'REFUND_POLICY': '《代购退货退款须知》',
            'NOT_LOGGED': '您还未登录',
            'REPORT': '请输入举报描述',
            'STOCK_0': '该商品库存为0不能下单',
            'OK': '我知道了',
            'REMIND': '今日不再提醒',
            'READ_ACCEPT': '我已了解并接受上述内容',
            'FORWARDING_SERVICE': '转寄是指你自己在中国购买的商品或你的亲戚朋友寄给你的商品需要运到我们的仓库，然后使用便捷的国际物流服务寄往海外。',
            'TRACKING': '请检查提交的追踪号码是否正确。',
            'TRANSFER': '转运订单确认',
            'LENGHTH': '实际长度',
            'PRE_PACKAGE': '预演单号',
            'EXACT_WIDTH': '实际宽度',
            'EXACT_HEIGHT': '实际高度',
            'CANCEL_REASON': '请输入取消支付原因',
            'CANCELLATION': '取消后订单无法恢复，请选择取消支付原因',
            'REFUND_REASON': '请选择退单原因',
            'NO_ORDER': '我不想买了',
            'INFO_WRONG': '信息填写有误',
            'OUT': '卖家缺货',
            'UNABLE': '无法付款',
            'PAYMENT_PENDING_TIP_1': '订单已提交，正在等待您付款',
            'PAYMENT_PENDING_TIP_2': '请在订单生成后的72小时内完成付款，否则订单会自动取消',
            'PAID_TIP_1': '您的订单已经成功提交，正在等待代理商采购',
            'PAID_TIP_2': '北京时间08:00至18:00之间提交的订单将在6小时内处理。',
            'PURCHASING_TIP_1': '采购人员正在向商家采购您所选购的商品',
            'PURCHASING_TIP_2': '我们将会在约定的采购时效内为您完成购买',
            'PURCHASED_TIP_1': '您所选购的商品已采购完成，等待商家发货',
            'PURCHASED_TIP_2': '卖家一般会在3~7天内发货(部分订制、预售款除外)',
            'SHIPPED_TIP_1': '商家已安排商品发出，请等待商品到达Tendabuy操作中心',
            'SHIPPED_TIP_2': '商品一般会在3个工作日内到达操作中心最晚不超过7个工作日',
            'IN_PACKING_CENTER_TIP_1': '商品已存放在Tendabuy操作中心，您可前往“我的仓库“查看.',
            'IN_PACKING_CENTER_TIP_2': '商品有100天免费仓储期，您可在商品保管期内随时提交寄送',
            'IN_AFTERSALES_1': '订单已发起售后申请，处理状态可前往“退款/售后”进行查看',
            'IN_AFTERSALES_2': '退款处理完毕后，款项会返回您的Tendabuy账号',
            'CANCELED_TIP_1': '采购接单前，被取消的订单。',
            'CANCELED_TIP_2': '采购接单前用户可以自行取消订单',
            'CLOSED_TIP_1': '付款后申请售后的订单，已经退款完成。',
            'CLOSED_TIP_2': '可前往个人中心首页，点击我的余额查看退款明细',
            'OUTSIDE_SHIPPED_TIP_1': '您的包裹已安排发出',
            'OUTSIDE_SHIPPED_TIP_2': '您可前往“包裹详情“查看相关物流轨迹',
            'REFUNDED_TIP_1': '付款后申请售后的订单，已经退款完成。',
            'REFUNDED_TIP_2': '可前往个人中心首页，点击我的余额查看退款明细',
            'NO_PAID_72': '72小时内未支付，订单已被取消。',
            'DECLARE': '若您需要TENDABUY代您申报，可选择',
            'DECLARATION': '委托申报',
            'DECLARATION2': '您是否确认委托TendaBuy平台帮您操作申报，',
            'INSTRUCTIONS': '《委托申报须知》',
            'INSURED2': '保价费',
            'PURCHASE': '下单并支付',
            'PASTE_ORDER': '粘贴商品链接下单',
            'PAY_SHIPPING': '支付商品售价及中国大陆运费',
            'SHIP_ITEM': '为您订购商品并寄送至仓库',
            'QC_STORAGE': '质检&存储',
            'INSPECTION': '入库质检',
            'FREEQC': '赠送3张免费质检图片',
            'FREE_STORAGE': '90天免存储费',
            'GLOBAL': '国际运输',
            'ONE_PARCEL': '支持不同订单商品合并寄送',
            'SHIPPING_100': '定制化打包，100+物流线路',
            'GLOBAL_FEE': '支付国际运费',
            'EMPTY': '城市不能为空',
            'ROUTE': '当前路线为空，请重新选择新的地区',
            'GLOBAL_PLATFORM': '全球领先的跨境电商服务平台',
            'EASILY': '通过 Tendabuy 轻松订购来自中国的产品。',
            'STEPS': '只需5步',
            'GO_HOMEPAGE': '进入主页',
            'PAGE': '下一页',
            'FREE2': '自由的！ 30天订单费',
            'WELCOME': '欢迎来到本网站。',
            'ASSISTANT': '一个让您轻松从中国订购产品的助手。',
            'NO_NEED': '您可以自己订购。无需经过中间人。',
            'PLEASE_SELECT_PAYMENT_METHOD': '请选择付款方式',
            'AUTHENTICATOR_LABEL': 'Authenticator验证',
            'AUTHENTICATOR_DESCRIPTION': '开启了虚拟Authenticator验证，让账号更安全。请备份您的密钥。',
            'OPEN': '开启',
            'CLOSE': '关闭',
            'UNBIND': '解除绑定',
            'LABEL_TRUST_CURRENT_DEVICE': '信任此设备',
            'LABEL_CURRENT_DEVICE': '此设备',
            'TITLE_GOOGLE_Authenticator': 'Google Authenticator验证',
            'TITLE_OPEN_GOOGLE_AUTHENTICATOR': '开启虚拟Authenticator设备验证',
            'TITLE_UNBIND_GOOGLE_Authenticator': '解绑Google Authenticator验证',
            'TITLE_CLOSE_GOOGLE_AUTHENTICATOR': '关闭虚拟Authenticator设备验证',
            'ALERT_OPEN_GOOGLE_AUTHENTICATOR': '您的账号绑定了虚拟Authenticator验证，请输入您在智能设备上绑定的虚拟Authenticator动态码。',
            'PLACEHOLDER_AUTHENTICATOR_VERIFICATION_CODE_INPUT': '请输入6位数字安全码',
            'BUTTON_SUBMIT_VERIFICATION_CODE': '提交验证',
            'STEP_1_OPEN_GOOGLE_AUTHENTICATOR': '验证身份',
            'STEP_2_OPEN_GOOGLE_AUTHENTICATOR': '安装应用',
            'STEP_3_OPEN_GOOGLE_AUTHENTICATOR': '绑定',
            'STEP_4_OPEN_GOOGLE_AUTHENTICATOR': '完成',
            'ALERT_CONFIRM_EMAIL_VERIFICATION_CODE': '为确认是你本人操作，请完成以下验证。如没收到验证码，请重新获取或稍侯再试。',
            'BUTTON_RESEND_EMAIL_VERIFICATION_CODE': '重新获取',
            'ALERT_CONFIRM_EMAIL_ADDRESS': '为确保是你本人操作，请确认您的验证邮箱来完成身份验证。',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR': '请在手机端下载并安装 Google Authenticator (身份验证器)',
            'TIP_IPHONE_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'IPhone:在AppStore搜索Google Authenticator (身份验证器)',
            'TIP_ANDROID_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Android:在应用市场搜索Google Authenticator (身份验证器)',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR_NEXT_STEP': '安装完成后点击下一步绑定页面（如已安装，直接进入下一步）',
            'TIP_USE_GOOGLE_AUTHENTICATOR_TO_SCAN': '使用Google Authenticator (身份验证器)扫描以下二维码，获取6位验证码',
            'TIP_BIND_GOOGLE_AUTHENTICATOR_SUCCESS': '您已成功绑定Google Authenticator (身份验证器)!',
            'BUTTON_RETURN': '返回',
            'ALERT_CHECK_GOOGLE_AUTHENTICATOR_VERIFICATION_CODE': '为确认是你本人操作，请输入您在智能设备上绑定的虚拟Authenticator动态码。',
            'BUTTON_USE_EMAIL_TO_UNBIND': '使用邮箱解除绑定',
            'BUTTON_USE_EMAIL_TO_CHECK': '使用邮箱验证',
            'TITLE_MODIFY_DEVICE_NAME': '修改设备名称',
            'TITLE_CURRENT_DEVICE': '当前设备',
            'PLACEHOLDER_MODIFY_CURRENT_DEVICE_NAME': '请输入要修改的设备名称',
            'MESSAGE_OPEN_SUCCESS': '开启成功',
            'MESSAGE_UNBIND_SUCCESS': '解绑成功',
            'MESSAGE_CLOSE_SUCCESS': '关闭成功',
            'DELETE_BILLING_ADDRESS': '删除账单地址',
            'EDIT_BILLING_ADDRESS': '修改账单地址',
            'CLICK_VIEW': '点击此处可在TENDABUY查看该店铺所有商品',
            'TRANSIT': '发货中',
            'SERVICE_NOT': '服务费待付款',
            'SERVICE_PAID': '服务费已付款',
            'AWAITING_PACK': '等待打包',
            'PACKED_2': '包裹已打包',
            'WAIT_SHIPMENT': '待发货',
            'PENDING_CLEAR': '等待海关验收',
            'TRANSIT_2': '国际途中',
            'ARRIVED_COUNTRY': '已到目标国',
            'IN_COUNTRY': '目标国内运输',
            'DELIVERED_2': '已送达',
            'CANCEL_2': '取消',
            'INTER_NOT': '待付国际运费',
            'INTER_PAID': '国际运费已支付',
            'INTER_OPTIONS': '国际运费路线选择',
            'ESTIMATED_PAID': '预估全部费用已支付',
            'ESTIMATED_NOT': '预估全部费用未支付',
            'NOTICES': '公告',
            'SHIPPING': '物流名称',
            'SELECT_COUPON': '选择运费优惠券',
            'CURRENT_PAGE': '当前商品为1688商品，下单金额以实际成交为准，如你有特殊需求请备注',
            'ITEM': '件',
            'WE_OFFER_5': '我们免费提供5张高清质检照片',
            'AFTER_YOU_CONFIRM': '确认货物后，选择国际快递。',
            'NAVIGATION': '从这里搜索您想要购买的商品，Tendabuy支持关键词搜索，使用商品链接搜索和使用商品图片搜索',
            'NAVIGATION_1': '点击这里可以测算包裹运费，了解物流线路信息。',
            'BACK': '上一步介绍',
            'NEST': '下一步介绍',
            'FINISH': '完成',
            'INSURED_RANGE': '保价费范围',
            'INSURANCE_FEE_WITHOUT': '保价费不在范围内',
            'EMAIL_NOT_VERIFIED': '邮箱未激活',
            'TO_BE_SUPPLEMENTED': '待补款',
            'PARCEL_CLOSED': '包裹关闭',
            'CONFIRM_RECEIPT': '确认收货',
            'SIZE_GUIDE_NOTES': '请注意，这只是一个粗略的换算表，因为不同卖家和品牌的尺寸可能不同。建议在购买前查看产品的详细尺寸表，并根据实际尺寸选择合适的尺寸。',
            'SIZE_GUIDE': '尺寸指南',
            'SIZE_ASSISTANT': '尺寸助手',
            'TOOL_MENU': '工具菜单',
            'SWITCH_TO': '切换到',
            'PRODUCT_NOT_FOUND': '查无此商品，返回我的订单。',
            'SELECT_COUPON_1': '选择优惠券',
            'PAID_1': '已支付',
            'FIRST_WEIGHT_CHARGE': '首重费用',
            'RENEWAL_CHARGE': '续重费用',
            'ACTIVA': '发送激活邮件',
            'ACTIVATE': '是否激活邮件？',
            'ACCEPT': '同意',
            'CANCEL0': '拒绝'
        }, 'language2': {
            'HOME_PAGE': 'Home',
            'SHOPPING_CART': 'Cart',
            'FAVORITE_LIST': 'Wishlist',
            'MY_ORDERS': 'Orders',
            'MY_WAREHOUSE': 'Warehouse',
            'MY_ASSETS': 'Wallet',
            'MY_INQUIRY': 'Enquiry',
            'MESSAGING_CENTER': 'Message',
            'ACCOUNT_BALANCE': 'Account Balance (¥)',
            'CUSTOM_FEATURES': 'Custom function',
            'AFFILIATE_MARKETING': 'Affiliates',
            'HELP_CENTER': 'Help Center',
            'PRODUCT_INFORMATION': 'Product Info',
            'ACCOUNT_BALANCE2': 'Balance',
            'PRODUCT_NOTES': 'Product Notes',
            'SPECIAL_PURCHASE_REQUIREMENT_MESSAGE': 'Write a message ONLY if you have special purchasing requirements',
            'ACCOUNT_SETTINGS': 'Account',
            'ACCOUNT_SETTINGS2': 'Account',
            'UNIT_PRICE_LABEL': 'Unit Price',
            'QUANTITY_LABEL': 'Qty',
            'AMOUNT_LABEL': 'Amount',
            'ACTION_LABEL': 'Action',
            'TAGS_LABEL': 'Tags',
            'DELETE_BUTTON': 'Delete',
            'KIND_REMINDER': 'Kind Reminder',
            'CONFIRM_DELETE_PRODUCT': 'Do you confirm to delete?',
            'CANCEL_1': 'Cancel',
            'CONFIRM_1': 'Confirm',
            'ADD_TO_CART': 'Add to Cart',
            'REMOVE_SELECTED_ITEMS': 'Remove Selected',
            'PRODUCT_SELECTED_1_NO_FREIGHT': 'Selected Products 1 PCS (Not included freight)',
            'ESTIMATE_SHIPPING_COST': 'Freight Calculator',
            'PROCEED_TO_CHECKOUT': 'Submit',
            'SHOP': 'Shop',
            'PRODUCTS': 'Product',
            'SEARCH_BY_NOTE_OR_STORE_NAME': 'Search by note name/store name',
            'CHOOSE_PLATFORM': 'Select Platform',
            'TAOBAO': 'Taobao',
            'TMALL': 'Tmall',
            'ALIBABA_1688': 1688.0,
            'ENTER_PRODUCT': 'Please enter the product',
            'FAVORITE_ITEM_KEYWORDS': 'Enter favorite product keywords',
            'ADD_TO_CART2': 'Add to Cart',
            'VIEW_WAREHOUSE_IMAGES': 'View warehouse photos',
            'NAVIGATE_TO': 'Go to',
            'SHOW_ALL': 'All',
            'AWAITING_PAYMENT': 'Payment Pending',
            'SORT_BY_DATE': 'Sort By Time',
            'ORDER_RECYCLE_BIN': 'Order Recycle Bin',
            'ITEM_DETAILS_HEADER': 'Name/OrderNo./ItemNo.',
            'SIMPLIFY_FILTERS': 'Fold Filter',
            'ORDER_STATUS': 'Order status',
            'UNPAID_ORDERS': 'Payment Pending',
            'PAYMENT_COMPLETED': 'Paid',
            'PENDING_ORDERS': 'Purchasing',
            'AWAITING_CONFIRMATION': 'Order Awaiting Confirmation',
            'SHIPPED_ORDERS': 'Shipped',
            'DELIVERED_ORDERS': 'Order Delivered',
            'CANCEL_ORDER_ACTION': 'Cancelled',
            'START_DATE_RANGE': 'Start Date',
            'END_DATE_RANGE': 'End Date',
            'SEARCH_ACTION': 'Search',
            'AFTER_SALES_SERVICE_1': 'After Sales',
            'UNIT_PRICE_FIELD': 'Unit Price',
            'QUANTITY_FIELD': 'Qty',
            'STATUS_OF_ORDER': 'Order Status',
            'ADDITIONAL_SERVICES': 'Value Services',
            'AFTER_SALES_SERVICE2': 'Aftersale',
            'ORDER_TOTAL': 'Total Amount',
            'ADDITIONAL_ACTIONS': 'Others',
            'ORDER_NUMBER_LABEL': 'Order No',
            'CREATION_TIMESTAMP': 'Created Time',
            'WAREHOUSE_NAME_TENDBUY': 'Warehouse: Tendabuy Warehouse',
            'UNPAID_STATUS': 'Unpaid',
            'PROCEED_TO_PAYMENT': 'Pay',
            'PRODUCT_NOTE_COLON': 'Product note:',
            'SELECT_ALL_2': 'Select All',
            'PAGE_NAVIGATION': 'Page',
            'ITEM_IDENTIFIER_HEADER': 'Goods Name/Order No. /ltem No',
            'FILTER_COLLAPSE': 'Fold Filter',
            'DATE_RANGE_START': 'Start Date',
            'DATE_RANGE_END': 'End Date',
            'SEARCH_QUERY': 'Search',
            'ORDER_SUCCESS_SHIPMENT_PROCESS': 'After a successful order, the seller will ship the goods to the warehouse. Once the warehouse receives the goods, you can view and manage them on this page.\n\n\n\n\n\n',
            'IDENTIFICATION_COLOR': 'Label Color',
            'PRODUCT_LABEL': 'Product Tag',
            'SELECT_ALL_ITEMS': 'Select All',
            'TENDABUY_WAREHOUSE': 'Tendabuy Warehouse',
            'SHIPPING_COUNTRY': 'Destination',
            'ESTIMATED_DEPOSIT_WEIGHT': 'Estimated Deposit (Actual Weight)',
            'SELECTED_ITEMS_COUNT': 'Selected * item (Total * items)',
            'PREVIEW_PACKAGING': 'Estimated Packaging',
            'PREPACKING_SERVICE_INFO': 'The pre-packaging service is not actual packaging, but a pre-packaging service. If you need to package, please return to the warehouse and "submit shipment',
            'SUBMIT_SHIPMENT': 'Submit Shipment',
            'SEARCH_PR_PO_PI': 'Search by PR/PO/PI',
            'PACKAGE_PREVIEW_INFO': 'Rehearsal Package Info',
            'INTENDED_ROUTE': 'Preferred Shipping Method',
            'WEIGHT_GRAMS': 'Weight',
            'DIMENSIONS_CM': 'Dimensions',
            'VALUE_ADDED': 'Value-added',
            'ALL_OPTIONS': 'All',
            'OPERATIONS': 'Action',
            'UNPROCESSED_STATUS': 'Unprocessed',
            'PRINT_OUTBOUND_ORDER': 'Print outbound order',
            'SHIPPED_STATUS': 'Shipped',
            'WEIGHT_CALCULATED': 'Weight Calculated',
            'SHIPPED_OUT': 'Shipped',
            'EXPIRED_STATUS': 'Expired',
            'CANCELLED_STATUS': 'Cancelled',
            'PACKAGING_WEIGHT_VOLUME_HINT': 'Before your items are packed, the system cannot obtain accurate weight and volume information. Therefore, you can purchase a "preview package" on the "My Warehouse" page to obtain the accurate weight and volume of your package.',
            'AFTER_SALES_SERVICE': 'After Sales Service',
            'RECHARGE': 'Recharge',
            'ARREARS': 'Underpaid',
            'SETTLE_ARREARS': 'Repay Arrears',
            'AFTER_SALES_SERVICE_2': 'After Sales Service',
            'START_DATE': 'Start Date',
            'END_DATE': 'End Date',
            'TRANSACTION_TYPE': 'Type',
            'WITHDRAWAL_DEDUCTION': 'Deduction for Withdrawal',
            'ORDER_REFUND': 'Order Refund',
            'VIEW_BILL': 'View Bill',
            'TXN_TIME': 'Transaction Time',
            'INCOME_EXPENSE': 'Income/Expense(¥)',
            'ALL_MESSAGES': 'All Messages',
            'TXN_ID': 'Transaction ID',
            'ALL_MESSAGES2': 'All',
            'NO_DATA_AVAILABLE': 'No Data',
            'INPUT_CONTENT': "Product's link or keyword",
            'CONTENT_TYPE': 'Type',
            'AVAILABLE_BALANCE': 'Available Balance :',
            'ENQUIRY_CONTENT': 'Enquiry Content',
            'AVAILABLE_BALANCE2': 'Available Balance',
            'BALANCE_DETAIL': 'Balance Details',
            'ALL_INQUIRIES': 'All',
            'ORDER_INQUIRY': 'Order inquiry',
            'PACKAGE_INQUIRY': 'Package inquiry',
            'AFTER_SALE_SERVICE': 'Parcel after-sales',
            'OTHER_QUERIES': 'Other inquiries',
            'PACKAGE_ORDER_NO': 'Package number / Order number',
            'ASSOCIATED_NO': 'Associated number',
            'CONSULT_CONTENT': 'Inquiry content',
            'ENQUIRY_SUBMIT_TIME': 'Submitted Time',
            'ALL_ITEMS': 'All',
            'BALANCE_DETAIL2': 'Balance Details',
            'PACKAGE_ORDER_PRODUCT_NO': 'Parcel/Order No/Item No',
            'TITLE': 'Title',
            'CREATION_TIME': 'Creation Time',
            'OPERATION': 'Action',
            'EMPTY_DATA': 'No Data',
            'SELECT_ALL_MESSAGES': 'Select All',
            'BIRTHDAY': 'Birthday',
            'SYSTEM_NOTIFICATION': 'System message',
            'MANUAL_MESSAGE': 'Manual message',
            'VIEW_DETAILS': 'View details',
            'PERSONAL_INFO': 'Personal Info',
            'EDIT_PROFILE': 'Edit',
            'BIRTHDAY2': 'Birthday',
            'BUYING_GUIDELINES': 'Shopping Agent Guidance',
            'PHONE_NUMBER': 'phone',
            'BUYING_GUIDELINES2': 'Purchase Agent Guidelines',
            'VOICE': 'Voice',
            'CANCEL_BUTTON': 'Cancel',
            'GROWTH_POINTS': 'Growth Value',
            'VIEW_GROWTH_HISTORY': 'View Level Records',
            'NEED_3000_MORE_POINTS_FOR_V1': 'You only need 3,000 more Growth Value to reach V1',
            'SHIPPING_ADDRESS': 'Shipping Address',
            'ADD_SHIPPING_ADDRESS': 'Add Shipping Address',
            'ZERO_ADDRESSES_CREATED_LEFT': 'You have created ',
            'ZERO_ADDRESSES_CREATED_RIGHT': 'shipping addresses',
            'PROMPT_ADD_ADDRESS': "You haven't added any shipping addresses yet. Please add a shipping address",
            'ACCOUNT_SECURITY': 'Account Security',
            'USER_NAME': 'Username',
            'EMAIL_ADDRESS': 'Email',
            'SECURITY_LEVEL': 'Security Level',
            'LAST_LOGIN': 'Recent Login',
            'AUTHORIZED_BINDINGS': 'Authorized Login',
            'MODIFY_EMAIL_IF_ISSUE': 'It is recommended to modify only when there is an error or problem with the email settings',
            'LOW_SECURITY_SET_PAYMENT_PW': 'Your security level is "Low" To ensure the security of your account, please set a payment password',
            'BINDING_ACTION': 'Bind',
            'CHANGE_LOGIN_PW': 'Change Login Password',
            'STRONG_PW_RECOMMENDED': 'A highly secure password can make your account more secure. We recommend regularly changing your password and setting one that includes both numbers and letters, with a length of at least 6 characters',
            'PAYMENT_PASSWORD': 'Payment password',
            'SET_PW_FOR_SECURE_PAYMENTS': 'The password required when using Tendabuy balance for payment, set the payment password to ensure payment security',
            'UPDATE_PASSWORD': 'Change Password',
            'CREDIT_CARD_BILLING_ADDRESS': 'Credit Card Billing Address',
            'SET_CARD_BILLING_ADDRESS_FOR_PAYMENTS': 'When you use a credit card for payment, you need to set the credit card billing address',
            'ADD_BILLING_ADDRESS': 'Add billing address',
            'TOP_UP_AUTHORIZATION': 'Autopay Authorization',
            'ENABLE_SMALL_TOPUP_CONFIRMATION': 'Enable this feature to waive the confirmation for small top-up amounts, effectively improving efficiency',
            'AUTHORIZATION_TYPE': 'Authorization Type',
            'CANCEL_BUTTON2': 'Cancel',
            'DAILY_CAP': 'Limit amount per day',
            'REMAINING_LIMIT': 'Remaining Limit',
            'CONFIRM_BUTTON': 'Confirm',
            'ACTION': 'Action',
            'DOMESTIC_SHIPPING_AUTH': 'Domestic Shipping',
            'EDIT_AUTH': 'Edit',
            'ORDER_TOPUP_AUTH': 'Order Payment Authorization',
            'INTERNATIONAL_SHIPPING_AUTH': 'International Shipping',
            'ORDER_TAG_MANAGEMENT': 'Order Tag',
            'ENABLE_CUSTOMER_MANAGEMENT': 'Enable this feature to manage your orders',
            'CREATE_TAG': 'Create a Tag',
            'TAG_NAME': 'Tag name',
            'TAG_COLOR': 'Tag color',
            'TAG_REMARK': 'Tag note',
            'CONFIRM_BUTTON2': 'Confirm',
            'COUNTRY': 'Country',
            'TAG': 'Tag',
            'NOTE': 'Remark',
            'EDIT_TAG': 'Edit',
            'DELETE_TAG': 'Delete',
            'CUSTOM_PHOTO_TEMPLATE': 'Customize photo template',
            'LEARN_CUSTOM_PHOTO': 'Learning to use this feature will make it easier to fill in the [Customize Photo] content',
            'ADD_TEMPLATE': 'Add new template',
            'TEMPLATE_NOT_FOUND': " You haven't added a custom template yet",
            'ADD_PHOTO_TEMPLATE': 'Go to add a detailed photo template',
            'CUSTOM_PHOTO': 'Custom Photo Template',
            'TEMPLATE_NAME': 'Template Name',
            'PHOTO_COUNT': 'Number of Photos',
            'PHOTO_REQUIREMENTS': 'Photo Requirements',
            'TEMPLATE_NAME2 ': 'Please enter the template name',
            'ENTER_REMARKS': 'Please enter a remark',
            'PHOTO_REQUIREMENTS2': 'Please specify your photo requirements, one requirement per photo, and you can upload a sample photo for reference.',
            'EMAIL_APP_NOTIFICATIONS': 'Email Notification Settings',
            'SELECT_NOTIFICATIONS': 'Check what we should notify you about',
            'CUSTOMIZE_PHOTO': 'Customize photos',
            'WATERMARK_ENABLED': 'Enabling watermark will add the selected watermark style to the photos you receive',
            'ADD_FORWARDING_ORDER': 'Add a forwarding order',
            'WAREHOUSE_ADDRESS': 'Tendabuy Address',
            'RETURN_ORDERS': 'Return to My Order',
            'ORDER_REMARK2': '\nOrder Remark',
            'SEARCH_COURIER': 'Search for a tracking number',
            'HISTORY_FORWARDING': 'Forwarding history',
            'RECIPIENT_ADDRESS': 'Warehouse Address',
            'CONTACT_ADDRESS': 'Recipient',
            'ADDDRESS': 'Address',
            'POST_CODE': 'Postal Code',
            'CONTACT_PHONE': 'Mobile',
            'WARNING_PACKAGE_SECURITY': "Don't share your tracking number with others when sending to Tendabuy warehouse. It's your unique ID.",
            'SHIPPING_INFO_Tendabuy': 'Info of shipment to Tendabuy',
            'TRACKING_NUMBER': 'Tracking Number',
            'TRACKING_NUMBER_REQUEST': 'Ask the sender for the info',
            'PARCEL_NAME': 'Product Name',
            'ADD_TRACKING_NUMBER': 'Add Tracking',
            'TRACK_PACKAGE_STATUS': 'View Tracking',
            'CANCEL_ACTION': 'Cancel',
            'SUBMIT_ACTION': 'Submit',
            'DISCLAIMER': 'Disclaimer',
            'SUBMIT_PRODUCT_INFO': 'Fill & Submit Product Information',
            'PAYMENT_SHIPPING_FEES': 'Pay for the product and shipping feight',
            'WAITING_MERCHANT_SHIPMENT': 'Waiting for merchant to ship',
            'COUNTRY2': 'Country',
            'REQUIRED_FIELDS': 'Required Fields',
            'PRODUCT_LINK': 'Product Link',
            'GENDER': 'Gender',
            'GENDER2': 'Gender',
            'INPUT_SHIPPING_COST': 'Please enter the shipping cost from the merchant to the Tendabuy warehouse',
            'UPLOAD_PICTURE': 'Upload Images',
            'PICTURE_SIZE_LIMIT': 'Image under 3MB only (please upload GIF, JPG and PNG form)',
            'PRODUCT_REMARK': 'Product Notes',
            'COST': 'Cost',
            'UNIT_PRICE': 'Unit Price',
            'FILL_IN_RMB_1_FOR_UNKNOWN_PRICE': 'If price is unknown, fill in 1CNY to place the order and wait for our agent to confirm the underpaid amount.',
            'DOMESTIC_SHIPPING_COST': 'Freight to our warehouse',
            'TOTAL_PRICE': 'Total',
            'INTERNATIONAL_SHIPPING_EXTRA': 'International shipping cost is not included and will be charged separately.',
            'ESTIMATE_SHIPPING': 'Shipping Calculator',
            'THIRD_PARTY_LINK_DISCLAIMER': 'The link you submitted on this page belongs to a third-party product link (such as Xianyu, Little Red Book, Bilibili, Yupoo, etc.). Tendabuy can only help you find the merchant associated with this link for product inquiries, proxy purchasing, and arranging third-party logistics services for delivery. Tendabuy cannot determine the quality or authenticity of the products. You need to bear the related risks before placing an order.',
            'BUYING_AGENT_GUIDE': 'Shopping Agent Notes',
            'PRODUCT_DETAILS': 'Product Details',
            'AGREE': 'Agreed',
            'BUY_NOW': 'Buy Now',
            'CUSTOM_ORDER_REQUIRED': 'DIY Order (*Required Field)',
            'UPLOAD_DESCRIBE_PRODUCT_1': '1.Upload an image or describe the product you wish to purchase.',
            'UPLOAD_DESCRIBE_PRODUCT_2': '2. We will help you to find the product(s) accordingly and confirm with you within 24 hours after received your submission.',
            'UPLOAD_DESCRIBE_PRODUCT_3': '3.The service fee is non-refundable if we locate the product(s) but you decide not to purchase',
            'PRODUCT_IMAGE': 'Product Images',
            'ORDER_REMARK': 'Remark',
            'PRICE_RANGE': 'Price Range',
            'ENTER_PROD_DESC': 'Leave Product Description & Specification',
            'QUANTITY': 'Quantity',
            'WAREHOUSE_SHIPPING_FEE_CNY_10': 'Shipping to Tendabuy warehouse; service fee CNY 10 per order.',
            'BUYER_QUALITY_DISCLAIMER': 'Disclaimer: We cannot determine the quality or authenticity of the products. While we will make every effort to negotiate with the seller on your behalf for after-sales service, we cannot guarantee the outcome. If you choose to use this service, you must assume the associated risks. Otherwise, we cannot proceed with the purchase on your behalf.',
            'CUSTOMIZE_NOW': 'Submit Now',
            'PRODUCT_IMAGE2': 'Product Image',
            'CONFIRM_AGREE': 'Agreed',
            'PRODUCT_NAME': 'Product Name',
            'PRODUCT_NAME2': 'Product Name',
            'PRODUCT_SPECIFICATIONS': 'Product Details',
            '': '',
            'PRODUCT_SPECIFICATIONS2': 'Specifications',
            'TRANSACTION_TIME': 'Transaction Time',
            'VIEW_STATEMENT': 'View Bill',
            'TRANSACTION_ID': 'Transaction ID',
            'REMARKS': 'Remask',
            'NO_DATA_FOUND': 'No Data',
            'PARCEL': 'Parcel',
            'PRIORITY_SHIPPING': 'Preferred Shipping',
            'RECHARGE_REQUIRED': 'Recharge',
            'IN_TRANSIT': 'On the way',
            'RECEIVED': 'Received',
            'CANCELLED': 'Canceled',
            'REVIEWABLE_PRODUCTS': 'Reviewable Products',
            'POSTED_REVIEWS': 'Posted Reviews',
            'EMPTY_FIELD': 'Empty',
            'SEARCH_ORDER_NUMBER': 'Search Order No',
            'RELATED_ORDER_NO': 'Related Order No',
            'COUPONS_SECTION': 'Coupon',
            'AVAILABLE_COUPONS': 'Available Coupon',
            'USED_COUPONS': 'Coupon Used',
            'COUPON_EXPIRED': 'Coupon Expired',
            'NO_ACTIVE_COUPONS': "You don't have a coupon yet",
            'MY_POINTS': 'My Points',
            'POINTS_THIS_MONTH': 'Points Redemption This Month',
            'REDEMPTION_DETAILS': 'Redemption Details',
            'REDEMPTION_RECORD': 'Redemption Record',
            'RELATED_ORDER_NO2': 'Related Order No',
            'RELATED_NUMBER': 'Related Number',
            'REDEMPTION_METHOD': 'Redemption Method',
            'TENDABUY_POINTS': 'Tendabuy points',
            'STORAGE_FEES': 'Storage Fees',
            'REDEMPTION_DATE': 'Redemption Date',
            'REMARK': 'Remark',
            'REDEMPTION_ACTION': 'Action',
            'REDEEMED_ITEMS': 'Redemption ltems',
            'ORDER_INQUIRIES': 'Order Enquiry',
            'PARCEL_INQUIRIES': 'Parcel Enquiry',
            'AFTER_SALE_INQUIRIES': 'After-Sales Parcel ',
            'OTHER_ENQUIRY': 'Other Enquiry',
            'QUERY_TYPE': 'Type',
            'REMARK2': 'Note',
            'QUERY_CONTENT': 'Enquiry Content',
            'SELECT_ALL': 'Select All',
            'QUERY_STATUS': 'Status',
            'WELCOME_NO_QUERIES_MSG': 'Welcome to "Enquiry" page, there\'s no enquiry at present,\n\nyou can click the "customer service" button on the right to leave us a message at any time.',
            'SELECT_ALL2': 'Select All',
            'SYSTEM_MESSAGES': 'System Message',
            'MANUAL_MESSAGES': 'Manual Message',
            'STATUS': 'Status',
            'NO_UNREAD_INBOX_MESSAGES': 'You haven’t had any unread internal messages yet',
            'STATUS2': 'Status',
            'STATUS3': 'Status',
            'PHONE': 'Mobile',
            'SUBMISSION_TIME': 'Submission Time',
            'LANGUAGE': 'Language',
            'SUBMISSION_TIME2': 'Submission Time',
            'VIEW_GROWTH_RECORDS': 'View Level Record',
            'POINTS_TO_LEVEL_UP': 'Get 3000 points more to level up to V1',
            'SIZE_INFORMATION': 'My size information',
            'MY_PRIVILEGES': 'My Privilege',
            'LEVEL_BENEFITS': 'Level Privilege',
            'INTERNATIONAL_SHIPPING_DISCOUNT': 'International Shipping Discount',
            'INSURANCE_DISCOUNT': 'Insurance Discount',
            'ADDED_SERVICE_DISCOUNT': 'Value Added Services Disc.',
            'RETURN_EXCHANGE': 'Free Aftersale Operation Fee ',
            'FEE_USED_AVAIL': 'Used/Available(Month)',
            'CUSTOMIZED_PHOTO_USED_MONTHLY': 'Extra Photo',
            'MY_RANK': 'My Level',
            'LEVEL_VALUE': 'Value',
            'AUTO_PAY_AUTHORIZATIONS': 'Autopay Authorization\n\nenable small amount autopay, and the orders/parcels handling',
            'ENABLE_MICRO_PAYMENTS': 'Authorization Type',
            'AUTHORIZATION_TYPES': 'Domestic Shipping',
            'DOMESTIC_SHIPPING': 'Underpaid',
            'UNPAID_TRANSPORT': 'International Shipping',
            'TRANSACTION_LIMIT': 'Limit amount per transaction',
            'DAILY_LIMIT': 'Limit amount per day',
            'AVAILABLE_LIMIT': 'Available quota',
            'STATUS_OPERATIONS': 'Status',
            'ORDER_TAGGING': 'Action',
            'TAGGING_FOR_EFFICIENCY': 'Change',
            'TRANSACTION_LIMIT2': 'Limit amount per transaction',
            'TAG_NOT_FOUND': 'The tag you were looking for was not found.',
            'PRODUCT_TAGS_EXPLANATION': "What is a product tag?\n\nIt's a more flexible and interesting way to categorize items by adding one or more tags to each item in your order.",
            'BENEFITS_OF_TAGS': 'The benefits of adding tags\n\nYou can filter and manage your orders more efficiently through custom tags on the "Shopping Cart", "Order" and "Warehouse" pages.',
            'HOW_TO_APPLY_TAGS': 'How to add tags？\n\nYou can add custom tags on the "Shopping Cart", "Order" and "Warehouse" pages.',
            'HIGH_RES_TEMPLATES': 'HD-Foto-Vorlage\n\nSpeichern Sie zusätzliche Fotovorlagen und wenden Sie Vorlagen mit einem einfachen Klick an',
            'ADVANCED_PHOTO_TEMPLATES_USAGE': 'How does a detailed photo template work?\n\nAdd detailed photo templates in the Checkout, My Order, and My Warehouse pages, then warehouse staff can take pictures of your products as the template requirements after the goods arrive in the warehouse.',
            'DETAILS_PHOTO_TEMPLATES_WORKFLOW': '2. Information that can be added to the detailed photo template\n\nAfter you have edited the photo template, just select the template, then warehouse staff will follow the template to shoot photos for your item, including watermark, the quantity of photos, photo shooting angles and details, etc.',
            'INFO_IN_DETAIL_TEMPLATES': '3. How to add a detailed photo template?\n\nYou can add custom tags on the "Shopping Cart", "Order", and "Warehouse" pages.',
            'ADDING_DETAIL_TEMPLATES': 'Email & App Notifications Management\n\nTick what we should notify you about.',
            'UNREAD_MESSAGES': 'Unread',
            'AUTO_PAY_ACTIVE': 'AutoPay Function Has Been Enabled',
            'COUPON_CLAIMED': 'Received Coupon',
            'COUPON_EXPIRING_SOON': 'Coupon is about to expire',
            'COUPON_USED': 'Coupon has been used successfully',
            'OPEN_BOX_REWARD_GRANTED': 'Unboxing Reward Application Success!',
            'OPEN_BOX_REJECTED': 'Unboxing Reward Application Rejected',
            'FINANCIAL_OPERATIONS': 'Finance',
            'RECHARGE_COMPLETED': 'Recharge Success!',
            'WITHDRAWAL_SUCCESS': 'Withdraw Success!',
            'ORDER_AUTO_PAYMENTS': 'Order Autopaid',
            'PARCELS': 'Parcels',
            'PARCEL_SHORTAGE_REFUND': 'Parcel Difference Refund',
            'REHEARSAL_DONE': 'Rehearsal Completed',
            'PARCEL_SUBMITTED': 'Parcel Submitted!',
            'RISK_CONTROL': 'Parcel Entered Risk Control',
            'PARCEL_CANCELLED': 'Parcel Canceled',
            'SHIPPED_PARCEL': 'Parcel Shipped',
            'PARCEL_UNPAID': 'Parcel Unpaid',
            'PARCEL_PAID': 'Parcel Has Been Paid',
            'PARCEL_RECHARGED': 'Parcel Recharged',
            'REHEARSAL_CANCELED': 'Rehearsal Canceled',
            'INSURANCE_CLAIM_APPROVED': 'Insurance Claim Approved',
            'INSURANCE_CLAIM_DENIED': 'Insurance Claim Rejected',
            'CLAIM_RECEIVED': 'Your insurance claim application has been received.',
            'ORDER_SUBMITTED': 'Orders',
            'ORDER_UNDER_REVIEW': 'Order Entered Risk Control',
            'DOMESTIC_SHIPPING_UNPAID': 'Domestic Shipping Fee Unpaid',
            'ORDER_PAYMENT_PENDING': 'Order Unpaid',
            'CONFIRMATION_NEEDED': 'Confirmation Needed',
            'ORDER_STORED': 'Order has been stored!',
            'PARTIAL_SHIPPING': 'Separate Delivery',
            'RETURN_REQUEST_ACCEPTED': 'Return Request Has Been Accepted',
            'RETURN_REQUEST_CANCELED': 'Retumn reguest has been cancelled',
            'EXCHANGE_REQUEST_ACCEPTED': 'Exchange request has been accepted',
            'EXCHANGE_REQUEST_CANCELED': 'Exchange request has been cancelled',
            'ORDER_RETURNED_SUCCESSFULLY': 'Order successfully retumned',
            'ORDER_EXPIRING': ' Order is expiring soon',
            'ORDER_OVERDUE': 'Order ls Overdued',
            'PAID_PHOTO_COMPLETE': 'Paid Photos Finished',
            'ORDER_RECHARGED': 'Order Recharged',
            'DOMESTIC_SHIPPING_RECHARGED': "Order's Domestic Shipping Recharged\n",
            'SHIPPING_REFUND_DONE': 'Order Shipping Has Been Refunded',
            'ORDER_RETRACTED': 'Order Withdrawn',
            'ORDER_CANCELED': 'Order Canceled',
            'ORDER_PAID': 'Order Has Been Paid',
            'QUALITY_CHECK_ALERT': 'Quality inspection Reminder',
            'AGENT_ORDER_WARNING': 'Order Reminder from Agent',
            'OVERDUE_ORDER_DELETION_IMMINENT': 'Overdue orders will be destroyed soon',
            'ORDER_DELETED_OVERDUE': 'Order is overdued and destroyed',
            'QUICK_WATERMARK_SETUP': 'QC Photo Watermark\n\nset now so your QC photos can be with the watermark you selected.',
            'WATERMARK_SYSTEM': 'System Watermark',
            'NO_WATERMARK': 'None Watermark',
            'SOURCE_PRODUCT': 'Source of this product',
            'SOURCE_PLATFORM': 'Alibaba platform',
            'SOURCE_ALIBABA_TENDABUY': "Tendabuy's agents will buy for you",
            'CERTIFIED_WAREHOUSE': 'Tendabuy Warehouse',
            'YOUR_LOCATION': 'Your address',
            'INTERNATIONAL_SHIPPING_ESTIMATE': 'Delivery Estimation',
            'BUYING_HOURS_09_TO_18_UTC8': 'Orders paid between 18:00-09:00 (Beijing time) will be processed by 14:00 next day',
            'REPORT_ISSUE': 'Report',
            'FORCE_REFRESH_PAGE': 'Force refresh',
            'MIN_ORDER_QUANTITY': 'Minimum Order Quantity is',
            'ORDER_FOLLOW_LINK': 'Tendabuy will strictly follow the order link to make purchases on your behalf. If you have any questions about your order, please feel free to contact our online customer service.',
            'CONTACT_SUPPORT_ANYTIME': "All displayed products are from third-party platforms. Tendabuy only provides information search, purchase agent, and shipping services. Tendabuy cannot verify the quality or authenticity of products. By using Tendabuy's purchase agent service, you automatically accept the aforementioned risks.",
            'UNREAD_MESSAGES2': 'Unread',
            'SPECIFICATIONS': 'Specifications',
            'USERNAME': 'Username',
            'USERNAME2': 'User Name',
            'FIND_SIMILAR': 'Find Similar',
            'affiLIATE_SHARE': 'Affiliate Share',
            'FAVORITE': 'Favorite Products',
            'MONTHLY_SALES': 'Monthly Sales',
            'ENTER_STORE': 'Enter Store',
            'CATEGORY': 'Categories',
            'MORE_SERVICES': 'Service',
            'ADDITIONAL_FEATURES': 'Service',
            'RESTRICTED_ITEMS_TITLE': 'Embargo',
            'RESTRICTED_ITEMS_CONTENT': '· Tobacco, alcohol, and prohibited items are not transported',
            'RESTRICTED_ITEMS': '\n\nProhibited items include, but are not limited to, the following: Swiss Army knives, full sets of kitchen knives, cleavers, paper cutters/letter openers, gardening tools/knives/shears/saws, seam rippers, pure fruit and vegetable juices, inflatable lighters, wine, putty knives, cheese/Western cheeses, Chinese mitten crabs, bamboo swords/wooden swords, utility card knives, ice skates, other outdoor knives, magnetic components, Swiss Army knives, milling cutters, carving knives, tobacco labels/cigarette cards, vajra bells/vajras/axes, seeds, etc.',
            'LIMITED_GOODS_NOTICE_TITLE': 'Purchase Limitations',
            'LIMITED_GOODS_NOTICE': '· Medicines, liquids, paste-like goods, and cosmetic powders are restricted export items by Chinese customs. Please consult [Customer Service] before ordering.',
            'CONSULT_BEFORE_ORDER_TITLE': 'CEO tips',
            'CONSULT_BEFORE_ORDER': '[About Customs and Logistics] Due to variations in customs policies across different countries, some nations or regions are particularly sensitive to specific goods, resulting in embargoes, purchase restrictions, logistic route limitations, and volume and weight restrictions.',
            'BEIAN_RIGHT': 'Copyright @ 2024 Tendabuy.com All rights reserved',
            'BEIAN': 'Specially provide products of Taobao, 1688, Weidian and other Chinese shopping platforms for global users,such as books, specialties, cosmetics and other types of products, support PayPal, Alipay and other payment methods',
            'GOODS_DETAIL_TIP1': "The purchased items will be sent to our TendaBuy's warehouse for inspection and photography upon arrival.",
            'GOODS_DETAIL_TIP2': ' You can view and manage your items in [My Warehouse].',
            'GOODS_DETAIL_TIP3': ' If you are not satisfied with the products that arrive at the TendaBuy warehouse, you can apply for a return or exchange within 5 days of their arrival, and our purchasing team will negotiate with the seller on your behalf for after-sales service.',
            'TIP2': 'Leave a message for the purchaser. If there are no special purchasing requirements, do not write anything.',
            'SERVICE_ONE_WEBSITE': 'All Service In One Website',
            'PURCHASE_ORDERS': 'Purchase Orders',
            'PURCHASE_ORDERS_CONTENT': 'Tendabuy agent team can help you purchase all the online & offline products in China. With pasting a product link to make an order, and we will provide a full range of best-in-class services after.',
            'SHIP_TO_WAREHOUSE': 'Ship To Warehouse',
            'SHIP_TO_WAREHOUSE_CONTENT': 'You can purchase items from different sellers with us, and these products will be shipped to the Tendabuy warehouse in China.',
            'QUALITY_CHECK': 'Quality Check',
            'QUALITY_CHECK_CONTENT': 'After your items arrived in Tendabuy warehouse, quality check of items will be provided with defections, size, colors, and etc. You will have a caring after-sales service guarantee in Tendabuy.',
            'GLOBAL_SHIPPING_CONTENT': 'You can freely choose items in your warehouse and submit them into one parcel, ship it to your home by choosing a reliable logistics provider provided by Tendabuy.',
            'HOT_LIST': 'Hot List',
            'ESTIMATION': 'Shipping Calculator',
            'FORWARDING': 'Forwarding',
            'FILL_BUY': 'Fill & Buy',
            'FIND_BUY': 'Diy & Buy',
            'BEGINNERS_GUIDE': 'Shopping Guide',
            'HELP_CENTER_INDEX': 'Help Center',
            'USER_CENTER_INDEX': 'User Center',
            'LOGOUT_INDEX': 'Sign Out',
            'GUIDE_USING_IMAGE_SEARCH': 'Image Search Function',
            'MY_BALANCE': 'My balance',
            'POINTS': 'Points',
            'My_COUPONS': 'My coupons',
            'MY_GROWTH_POINTS': 'My growth points',
            'WITHDRAW': 'Withdraw',
            'PENDING_PAYMENT': 'Payment Pending',
            'RECHARGE_AMOUNT': 'Recharge Amount',
            'ENTER_THE_AMOUNT': 'Please enter the amount',
            'DELETE_SUCCESSFUL': 'Deletion successful',
            'SHOP_CART_NULL': "Your cart is currently empty. Let's start the shopping journey!",
            'ORDER_DETAILS2': 'Order details',
            'IN_PROGRESS': 'Processing',
            'ORDERED': 'Purchased',
            'SENT': 'Sent',
            'ARRIVED': 'Arrived',
            'STORAGE': 'Storage',
            'CONTACT': 'Contact',
            'TOTAL_PRICE2': 'Total price',
            'SEND': 'Send',
            'ORDER_CONSULT_TIP': 'The window can only handle issues related to this order, for other questions please contact online support!',
            'ONLINE_CUSTOMER': 'Customer Support',
            'PRODUCT_AMOUNT': 'Item amount',
            'WAREHOUSE_DELIVERY_FEE': 'Freight to our warehouse',
            'STORAGE_TIME': 'Storage Time',
            'PRODUCT_TYPE': 'Product Type',
            'BUSINESS_TYPE': 'Business Type',
            'TRANSACTION_MODE': 'Payment Method',
            'Arrears_Info': 'Arrears refer to the parcel fees, order return or exchange shipping fees, or cash on delivery fees that you have not paid or supplemented, but we prioritize to help you process them to avoid delaying the timeliness. Please repay the arrears in a timely manner, otherwise, you will not be able to trade next time.',
            'Profile_Picture': 'Avatar',
            'Modify_User_Info': 'Edit Profile',
            'Female': 'Female',
            'Male': 'Male',
            'Currency': 'Currency',
            'Shopping_Cart': 'Cart',
            'Total_Order_Price': 'Total Price',
            'Proceed_To_Checkout': 'Checkout',
            'Recipient': 'Recipient',
            'First_Name': 'First Name',
            'Last_Name': 'Last Name',
            'Region': 'Regions',
            'Detailed_Address': 'Detailed Address',
            'ZIP_Code': 'ZIP Code',
            'Address_Info': 'Please enter your apartment number, street address, and other detailed address information. (Do not duplicate postal code, city, etc.)',
            'Phone_Number': 'Phone Number',
            'Address_Label': 'Address Label',
            'Home': 'Home',
            'Work': 'Company',
            'Default_Address': 'Default Address',
            'Login_Password': 'Login Password',
            'Enter_Old_Password': 'Please enter your original login password',
            'New_Password': 'New Password',
            'Enter_Password': 'Please enter your password',
            'Repeat_Password': 'Repeat Password',
            'Reenter_Password': 'Please enter your password again',
            'Verification_Code': 'verification code',
            'Send_Code': 'Send Verification Code',
            'Submit': 'Submit',
            'Custom_Template': 'Template',
            'Popular_Searches': 'Enter keywords to search',
            'Homepage': 'Home',
            'FAQs': 'FAQs',
            'Service_Quality': 'Service Quality',
            'Service_Guarantee': 'Service Guarantee',
            'Sort_By_Comprehensive': 'General Ranking',
            'Rebuy_Rate_30Days': 'Repurchase Rate in the Last 30 Days',
            'Sort_By_Price': 'Price Ranking',
            'Min': 'Min',
            'Max': 'Max',
            'Certified_Factory': 'Certified Factory',
            'Experience_Score': 'General Rating',
            'Five_Star': '5-star',
            'FourPointFive_Five_Stars': '4.5 stars - 5 stars',
            'Four_FourPointFive_Stars': '4 stars - 4.5 stars',
            'Below_Four_Stars': 'Below 4 stars',
            'Pickup_Rate_24H': '24-Hour Shipment Rate',
            'Pickup_Rate_48H': '48-Hour Shipment Rate',
            'Same_Day_Shipping': 'Ship out today',
            'Shipping_24H': '24-hour Dispatch',
            'Shipping_48H': '48-hour Dispatch',
            'Seven_Day_Return': '7-day no-reason Return',
            'Free_Shipping': 'Free Shipping',
            'Reset': 'Reset',
            'Collapse': 'See less',
            'More_Filters': 'More filtering options',
            'Accepted': 'Accepted',
            'Terms': '《Search Terms》',
            'Search_Service_Terms': 'The products searched for are all sourced from third-party platforms, and Tendabuy only provides services such as information search, purchasing agent, and shipping. Tendabuy is unable to authenticate the quality or authenticity of the products.',
            'Product_Info_Details': 'Accept the "search terms" to display the search results.',
            'Close_Page': 'Close',
            'Merchant_Info': 'Merchant',
            'Shipping_Fee': 'Shipping Fee',
            'Certified_Warehouse': 'Tendabuy Certified Warehouse',
            'User_Message': 'Message',
            'Sales_Volume_30Days': 'The sales volume of this product in the last 30 days. Some products may have errors. This data is from a third party and can be used for reference when purchasing.',
            'Report_Reason': 'Reason for Report',
            'Infringement_Report': 'Infringement Report',
            'Report_Record': 'Report Record',
            'Next_Step': 'Next Step',
            'Select_Report_Reason': 'Please Select the Reason for Report',
            'Previous_Step': 'Previous Step',
            'Report_Description': 'Report Description',
            'Image_Evidence': 'Image Evidence',
            'Submit_Report': 'Submit',
            'Select_Category': 'Please select a category',
            'Agree_And_Proceed': 'Please read and agree before proceeding',
            'TendaBuy_Warehouse': 'TendaBuy Warehouse',
            'Country_for_Shipping': 'Please select the destination country, Tendabuy agents will know whether the product can be sent there before purchasing for you.',
            'Shipping_Country': 'Select Shipping Country',
            'Order_Confirmation': 'Confirm Order',
            'Store_Total': 'Shop Total',
            'Payment_Total_Excl_SH_Fee': 'Total (International Shipping not included)',
            'Prohibited_Items': '《Prohibited Items Statement》',
            'Service_Terms_Management': ' \n《Service Terms and Users Management》',
            'Return_Exchange': '《Return/Exchange Service》',
            'Disclaimer': '《Disclaimer》',
            'Read_and_Agree': 'I have read and agreed',
            'Shipping_Destination': 'Ship to',
            'Sender_Information': 'Sender',
            'Return_to_Cart_for_Modification': 'Back to cart and edit',
            'Product_Information_Confirmation': 'Confirm Product Information',
            'Subtotal': 'Subtotal',
            'Submit_Order': 'Submit Order',
            'Warehouse_Arrival_Notification': 'Friendly reminder, after submitting the order and paid, please kindly wait for the seller to send, submit an international package order once stored at the warehouse.',
            'Payment_Due_By': 'Checkout',
            'Checkout_Successful': 'Order submitted successfully!',
            'Available_Payment_Methods': 'Please pay within 72 hours, otherwise your order will be automatically cancelled.',
            'Current_Country': 'Select country',
            'Open_Payment_Channels': 'Payment Methods',
            'Balance_Recharge_Needed': 'Your balance is insufficient. Please recharge before making payment',
            'Euro_Payment_Exchange': "The settlement currency for the current order is Euro. Local currency payment is supported, and the payment bank will exchange currencies according to the bank's exchange rate",
            'Global_Currency_Support': "Covering 202 countries or regions, supporting 25 currencies. Currency exchange and fees will be incurred when paying in non-USD currencies. Local partner banks' debit and credit cards are accepted",
            'Total_Price': 'Total Price',
            'Amount_Due': 'Amount Due',
            'Handling_Fee': 'Handling Fee',
            'Settlement_RMB_Only': 'Settlement is only available in Chinese currency (RMB)',
            'Purchase_Amount_Discount': 'Discount for reaching a certain purchase amount',
            'Negotiated_Price': 'Price negotiated with the merchant',
            'Shipping_Fee_Discount': 'Shipping fee discount',
            'Mobile_Exclusive_Price': 'Mobile-exclusive price',
            'Incorrect_Price_Displayed': 'Incorrect price displayed on the page',
            'Others': 'Others',
            'Login': 'or log in',
            'LOGIN_1': 'log in',
            'Forgot_Password': 'Forgot password?',
            'Sign_Up': 'sign up',
            'Email_Input': 'Please enter your email address',
            'Password_Input': 'Please enter your password',
            'Verification_Code_Input': 'Please enter the verification code',
            'TendaBuy_Reg_Agreement': '《TendaBuy User Registration Agreement》',
            'AND': 'and',
            'Privacy_Statement': '《Privacy Statement》',
            'Account_Existence': 'Already have an account',
            'Password_Confirmation': 'Please enter the password again',
            'Country_Selection': 'Please select a country',
            'Email_Address_Input': 'Email (Please provide a valid and usable email address)',
            'Invitation_Code_Optional': 'Please enter the invitation code (optional)',
            'MY_PACKAGE': 'Parcels',
            'PENDING': 'Pending',
            'PROCESSING': 'Processing',
            'PROCESSED': 'Processed',
            'DAILY_GOODS': 'General Daily Commodities',
            'SPECIAL_GOODS': 'Specialty Goods',
            'PAID': 'Paid',
            'WAIT_PACK': 'Awaiting',
            'PACKED': 'Packed',
            'READY_SHIP': 'Awaiting Shipment',
            'PROVINCE': 'Province',
            'CITY': 'City',
            'ENTER_NAME': 'Please enter your name',
            'ENTER_AREA': 'Please enter your region',
            'ADDR_REQUIRED': 'The address cannot be empty',
            'ZIP_REQUIRED': 'The postal code cannot be empty',
            'PHONE_REQUIRED': 'The mobile phone number cannot be empty',
            'PHONE_FMT_WRONG': 'The mobile phone number format is incorrect',
            'COUNTRY_ADDR_REQ': 'The national address cannot be empty',
            'FILES': 'File',
            'PHOTO_TEMPLATE': 'Photo template',
            'ENTER_REMARK': 'Please enter remark description',
            'SINGLE_PHOTO_REQ': 'Only one photo requirement is supported, such as: take a close-up photo of the logo, measure the size of the bracelet, etc.',
            'PHOTO': 'Photo',
            'PHOTO_TIP': 'Reminder: One requirement fits only one Extra Photo',
            'FREE_PHOTOS_FEE': 'Free Extra Photo this month 0 piece Service Fee:',
            'SERVICE': 'Service',
            'EDIT_SUCCESS': 'Successfully modified',
            'SHIPPING_FEE': 'Shipping cost',
            'SHIP_TIME': 'Shipping time',
            'CHARGE_WEIGHT': 'Billing weight',
            'FEATURES': 'Feature',
            'FEE_ITEM': 'Charging Items',
            'OPER_FEE': 'Packing fee',
            'SETTLE_STD': 'Settlement standards',
            'TOTAL_FEE_DET': 'Total fee details',
            'PAY_TOTAL': 'Total amount',
            'MAIL_LIMIT': 'Shipping restrictions',
            'WEIGHT_LIMIT': 'Weight limit',
            'MIN_LIMIT': 'Minimum limit',
            'MAX_LENGTH_LIMIT': 'Maximum length limit',
            'AFTERSALE_PERIOD': 'After-sales inquiry period',
            'COMP_BASIS': 'Compensation basis',
            'FUEL_SURCHARGE': 'Fuel surcharge',
            'AFTERSALE_SUPPORT': 'Does it support after-sales inquiry',
            'COMP_STD': 'Compensation standard',
            'COMP_PERIOD': 'Compensation period',
            'NO_DATA': 'No data',
            'ENTER_WEIGHT': 'Please enter the weight',
            'NO_ROUTE_CFG': 'The route under this configuration is not yet open',
            'PLEASE_SELECT': 'Item Category',
            'HISTORY_SEARCH': 'Search history',
            'TRIGGER_WIDTH_IS': 'The width of the trigger is',
            'IMG_COMPRESS_FAIL': 'Image compression failed',
            'NO_STORE_FOUND': 'The store was not found, please enter the correct store link',
            'CREATE_NEW_TAG': 'Create new tab',
            'DOMAIN_REQUIRED': 'The domain name cannot be empty',
            'ENTER_TAG_NAME': 'Please enter the tag name',
            'CART_EMPTY': 'Your shopping cart is empty! Go to add some items!',
            'SUCCESS': 'succeed',
            'LIMIT_5_FILES': 'Currently limited to selecting 5 files',
            'SHIPPING_NOW': 'In Transit',
            'AWAIT_CUSTOMS': 'Awaiting customs clearance',
            'ENROUTE_INTL': 'In Transit',
            'ARRIVED_CNTRY': 'Arrived at destination country',
            'DOMESTIC_TRNSP': 'Domestic Shipping',
            'DELIVERED': 'Delivered',
            'IMG_FMT_LIMIT': 'Image upload only JPG, JPEG, GIF, BMP, or PNG format!',
            'IMG_SIZE_10MB': 'Image under 10MB only (please upload GIF, JPG and PNG form)',
            'PARAM_ERROR': 'Parameter error, please contact the admin',
            'SYS_ERROR': 'System error, please contact the admin',
            'ENTER_PHONE': 'Please enter the correct mobile',
            'COPY': 'Copy',
            'SET_DEFAULT': 'Set default',
            'EDIT_ADDRESS': 'Edit',
            'PERMANENT_DELETE_ADDR': 'This action will permanently delete the address. Continue?',
            'DEL_ADDR_CONF': 'Delete shipping add',
            'ADDR_DELETED': 'Deletion cancelled',
            'V1_GROWTH_VAL': 'Only 2 points to Level V1',
            'INT_FEE_DISCNT': 'International shipping discount',
            'INSURE_DIS_AMT': 'Insurance Discount',
            'VALUE_ADD_DISC': 'Value Added Services Disc',
            'UPLOAD_DESPER': 'Uploading Actively',
            'SELECT_DATE': 'Select date',
            'IMG_FORMAT_PNG': 'Image upload only JPG, JPEG/PNG format!',
            'IMG_MAX_20MB': 'Uploaded images must not exceed 20MB in size!',
            'LIMIT_1_FILE': 'Currently limited to selecting 1 file',
            'VIEW_RECORDS': 'View records',
            'TOPUP_AMOUNT': 'Please enter the recharge amount',
            'BALANCE_TOPUP': 'Balance recharge',
            'MY_BILL': 'My bill',
            'SELLER_1': 'Merchant',
            'COPY_ADDRESS': 'Copy address',
            'ZHU_JIAXUAN': 'Zhu Jiaxuan',
            'PKG_NAME': 'Parcel name',
            'COPY_SUCCESS': 'Copy successful',
            'COPY_FAIL': 'Copy failed',
            'FUJIAN_ADDR': 'Fujian Sheng Quanzhou Shi Fengze Qu Qiyun Lu 152 Hao Huafeng Chuangyeyuan Sanlou Chu Dianti Youshoubian',
            'ADD_TRACK_NUM': 'Please add the tracking number',
            'TRANSIT_ORDER': 'Forwarding order submitted successfully',
            'SEARCH_TRACK': 'Track Shipment',
            'SEARCH_KEYWORD': 'Search keyword',
            'RESULT': 'Result',
            'READ': 'Read',
            'SELECT_DATA': 'Please select',
            'PKG_DETAILS': 'Parcel Details',
            'RECV_INFO': 'delivery Info',
            'DECLARE_NAME': 'Declaration name',
            'CURRENCY_1': 'Currency',
            'DECLARED_PRICE': 'Declared price',
            'DECLARED_QTY': 'Declared quantity',
            'TOTAL_DECLARED_VALUE': 'Total declared value',
            'TOTAL': 'Total',
            'TOTAL_VALUE': 'Total value',
            'DECLARATION_INFO': 'Declaration info(self-declaration)',
            'LOGISTICS_INFO': 'Logistics info',
            'PACKAGE_ID': 'Parcel number',
            'DETAILS': 'Details',
            'SHIPPED': 'Shipped',
            'CHOOSE_RECEIVING_ADDR': 'Select delivery address',
            'CHOOSE_LOGISTICS_ROUTE': 'Select logistics route',
            'AUTH_DEDUCT_DIFF': 'Repayment Authorization',
            'READ_AGREE_DISCLAIMER': 'I have read and agree to the "Tendabuy Disclaimer"',
            'ADDRESS': 'Address',
            'SELECT': 'Select',
            'CHOOSE_SERVICE_ROUTE': 'Select parcel service and route',
            'MAIL_LIMIT_1': 'Postage limit',
            'VOLUME': 'Volume',
            'ADD_SERVICE': 'Add service',
            'PRODUCT_PRICE': 'Product price',
            'CHOOSE_PACKAGE_SERVICE': 'Please select a package service',
            'DECLARATION_ADVICE': 'As each logistics route has restrictions on the name and number of items to be declared, please select and fill in according to the system prompts. If you have any questions about the declaration, you can consult the online customer service',
            'DECL_VAL_UPPER_LIMIT': 'Maximum declared value',
            'DECL_VAL_LOWER_LIMIT': '\nMinimum declared value',
            'DECL_ITEM_UPPER_LIMIT': 'Maximum of declared types of items',
            'DECL_ITEM_LOWER_LIMIT': 'Minimum of declared types of items',
            'ADD_DECLARATION_ITEM': 'Add declaration item',
            'ENTER': 'Please fill in',
            'TOTAL_QUANTITY': 'Total quantity',
            'BUY_INSURANCE': 'Buy Insurance',
            'INSURANCE_NOTICE': 'Insurance notes',
            'INSURED_AMOUNT': 'Insured Amount',
            'PREMIUM': 'Premium',
            'SERVICE_FEE': 'Value-added service fee',
            'CHECK_URL_OR_HOME': 'Please check if the URL you entered is correct, or click the button below to return to the home',
            'GO_HOME': 'Return to home',
            'PAGE_NOT_FOUND': 'Page not found...',
            'BANNED_RESTRICTED_ITEMS': '[Prohibited and restricted items]',
            'TRACK_LOGISTICS': 'Track Shipment',
            'PRODUCT_ISSUE': 'Product anomaly',
            'PACKAGE_LOST': 'Package lost',
            'REQUIRED_FIELD': '(*Required)',
            'UPLOAD_LIMIT_5_2MB': 'A maximum of 5 images can be uploaded, each image size is limited to within 2MB (supports  jpg, png formats).',
            'MIN_PRICE': 'Lowest(CNY)',
            'MAX_PRICE': 'Highest(CNY)',
            'SHIP_TO_TENDABUY': 'Please send to the Tendabuy warehouse; service fee CNY 10 per order',
            'ENTER_MAX_PRICE': 'Please enter the maximum unit price',
            'MAX_GT_MIN_PRICE': 'The maximum unit price cannot be lower than the minimum unit price',
            'ENTER_MIN_PRICE': 'Please enter the minimum unit price',
            'MIN_LT_MAX_PRICE': 'The minimum unit price cannot be higher than the maximum unit price',
            'AVATAR_JPG_ONLY': 'Upload avatar pictures can only be in JPG format!',
            'ADD_SUCCESS': 'Added successfully',
            'HAVE_ACCOUNT': 'Already have an account,',
            'BACK_TO_TENDABUY': 'Return to TENDABUY',
            'ACTIVATE_ACCOUNT': 'Activate account',
            'EMAIL_ACTIVATED': 'Email activation successful',
            'PASSWORD_REQUIREMENT': '6-20 characters, must include letter & number',
            'PASSWORDS_NOT_MATCH': 'The two passwords entered do not match!',
            'VALID_EMAIL_REQUIRED': 'Please enter the correct email address',
            'CODE_REQUEST_FAST': 'Verification code request too frequent',
            'LOGIN_SUCCESS': 'Login successful',
            'PARTNER_LOW_COST': 'Cooperate with many international logistics companies, Tendabuy offers the nicest discount!',
            'LENGTH': 'Length',
            'WIDTH': 'Width',
            'HEIGHT': 'Height',
            'SEARCH': 'Inquire',
            'THIRD_PARTY_SHIPPING': 'Except for Tendabuy proprietary shipping lines, other shipping services are provided by related cooperated logistics providers. Logistics providers have the right to adjust the shipping prices according to the market situation, of course, Tendabuy informs you before having the price adjusted. We are more than happy to negotiate with the logistics providers upon your request.',
            'LOGISTICS_NOTICE': 'Logistics Service Statement',
            'QUERY_SHIPPING': 'Please input information to check estimated shipping fee！',
            'TO_TENDABUY_WAREHOUSE': 'From Tendabuy to',
            'SEARCHING': 'Searching',
            'FOUND_PRODUCTS': 'found the following products for you',
            'COMPREHENSIVE': 'Comprehensive',
            'NO_PRODUCTS': 'No products available',
            'LOADING': 'Loading...',
            'NO_MORE': 'No more...',
            'FINAL_PRICE': 'Please fill in the final price',
            'COUPON_RULES_EFFECT': "Due to the coupon rules, your order might not be discounted, we suggest you fill in the regular price shown on the seller's site first!",
            'PRICE_CHANGE_ADVICE': 'Please select price edit reason',
            'PRODUCT_RISK_NOTICE': 'Product risk reminder',
            'PRODUCT_RISK_ALERT': 'The product you submitted may have -1- risk(s), it is recommended that you carefully read about the risk(s) before considering whether to purchase~',
            'BRAND_PRODUCT': 'Branded product',
            'BRAND_AUTHENTICITY': 'This product is a -1- brand product, please pay attention to authenticity',
            'UNDERSTOOD': 'I understand',
            'REPORT_DESC': 'Please provide description for your report',
            'MINIMUM_QUANTITY_ERROR': 'The quantity of goods cannot be less than the minimum batch quantity',
            'FREE': 'Free',
            'CANCEL_SUCCESS': 'Cancel product successfully',
            'CANCEL_FAIL': 'Cancel product failed',
            'SAVE_SUCCESS': 'Favorite product successfully',
            'SAVE_FAIL': 'Favorite product failed',
            'PASSWORD': 'Password',
            'OR_CONTINUE': 'Or continue',
            'BACK_TO_PASSWORD_LOGIN': 'Return to password login',
            'OPEN_APP_HOME': 'Open Tendabuy APP homepage',
            'CONFIRM_PASSWORD': 'Confirm password',
            'YOUR_EMAIL': 'Your email',
            'EMAIL_INACTIVE': 'Not activated, to continue using please click the button below to resend the activation email',
            'RESEND_ACTIVATION': 'Resend activation email',
            'CHECK_ACTIVATION_EMAIL': 'Please go to your email to view the activation email and verify your account.',
            'NEW_LOCATION_LOGIN': 'Your account is being accessed from a new location, please enter the verification code from the email to continue.',
            'OPEN_LINK_ACTIVATE': 'Please check the email and open the link to activate your Tendabuy account',
            'REGISTER_SUCCESS': 'Registration successful',
            'WINDOW_FAIL': 'Window opening failed',
            'LOGIN_SUCCESSFUL': 'Login successful',
            'ACCOUNT_NOT_ACTIVE': 'Account not activated',
            'READ_AGREE': 'Please read and agree to the instructions',
            'ORDER_IDENTIFICATION': 'Order identification',
            'DESCRIPTION_TEXT': 'Description text',
            'CUSTOM_PHOTO_SERV': "Custom photography is a value-added service of Tendabuy, charging 1 CNY per photo. It allows photos of products to be taken from specific angles according to custom requirements, such as requesting measurement of insole length, needing to see details of a clothing logo, or asking for measurements of a sweatshirt's chest",
            'REMAINING_FREE_PHOTO': 'Remaining free custom photography for this month -1- photos Service fee:',
            'PHOTO_QUALITY_PROMISE': 'Custom photography - Quality commitment',
            'TOTAL_PRODUCT_PRICE': 'Total price of goods',
            'VALUE_ADDED_FEE': 'Value-added service fee',
            'EDIT_REMARK': 'Modify remark information',
            'SEND_1': 'Send',
            'CANCEL_PAYMENT': 'Cancel Payment',
            'CANCEL_PAYMENT_1': 'Cancel payment?',
            'TRANSIT_SELF_SERVICE': 'Self-Service of Forwarding Orders',
            'SERVICE_1': '[Service]',
            'FILE_SELECT': 'Select file',
            'HISTORY_MESSAGES': 'Historical messages',
            'COLLAPSE_MESSAGES': 'Collapse messages',
            'EDIT_LABEL': 'Edit label',
            'ENTER_LABEL_COLOR': 'Please enter label color',
            'MODIFY_LABEL': 'Modify label',
            'DELETE_LABEL': 'Delete label',
            'CANCEL_EDIT': 'Cancel editing',
            'SELF_CHECK_REMINDER': 'Self-check reminder',
            'ORDER_REMINDER': 'Order reminder',
            'SERVICE_2': 'Service',
            'UPLOAD_FAIL': 'File upload failed',
            'JPG_PNG_500KB_LIMIT': 'Only jpg/png files can be uploaded, and must not exceed 500kb',
            'DELETE_LABEL_CONFIRM': 'Confirm permanent deletion of the label?',
            'WARNING': 'Warning',
            'CHOOSE_PRODUCT': 'Please select a product',
            'CHOOSE_COLOR': 'Please select a label color',
            'ADD_SERVICE_1': 'Add service',
            'STRENGTHEN_FRAME': 'Fixed foot+frame',
            'CALC_WEIGHT': 'Calculate weight (g)',
            'PACKAGE_DECLARATION_ALERT': 'Parcel declaration reminder',
            'NEW_DECLARATION_ITEM': 'Add new declaration item',
            'INSURANCE_PURCHASE': 'Purchase insurance',
            'INSURANCE_APPLICATION': 'Insurance application',
            'INSURANCE_SUGGESTION': 'Suggestion: Insured amount = Total value of goods + Shipping cost',
            'SELECT_SERVICE': 'Select service',
            'BOX_STRENGTHENING': 'Fixed foot crate packaging',
            'ASSOCIATED_EMAIL': 'Please enter the email linked to your account',
            'ENTER_ASSOCIATED_EMAIL': 'Please enter your bound email',
            'SENT_EMAIL_SUCCESS': 'Email sent successfully, please check your email promptly',
            'PAYMENT_CANCEL': 'Payment canceled',
            'CLOSE_1': 'Close',
            'SHIPPING_PAYPAL': 'Please ensure the actual shipping address of the goods matches the PayPal receiving address, otherwise the transaction will be reviewed, affecting processing time',
            'PAYMENT_TIP': 'Warm tips: please open a new page for payment operation, payment is not completed, please do not close this window',
            'COMPLETED_PAYMENT': 'If you have successfully paid, please click Payment successful',
            'PAYMENT_PROBLEM': 'If you encounter any issues with the payment, you can try to pay again or click to give feedback',
            'PAYMENT_FAIL_FEEDBACK': 'Payment failure feedback',
            'PAYMENT_SUCCESS': 'Paid',
            'PAYMENT_FAIL': 'Payment failed',
            'ORDER_PROCESS_6_HOURS': 'Your order will be processed within 6 working hours (except for some third-party platforms)',
            'ATTENTION_ITEMS': 'Please note the following:',
            'PACKAGE_SUBMIT_SELF': 'The goods will not be shipped to you; you need to submit the package yourself.',
            'AFTER_STORAGE_SUBMIT': 'After the goods are warehoused, you can select the goods in "User Center - My Warehouse", submit the package, and choose logistics to pay for international freight.',
            'WATCH_INTERNAL_INFO': 'Follow in-site information',
            'COMMUNICATE_ISSUES': 'During the order processing, if there are any issues, we will communicate and consult with you through in-site messages in a timely manner.',
            'CONTINUE_SHOPPING': 'Continue shopping',
            'VIEW_ORDER': 'Check orders',
            'RECHARGE_NOT_EMPTY': 'The recharge amount cannot be empty',
            'SIMULATE_PACKAGE': 'Rehearsal Shipping',
            'MY_PARCEL': 'My package',
            'TENDABUY_AUDIT_7DAY': 'Tendabuy will review within 7 working days, thank you for your feedback',
            'REPORT_ID': 'Report number',
            'REPORT_TIME': 'Report time',
            'REPORT_TYPE': 'Report type',
            'REPORT_PRODUCT': 'Report product',
            'REPORT_PROGRESS': 'Report progress',
            'STORE_FAVORITES': 'Favorites',
            'SORT': 'Sort',
            'PRICE': 'Price',
            'REORDER_RATE': 'Repurchase rate',
            'FAVORITE_SUCCESS': 'Favorite successfully',
            'UNFAVORITE_SUCCESS': 'Unfavorite successfully',
            'EXTEND_STORAGE': 'Extend storage',
            'STORAGE_EXTRA_DAYS': 'The storage time of your goods in the warehouse will be extended by (30*N) days, the expiration date is',
            'CANCEL': 'Cancel',
            'CONFIRM': 'Confirm',
            'MONTHS': 'Months',
            'SERVICES': 'Service',
            'SELECT_STORAGE_TIME': 'Please select the storage period',
            'ENTER_PRODUCT_LINK': 'Please enter the product link',
            'ENTER_PRODUCT_NAME': 'Please enter the product name',
            'ENTER_PRODUCT_SPEC': 'Please enter the product specifications',
            'IMAGE_LIMIT_2MB': 'The size of a single image is limited to within 2MB (supports  jpg, png formats)',
            'PRICE_UNKNOWN_INPUT': "Tendabuy cannot discern the price of products from third-party platforms, you need to enter the price below. If you are also unclear about the product's price, you can place an order for 1 yuan. After Tendabuy's purchaser receives the order, they will contact the seller, and then initiate a product additional payment request to you.",
            'SELLER_SHIP_FEE': "Please fill in the shipping cost from the seller to Tendabuy's warehouse",
            'TOTAL_AMOUNT_RMB': 'Total amount = Product cost + Shipping to warehouse Conversion standard (based on RMB)',
            'ENTER_UNIT_PRICE': 'Please enter the unit price',
            'PRODUCT_NAME_100CHAR': 'Please enter the product name (limited to 100 characters)',
            'SELLER_FEE_TENDABUY': "Please fill in the shipping cost from the seller to Tendabuy's warehouse",
            'HTTP_LINK_REQUIRED': 'Please enter the product link, starting with http:// or https://',
            'SPECIFY_COLOR_SIZE': 'To purchase the product more accurately for you, please leave the information of color/size and other specifications here',
            'CLICK_COUNT': 'Click count',
            'RECORD': 'Record',
            'VIEW': 'View',
            'SIGNED': 'Received',
            'PAID1': 'Paid',
            'ITEM_MISSING': 'Cannot find this item',
            'SECURITY_SET_2FA': 'Your security level is "-1-" Please set up two-step verification to secure your account',
            'SECURITY_LOW': 'Your security level is "-1-" ',
            'CHANGE_PWD': 'Change Payment Password',
            'OLD_PWD': 'Payment Password',
            'PWD_MIN_6': 'The password must be at least 6 characters long',
            'ENTER_OLD_PWD': 'Initial Password',
            'LOGIN_PWD_OK': 'Login password changed successfully',
            'PAY_PWD_OK': 'Payment password changed successfully',
            'VALUE_ADD': 'Value Added Services',
            'ORDER_AMOUNT': 'Order Amount',
            'REAUTH': 'Whether to repayment authorization',
            'ITEMS_QTY': '\nQuantity',
            'PARCEL_INFO': 'Parcels info',
            'EST_SHIPPING': 'Estimated freight',
            'EST_WEIGHT': 'Estimated weight',
            'EST_VOLUME': 'Estimated volume',
            'ACT_SHIPPING': 'Final freight',
            'ACT_WEIGHT': 'Exact weight',
            'ACT_VOLUME': 'Exact volume',
            'RECIPIENT': 'Recipient',
            'CUBIC_CM': 'cm³',
            'TOTAL_WEIGHT': 'Total weight',
            'TOTAL_VOLUME': 'Total volume',
            'LOG_ROUTE': 'logistics route',
            'TRACK_NO': 'Shipping number',
            'LOG_STATUS': 'Tracking status',
            'ACT_AMOUNT': 'Final Amount',
            'SELF_DECLAR': 'Declare by myself',
            'PARCEL_SERVICE': 'Parcel service',
            'ORDER_SERVICE': 'Order services',
            'AGREE_TNC': 'Please agree to the terms and conditions first',
            'DECLAR_EXCEED': 'Too many items declared',
            'FILL_ITEMS': 'Please declare your items',
            'SELECT_ROUTE': 'Please select logistics route',
            'PRE_FEE': 'Pre-packaging fee',
            'IMG_2MB': 'Image under 2MB only',
            'MODIFY_PRICE': 'Edit Price',
            'STOCK': 'Inventory',
            '待删除': '',
            'ACTIVATE_EMAIL': 'Activate',
            'EMAIL_SENT': 'Email verification code successfully sent',
            'TEMPLATE': 'Template',
            'PENDING_CON': 'Pending Confirmation',
            'WEIGHT_MAX': 'Upper weight limit',
            'WEIGHT_MIN': 'Lower weight limit',
            'AFTER_SALES_DE': 'After sales service description',
            '与上面重复': 'Account Security',
            'INVALID_PARAMS': 'Parameter error',
            'PWD_RESET_SU': 'Password reset successful',
            'PAY_BE_TIMEOUT': '',
            'CON_PAYMENT': 'Confirm Payment',
            'PAY_ISSUE': 'Something wrong with Payment',
            'RE_DETAILS': 'Report Details',
            'LOGISTICS_LABEL': 'This label is used by the Tendabuy system to identify logistics restrictions and will not be attached to products or packaging.',
            'NEWBIE_GUIDE': 'New User Guide',
            'MEMBER_GIFT': 'New Member Gift Voucher',
            '删掉': 'Membership Registration',
            'TUTORIAL': 'Tutorial',
            'SERVICE_FEES': 'Service & Fees',
            'RETURNS_REFUNDS': 'Returns & Refunds',
            'CUSTOMER_SERVICE': 'Customer Service',
            'PAYMENT_DEPOSIT': 'Payment & Deposit',
            'STORAGE1': 'Storage',
            'INSPECTION_RULES': 'Inspection Rules',
            'INSURANCE_COM': 'Insurance & Compensation',
            'DELIVERY': 'Delivery',
            'CHARGES': 'Charges',
            'PACKAGING': 'Packaging',
            'CUSTOMS_TAX': 'Customs & Tax',
            'RECEIPT_INFORMATION': 'Receipt Information',
            'LOGISTICS_TRACKING': 'Logistics Tracking',
            'ABOUT_US': 'About Us',
            'TENDABUY': 'Tendabuy',
            'TERMS_OF_USE': 'Terms of Use',
            'PRIVACY_SECURITY': 'Privacy & Security',
            'LEGAL_NOTICE': 'Legal Notice',
            'CONTACT_US': 'Contact Us',
            'TRACK_INFO': 'How to track my package shipping info?',
            'DELIVERY_AWARENESS': 'What should I be aware of when my package is delivered?',
            'DAMAGED_ITEM': 'What should I do if a fragile item is damaged？',
            'PROBLEMATIC_PRODUCT': 'What should I do if the product from a shopping agent is problematic upon receipt？',
            'WAREHOUSE_PROBLEM': 'What should I do if I find a problem with the product after it arrives at our Tendabuy warehouse？',
            '上面已经有了': '',
            'MULTIPLE_CHINA': 'Multiples Shopping Platform From China',
            'COMPETITIVE_ITEMS': 'Offer competitive prices on millions of online & offline items',
            'COMBINE_DELIVER': 'Combine items from various sellers into one package & deliver to you',
            'COVERING_SAFE': 'Covering more than 200 countries worldwide, safe and reliable',
            'PURCHASE_TENDABUY': 'Purchase through Tendabuy',
            'CHECK_IN': 'Quality Inspection & Storage',
            'SEND_PKG': 'International Shipping Made Easy',
            'VIEW_MORE': 'View More',
            'MORE_NEWS': 'More News',
            '购物车支付界面': 'Destination',
            'GOODS_INTER_CONTENT': 'Only write if you have special requirements for this order',
            '收藏夹页面': 'keyword searching in favorite list',
            'OFF_500': '￥50 off orders over￥500',
            'COUPONS': 'Coupons',
            'NO-THRESHOLD_COUPON': '￥20 No-threshold coupon',
            'OVER_500': 'Over ￥500',
            '20%_OFF': '20% off',
            'APPLICABLE_COUNTRY': 'Applicable country',
            'APPLICABLE_LINE': 'Applicable line',
            'ALL_COUNTRIES': 'All countries',
            'USAGE_RULES': 'Usage rules',
            'USE': 'Use',
            'CONVERT': 'Convert',
            'ENTER_REDEEM': 'Please enter redeem code',
            'AVAILABLE_COUPON': 'Available Coupon',
            'USED_COUPONS0': 'Used Coupons',
            'EXPIRED_COUPON': 'Expired Coupon',
            'ANY_COUPONS': "You don't have any coupons yet",
            'DO_YOU_DELETE': 'Do you confirm to delete?',
            'COLLECTED_PRODUCTS': "You haven't collected any products yet, go to collect them",
            'COLLECTED_SHOP': "You haven't collected any shops yet, go to collect them",
            'ORDER_DETAIL': 'Order Details',
            'PACKAGE_NUMBER': '\nRehearsal No',
            'SUBMIT_TIME': 'Submitted time',
            'VALUE_ADD_SERVICE_FEE': 'Value-added service fee',
            '前面有': '\nOrder amount',
            'GOODS_QUANTITY': 'Goods quantity',
            'PARCEL_INFO2': 'Parcel Info',
            'ESTIMATED_FREIGHT': 'Estimated freight',
            'ESTIMATED_WEIGHT': 'Estimated weight',
            'ESTIMATED_VOLUME': 'Estimated volume',
            'FINAL_FREIGHT': 'Final freight',
            'EXACT_WEIGHT': 'Exact weight',
            'EXACT_VOLUME': 'Exact volume',
            'DETAILED_COSTS': 'Detailed Costs',
            'PRE-PACKAGING_OPERATION_FEE': 'Pre-packaging fee',
            'RECIPIENTS_INFO': "Recipient's info",
            'RECIPIENT2': 'Recipient',
            'MOBILE_PHONE': 'Mobile phone',
            'SHIPPING_ADDRESS2': 'Shipping address',
            'SHIPPING_LINE': 'Shipping Line',
            'SHIPPING_ID': 'Shipping ID',
            'SHIPPING_STATUS': 'Shipping status',
            'SHIPPING_INFO': 'Shipping information',
            'STATUS1': 'Status',
            'PARCEL_PAGE': 'After the parcel order is successfully paid, the order will go to "Parcel" page and we will soon arrange shipments.',
            'SELECT_LOGISTICS': 'Please Select logistics route',
            'NO_SELECTED': 'No Route Selected',
            'INTENTIONAL_ROUTE': 'Intentional route',
            'SELECT_ROUTE1': 'Select logistics',
            'SUCCESSFULLY_CANCELED': 'Successfully Canceled',
            'TOTAL0': 'Total',
            'ENQUIRY_PAGE': 'Welcome to "Enquiry" page, there\'s no enquiry at present,\n\nyou can click the "customer service" button on the right to leave us a message at any time.',
            'UNDER_2MB': 'Image under 2MB only!',
            'TRACK_PARCEL': 'How to track my parcel?',
            'NOTICES_RECEIVING': 'Notices of the parcel receiving',
            'PRODUCTS_DAMAGED': 'What if fragile products arrived damaged?',
            'ISSUE_PRODUCT': 'What do I do if there is a product issue after I received it?',
            'APPLY_AFTERSALES': 'How to apply for aftersales?',
            'LEAVE_INFO': 'Leave product description and specification info.',
            'AMOUNT_LESS': 'The amount cannot be less than 0',
            'AMOUNT_MORE': 'The amount cannot be more than 0',
            'COMMON_BRAND': 'Common Brand',
            'INTERNATIONAL_BRAND': 'International Brand',
            'COMMON_BATTERY': 'Common Brand(with battery)',
            'COMMON_WITHOUT_BATT': 'Common Brand(without battery)',
            'REMOVE_ORDER': 'Remove Order',
            'COMBINE_PAYMENT': 'Combine Payment',
            'GO_PLACE': 'Go to place an order',
            'PLACE_AN_ORDER': "You don't have any orders yet, go to place an order.",
            'ORDERS_UNPAID': 'Please check if all orders are unpaid',
            'RESET_SUCCESSFUL': 'Password reset successful',
            'WRONG_PAYMENT': 'Something wrong with Payment',
            'WITHDRAWAL_AMOUNT': 'Withdrawal amount',
            'MODIFY_REMARK': 'Modify remark',
            'SELECT_DESTINATION': 'Please select your destination',
            'LESS_THAN': 'The amount cannot be less than 0',
            'LESS_THAN2': 'The amount cannot be less than 0',
            'PARCEL_NO': 'Parcel No',
            'ORDER_SERVER_FEE': 'Service',
            'NO_THRESHOLD': 'No-threshold',
            'UP_20OFF': 'Up to ¥20.00 off',
            '50_OFF': '50% off, up to ¥20.00 off',
            'ALL_LINES': 'International Shipping-All lines',
            'IN_PACKING': 'In Packing Center',
            'CONFIRM_REFUND': 'Confirm to delete order? Your item won’t return to the seller or get a refund.',
            'REMINDER': 'Reminder',
            'QUALITY_PHOTOS': 'Quality inspection photos',
            'ENTER_PARCEL': 'Enter this name of parcel',
            'SHIPPING_COMPANY': 'Shipping company',
            'SHIPPING_EXPRESS_NO': 'Shipping ID is ',
            'NO_STATUS': 'There is no shipping status for 1111 at the moment.',
            'REFUND': 'Refund',
            'ORDER_ID': 'Order ID',
            'REQUEST_REFUND': 'Request a refund',
            'REASON': 'Reason for application',
            'SELECT_REASON': 'Please select your reason',
            'DESCRIPTION': 'Description',
            'IN_DESCRIPTION': 'Please enter a description',
            'REFUNDED': 'Order Refunded Successfully',
            'REVIEWS': 'Reviews',
            'REDEMPTION': 'Redemption successful',
            'BLOG': 'Blog',
            'COMPLETED': 'Completed',
            'AFTERSALES': 'In AfterSales',
            'REFUNDED2': 'Refunded',
            'NO_LONGER': 'I no longer want the order',
            'PRICE_CHANGE': 'I would like to request a refund due to a price change',
            'OUT_OF_STOCK': 'Refund due to Out of Stock',
            'WRONG_SIZE': 'Wrong Color/Size Ordered',
            'LATE_DELIVERY': 'Late delivery',
            'QUALITY_ISSUES': 'Product quality issues',
            'NOT_SAME': 'Dissatisfaction with product',
            'INCORRECT': 'Incorrect item received',
            'YES': 'Yes',
            'NO': 'No',
            'BUY_SHIP': 'Buy & Ship Any Chinese Items For You',
            'SHOP_CHINA': 'Shop China',
            'CHOOSE_TENDABUY': 'Choose Tendabuy',
            'NOT_FOUND': 'Item not found',
            'PARTNERS': 'Partners',
            'VALUE_MONEY': 'Value-For-Money',
            'ADDED_SERVICE': 'Value-Added Service',
            'LOGISTICS': 'Logistics Provider',
            'DESTINATION': 'Please select your destination',
            'REFUND_RETURN': "1. Your refund/return application, the purchase agent will be accepted within 24 hours and communicate with the seller to negotiate processing. (Please choose the reason according to the actual situation, if you choose the reason and the actual reason is not consistent, will be the final verification of the merchant's responsibility shall prevail.)",
            'RETURN_TIME': '2. Whether a return is possible and the refund processing time depends on the seller, approximately 7-15 business days.',
            'CLOSE_SERVICE': '3. If the product has been in our warehouse for more than 5 days, the seller will reject "returns without reason". If it has been in our warehouse for more than 10 days, the seller will close the after-sales service portal, and return requests cannot be initiated.',
            'APPLYING_RETURN': '4. Before applying for a return, please read carefully → Click here',
            'REFUND_POLICY': '《Refund/Return Policy》',
            'NOT_LOGGED': 'You are not logged in',
            'REPORT': 'Please enter a description of the report',
            'STOCK_0': 'This item is in stock 0 cannot be ordered',
            'OK': 'Ok',
            'REMIND': 'Do not remind again today',
            'READ_ACCEPT': 'I have read and accept the above content',
            'FORWARDING_SERVICE': 'Forwarding means that your own purchases in China or goods sent to you by your relatives or friends need to be shipped to our warehouse and then sent overseas using convenient international logistics services.',
            'TRACKING': 'Please check that the tracking number submitted is correct.',
            'TRANSFER': 'The transfer order was added successfully',
            'LENGHTH': 'Exact lenghth',
            'PRE_PACKAGE': 'Pre-package No',
            'EXACT_WIDTH': 'Exact width',
            'EXACT_HEIGHT': 'Exact height',
            'CANCEL_REASON': 'Please enter the reason for cancelling the payment',
            'CANCELLATION': 'Orders cannot be restored after cancellation, please select the reason for payment cancellation',
            'REFUND_REASON': 'Please select the refund reason',
            'NO_ORDER': 'I no longer want the order',
            'INFO_WRONG': 'Information entered incorrectly',
            'OUT': 'Seller is out of stock',
            'UNABLE': 'Unable to make payment',
            'PAYMENT_PENDING_TIP_1': 'The order has been submitted and is waiting for your payment.',
            'PAYMENT_PENDING_TIP_2': 'Please complete the payment within 72hours after the order is generated, otherwise the order will be automatically cancelled.',
            'PAID_TIP_1': 'Your order has been successfully placed and is waiting for the agent to purchase it.',
            'PAID_TIP_2': 'Orders submitted between 08:00 and 18:00 Beifing time will be processed within 6hours.',
            'PURCHASING_TIP_1': 'The procurement personnel are purchasing the goods you have selected from the merchant.',
            'PURCHASING_TIP_2': 'We will complete the purchase for you within the agreed procurement time. ',
            'PURCHASED_TIP_1': 'The goods you have selected have been purchased and are waiting for the merchant to ship them',
            'PURCHASED_TIP_2': 'Merchant usually ship within 3-7 days\n(excluding some custom-made and pre-saleitems).',
            'SHIPPED_TIP_1': 'The merchant has arranged for the goods to be shipped. Please wait for the goods to arrive at the TENDABUY Warehouse',
            'SHIPPED_TIP_2': 'The goods usually arrive at our warehouse within 3 working days, and no later than 7 working days. ',
            'IN_PACKING_CENTER_TIP_1': 'The goods have been stored in the Tendabuy packing center. You can go to the Warehouse to check. ',
            'IN_PACKING_CENTER_TIP_2': 'The goods have a free storage period of 100 days, and you can submit the shipment at any time during the storage period.',
            'IN_AFTERSALES_1': "Your order has initiated an after-sales request, the processing status can be viewed in 'Refunds/After-sales'.",
            'IN_AFTERSALES_2': 'Once the refund process is complete, the funds will be returned to your Tendabuy account.',
            'CANCELED_TIP_1': 'The orders are withdrawn by you manually or by the system automatically.',
            'CANCELED_TIP_2': 'you can withdraw it before it gets purchased.',
            'CLOSED_TIP_1': 'The order for which a refund was requested after payment has been completed. ',
            'CLOSED_TIP_2': 'You can go to the homepage of Home, click on My Balance to view the details of the refund.',
            'OUTSIDE_SHIPPED_TIP_1': 'Your parcel has been shipped',
            'OUTSIDE_SHIPPED_TIP_2': 'You can go to Parcel Details to view the relevant logistics track.',
            'REFUNDED_TIP_1': 'The order for which a refund was requested after payment has been completed.',
            'REFUNDED_TIP_2': 'You can go to the homepage of Home, click on My Balance to view the details of the refund.',
            'NO_PAID_72': 'This order will be cancelled if the payment is not made within 72 hours.',
            'DECLARE': 'If you need TENDABUY to declare for you, you can choose',
            'DECLARATION': 'Entrust the declaration',
            'DECLARATION2': 'Are you sure to entrust the TENDABUY to assist you in handling the declaration,',
            'INSTRUCTIONS': '《Instructions for Entrusted Declaration》',
            'INSURED2': 'Insured Amount',
            'PURCHASE': 'PURCHASE',
            'PASTE_ORDER': 'paste an item link to submit an order',
            'PAY_SHIPPING': 'pay for item price & domestic shipping',
            'SHIP_ITEM': 'we purchase & ship item to our warehouse',
            'QC_STORAGE': 'QC & STORAGE',
            'INSPECTION': 'quality inspection in warehouse',
            'FREEQC': '5 free QC photos will be taken',
            'FREE_STORAGE': '90 days free storage service',
            'GLOBAL': 'GLOBAL DELIVERY',
            'ONE_PARCEL': 'submit different orders into one parcel',
            'SHIPPING_100': 'customized packing, 100+ shipping lines',
            'GLOBAL_FEE': 'pay for global shipping fee',
            'EMPTY': 'Please enter the city',
            'ROUTE': 'This route does not exist. Please select a new area.',
            'GLOBAL_PLATFORM': 'Global Leading Cross-border Integrated Service Platform',
            'EASILY': 'Purchase products from China easily with Tendabuy.',
            'STEPS': 'It only takes five steps',
            'GO_HOMEPAGE': 'Go to homepage',
            'PAGE': 'Next Page',
            'FREE2': 'Free! 90 days storage fee',
            'WELCOME': 'WELCOME to TENDABUY',
            'ASSISTANT': 'An assistant that makes it easy for you to purchase products from China.',
            'NO_NEED': 'You can order it yourself. No need to go through a middleman.',
            'PLEASE_SELECT_PAYMENT_METHOD': 'Please select payment method',
            'AUTHENTICATOR_LABEL': 'Authenticator Verification',
            'AUTHENTICATOR_DESCRIPTION': 'The Authenticator Verification is in use to make the account more secure. Please backup your key.',
            'OPEN': 'ON',
            'CLOSE': 'OFF',
            'UNBIND': 'Unbind',
            'LABEL_TRUST_CURRENT_DEVICE': 'Trust this device',
            'LABEL_CURRENT_DEVICE': 'Current Device',
            'TITLE_GOOGLE_Authenticator': 'Google Authenticator Validation',
            'TITLE_OPEN_GOOGLE_AUTHENTICATOR': 'Open Google Authenticator Validation',
            'TITLE_UNBIND_GOOGLE_Authenticator': 'Unbind Google Authenticator Validation',
            'TITLE_CLOSE_GOOGLE_AUTHENTICATOR': 'Turn off virtual Authenticator device verification',
            'ALERT_OPEN_GOOGLE_AUTHENTICATOR': 'Your account is bound to a virtual Authenticator for verification. Please enter the virtual Authenticator dynamic code that you bound on your smart device.',
            'PLACEHOLDER_AUTHENTICATOR_VERIFICATION_CODE_INPUT': 'Please enter a 6-digit security code',
            'BUTTON_SUBMIT_VERIFICATION_CODE': 'Submit verification',
            'STEP_1_OPEN_GOOGLE_AUTHENTICATOR': 'Verify identity',
            'STEP_2_OPEN_GOOGLE_AUTHENTICATOR': 'Installing Application',
            'STEP_3_OPEN_GOOGLE_AUTHENTICATOR': 'Binding',
            'STEP_4_OPEN_GOOGLE_AUTHENTICATOR': 'Complete',
            'ALERT_CONFIRM_EMAIL_VERIFICATION_CODE': 'To confirm that it was your own operation, please complete the following verification. If you have not received the verification code, please retrieve it again or try again later.',
            'BUTTON_RESEND_EMAIL_VERIFICATION_CODE': 'Retrieve again',
            'ALERT_CONFIRM_EMAIL_ADDRESS': 'To ensure that it is your own operation, please confirm your verification email to complete the identity verification.',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Please download and install Google Authenticator on your mobile phone',
            'TIP_IPHONE_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'IPhone: Search for Google Authenticator in the AppStore',
            'TIP_ANDROID_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Android: Search for Google Authenticator in the app market',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR_NEXT_STEP': 'After installation is completed, click on the next step to bind the page (if installed, proceed directly to the next step)',
            'TIP_USE_GOOGLE_AUTHENTICATOR_TO_SCAN': 'Scan the following QR code using Google Authenticator to obtain a 6-digit verification code',
            'TIP_BIND_GOOGLE_AUTHENTICATOR_SUCCESS': 'You have successfully bound Google Authenticator!',
            'BUTTON_RETURN': 'Return',
            'ALERT_CHECK_GOOGLE_AUTHENTICATOR_VERIFICATION_CODE': 'To confirm that it was your own operation, please enter the virtual Authenticator dynamic code that you bound on the smart device.',
            'BUTTON_USE_EMAIL_TO_UNBIND': 'Unbind using email',
            'BUTTON_USE_EMAIL_TO_CHECK': 'Use email verification',
            'TITLE_MODIFY_DEVICE_NAME': 'Modify device name',
            'TITLE_CURRENT_DEVICE': 'Current device',
            'PLACEHOLDER_MODIFY_CURRENT_DEVICE_NAME': 'Please enter the device name to be modified',
            'MESSAGE_OPEN_SUCCESS': 'Opened successfully',
            'MESSAGE_UNBIND_SUCCESS': 'Unbind successful',
            'MESSAGE_CLOSE_SUCCESS': 'Closed successfully',
            'DELETE_BILLING_ADDRESS': 'Delete billing address',
            'EDIT_BILLING_ADDRESS': 'Edit billing address',
            'CLICK_VIEW': 'Click to view all products in this store on Tendabuy',
            'TRANSIT': 'In Transit',
            'SERVICE_NOT': 'Service fee to be paid',
            'SERVICE_PAID': 'Service fee paid',
            'AWAITING_PACK': 'Awaiting packaging',
            'PACKED_2': 'Package is packed',
            'WAIT_SHIPMENT': 'Awaiting shipment',
            'PENDING_CLEAR': 'Pending customs clearance',
            'TRANSIT_2': 'International transit',
            'ARRIVED_COUNTRY': 'Arrived at destination country',
            'IN_COUNTRY': 'In-country transportation',
            'DELIVERED_2': 'Delivered',
            'CANCEL_2': 'Cancel',
            'INTER_NOT': 'International shipping fee to be paid',
            'INTER_PAID': 'International shipping fee paid',
            'INTER_OPTIONS': 'International Shipping Routing Options',
            'ESTIMATED_PAID': 'Estimated full fees paid',
            'ESTIMATED_NOT': 'Estimated full fees not paid',
            'NOTICES': 'Notices',
            'SHIPPING': 'Shipping Company',
            'SELECT_COUPON': 'Select shipping coupon',
            'CURRENT_PAGE': 'The current product is 1688 products, and the order amount is subject to the actual transaction. If you have special needs, please note',
            'ITEM': 'Item',
            'WE_OFFER_5': 'We offer 5 free HD inspection photos',
            'AFTER_YOU_CONFIRM': 'After you confirm the goods, choose to send them out by international express.',
            'NAVIGATION': 'Search for the item you want to purchase here. TendaBuy supports keyword search, as well as searching using a product link or a product image.',
            'NAVIGATION_1': 'Click here to calculate the shipping cost and learn about the shipping route information.',
            'BACK': 'Back',
            'NEST': 'Next',
            'FINISH': 'Finish',
            'INSURED_RANGE': 'Insured Range',
            'INSURANCE_FEE_WITHOUT': 'Insurance fee is not within the range',
            'EMAIL_NOT_VERIFIED': 'Email not verified',
            'TO_BE_SUPPLEMENTED': 'To be supplemented',
            'PARCEL_CLOSED': 'Parcel closed',
            'CONFIRM_RECEIPT': 'confirm receipt',
            'SIZE_GUIDE_NOTES': 'Please note, this is just a rough size conversion chart as sizes may vary between different sellers and brands. We recommend checking the detailed size chart of the product before making a purchase and selecting the appropriate size based on your actual measurements.',
            'SIZE_GUIDE': 'Size Guide',
            'SIZE_ASSISTANT': 'Size Assistant',
            'TOOL_MENU': 'Tool Menu',
            'SWITCH_TO': 'Switch to',
            'PRODUCT_NOT_FOUND': 'Product not found, return to my orders.',
            'SELECT_COUPON_1': 'Select Coupon',
            'PAID_1': 'paid',
            'FIRST_WEIGHT_CHARGE': 'First weight charge',
            'RENEWAL_CHARGE': 'Renewal charge',
            'ACTIVA': 'Send an activation email',
            'ACTIVATE': 'Did you activate your email?',
            'ACCEPT': 'Accept',
            'CANCEL0': 'Cancel'
        }, 'language3': {
            'HOME_PAGE': 'Zuhause',
            'SHOPPING_CART': 'Einkaufswagen',
            'FAVORITE_LIST': 'Wunschliste',
            'MY_ORDERS': 'Bestellungen',
            'MY_WAREHOUSE': 'Mein Lager',
            'MY_ASSETS': 'Geldbörse',
            'MY_INQUIRY': 'Anfrage',
            'MESSAGING_CENTER': 'Nachricht',
            'ACCOUNT_BALANCE': 'Kontostand (¥)',
            'CUSTOM_FEATURES': 'Personalisiert',
            'AFFILIATE_MARKETING': 'Partner',
            'HELP_CENTER': 'Hilfezentrum',
            'PRODUCT_INFORMATION': 'Produktinformation',
            'ACCOUNT_BALANCE2': 'Gleichgewicht',
            'PRODUCT_NOTES': 'Produktnotizen',
            'SPECIAL_PURCHASE_REQUIREMENT_MESSAGE': 'Schreiben Sie eine Nachricht NUR, wenn Sie besondere Einkaufsanforderungen haben',
            'ACCOUNT_SETTINGS': 'Konto',
            'ACCOUNT_SETTINGS2': 'Konto',
            'UNIT_PRICE_LABEL': 'Stückpreis',
            'QUANTITY_LABEL': 'Menge',
            'AMOUNT_LABEL': 'Betrag',
            'ACTION_LABEL': 'Aktion',
            'TAGS_LABEL': 'Stichworte',
            'DELETE_BUTTON': 'Löschen',
            'KIND_REMINDER': 'Freundliche Erinnerung',
            'CONFIRM_DELETE_PRODUCT': 'Bestätigen Sie die Löschung?',
            'CANCEL_1': 'Abbrechen',
            'CONFIRM_1': 'Bestätigen',
            'ADD_TO_CART': 'In den Warenkorb',
            'REMOVE_SELECTED_ITEMS': 'Auswahl entfernen',
            'PRODUCT_SELECTED_1_NO_FREIGHT': 'Ausgewählte Produkte 1 Stück (ohne Fracht)',
            'ESTIMATE_SHIPPING_COST': 'Frachtkostenrechner',
            'PROCEED_TO_CHECKOUT': 'Absenden',
            'SHOP': 'Geschäft',
            'PRODUCTS': 'Produkte',
            'SEARCH_BY_NOTE_OR_STORE_NAME': 'Nach Notizname/Geschäftsname suchen',
            'CHOOSE_PLATFORM': 'Plattform auswählen',
            'TAOBAO': 'Taobao',
            'TMALL': 'Tmall',
            'ALIBABA_1688': 1688.0,
            'ENTER_PRODUCT': 'Bitte geben Sie das Produkt ein',
            'FAVORITE_ITEM_KEYWORDS': 'Geben Sie Ihre Lieblingsprodukt-Stichwörter ein',
            'ADD_TO_CART2': 'In den Warenkorb',
            'VIEW_WAREHOUSE_IMAGES': 'Lagerfotos anzeigen',
            'NAVIGATE_TO': 'Gehe zu',
            'SHOW_ALL': 'Alle',
            'AWAITING_PAYMENT': 'Zahlung Ausstehend',
            'SORT_BY_DATE': 'Nach Zeit sortieren',
            'ORDER_RECYCLE_BIN': 'Bestellungs-Papierkorb',
            'ITEM_DETAILS_HEADER': 'Warenname/Bestellnummer/Artikelnummer',
            'SIMPLIFY_FILTERS': 'Filter ausblenden',
            'ORDER_STATUS': 'Bestellstatus',
            'UNPAID_ORDERS': 'Zahlung Ausstehend',
            'PAYMENT_COMPLETED': 'Bezahlt',
            'PENDING_ORDERS': 'Einkauf',
            'AWAITING_CONFIRMATION': 'Erwarte Bestätigung',
            'SHIPPED_ORDERS': 'Versendet',
            'DELIVERED_ORDERS': 'Bestellung geliefert',
            'CANCEL_ORDER_ACTION': 'Storniert',
            'START_DATE_RANGE': 'Startdatum',
            'END_DATE_RANGE': 'Enddatum',
            'SEARCH_ACTION': 'Suche',
            'AFTER_SALES_SERVICE_1': 'Kundendienst',
            'UNIT_PRICE_FIELD': 'Stückpreis',
            'QUANTITY_FIELD': 'Menge',
            'STATUS_OF_ORDER': 'Bestellstatus',
            'ADDITIONAL_SERVICES': 'Mehrwertdienste',
            'AFTER_SALES_SERVICE2': 'Nachverkauf',
            'ORDER_TOTAL': 'Gesamtbetrag',
            'ADDITIONAL_ACTIONS': 'Sonstige',
            'ORDER_NUMBER_LABEL': 'Bestellnummer',
            'CREATION_TIMESTAMP': 'Erstellungszeit',
            'WAREHOUSE_NAME_TENDBUY': 'Lagerhaus: Tendabuy Lagerhaus',
            'UNPAID_STATUS': 'Unbezahlt',
            'PROCEED_TO_PAYMENT': 'Zahlen',
            'PRODUCT_NOTE_COLON': 'Produktnotiz:',
            'SELECT_ALL_2': 'Alles auswählen',
            'PAGE_NAVIGATION': 'Seite',
            'ITEM_IDENTIFIER_HEADER': 'Warenname/Bestellnummer/Artikelnummer',
            'FILTER_COLLAPSE': 'Filter ausblenden',
            'DATE_RANGE_START': 'Startdatum',
            'DATE_RANGE_END': 'Enddatum',
            'SEARCH_QUERY': 'Suche',
            'ORDER_SUCCESS_SHIPMENT_PROCESS': 'Nach einer erfolgreichen Bestellung wird der Verkäufer die Waren an das Lager versenden. Sobald das Lager die Waren erhält, können Sie sie auf dieser Seite einsehen und verwalten.',
            'IDENTIFICATION_COLOR': 'Etikettenfarbe',
            'PRODUCT_LABEL': 'Produkt-Tag',
            'SELECT_ALL_ITEMS': 'Alles auswählen',
            'TENDABUY_WAREHOUSE': 'Tendabuy Lagerhaus',
            'SHIPPING_COUNTRY': 'Ziel',
            'ESTIMATED_DEPOSIT_WEIGHT': 'Geschätze Kaution (tatsächliches Gewicht)',
            'SELECTED_ITEMS_COUNT': 'Ausgewählte * Artikel (Insgesamt * Artikel)',
            'PREVIEW_PACKAGING': 'Geschätzte Verpackung',
            'PREPACKING_SERVICE_INFO': 'Der Vorpaketservice ist keine tatsächliche Verpackung, sondern ein Vorpaketservice. Wenn Sie verpacken müssen, kehren Sie bitte zum Lager zurück und "geben Sie die Sendung frei',
            'SUBMIT_SHIPMENT': 'Sendung freigeben',
            'SEARCH_PR_PO_PI': 'Nach PR/PO/PI suchen',
            'PACKAGE_PREVIEW_INFO': 'Probenpaket Info',
            'INTENDED_ROUTE': 'Geplante Route',
            'WEIGHT_GRAMS': 'Gewicht',
            'DIMENSIONS_CM': 'Abmessungen',
            'VALUE_ADDED': 'Mehrwert',
            'ALL_OPTIONS': 'Alle',
            'OPERATIONS': 'Aktion',
            'UNPROCESSED_STATUS': 'Nicht verarbeitet',
            'PRINT_OUTBOUND_ORDER': 'Ausgangsbestellung drucken',
            'SHIPPED_STATUS': 'Versendet',
            'WEIGHT_CALCULATED': 'Gewicht berechnet',
            'SHIPPED_OUT': 'Versendet',
            'EXPIRED_STATUS': 'Abgelaufen',
            'CANCELLED_STATUS': 'Storniert',
            'PACKAGING_WEIGHT_VOLUME_HINT': 'Bevor Ihre Artikel verpackt werden, kann das System keine genauen Gewichts- und Volumeninformationen erhalten. Daher können Sie auf der Seite "Mein Lager" eine "Vorschau des Pakets" kaufen, um das genaue Gewicht und Volumen Ihres Pakets zu erhalten.',
            'AFTER_SALES_SERVICE': 'Kundendienst nach dem Verkauf',
            'RECHARGE': 'Aufladen',
            'ARREARS': 'Rückstände',
            'SETTLE_ARREARS': 'Zahlungsrückstände Zurückzahlen',
            'AFTER_SALES_SERVICE_2': 'Kundendienst nach dem Verkauf',
            'START_DATE': 'Startdatum',
            'END_DATE': 'Enddatum',
            'TRANSACTION_TYPE': 'Typ',
            'WITHDRAWAL_DEDUCTION': 'Abzug für Abhebung',
            'ORDER_REFUND': 'Bestellrückerstattung',
            'VIEW_BILL': 'Rechnung anzeigen',
            'TXN_TIME': 'Transaktionszeit',
            'INCOME_EXPENSE': 'Einnahmen/Ausgaben(¥)',
            'ALL_MESSAGES': 'Alle Nachrichten',
            'TXN_ID': 'Transaktions-ID',
            'ALL_MESSAGES2': 'Alle Nachrichten',
            'NO_DATA_AVAILABLE': 'Keine Daten',
            'INPUT_CONTENT': 'Produktlink oder Schlüsselwort',
            'CONTENT_TYPE': 'Typ',
            'AVAILABLE_BALANCE': 'Verfügbares Guthaben :',
            'ENQUIRY_CONTENT': 'Anfrageinhalt',
            'AVAILABLE_BALANCE2': 'Verfügbares Guthaben',
            'BALANCE_DETAIL': 'Guthabendetails',
            'ALL_INQUIRIES': 'Alle',
            'ORDER_INQUIRY': 'Bestellungsanfrage',
            'PACKAGE_INQUIRY': 'Paketanfrage',
            'AFTER_SALE_SERVICE': 'Paket-Nachverkauf',
            'OTHER_QUERIES': 'Andere Anfragen',
            'PACKAGE_ORDER_NO': 'Paketnummer / Bestellnummer',
            'ASSOCIATED_NO': 'Zugehörige Nummer',
            'CONSULT_CONTENT': 'Anfrageinhalt',
            'ENQUIRY_SUBMIT_TIME': 'Eingereichte Zeit',
            'ALL_ITEMS': 'Alle',
            'BALANCE_DETAIL2': 'Guthabendetails',
            'PACKAGE_ORDER_PRODUCT_NO': 'Paket/Sendungsnummer/Artikelnummer',
            'TITLE': 'Titel',
            'CREATION_TIME': 'Erstellungszeit',
            'OPERATION': 'Aktion',
            'EMPTY_DATA': 'Keine Daten',
            'SELECT_ALL_MESSAGES': 'Alles auswählen',
            'BIRTHDAY': 'Geburtstag',
            'SYSTEM_NOTIFICATION': 'Systemnachricht',
            'MANUAL_MESSAGE': 'Manuelle Nachricht',
            'VIEW_DETAILS': 'Details anzeigen',
            'PERSONAL_INFO': 'Persönliche Informationen',
            'EDIT_PROFILE': 'Bearbeiten',
            'BIRTHDAY2': 'Geburtstag',
            'BUYING_GUIDELINES': 'Einkaufsagent-Anleitung',
            'PHONE_NUMBER': 'Telefon',
            'BUYING_GUIDELINES2': 'Einkaufsagentenrichtlinien',
            'VOICE': 'Stimme',
            'CANCEL_BUTTON': 'Stornieren',
            'GROWTH_POINTS': 'Wachstumswert',
            'VIEW_GROWTH_HISTORY': 'Ebenendatensätze Anzeigen',
            'NEED_3000_MORE_POINTS_FOR_V1': 'Sie benötigen nur noch 3.000 weitere Wachstumswerte, um V1 zu erreichen',
            'SHIPPING_ADDRESS': 'Versandadresse',
            'ADD_SHIPPING_ADDRESS': 'Versandadresse hinzufügen',
            'ZERO_ADDRESSES_CREATED_LEFT': 'Sie haben erstellt',
            'ZERO_ADDRESSES_CREATED_RIGHT': 'Versandadressen erstellt',
            'PROMPT_ADD_ADDRESS': 'Sie haben noch keine Versandadressen hinzugefügt. Bitte fügen Sie eine Versandadresse hinzu',
            'ACCOUNT_SECURITY': 'Kontosicherheit',
            'USER_NAME': 'Benutzername',
            'EMAIL_ADDRESS': 'E-Mail',
            'SECURITY_LEVEL': 'Sicherheitsstufe',
            'LAST_LOGIN': 'Letzte Anmeldung',
            'AUTHORIZED_BINDINGS': 'Autorisierte Anmeldung',
            'MODIFY_EMAIL_IF_ISSUE': 'Es wird empfohlen, nur dann Änderungen vorzunehmen, wenn ein Fehler oder ein Problem mit den E-Mail-Einstellungen vorliegt',
            'LOW_SECURITY_SET_PAYMENT_PW': 'Ihr Sicherheitslevel ist "Niedrig". Um die Sicherheit Ihres Kontos zu gewährleisten, legen Sie bitte ein Zahlungspasswort fest',
            'BINDING_ACTION': 'Binden',
            'CHANGE_LOGIN_PW': 'Ändern Sie das Anmeldepasswort',
            'STRONG_PW_RECOMMENDED': 'Ein sehr sicheres Passwort kann Ihr Konto sicherer machen. Wir empfehlen, Ihr Passwort regelmäßig zu ändern und ein Passwort festzulegen, das sowohl Zahlen als auch Buchstaben enthält und mindestens 6 Zeichen lang ist',
            'PAYMENT_PASSWORD': 'Zahlungspasswort',
            'SET_PW_FOR_SECURE_PAYMENTS': 'Das Passwort, das bei der Verwendung des Tendabuy-Guthabens für Zahlungen erforderlich ist, wird als Zahlungspasswort bezeichnet. Legen Sie das Zahlungspasswort fest, um die Sicherheit der Zahlungen zu gewährleisten',
            'UPDATE_PASSWORD': 'Passwort ändern',
            'CREDIT_CARD_BILLING_ADDRESS': 'Kreditkartenabrechnungsadresse',
            'SET_CARD_BILLING_ADDRESS_FOR_PAYMENTS': 'Wenn Sie eine Kreditkarte für die Zahlung verwenden, müssen Sie die Rechnungsadresse der Kreditkarte festlegen',
            'ADD_BILLING_ADDRESS': 'Rechnungsadresse hinzufügen',
            'TOP_UP_AUTHORIZATION': 'Einziehungsermächtigung',
            'ENABLE_SMALL_TOPUP_CONFIRMATION': 'Aktivieren Sie diese Funktion, um die Bestätigung für kleine Aufladungsbeträge zu umgehen und die Effizienz zu steigern',
            'AUTHORIZATION_TYPE': 'Autorisierungstyp',
            'CANCEL_BUTTON2': 'Abbrechen',
            'DAILY_CAP': 'Begrenzung der Menge pro Tag',
            'REMAINING_LIMIT': 'Verbleibendes Limit',
            'CONFIRM_BUTTON': 'Bestätigen',
            'ACTION': 'Aktion',
            'DOMESTIC_SHIPPING_AUTH': 'Inlandsversand',
            'EDIT_AUTH': 'Bearbeiten',
            'ORDER_TOPUP_AUTH': 'Zahlungsautorisierung der Bestellung',
            'INTERNATIONAL_SHIPPING_AUTH': 'Internationaler Versand',
            'ORDER_TAG_MANAGEMENT': 'Bestellkennzeichnung',
            'ENABLE_CUSTOMER_MANAGEMENT': 'Aktivieren Sie diese Funktion, um Ihre Bestellungen zu verwalten',
            'CREATE_TAG': 'Erstellen Sie einen Tag',
            'TAG_NAME': 'Verlinke den Namen',
            'TAG_COLOR': 'Verlinke die Farbe',
            'TAG_REMARK': 'Notiz zum Tag',
            'CONFIRM_BUTTON2': 'Bestätigen',
            'COUNTRY': 'Land',
            'TAG': 'Tag',
            'NOTE': 'Bemerkung',
            'EDIT_TAG': 'Bearbeiten',
            'DELETE_TAG': 'Löschen',
            'CUSTOM_PHOTO_TEMPLATE': 'Foto-Vorlage anpassen',
            'LEARN_CUSTOM_PHOTO': 'Das Erlernen der Verwendung dieser Funktion wird es erleichtern, den [Foto anpassen]-Inhalt auszufüllen',
            'ADD_TEMPLATE': 'Neue Vorlage hinzufügen',
            'TEMPLATE_NOT_FOUND': 'Sie haben noch keine benutzerdefinierte Vorlage hinzugefügt',
            'ADD_PHOTO_TEMPLATE': 'Gehen Sie zu "Fügen Sie eine detaillierte Fotovorlage hinzu',
            'CUSTOM_PHOTO': 'Individuelle Fotovorlage',
            'TEMPLATE_NAME': 'Vorlagenname',
            'PHOTO_COUNT': 'Anzahl der Fotos',
            'PHOTO_REQUIREMENTS': 'Fotovoraussetzungen',
            'TEMPLATE_NAME2 ': 'Bitte geben Sie den Namen der Vorlage ein',
            'ENTER_REMARKS': 'Bitte geben Sie eine Bemerkung ein',
            'PHOTO_REQUIREMENTS2': 'Bitte geben Sie Ihre Fotowünsche an, pro Foto einen Wunsch, und Sie können ein Beispielbild zum Vergleich hochladen',
            'EMAIL_APP_NOTIFICATIONS': 'E-Mail-Benachrichtigungseinstellungen',
            'SELECT_NOTIFICATIONS': 'Überprüfen, worüber wir Sie benachrichtigen sollen',
            'CUSTOMIZE_PHOTO': 'Fotos anpassen',
            'WATERMARK_ENABLED': 'Das Aktivieren des Wasserzeichens fügt den ausgewählten Wasserzeichenstil zu den von Ihnen erhaltenen Fotos hinzu',
            'ADD_FORWARDING_ORDER': 'Eine Weiterleitungsauftrag hinzufügen',
            'WAREHOUSE_ADDRESS': 'Tendabuy-Adresse',
            'RETURN_ORDERS': 'Zurück zu meiner Bestellung',
            'ORDER_REMARK2': 'Bestellbemerkung',
            'SEARCH_COURIER': 'Suche nach einer Sendungsverfolgungsnummer',
            'HISTORY_FORWARDING': 'Weiterleitungshistorie',
            'RECIPIENT_ADDRESS': 'Lagerhausadresse',
            'CONTACT_ADDRESS': 'Empfänger',
            'ADDDRESS': 'Adresse',
            'POST_CODE': 'Postleitzahl',
            'CONTACT_PHONE': 'Mobilnummer',
            'WARNING_PACKAGE_SECURITY': 'Teilen Sie Ihre Sendungsverfolgungsnummer nicht mit anderen, wenn Sie an das Tendabuy-Warenlager senden. Es ist Ihre einzigartige ID.',
            'SHIPPING_INFO_Tendabuy': 'Informationen zur Sendung an Tendabuy',
            'TRACKING_NUMBER': 'Sendungsnummer',
            'TRACKING_NUMBER_REQUEST': 'Fragen Sie den Absender nach den Informationen',
            'PARCEL_NAME': 'Produktname',
            'ADD_TRACKING_NUMBER': 'Tracking hinzufügen',
            'TRACK_PACKAGE_STATUS': 'Tracking anzeigen',
            'CANCEL_ACTION': 'Abbrechen',
            'SUBMIT_ACTION': 'Absenden',
            'DISCLAIMER': 'Haftungsausschluss',
            'SUBMIT_PRODUCT_INFO': 'Produktinformationen ausfüllen und absenden',
            'PAYMENT_SHIPPING_FEES': 'Für das Produkt und die Versandkosten bezahlen',
            'WAITING_MERCHANT_SHIPMENT': 'Warten darauf, dass der Händler versendet',
            'COUNTRY2': 'Land',
            'REQUIRED_FIELDS': 'Erforderliche Felder',
            'PRODUCT_LINK': 'Produktlink',
            'GENDER': 'Geschlecht',
            'GENDER2': 'Geschlecht',
            'INPUT_SHIPPING_COST': 'Bitte geben Sie die Versandkosten vom Händler zum Tendabuy Lagerhaus ein',
            'UPLOAD_PICTURE': 'Bilder hochladen',
            'PICTURE_SIZE_LIMIT': 'Bild nur unter 3 MB (bitte GIF, JPG und PNG Formate hochladen)',
            'PRODUCT_REMARK': 'Produktnotizen',
            'COST': 'Kosten',
            'UNIT_PRICE': 'Stückpreis',
            'FILL_IN_RMB_1_FOR_UNKNOWN_PRICE': 'Wenn der Preis unbekannt ist, tragen Sie 1CNY ein, um die Bestellung aufzugeben, und warten Sie, bis unser Agent den zu wenig gezahlten Betrag bestätigt.',
            'DOMESTIC_SHIPPING_COST': 'Fracht zu unserem Lager',
            'TOTAL_PRICE': 'Gesamt',
            'INTERNATIONAL_SHIPPING_EXTRA': 'Die internationalen Versandkosten sind nicht inbegriffen und werden separat berechnet',
            'ESTIMATE_SHIPPING': 'Versandkostenrechner',
            'THIRD_PARTY_LINK_DISCLAIMER': 'Der von Ihnen auf dieser Seite eingereichte Link gehört zu einem Produktlink eines Drittanbieters (wie Xianyu, Little Red Book, Bilibili, Yupoo usw.). Tendabuy kann Ihnen nur helfen, den mit diesem Link verbundenen Händler für Produktanfragen, Proxy-Käufe und die Organisation von Logistikdienstleistungen von Drittanbietern für die Lieferung zu finden. Tendabuy kann nicht die Qualität oder Echtheit der Produkte bestimmen. Sie müssen die damit verbundenen Risiken tragen, bevor Sie eine Bestellung aufgeben.',
            'BUYING_AGENT_GUIDE': 'Einkaufsagent Notizen',
            'PRODUCT_DETAILS': 'Produktdetails',
            'AGREE': 'Vereinbart',
            'BUY_NOW': 'Jetzt kaufen',
            'CUSTOM_ORDER_REQUIRED': 'DIY-Bestellung (*Pflichtfeld)',
            'UPLOAD_DESCRIBE_PRODUCT_1': '1. Laden Sie ein Bild hoch oder beschreiben Sie das Produkt, das Sie kaufen möchten.',
            'UPLOAD_DESCRIBE_PRODUCT_2': '2. Wir werden Ihnen dabei helfen, das entsprechende Produkt oder die entsprechenden Produkte zu finden und Ihnen innerhalb von 24 Stunden nach Erhalt Ihrer Einsendung zu bestätigen.',
            'UPLOAD_DESCRIBE_PRODUCT_3': '3. Die Servicegebühr ist nicht erstattungsfähig, wenn wir das Produkt oder die Produkte finden, Sie aber beschließen, nicht zu kaufen.',
            'PRODUCT_IMAGE': 'Produktbilder',
            'ORDER_REMARK': 'Bemerkung',
            'PRICE_RANGE': 'Preisspanne',
            'ENTER_PROD_DESC': 'Hinterlassen Sie eine Produktbeschreibung und -spezifikation',
            'QUANTITY': 'Menge',
            'WAREHOUSE_SHIPPING_FEE_CNY_10': 'Versand an das Tendabuy Lagerhaus; Servicegebühr 10 CNY pro Bestellung',
            'BUYER_QUALITY_DISCLAIMER': 'Haftungsausschluss: Wir können die Qualität oder Echtheit der Produkte nicht bestimmen. Obwohl wir uns bemühen werden, im Rahmen des Kundendienstes mit dem Verkäufer zu verhandeln, können wir das Ergebnis nicht garantieren. Wenn Sie sich entscheiden, diesen Service zu nutzen, müssen Sie die damit verbundenen Risiken übernehmen. Andernfalls können wir den Kauf nicht in Ihrem Namen durchführen',
            'CUSTOMIZE_NOW': 'Jetzt absenden',
            'PRODUCT_IMAGE2': 'Produktbild',
            'CONFIRM_AGREE': 'Zustimmen',
            'PRODUCT_NAME': 'Produktname',
            'PRODUCT_NAME2': 'Produktname',
            'PRODUCT_SPECIFICATIONS': 'Produktdetails',
            '': '',
            'PRODUCT_SPECIFICATIONS2': 'Spezifikationen',
            'TRANSACTION_TIME': 'Transaktionszeit',
            'VIEW_STATEMENT': 'Rechnungsprüfung',
            'TRANSACTION_ID': 'Transaktions-ID',
            'REMARKS': 'Bemerkungen',
            'NO_DATA_FOUND': 'Keine Daten',
            'PARCEL': 'Paket',
            'PRIORITY_SHIPPING': 'Bevorzugter Versand',
            'RECHARGE_REQUIRED': 'Aufladen',
            'IN_TRANSIT': 'Unterwegs',
            'RECEIVED': 'Erhalten',
            'CANCELLED': 'Storniert',
            'REVIEWABLE_PRODUCTS': 'Produkte, die überprüft werden können',
            'POSTED_REVIEWS': 'Veröffentlichte Bewertungen',
            'EMPTY_FIELD': 'Leer',
            'SEARCH_ORDER_NUMBER': 'Bestellnummer suchen',
            'RELATED_ORDER_NO': 'Zugehörige Bestellnummer',
            'COUPONS_SECTION': 'Gutschein',
            'AVAILABLE_COUPONS': 'Verfügbarer Gutschein',
            'USED_COUPONS': 'Verwendeter Gutschein',
            'COUPON_EXPIRED': 'Gutschein abgelaufen',
            'NO_ACTIVE_COUPONS': 'Sie haben noch keinen Gutschein',
            'MY_POINTS': 'Meine Punkte',
            'POINTS_THIS_MONTH': 'Punkteeinlösung in diesem Monat',
            'REDEMPTION_DETAILS': 'Einlösungsdetails',
            'REDEMPTION_RECORD': 'Einlösungsprotokoll',
            'RELATED_ORDER_NO2': 'Zugehörige Bestellnummer',
            'RELATED_NUMBER': 'Zugehörige Nummer',
            'REDEMPTION_METHOD': 'Einlösemethode',
            'TENDABUY_POINTS': 'Tendabuy-Punkte',
            'STORAGE_FEES': 'Lagergebühren',
            'REDEMPTION_DATE': 'Einlösungsdatum',
            'REMARK': 'Bemerkung',
            'REDEMPTION_ACTION': 'Aktion',
            'REDEEMED_ITEMS': 'Einlösegegenstände',
            'ORDER_INQUIRIES': 'Bestellungsanfrage',
            'PARCEL_INQUIRIES': 'Paketanfrage',
            'AFTER_SALE_INQUIRIES': 'Nachverkaufspaket',
            'OTHER_ENQUIRY': 'Andere Anfrage',
            'QUERY_TYPE': 'Typ',
            'REMARK2': 'Notiz',
            'QUERY_CONTENT': 'Anfrageinhalt',
            'SELECT_ALL': 'Alle auswählen',
            'QUERY_STATUS': 'Status',
            'WELCOME_NO_QUERIES_MSG': 'Willkommen auf der "Anfrageseite", es gibt derzeit keine Anfrage.\n\nSie können jederzeit auf die Schaltfläche "Kundenservice" rechts klicken, um uns eine Nachricht zu hinterlassen.',
            'SELECT_ALL2': 'Alles auswählen',
            'SYSTEM_MESSAGES': 'Systemnachricht',
            'MANUAL_MESSAGES': 'Manuelle Nachricht',
            'STATUS': 'Status',
            'NO_UNREAD_INBOX_MESSAGES': 'Sie haben noch keine ungelesenen internen Nachrichten',
            'STATUS2': 'Status',
            'STATUS3': 'Status',
            'PHONE': 'Mobilnummer',
            'SUBMISSION_TIME': 'Einreichungszeit',
            'LANGUAGE': 'Sprache',
            'SUBMISSION_TIME2': 'Abgabezeit',
            'VIEW_GROWTH_RECORDS': 'Stufenprotokoll anzeigen',
            'POINTS_TO_LEVEL_UP': 'Erhalten Sie 3000 Punkte mehr, um auf V1 aufzusteigen',
            'SIZE_INFORMATION': 'Meine Größeninformationen',
            'MY_PRIVILEGES': 'Meine Privilegien',
            'LEVEL_BENEFITS': 'Level-Privileg',
            'INTERNATIONAL_SHIPPING_DISCOUNT': 'Internationaler Versandrabatt',
            'INSURANCE_DISCOUNT': 'Versicherungsrabatt',
            'ADDED_SERVICE_DISCOUNT': 'Rabatt für Mehrwertdienste',
            'RETURN_EXCHANGE': 'Kostenlose Nachverkaufs-Operationen verwendet/verfügbar (Monat)',
            'FEE_USED_AVAIL': 'Verwendet/Verfügbar (Monat)',
            'CUSTOMIZED_PHOTO_USED_MONTHLY': 'Zusätzliches Foto',
            'MY_RANK': 'Stufe',
            'LEVEL_VALUE': 'Wert',
            'AUTO_PAY_AUTHORIZATIONS': 'Autopay-Autorisierung\nAktivieren Sie die Autopay für kleine Beträge und die Abwicklung von Bestellungen/Paketen.',
            'ENABLE_MICRO_PAYMENTS': 'Autorisierungstyp',
            'AUTHORIZATION_TYPES': 'Inlandsversand',
            'DOMESTIC_SHIPPING': 'Unterbezahlt',
            'UNPAID_TRANSPORT': 'Internationaler Versand',
            'TRANSACTION_LIMIT': 'Begrenzung des Betrags pro Transaktion',
            'DAILY_LIMIT': 'Begrenzung der Menge pro Tag',
            'AVAILABLE_LIMIT': 'Verfügbares Kontingent',
            'STATUS_OPERATIONS': 'Status',
            'ORDER_TAGGING': 'Aktion',
            'TAGGING_FOR_EFFICIENCY': 'Ändern',
            'TRANSACTION_LIMIT2': 'Begrenzung Betrag pro Transaktion',
            'TAG_NOT_FOUND': 'Der gesuchte Tag wurde nicht gefunden',
            'PRODUCT_TAGS_EXPLANATION': 'Was ist ein Produkttag?\nEin Produkttag ist eine flexiblere und interessantere Möglichkeit, Artikel zu kategorisieren, indem man jedem Artikel in seiner Bestellung einen oder mehrere Tags hinzufügt.',
            'BENEFITS_OF_TAGS': 'Die Vorteile des Hinzufügens von Tags\n\nDurch benutzerdefinierte Tags auf den Seiten "Warenkorb", "Bestellung" und "Lager" können Sie Ihre Bestellungen effizienter filtern und verwalten.\n\n\n\n\n',
            'HOW_TO_APPLY_TAGS': 'Wie füge ich Tags hinzu?\n\nSie können benutzerdefinierte Tags auf den Seiten "Warenkorb", "Bestellung" und "Lager" hinzufügen.',
            'HIGH_RES_TEMPLATES': 'HD-Photo Template\n\nsave Extra Photo templates and apply templates with one simple click.',
            'ADVANCED_PHOTO_TEMPLATES_USAGE': 'Wie funktioniert eine detaillierte Fotovorlage?\n\nFügen Sie detaillierte Fotovorlagen auf den Seiten "Kasse", "Meine Bestellung" und "Mein Lager" hinzu. Das Lagerpersonal kann dann nach Erhalt der Waren Fotos Ihrer Produkte gemäß den Vorlagenanforderungen aufnehmen.',
            'DETAILS_PHOTO_TEMPLATES_WORKFLOW': 'Information, die der detaillierten Fotovorlage hinzugefügt werden kann:\n\nNachdem Sie die Fotovorlage bearbeitet haben, wählen Sie einfach die Vorlage aus. Das Lagerpersonal wird dann gemäß der Vorlage Fotos von Ihrem Artikel aufnehmen, einschließlich Wasserzeichen, Anzahl der Fotos, Aufnahmewinkel und -details usw.',
            'INFO_IN_DETAIL_TEMPLATES': 'Wie man eine detaillierte Fotovorlage hinzufügt:\n\nSie können benutzerdefinierte Tags auf den Seiten "Warenkorb", "Bestellung" und "Lager" hinzufügen.',
            'ADDING_DETAIL_TEMPLATES': 'E-Mail- und App-Benachrichtigungsverwaltung\n\nMarkieren Sie, worüber wir Sie benachrichtigen sollen.',
            'UNREAD_MESSAGES': 'Ungelesen',
            'AUTO_PAY_ACTIVE': 'Die AutoPay-Funktion wurde aktiviert.',
            'COUPON_CLAIMED': 'Gutschein erhalten',
            'COUPON_EXPIRING_SOON': 'Der Gutschein läuft bald ab',
            'COUPON_USED': 'Gutschein wurde erfolgreich verwendet',
            'OPEN_BOX_REWARD_GRANTED': 'Unboxing-Belohnungsantrag erfolgreich!',
            'OPEN_BOX_REJECTED': 'Unboxing-Belohnungsantrag abgelehnt',
            'FINANCIAL_OPERATIONS': 'Finanzen',
            'RECHARGE_COMPLETED': 'Aufladung erfolgreich!',
            'WITHDRAWAL_SUCCESS': 'Auszahlung erfolgreich!',
            'ORDER_AUTO_PAYMENTS': 'Bestellung automatisch bezahlt',
            'PARCELS': 'Pakete',
            'PARCEL_SHORTAGE_REFUND': 'Rückerstattung aufgrund von Paketdifferenzen',
            'REHEARSAL_DONE': 'Probelauf abgeschlossen',
            'PARCEL_SUBMITTED': 'Paket eingereicht!',
            'RISK_CONTROL': 'Paket in die Risikokontrolle aufgenommen',
            'PARCEL_CANCELLED': 'Paket storniert',
            'SHIPPED_PARCEL': 'Paket versendet',
            'PARCEL_UNPAID': 'Paket unbezahlt',
            'PARCEL_PAID': 'Paket wurde bezahlt',
            'PARCEL_RECHARGED': 'Paket wurde wieder aufgeladen',
            'REHEARSAL_CANCELED': 'Probe abgebrochen',
            'INSURANCE_CLAIM_APPROVED': 'Versicherungsanspruch genehmigt',
            'INSURANCE_CLAIM_DENIED': 'Versicherungsanspruch abgelehnt',
            'CLAIM_RECEIVED': 'Ihre Versicherungsanspruchsanmeldung wurde erhalten.',
            'ORDER_SUBMITTED': 'Bestellungen',
            'ORDER_UNDER_REVIEW': 'Bestellung in die Risikokontrolle aufgenommen',
            'DOMESTIC_SHIPPING_UNPAID': 'Inländische Versandgebühr unbezahlt',
            'ORDER_PAYMENT_PENDING': 'Bestellung unbezahlt',
            'CONFIRMATION_NEEDED': 'Bestätigung erforderlich',
            'ORDER_STORED': 'Bestellung wurde gespeichert!',
            'PARTIAL_SHIPPING': 'Separate Lieferung',
            'RETURN_REQUEST_ACCEPTED': 'Rücksendeanfrage wurde akzeptiert',
            'RETURN_REQUEST_CANCELED': 'Rücksendeanfrage wurde storniert',
            'EXCHANGE_REQUEST_ACCEPTED': 'Umtauschanfrage wurde akzeptiert',
            'EXCHANGE_REQUEST_CANCELED': 'Umtauschanfrage wurde storniert',
            'ORDER_RETURNED_SUCCESSFULLY': 'Bestellung erfolgreich zurückgesendet',
            'ORDER_EXPIRING': 'Die Bestellung läuft bald ab',
            'ORDER_OVERDUE': 'Bestellung ist überfällig',
            'PAID_PHOTO_COMPLETE': 'Bezahlte Fotos abgeschlossen',
            'ORDER_RECHARGED': 'Bestellung aufgeladen',
            'DOMESTIC_SHIPPING_RECHARGED': 'Inlandsversand der Bestellung wurde aufgeladen',
            'SHIPPING_REFUND_DONE': 'Die Versandkosten der Bestellung wurden erstattet',
            'ORDER_RETRACTED': 'Bestellung zurückgezogen',
            'ORDER_CANCELED': 'Bestellung storniert',
            'ORDER_PAID': 'Bestellung wurde bezahlt',
            'QUALITY_CHECK_ALERT': 'Qualitätsinspektions-Erinnerung',
            'AGENT_ORDER_WARNING': 'Bestellerinnerung vom Agenten',
            'OVERDUE_ORDER_DELETION_IMMINENT': 'Überfällige Bestellung soll vernichtet werden',
            'ORDER_DELETED_OVERDUE': 'Bestellung ist überfällig und wurde vernichtet',
            'QUICK_WATERMARK_SETUP': 'QC-Fotowasserzeichen\n\nLegen Sie jetzt fest, damit Ihre QC-Fotos mit dem von Ihnen ausgewählten Wasserzeichen versehen werden können',
            'WATERMARK_SYSTEM': 'Systemwasserzeichen',
            'NO_WATERMARK': 'Kein Wasserzeichen',
            'SOURCE_PRODUCT': 'Quelle dieses Produkts',
            'SOURCE_PLATFORM': 'Alibaba-Plattform',
            'SOURCE_ALIBABA_TENDABUY': 'Die Agenten von Tendabuy werden für Sie kaufen',
            'CERTIFIED_WAREHOUSE': 'Tendabuy Lager',
            'YOUR_LOCATION': 'Ihre Adresse',
            'INTERNATIONAL_SHIPPING_ESTIMATE': 'Lieferungsschätzung',
            'BUYING_HOURS_09_TO_18_UTC8': 'Bestellungen, die zwischen 18:00 und 09:00 Uhr (Pekinger Zeit) bezahlt werden, werden bis 14:00 Uhr am nächsten Tag bearbeitet.',
            'REPORT_ISSUE': 'Bericht',
            'FORCE_REFRESH_PAGE': 'Erzwinge Aktualisierung',
            'MIN_ORDER_QUANTITY': 'Die Mindestbestellmenge beträgt',
            'ORDER_FOLLOW_LINK': 'Tendabuy wird streng dem Bestelllink folgen, um in Ihrem Auftrag Einkäufe zu tätigen. Wenn Sie Fragen zu Ihrer Bestellung haben, kontaktieren Sie bitte unseren Online-Kundenservice',
            'CONTACT_SUPPORT_ANYTIME': 'Alle angezeigten Produkte stammen von Drittanbieterplattformen. Tendabuy bietet nur Informationsrecherche, Einkaufsagenten- und Versandservices an. Tendabuy kann die Qualität oder Authentizität der Produkte nicht überprüfen. Durch die Nutzung des Einkaufsagenten-Service von Tendabuy akzeptieren Sie automatisch die genannten Risiken.',
            'UNREAD_MESSAGES2': 'Ungelesen',
            'SPECIFICATIONS': 'Spezifikationen',
            'USERNAME': 'Benutzername',
            'USERNAME2': 'Benutzername',
            'FIND_SIMILAR': 'Ähnliches finden',
            'affiLIATE_SHARE': 'Partnerprogramm-Anteil',
            'FAVORITE': 'Lieblings',
            'MONTHLY_SALES': 'Verkäufe',
            'ENTER_STORE': 'Geschäft betreten',
            'CATEGORY': 'Kategorien',
            'MORE_SERVICES': 'Service',
            'ADDITIONAL_FEATURES': 'Service',
            'RESTRICTED_ITEMS_TITLE': 'Embargo',
            'RESTRICTED_ITEMS_CONTENT': '· Tabak, Alkohol und verbotene Artikel werden nicht transportiert',
            'RESTRICTED_ITEMS': 'Verbotene Artikel umfassen, sind aber nicht beschränkt auf: Schweizer Taschenmesser, komplette Sets von Küchenmessern, Hackmesser, Papiermesser/Brieföffner, Gartengeräte/Messer/Scheren/Sägen, Nahttrenner, reine Obst- und Gemüsesäfte, aufblasbare Feuerzeuge, Wein, Spachtelmesser, Käse/Westernkäse, Chinesische Wollhandkrabben, Bambusschwerter/Holzschwerter, Mehrzweckkartenmesser, Schlittschuhe, andere Outdoor-Messer, magnetische Komponenten, Schweizer Taschenmesser, Fräser, Schnitzmesser, Tabaketiketten/Zigarettenkarten, Vajra-Glocken/Vajras/Äxte, Samen, etc',
            'LIMITED_GOODS_NOTICE_TITLE': 'Einkaufsbeschränkungen',
            'LIMITED_GOODS_NOTICE': 'Medikamente, Flüssigkeiten, pastenartige Waren und kosmetische Pulver sind vom chinesischen Zoll beschränkte Exportartikel. Bitte konsultieren Sie [den Kundendienst], bevor Sie eine Bestellung aufgeben.',
            'CONSULT_BEFORE_ORDER_TITLE': 'Tipps vom CEO',
            'CONSULT_BEFORE_ORDER': '[Über Zoll und Logistik] Aufgrund von Unterschieden in den Zollbestimmungen in verschiedenen Ländern sind einige Nationen oder Regionen besonders empfindlich gegenüber bestimmten Waren, was zu Embargos, Kaufbeschränkungen, Einschränkungen der Logistikrouten sowie Volumen- und Gewichtsbeschränkungen führen kann.',
            'BEIAN_RIGHT': 'Copyright @ 2024 Tendabuy.com All rights reserved',
            'BEIAN': 'Wir bieten speziell Produkte von Taobao, 1688, Weidian und anderen chinesischen Shopping-Plattformen für globale Benutzer an, wie Bücher, Spezialitäten, Kosmetika und andere Produkttypen, unterstützen PayPal, Alipay und andere Zahlungsmethoden.',
            'GOODS_DETAIL_TIP1': 'Die gekauften Artikel werden nach der Ankunft an unser TendaBuy-Lager zur Inspektion und Fotografie gesendet',
            'GOODS_DETAIL_TIP2': 'Sie können Ihre Artikel in [Mein Lager] anzeigen und verwalten.',
            'GOODS_DETAIL_TIP3': 'Wenn Sie mit den Produkten, die im TendaBuy-Lager ankommen, nicht zufrieden sind, können Sie innerhalb von 5 Tagen nach deren Ankunft eine Rückgabe oder Umtausch beantragen. Unser Einkaufsteam wird dann in Ihrem Namen mit dem Verkäufer verhandeln, um den Kundendienst nach dem Verkauf zu gewährleisten',
            'TIP2': 'Für den Käufer: Falls keine speziellen Einkaufsanforderungen vorliegen, bitte nichts schreiben.',
            'SERVICE_ONE_WEBSITE': 'One-Stop-Agenten-Einkaufsservice',
            'PURCHASE_ORDERS': 'Einkaufsaufträge',
            'PURCHASE_ORDERS_CONTENT': 'Das Tendabuy-Agenten-Team kann Ihnen helfen, alle Online- und Offline-Produkte in China zu kaufen. Geben Sie einfach einen Produktlink ein, um eine Bestellung aufzugeben, und wir bieten Ihnen anschließend eine umfassende Palette erstklassiger Dienstleistungen.',
            'SHIP_TO_WAREHOUSE': 'An das Lager senden',
            'SHIP_TO_WAREHOUSE_CONTENT': 'Sie können Artikel von verschiedenen Verkäufern über uns kaufen, und diese Produkte werden an das Tendabuy-Lager in China versendet.',
            'QUALITY_CHECK': 'Qualitätsprüfung',
            'QUALITY_CHECK_CONTENT': 'Nachdem Ihre Artikel im Tendabuy-Lager angekommen sind, wird eine Qualitätsprüfung der Artikel auf Mängel, Größe, Farben usw. durchgeführt. Sie erhalten eine umfassende Garantie für den Kundendienst bei Tendabuy.',
            'GLOBAL_SHIPPING_CONTENT': 'Sie können frei wählen, welche Artikel sich in Ihrem Lager befinden und diese zu einem Paket zusammenstellen, das Sie zu sich nach Hause senden können, indem Sie einen zuverlässigen Logistikdienstleister von Tendabuy wählen.',
            'HOT_LIST': 'Heiß begehrt ',
            'ESTIMATION': 'Versandkosten Rechner',
            'FORWARDING': 'Weiterleitung',
            'FILL_BUY': 'Ausfüllen und Kaufen',
            'FIND_BUY': 'Diy & Kauf',
            'BEGINNERS_GUIDE': 'Einkaufsführer',
            'HELP_CENTER_INDEX': 'Hilfezentrum',
            'USER_CENTER_INDEX': 'Benutzerzentrum',
            'LOGOUT_INDEX': 'Abmelden',
            'GUIDE_USING_IMAGE_SEARCH': 'Bildsuchfunktion',
            'MY_BALANCE': 'Mein Kontostand',
            'POINTS': 'Punkte',
            'My_COUPONS': 'Meine Gutscheine',
            'MY_GROWTH_POINTS': 'Meine Wachstumspunkte',
            'WITHDRAW': 'Abheben',
            'PENDING_PAYMENT': 'Zahlung ausstehend',
            'RECHARGE_AMOUNT': 'Aufladebetrag',
            'ENTER_THE_AMOUNT': 'Bitte geben Sie den Betrag ein',
            'DELETE_SUCCESSFUL': 'Löschung erfolgreich',
            'SHOP_CART_NULL': 'Ihr Warenkorb ist derzeit leer. Lassen Sie uns die Einkaufsreise beginnen!',
            'ORDER_DETAILS2': 'Bestelldetails',
            'IN_PROGRESS': 'Bearbeitung',
            'ORDERED': 'Gekauft',
            'SENT': 'Gesendet',
            'ARRIVED': 'Angekommen',
            'STORAGE': 'Speicher',
            'CONTACT': 'Kontakt',
            'TOTAL_PRICE2': 'Gesamtpreis',
            'SEND': 'Senden',
            'ORDER_CONSULT_TIP': 'Dieses Fenster kann nur Probleme behandeln, die mit dieser Bestellung zusammenhängen. Für andere Fragen kontaktieren Sie bitte den Online-Support!',
            'ONLINE_CUSTOMER': 'Kundensupport',
            'PRODUCT_AMOUNT': 'Artikelmenge',
            'WAREHOUSE_DELIVERY_FEE': 'Fracht zu unserem Lagerhaus',
            'STORAGE_TIME': 'Lagerzeit',
            'PRODUCT_TYPE': 'Produkttyp',
            'BUSINESS_TYPE': 'Geschäftstyp',
            'TRANSACTION_MODE': 'Zahlungsmethode',
            'Arrears_Info': 'Rückstände beziehen sich auf die Paketgebühren, die Versandkosten für die Rücksendung oder den Umtausch von Bestellungen oder die Nachnahmegebühren, die Sie nicht bezahlt oder ergänzt haben, aber wir priorisieren, Ihnen zu helfen, diese zu bearbeiten, um die Zeitlichkeit nicht zu verzögern. Bitte begleichen Sie die Rückstände rechtzeitig, sonst werden Sie beim nächsten Mal nicht handeln können.',
            'Profile_Picture': 'Avatar',
            'Modify_User_Info': 'Profil bearbeiten',
            'Female': 'Weiblich',
            'Male': 'Männlich',
            'Currency': 'Währung',
            'Shopping_Cart': 'Warenkorb',
            'Total_Order_Price': 'Gesamtpreis',
            'Proceed_To_Checkout': 'Kasse',
            'Recipient': 'Empfänger',
            'First_Name': 'Vorname',
            'Last_Name': 'Nachname',
            'Region': 'Regions',
            'Detailed_Address': 'Details Adresse',
            'ZIP_Code': 'Postleitzahl',
            'Address_Info': 'Bitte geben Sie Ihre Apartmentnummer, Straßenadresse und andere detaillierte Adressinformationen ein. (Bitte keine doppelten Postleitzahlen, Städte, etc.)',
            'Phone_Number': 'Telefonnummer',
            'Address_Label': 'Adressetikett',
            'Home': 'Zuhause',
            'Work': 'Unternehmen',
            'Default_Address': 'Standardadresse',
            'Login_Password': 'Anmeldepasswort',
            'Enter_Old_Password': 'Bitte geben Sie Ihr ursprüngliches Anmeldepasswort ein',
            'New_Password': 'Neues Passwort',
            'Enter_Password': 'Bitte geben Sie Ihr Passwort ein',
            'Repeat_Password': 'Bitte wiederholen Sie Ihr Passwort',
            'Reenter_Password': 'Bitte geben Sie Ihr Passwort erneut ein',
            'Verification_Code': 'Verifizierungscode',
            'Send_Code': 'Sende Verifizierungscode',
            'Submit': 'Einreichen',
            'Custom_Template': 'Vorlage',
            'Popular_Searches': 'Geben Sie Schlüsselwörter zur Suche ein',
            'Homepage': 'Zuhause',
            'FAQs': 'Häufig gestellte Fragen',
            'Service_Quality': 'Servicequalität',
            'Service_Guarantee': 'Servicegarantie',
            'Sort_By_Comprehensive': 'Allgemeines Ranking',
            'Rebuy_Rate_30Days': 'Wiederkaufsrate in den letzten 30 Tagen',
            'Sort_By_Price': 'Preis-Ranking',
            'Min': 'Min',
            'Max': 'Max',
            'Certified_Factory': 'Zertifizierte Fabrik',
            'Experience_Score': 'General Rating',
            'Five_Star': '5-Sterne',
            'FourPointFive_Five_Stars': '4,5 Sterne - 5 Sterne',
            'Four_FourPointFive_Stars': '4 Sterne - 4,5 Sterne',
            'Below_Four_Stars': 'Unter 4 Sternen',
            'Pickup_Rate_24H': '24-Stunden-Versandquote',
            'Pickup_Rate_48H': '48-Stunden-Versandquote',
            'Same_Day_Shipping': 'Versand am gleichen Tag',
            'Shipping_24H': '24-Stunden-Versand',
            'Shipping_48H': '48-Stunden-Versand',
            'Seven_Day_Return': '7-tägige Rückgabe ohne Angabe von Gründen',
            'Free_Shipping': 'Kostenloser Versand',
            'Reset': 'Zurücksetzen',
            'Collapse': 'Weniger anzeigen',
            'More_Filters': 'Mehr Filteroptionen',
            'Accepted': 'Akzeptiert',
            'Terms': '《Suchbegriffe》',
            'Search_Service_Terms': 'Die gesuchten Produkte stammen alle von Drittanbieter-Plattformen, und Tendabuy bietet lediglich Dienstleistungen wie Informationssuche, Einkaufsagentur und Versand an. Tendabuy kann die Qualität oder Echtheit der Produkte nicht authentifizieren.',
            'Product_Info_Details': 'Akzeptieren Sie die "Suchbegriffe", um die Suchergebnisse anzuzeigen.',
            'Close_Page': 'Schließen',
            'Merchant_Info': 'Händler',
            'Shipping_Fee': 'Inlandsfracht',
            'Certified_Warehouse': 'Tendabuy zertifiziertes Lager',
            'User_Message': 'Nachricht',
            'Sales_Volume_30Days': 'Der Absatz dieses Produkts in den letzten 30 Tagen. Einige Produkte können Fehler aufweisen. Diese Daten stammen von einem Drittanbieter und können beim Kauf als Referenz dienen',
            'Report_Reason': 'Grund für die Meldung',
            'Infringement_Report': 'Verletzungsbericht',
            'Report_Record': 'Berichtsprotokoll',
            'Next_Step': 'Nächster Schritt',
            'Select_Report_Reason': 'Bitte wählen Sie den Grund für die Meldung aus',
            'Previous_Step': 'Vorheriger Schritt',
            'Report_Description': 'Berichtsbeschreibung',
            'Image_Evidence': 'Bildbeweis',
            'Submit_Report': 'Einreichen',
            'Select_Category': 'Bitte wählen Sie eine Kategorie',
            'Agree_And_Proceed': 'Bitte lesen Sie die Bedingungen und stimmen Sie ihnen zu, bevor Sie fortfahren',
            'TendaBuy_Warehouse': 'TendaBuy-Lager',
            'Country_for_Shipping': 'Bitte wählen Sie das Zielland aus. Tendabuy-Agenten werden vor dem Kauf wissen, ob das Produkt dorthin gesendet werden kann.',
            'Shipping_Country': 'Wählen Sie das Versandland aus',
            'Order_Confirmation': 'Bestätigen Sie die Bestellung',
            'Store_Total': 'Gesamtsumme des Einkaufs',
            'Payment_Total_Excl_SH_Fee': 'Gesamtsumme (internationaler Versand nicht inbegriffen)',
            'Prohibited_Items': '《Erklärung zu verbotenen Artikeln》',
            'Service_Terms_Management': '《Nutzungsbedingungen und Benutzerverwaltung》',
            'Return_Exchange': '《Rückgabe/Umtauschservice》',
            'Disclaimer': '《Haftungsausschluss》',
            'Read_and_Agree': 'Ich habe gelesen und stimme zu',
            'Shipping_Destination': 'An den folgenden Ort versenden',
            'Sender_Information': 'Absender',
            'Return_to_Cart_for_Modification': 'Zurück zum Warenkorb und bearbeiten',
            'Product_Information_Confirmation': 'Bestätigen Sie die Produktinformationen',
            'Subtotal': 'Zwischensumme',
            'Submit_Order': 'Bestellung abschicken',
            'Warehouse_Arrival_Notification': 'Freundliche Erinnerung, nach dem Absenden der Bestellung und bezahlt, bitte warten Sie auf den Verkäufer zu senden, legen Sie ein internationales Paket, um einmal im Lager gespeichert.',
            'Payment_Due_By': 'Zur Kasse',
            'Checkout_Successful': 'Bestellung erfolgreich übermittelt!',
            'Available_Payment_Methods': 'Bitte bezahlen Sie innerhalb von 72 Stunden, da Ihre Bestellung ansonsten automatisch storniert wird.',
            'Current_Country': 'Land auswählen',
            'Open_Payment_Channels': 'Zahlungsmethoden',
            'Balance_Recharge_Needed': 'Ihr Guthaben ist nicht ausreichend. Bitte laden Sie es vor der Zahlung auf',
            'Euro_Payment_Exchange': 'Die Abrechnungswährung für die aktuelle Bestellung ist Euro. Die Zahlung in lokaler Währung wird unterstützt, und die Zahlungsbank wird die Währungen gemäß dem Wechselkurs der Bank umrechnen',
            'Global_Currency_Support': 'Mit 202 Ländern oder Regionen, die Unterstützung für 25 Währungen bieten. Es fallen Währungsumrechnungsgebühren an, wenn in nicht USD-Währungen bezahlt wird. Lokale Partnerbanken akzeptieren Debit- und Kreditkarten',
            'Total_Price': 'Gesamtpreis',
            'Amount_Due': 'Betrag fällig',
            'Handling_Fee': 'Bearbeitungsgebühr',
            'Settlement_RMB_Only': 'Die Abrechnung ist nur in chinesischer Währung (RMB) möglich',
            'Purchase_Amount_Discount': 'Rabatt für das Erreichen eines bestimmten Einkaufsbetrags',
            'Negotiated_Price': 'Preis, der mit dem Händler ausgehandelt wurde',
            'Shipping_Fee_Discount': 'Versandkostenrabatt',
            'Mobile_Exclusive_Price': 'Preis nur für mobile Geräte',
            'Incorrect_Price_Displayed': 'Falscher Preis auf der Seite dargestellt',
            'Others': 'Andere',
            'Login': 'oder anmelden',
            'LOGIN_1': 'anmelden',
            'Forgot_Password': 'Passwort vergessen?',
            'Sign_Up': 'registrieren',
            'Email_Input': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
            'Password_Input': 'Bitte geben Sie Ihr Passwort ein',
            'Verification_Code_Input': 'Bitte geben Sie den Verifizierungscode ein',
            'TendaBuy_Reg_Agreement': '《TendaBuy Nutzerregistrierungsvereinbarung》',
            'AND': 'und',
            'Privacy_Statement': '《Datenschutzerklärung》',
            'Account_Existence': 'Bereits ein Konto vorhanden',
            'Password_Confirmation': 'Bitte geben Sie das Passwort erneut ein',
            'Country_Selection': 'Bitte wählen Sie ein Land aus',
            'Email_Address_Input': 'E-Mail (Bitte geben Sie eine gültige und nutzbare E-Mail-Adresse an)',
            'Invitation_Code_Optional': 'Bitte geben Sie den Einladungscode ein (optional)',
            'MY_PACKAGE': 'Pakete',
            'PENDING': 'Ausstehend',
            'PROCESSING': 'In Bearbeitung',
            'PROCESSED': 'Verarbeitung beendet',
            'DAILY_GOODS': 'Allgemeine Alltagsgüter',
            'SPECIAL_GOODS': 'Spezialwaren',
            'PAID': 'Bezahlt',
            'WAIT_PACK': 'Warten auf Verpackung',
            'PACKED': 'Paket ist Verpackt',
            'READY_SHIP': 'Bereit zum Versand',
            'PROVINCE': 'Provinz',
            'CITY': 'Stadt',
            'ENTER_NAME': 'Bitte geben Sie Ihren Namen ein',
            'ENTER_AREA': 'Bitte geben Sie die Region ein',
            'ADDR_REQUIRED': 'Die Adresse darf nicht leer sein',
            'ZIP_REQUIRED': 'Die Postleitzahl darf nicht leer sein',
            'PHONE_REQUIRED': 'Die Handynummer darf nicht leer sein',
            'PHONE_FMT_WRONG': 'Das Format der Handynummer ist falsch',
            'COUNTRY_ADDR_REQ': 'Die Nationaladresse darf nicht leer sein',
            'FILES': 'Datei',
            'PHOTO_TEMPLATE': 'Foto-Vorlage',
            'ENTER_REMARK': 'Bitte geben Sie eine Bemerkungsbeschreibung ein',
            'SINGLE_PHOTO_REQ': 'Es wird nur eine Fotoanforderung unterstützt, wie zum Beispiel: Nahaufnahme des Logos, Messen der Armbandgröße, usw.',
            'PHOTO': 'Foto',
            'PHOTO_TIP': 'Freundliche Erinnerung: Ein Foto bietet nur eine Detailanforderung für die Aufnahme eines Produkts.',
            'FREE_PHOTOS_FEE': 'Verbleibende kostenlose benutzerdefinierte Fotos in diesem Monat: 0. Servicegebühr:',
            'SERVICE': 'Dienstleistung',
            'EDIT_SUCCESS': 'Änderung erfolgreich',
            'SHIPPING_FEE': 'Transportkosten',
            'SHIP_TIME': 'Transportzeit',
            'CHARGE_WEIGHT': 'Abrechnungsgewicht',
            'FEATURES': 'Merkmal',
            'FEE_ITEM': 'Gebührenposition',
            'OPER_FEE': 'Bearbeitungsgebühr',
            'SETTLE_STD': 'Abrechnungsstandard',
            'TOTAL_FEE_DET': 'Gesamtkostenaufstellung',
            'PAY_TOTAL': 'Gesamtzahlungsbetrag',
            'MAIL_LIMIT': 'Versandbeschränkungen',
            'WEIGHT_LIMIT': 'Gewichtslimit',
            'MIN_LIMIT': 'Mindestgrenze',
            'MAX_LENGTH_LIMIT': 'Maximale Längenbeschränkung',
            'AFTERSALE_PERIOD': 'Nachverkaufs-Anfragezeitraum',
            'COMP_BASIS': 'Entschädigungsgrundlage',
            'FUEL_SURCHARGE': 'Treibstoffzuschlag',
            'AFTERSALE_SUPPORT': 'Unterstützung für Nachverkaufsanfragen',
            'COMP_STD': 'Entschädigungsstandard',
            'COMP_PERIOD': 'Entschädigungszeitraum',
            'NO_DATA': 'Keine Daten verfügbar',
            'ENTER_WEIGHT': 'Bitte geben Sie das Gewicht ein',
            'NO_ROUTE_CFG': 'Die Strecke unter dieser Konfiguration ist noch nicht geöffnet',
            'PLEASE_SELECT': 'Artikelkategorie',
            'HISTORY_SEARCH': 'Suchverlauf',
            'TRIGGER_WIDTH_IS': 'Die Breite des Auslösers beträgt',
            'IMG_COMPRESS_FAIL': 'Bildkompression fehlgeschlagen',
            'NO_STORE_FOUND': 'Der Laden wurde nicht gefunden, bitte geben Sie den richtigen Ladenlink ein',
            'CREATE_NEW_TAG': 'Neuen Tab erstellen',
            'DOMAIN_REQUIRED': 'Der Domainname darf nicht leer sein',
            'ENTER_TAG_NAME': 'Bitte geben Sie den Namen des Tags ein',
            'CART_EMPTY': 'Der Einkaufswagen ist leer, fügen Sie jetzt Produkte hinzu!',
            'SUCCESS': 'Erfolg',
            'LIMIT_5_FILES': 'Derzeit ist die Auswahl auf 5 Dateien begrenzt',
            'SHIPPING_NOW': 'Versand',
            'AWAIT_CUSTOMS': 'Warten auf die Zollabfertigung',
            'ENROUTE_INTL': 'Auf internationalem Weg',
            'ARRIVED_CNTRY': 'Im Zielland angekommen',
            'DOMESTIC_TRNSP': 'Inlandsbeförderung im Zielland',
            'DELIVERED': 'Zugestellt',
            'IMG_FMT_LIMIT': 'Hochgeladene Bilder können nur in den Formaten JPG, JPEG, gif, bmp, PNG sein!',
            'IMG_SIZE_10MB': 'Hochgeladene Bilder dürfen nicht größer als 10MB sein!',
            'PARAM_ERROR': 'Parameterfehler, bitte kontaktieren Sie den Admin',
            'SYS_ERROR': 'Systemfehler, bitte kontaktieren Sie den Admin',
            'ENTER_PHONE': 'Bitte geben Sie die richtige Telefonnummer ein',
            'COPY': 'Kopieren',
            'SET_DEFAULT': 'Als Standard setzen',
            'EDIT_ADDRESS': 'Adresse ändern',
            'PERMANENT_DELETE_ADDR': 'Diese Aktion wird die Adresse dauerhaft löschen, möchten Sie fortfahren?',
            'DEL_ADDR_CONF': 'Lieferadresse löschen',
            'ADDR_DELETED': 'Löschung abgebrochen',
            'V1_GROWTH_VAL': 'Nur noch -2- Wachstumspunkte bis V-1',
            'INT_FEE_DISCNT': 'Ermäßigung auf internationale Versandkosten',
            'INSURE_DIS_AMT': 'Versicherungsrabattbetrag',
            'VALUE_ADD_DISC': 'Rabatt auf Mehrwertdienste',
            'UPLOAD_DESPER': 'Wird intensiv hochgeladen',
            'SELECT_DATE': 'Datum auswählen',
            'IMG_FORMAT_PNG': 'Hochgeladene Bilder können nur im JPG/PNG-Format sein!',
            'IMG_MAX_20MB': 'Hochgeladene Bilder dürfen nicht größer als 20MB sein!',
            'LIMIT_1_FILE': 'Derzeit ist die Auswahl auf 1 Datei begrenzt',
            'VIEW_RECORDS': 'Aufzeichnungen ansehen',
            'TOPUP_AMOUNT': 'Bitte geben Sie den Aufladebetrag ein',
            'BALANCE_TOPUP': 'Guthaben aufladen',
            'MY_BILL': 'Meine Rechnung',
            'SELLER_1': 'Verkäufer',
            'COPY_ADDRESS': 'Adresse kopieren',
            'ZHU_JIAXUAN': 'Zhu Jiaxuan',
            'PKG_NAME': 'Paketname',
            'COPY_SUCCESS': 'Kopieren erfolgreich',
            'COPY_FAIL': 'Kopieren fehlgeschlagen',
            'FUJIAN_ADDR': 'Fujian Sheng Quanzhou Shi Fengze Qu Qiyun Lu 152 Hao Huafeng Chuangyeyuan Sanlou Chu Dianti Youshoubian',
            'ADD_TRACK_NUM': 'Bitte fügen Sie die Sendungsnummer hinzu',
            'TRANSIT_ORDER': 'Transportauftrag erfolgreich eingereicht',
            'SEARCH_TRACK': 'Sendungsnummer suchen',
            'SEARCH_KEYWORD': 'Suchbegriff',
            'RESULT': 'Ergebnis',
            'READ': 'Gelesen',
            'SELECT_DATA': 'Bitte Daten auswählen',
            'PKG_DETAILS': 'Paketdetails',
            'RECV_INFO': 'Empfangsinformationen',
            'DECLARE_NAME': 'Angegebener Name',
            'CURRENCY_1': 'Währung',
            'DECLARED_PRICE': 'Angegebener Stückpreis',
            'DECLARED_QTY': 'Angegebene Menge',
            'TOTAL_DECLARED_VALUE': 'Gesamtdeklarierter Wert',
            'TOTAL': 'Gesamtsumme',
            'TOTAL_VALUE': 'Gesamtwert',
            'DECLARATION_INFO': 'Deklarationsinformationen (Selbstdeklaration)',
            'LOGISTICS_INFO': 'Logistikinformationen',
            'PACKAGE_ID': 'Paketnummer',
            'DETAILS': 'Details',
            'SHIPPED': 'Versandt',
            'CHOOSE_RECEIVING_ADDR': 'Lieferadresse auswählen',
            'CHOOSE_LOGISTICS_ROUTE': 'Logistikroute wählen',
            'AUTH_DEDUCT_DIFF': 'Abzug des Unterschiedsbetrags autorisieren',
            'READ_AGREE_DISCLAIMER': 'Ich habe die "Tendabuy-Haftungsausschluss" gelesen und akzeptiert',
            'ADDRESS': 'Adresse',
            'SELECT': 'Auswählen',
            'CHOOSE_SERVICE_ROUTE': 'Paketdienst und Route wählen',
            'MAIL_LIMIT_1': 'Postbeschränkungen',
            'VOLUME': 'Volumen',
            'ADD_SERVICE': 'Dienstleistung hinzufügen',
            'PRODUCT_PRICE': 'Produktpreis',
            'CHOOSE_PACKAGE_SERVICE': 'Bitte wählen Sie einen Paketdienst',
            'DECLARATION_ADVICE': 'Da jede Logistikroute Einschränkungen bezüglich der deklarierten Artikelnamen und Stückzahlen hat, treffen Sie bitte eine Auswahl und füllen Sie das Formular gemäß den Systemhinweisen aus. Wenn Sie Fragen zur Deklaration haben, können Sie den Online-Kundendienst konsultieren.',
            'DECL_VAL_UPPER_LIMIT': 'Obergrenze des deklarierten Gesamtwerts',
            'DECL_VAL_LOWER_LIMIT': 'Untergrenze des deklarierten Gesamtwerts',
            'DECL_ITEM_UPPER_LIMIT': 'Obergrenze für deklarierte Artikel',
            'DECL_ITEM_LOWER_LIMIT': 'Untergrenze für deklarierte Artikel',
            'ADD_DECLARATION_ITEM': 'Deklarierten Artikel hinzufügen',
            'ENTER': 'Bitte eingeben',
            'TOTAL_QUANTITY': 'Gesamtmenge',
            'BUY_INSURANCE': 'Versicherten Wert kaufen',
            'INSURANCE_NOTICE': 'Versicherungshinweise',
            'INSURED_AMOUNT': 'Versicherungssumme',
            'PREMIUM': 'Prämie',
            'SERVICE_FEE': 'Servicegebühr',
            'CHECK_URL_OR_HOME': 'Bitte überprüfen Sie, ob die eingegebene URL korrekt ist, oder klicken Sie auf den unten stehenden Knopf, um zur Startseite zurückzukehren.',
            'GO_HOME': 'Zur Startseite zurückkehren',
            'PAGE_NOT_FOUND': 'Seite nicht gefunden...',
            'BANNED_RESTRICTED_ITEMS': '[Verbotene und beschränkte Artikel]',
            'TRACK_LOGISTICS': 'Logistik verfolgen',
            'PRODUCT_ISSUE': 'Produktanomalie',
            'PACKAGE_LOST': 'Paket verloren',
            'REQUIRED_FIELD': '(*Pflichtfeld)',
            'UPLOAD_LIMIT_5_2MB': 'Es können maximal 5 Bilder hochgeladen werden, die Größe jedes Bildes ist auf maximal 2 MB beschränkt (Unterstützung von  jpg-, png-Formaten).',
            'MIN_PRICE': 'Niedrigster Preis',
            'MAX_PRICE': 'Höchster Preis',
            'SHIP_TO_TENDABUY': 'Bitte an das Tendabuy-Lager senden; Dienstleistungsgebühr CNY 10 pro Bestellung',
            'ENTER_MAX_PRICE': 'Bitte geben Sie den Höchststückpreis ein',
            'MAX_GT_MIN_PRICE': 'Der Höchststückpreis darf nicht niedriger als der Mindeststückpreis sein',
            'ENTER_MIN_PRICE': 'Bitte geben Sie den Mindeststückpreis ein',
            'MIN_LT_MAX_PRICE': 'Der Mindeststückpreis darf nicht höher als der Höchststückpreis sein',
            'AVATAR_JPG_ONLY': 'Hochgeladene Profilbilder dürfen nur im JPG-Format sein!',
            'ADD_SUCCESS': 'Erfolgreich hinzugefügt',
            'HAVE_ACCOUNT': 'Bereits ein Konto vorhanden,',
            'BACK_TO_TENDABUY': 'Zurück zu TENDABUY',
            'ACTIVATE_ACCOUNT': 'Konto aktivieren',
            'EMAIL_ACTIVATED': 'E-Mail-Aktivierung erfolgreich',
            'PASSWORD_REQUIREMENT': '6-20 Zeichen, muss Buchstaben & Zahl enthalten.',
            'PASSWORDS_NOT_MATCH': 'Die zwei Passworteingaben stimmen nicht überein!',
            'VALID_EMAIL_REQUIRED': 'Bitte geben Sie die richtige E-Mail-Adresse ein',
            'CODE_REQUEST_FAST': 'Anforderung des Verifizierungscodes zu häufig',
            'LOGIN_SUCCESS': 'Anmeldung erfolgreich',
            'PARTNER_LOW_COST': 'Zusammenarbeit mit vielen internationalen Kurierdiensten, super niedrige Versandrabatte',
            'LENGTH': 'Länge',
            'WIDTH': 'Breite',
            'HEIGHT': 'Höhe',
            'SEARCH': 'Anfrage',
            'THIRD_PARTY_SHIPPING': 'Alle Logistikdienste für Routen, die nicht von TendaBuy betrieben werden, werden von Drittlogistikdienstleistern bereitgestellt, und sie werden den Versandpreis entsprechend den Marktbedingungen anpassen. TendaBuy wird Sie im Voraus benachrichtigen, wenn es eine Preisänderung gibt. Um ein ultimatives Serviceerlebnis zu bieten, kann TendaBuy bei Problemen mit Logistikdiensten in Ihrem Namen mit Drittlogistikdienstleistern verhandeln und kommunizieren.',
            'LOGISTICS_NOTICE': 'Erklärung des Logistikdienstes',
            'QUERY_SHIPPING': 'Bitte geben Sie die entsprechenden Parameter ein, um die Versandkosten zu erfragen!',
            'TO_TENDABUY_WAREHOUSE': 'Von Tendabuy zu',
            'SEARCHING': 'Suchen',
            'FOUND_PRODUCTS': 'hat folgende Produkte für Sie gefunden',
            'COMPREHENSIVE': 'Umfassend',
            'NO_PRODUCTS': 'Keine Produkte verfügbar',
            'LOADING': 'Lädt...',
            'NO_MORE': 'Nichts weiter...',
            'FINAL_PRICE': 'Bitte tragen Sie den Endpreis ein',
            'COUPON_RULES_EFFECT': 'Aufgrund der Regeln für Einkaufsgutscheine sind Rabattgutscheine oder vollständige Reduzierungen möglicherweise nicht verfügbar, es wird empfohlen, dass der von Ihnen geänderte Preis mit dem tatsächlichen Verkaufspreis des Verkäufers übereinstimmt!',
            'PRICE_CHANGE_ADVICE': 'Bitte wählen Sie einen Grund für die Preisänderung',
            'PRODUCT_RISK_NOTICE': 'Produktrisikohinweis',
            'PRODUCT_RISK_ALERT': 'Das von Ihnen eingereichte Produkt kann -1- Risiko(en) haben, es wird empfohlen, dass Sie sich sorgfältig über das/die Risiko(en) informieren, bevor Sie erwägen, es zu kaufen~',
            'BRAND_PRODUCT': 'Markenprodukt',
            'BRAND_AUTHENTICITY': 'Dieses Produkt ist ein -1- Markenprodukt, bitte achten Sie auf die Echtheit',
            'UNDERSTOOD': 'Ich verstehe',
            'REPORT_DESC': 'Bitte geben Sie eine Beschreibung der Meldung ein',
            'MINIMUM_QUANTITY_ERROR': 'Die Produktmenge kann nicht geringer sein als die Mindestbestellmenge',
            'FREE': 'Kostenlos',
            'CANCEL_SUCCESS': 'Produkt erfolgreich storniert',
            'CANCEL_FAIL': 'Produkt stornieren fehlgeschlagen',
            'SAVE_SUCCESS': 'Produkt erfolgreich zu Favoriten hinzugefügt',
            'SAVE_FAIL': 'Produkt zu Favoriten hinzufügen fehlgeschlagen',
            'PASSWORD': 'Passwort',
            'OR_CONTINUE': 'Oder fortfahren',
            'BACK_TO_PASSWORD_LOGIN': 'Zurück zur Passwort-Anmeldung',
            'OPEN_APP_HOME': 'Öffnen Sie die Startseite der Tendabuy APP',
            'CONFIRM_PASSWORD': 'Passwort bestätigen',
            'YOUR_EMAIL': 'Ihre E-Mail',
            'EMAIL_INACTIVE': 'Nicht aktiviert, zum Fortfahren klicken Sie bitte unten auf die Schaltfläche, um die Aktivierungs-E-Mail erneut zu senden',
            'RESEND_ACTIVATION': 'Aktivierungs-E-Mail erneut senden',
            'CHECK_ACTIVATION_EMAIL': 'Bitte gehen Sie zu Ihrer E-Mail, um die Aktivierungs-E-Mail zu betrachten und Ihr Konto zu überprüfen.',
            'NEW_LOCATION_LOGIN': 'Ihr Konto wird von einem neuen Standort aus zugegriffen, bitte geben Sie den Verifizierungscode aus der E-Mail ein, um fortzufahren.',
            'OPEN_LINK_ACTIVATE': 'Bitte überprüfen Sie die E-Mail und öffnen Sie den Link, um Ihr Tendabuy-Konto zu aktivieren',
            'REGISTER_SUCCESS': 'Registrierung erfolgreich',
            'WINDOW_FAIL': 'Fensteröffnung fehlgeschlagen',
            'LOGIN_SUCCESSFUL': 'Anmeldung erfolgreich',
            'ACCOUNT_NOT_ACTIVE': 'Konto nicht aktiviert',
            'READ_AGREE': 'Bitte lesen Sie die Anweisungen und stimmen Sie zu',
            'ORDER_IDENTIFICATION': 'Bestellkennzeichnung',
            'DESCRIPTION_TEXT': 'Beschreibungstext',
            'CUSTOM_PHOTO_SERV': 'Kundenspezifische Fotografie ist ein Mehrwertdienst von Tendabuy, der 1 CNY pro Foto berechnet. Es ermöglicht, Produktfotos aus bestimmten Winkeln gemäß individuellen Anforderungen aufzunehmen, wie zum Beispiel die Anfrage zur Messung der Einlagenlänge, den Wunsch, Details eines Kleidungslogos zu sehen oder die Anfrage nach Messung des Brustumfangs eines Sweatshirts',
            'REMAINING_FREE_PHOTO': 'Verbleibende kostenlose individuelle Fotografie für diesen Monat -1- Fotos Servicegebühr:',
            'PHOTO_QUALITY_PROMISE': 'Kundenspezifische Fotografie - Qualitätszusage',
            'TOTAL_PRODUCT_PRICE': 'Gesamtpreis der Waren',
            'VALUE_ADDED_FEE': 'Mehrwertdienstgebühr',
            'EDIT_REMARK': 'Bemerkungsinformationen ändern',
            'SEND_1': 'Senden',
            'CANCEL_PAYMENT': 'Zahlung Stornieren',
            'CANCEL_PAYMENT_1': 'Zahlung stornieren?',
            'TRANSIT_SELF_SERVICE': 'Selbstbedienung für Weiterleitungsaufträge',
            'SERVICE_1': '[Service]',
            'FILE_SELECT': 'Datei auswählen',
            'HISTORY_MESSAGES': 'Historische Nachrichten',
            'COLLAPSE_MESSAGES': 'Nachrichten zusammenklappen',
            'EDIT_LABEL': 'Etikett bearbeiten',
            'ENTER_LABEL_COLOR': 'Bitte geben Sie die Etikettenfarbe ein',
            'MODIFY_LABEL': 'Etikett ändern',
            'DELETE_LABEL': 'Etikett löschen',
            'CANCEL_EDIT': 'Bearbeitung abbrechen',
            'SELF_CHECK_REMINDER': 'Selbstprüfungserinnerung',
            'ORDER_REMINDER': 'Bestellungserinnerung',
            'SERVICE_2': 'Dienstleistung',
            'UPLOAD_FAIL': 'Datei-Upload fehlgeschlagen',
            'JPG_PNG_500KB_LIMIT': 'Es können nur jpg/png-Dateien hochgeladen werden, und sie dürfen 500kb nicht überschreiten',
            'DELETE_LABEL_CONFIRM': 'Dauerhafte Löschung des Etiketts bestätigen?',
            'WARNING': 'Warnung',
            'CHOOSE_PRODUCT': 'Bitte wählen Sie ein Produkt',
            'CHOOSE_COLOR': 'Bitte wählen Sie eine Etikettenfarbe',
            'ADD_SERVICE_1': 'Dienst hinzufügen',
            'STRENGTHEN_FRAME': 'Fester Fuß+Rahmen',
            'CALC_WEIGHT': 'Gewicht berechnen (g)',
            'PACKAGE_DECLARATION_ALERT': 'Paketdeklarationserinnerung',
            'NEW_DECLARATION_ITEM': 'Neues Deklarationselement hinzufügen',
            'INSURANCE_PURCHASE': 'Versicherung kaufen',
            'INSURANCE_APPLICATION': 'Versicherungsantrag',
            'INSURANCE_SUGGESTION': 'Vorschlag: Versicherungsbetrag = Gesamtwert der Ware + Versandkosten',
            'SELECT_SERVICE': 'Dienst wählen',
            'BOX_STRENGTHENING': 'Verpackung mit festen Füßen',
            'ASSOCIATED_EMAIL': 'Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail ein',
            'ENTER_ASSOCIATED_EMAIL': 'Bitte geben Sie Ihre gebundene E-Mail ein',
            'SENT_EMAIL_SUCCESS': 'E-Mail erfolgreich versandt, bitte überprüfen Sie Ihre E-Mails umgehend',
            'PAYMENT_CANCEL': 'Zahlung abgebrochen',
            'CLOSE_1': 'Schließen',
            'SHIPPING_PAYPAL': 'Bitte stellen Sie sicher, dass die tatsächliche Versandadresse der Ware mit der PayPal-Lieferadresse übereinstimmt, andernfalls wird die Transaktion überprüft, was die Bearbeitungszeit beeinflusst',
            'PAYMENT_TIP': 'Wichtige Hinweise: Bitte öffnen Sie eine neue Seite für den Zahlungsvorgang. Die Zahlung ist noch nicht abgeschlossen. Schließen Sie dieses Fenster bitte nicht',
            'COMPLETED_PAYMENT': "Wenn Sie erfolgreich bezahlt haben, klicken Sie bitte auf 'Zahlung erfolgreich",
            'PAYMENT_PROBLEM': 'Wenn Sie Probleme bei der Zahlung haben, können Sie erneut versuchen zu bezahlen oder klicken, um Feedback zu geben',
            'PAYMENT_FAIL_FEEDBACK': 'Feedback bei Zahlungsfehler',
            'PAYMENT_SUCCESS': 'Bezahlt',
            'PAYMENT_FAIL': 'Zahlung fehlgeschlagen',
            'ORDER_PROCESS_6_HOURS': 'Ihre Bestellung wird innerhalb von 6 Arbeitsstunden bearbeitet (ausgenommen einige Drittanbieter-Plattformen)',
            'ATTENTION_ITEMS': 'Bitte beachten Sie folgendes:',
            'PACKAGE_SUBMIT_SELF': 'Die Ware wird nicht an Sie versandt; Sie müssen das Paket selbst einreichen.',
            'AFTER_STORAGE_SUBMIT': 'Nachdem die Ware eingelagert ist, können Sie die Ware im "Benutzerzentrum - Mein Lager" auswählen, das Paket einreichen und die Logistik auswählen, um die internationalen Frachtkosten zu bezahlen.',
            'WATCH_INTERNAL_INFO': 'Folgen Sie den Informationen auf der Website',
            'COMMUNICATE_ISSUES': 'Während der Auftragsbearbeitung werden wir bei Problemen zeitnah über Nachrichten auf der Webseite mit Ihnen kommunizieren und uns beraten.',
            'CONTINUE_SHOPPING': 'Weiter einkaufen',
            'VIEW_ORDER': 'Bestellungen überprüfen',
            'RECHARGE_NOT_EMPTY': 'Der Aufladebetrag darf nicht leer sein',
            'SIMULATE_PACKAGE': 'Probelieferung',
            'MY_PARCEL': 'Mein Paket',
            'TENDABUY_AUDIT_7DAY': 'Tendabuy wird innerhalb von 7 Arbeitstagen eine Überprüfung durchführen, danke für Ihr Feedback',
            'REPORT_ID': 'Meldenummer',
            'REPORT_TIME': 'Meldezeit',
            'REPORT_TYPE': 'Meldetyp',
            'REPORT_PRODUCT': 'Produkt melden',
            'REPORT_PROGRESS': 'Fortschritt melden',
            'STORE_FAVORITES': 'Lieblingsgeschäfte',
            'SORT': 'Sortieren',
            'PRICE': 'Preis',
            'REORDER_RATE': 'Wiederkaufsrate',
            'FAVORITE_SUCCESS': 'Erfolgreich favorisiert',
            'UNFAVORITE_SUCCESS': 'Erfolgreich als nicht favorisiert markiert',
            'EXTEND_STORAGE': 'Lagerung verlängern',
            'STORAGE_EXTRA_DAYS': 'Die Lagerzeit Ihrer Ware im Lager wird um (30*N) Tage verlängert, das Ablaufdatum ist',
            'CANCEL': 'Abbrechen',
            'CONFIRM': 'Bestätigen',
            'MONTHS': 'Monate',
            'SERVICES': 'Dienst',
            'SELECT_STORAGE_TIME': 'Bitte wählen Sie die Lagerdauer',
            'ENTER_PRODUCT_LINK': 'Bitte geben Sie den Produktlink ein',
            'ENTER_PRODUCT_NAME': 'Bitte geben Sie den Produktnamen ein',
            'ENTER_PRODUCT_SPEC': 'Bitte geben Sie die Produktspezifikationen ein',
            'IMAGE_LIMIT_2MB': 'Die Größe eines einzelnen Bildes ist auf 2MB begrenzt (unterstützt jpg, png Formate)',
            'PRICE_UNKNOWN_INPUT': 'Tendabuy kann die Preise von Produkten von Drittanbieter-Plattformen nicht erkennen, Sie müssen den Preis unten eingeben. Wenn Sie sich auch über den Preis des Produkts nicht sicher sind, können Sie eine Bestellung für 1 Yuan aufgeben. Nachdem der Einkäufer von Tendabuy die Bestellung erhalten hat, wird er den Verkäufer kontaktieren und dann eine zusätzliche Zahlungsaufforderung für das Produkt an Sie senden.',
            'SELLER_SHIP_FEE': 'Bitte geben Sie die Versandkosten vom Verkäufer zum Lager von Tendabuy an',
            'TOTAL_AMOUNT_RMB': 'Gesamtbetrag = Produktkosten + Versand zum Lager Umrechnungsstandard (basierend auf RMB)',
            'ENTER_UNIT_PRICE': 'Bitte geben Sie den Einzelpreis ein',
            'PRODUCT_NAME_100CHAR': 'Bitte geben Sie den Produktnamen ein (begrenzt auf 100 Zeichen)',
            'SELLER_FEE_TENDABUY': 'Bitte geben Sie die Versandkosten vom Verkäufer zum Tendabuy-Lager an',
            'HTTP_LINK_REQUIRED': 'Bitte geben Sie den Produktlink ein, beginnend mit http:// oder https://',
            'SPECIFY_COLOR_SIZE': 'Um das Produkt genauer für Sie zu kaufen, hinterlassen Sie bitte hier die Farbe/Größe und andere Spezifikationen',
            'CLICK_COUNT': 'Klickzahlen',
            'RECORD': 'Aufzeichnung',
            'VIEW': 'Ansicht',
            'SIGNED': 'Erhalten',
            'PAID1': 'Bezahlt',
            'ITEM_MISSING': 'Kann diesen Artikel nicht finden',
            'SECURITY_SET_2FA': 'Ihr Sicherheitslevel ist "-1-" Bitte richten Sie zur Sicherung Ihres Kontos die Zwei-Faktor-Authentifizierung ein',
            'SECURITY_LOW': 'Ihr Sicherheitslevel ist "-1-"',
            'CHANGE_PWD': 'Zahlungspasswort ändern',
            'OLD_PWD': 'Zahlungskennwort',
            'PWD_MIN_6': 'Das Passwort muss mindestens 6 Zeichen lang sein.',
            'ENTER_OLD_PWD': 'Anfangspasswort',
            'LOGIN_PWD_OK': 'Anmeldepasswort erfolgreich geändert',
            'PAY_PWD_OK': 'Zahlungspasswort erfolgreich geändert',
            'VALUE_ADD': 'Mehrwertdienste',
            'ORDER_AMOUNT': 'Bestellbetrag',
            'REAUTH': 'Ob die Rückzahlung genehmigt werden soll',
            'ITEMS_QTY': 'Quantität',
            'PARCEL_INFO': 'Paketinformationen',
            'EST_SHIPPING': 'Versandkostenrechner',
            'EST_WEIGHT': 'Geschätztes Paketgewicht',
            'EST_VOLUME': 'Geschätztes Volumen des Pakets',
            'ACT_SHIPPING': 'Tatsächliche Versandgebühr',
            'ACT_WEIGHT': 'Tatsächliches Versandgewicht',
            'ACT_VOLUME': 'Tatsächliches Versandvolumen',
            'RECIPIENT': 'Empfänger',
            'CUBIC_CM': 'cm³',
            'TOTAL_WEIGHT': 'Gesamtgewicht',
            'TOTAL_VOLUME': 'Gesamtvolumen',
            'LOG_ROUTE': 'Logistikroute',
            'TRACK_NO': 'Sendungsnummer',
            'LOG_STATUS': 'Sendungsstatus',
            'ACT_AMOUNT': 'Endbetrag',
            'SELF_DECLAR': 'Selbst erklären',
            'PARCEL_SERVICE': 'Paketdienst',
            'ORDER_SERVICE': 'Bestellservices',
            'AGREE_TNC': 'Bitte stimmen Sie zuerst den Allgemeinen Geschäftsbedingungen zu',
            'DECLAR_EXCEED': 'Zu viele Artikel deklariert',
            'FILL_ITEMS': 'Bitte deklarieren Sie Ihre Artikel',
            'SELECT_ROUTE': 'Bitte wählen Sie die Logistikroute aus',
            'PRE_FEE': 'Gebühr für Vorverpackungsvorgang',
            'IMG_2MB': 'Nur Bilder unter 2 MB',
            'MODIFY_PRICE': 'Preis bearbeiten',
            'STOCK': 'der Lagerbestand',
            '待删除': '',
            'ACTIVATE_EMAIL': 'Aktivieren',
            'EMAIL_SENT': 'E-Mail-Bestätigungscode erfolgreich gesendet',
            'TEMPLATE': 'Vorlage',
            'PENDING_CON': 'Ausstehende Bestätigung',
            'WEIGHT_MAX': 'Oberes Gewichtslimit',
            'WEIGHT_MIN': 'Unteres Gewichtslimit',
            'AFTER_SALES_DE': 'Kundendienstbeschreibung',
            '与上面重复': 'Kontosicherheit',
            'INVALID_PARAMS': 'Parameterfehler ',
            'PWD_RESET_SU': 'Passwort erfolgreich zurückgesetzt',
            'PAY_BE_TIMEOUT': '',
            'CON_PAYMENT': 'Zahlung Bestätigen',
            'PAY_ISSUE': 'Etwas ist schief gelaufen mit der Zahlung',
            'RE_DETAILS': 'Berichtdetails',
            'LOGISTICS_LABEL': 'Dieses Etikett wird vom Tendabuy-System verwendet, um Logistikbeschränkungen zu identifizieren, und wird nicht an Produkten oder Verpackungen angebracht sein',
            'NEWBIE_GUIDE': 'Neuer Benutzerführer',
            'MEMBER_GIFT': 'Neumitglied Coupon',
            '删掉': '',
            'TUTORIAL': 'Tutorial',
            'SERVICE_FEES': 'Service & Gebühren',
            'RETURNS_REFUNDS': 'Rückgabe & Erstattung',
            'CUSTOMER_SERVICE': 'Kundenservice',
            'PAYMENT_DEPOSIT': 'Zahlung & Einzahlung',
            'STORAGE1': 'Lagerung',
            'INSPECTION_RULES': 'Inspektionsregeln',
            'INSURANCE_COM': 'Versicherung & Entschädigung',
            'DELIVERY': 'Lieferung',
            'CHARGES': 'Gebühren',
            'PACKAGING': 'Verpackung',
            'CUSTOMS_TAX': 'Zoll & Steuer',
            'RECEIPT_INFORMATION': '',
            'LOGISTICS_TRACKING': 'Logistikverfolgung',
            'ABOUT_US': 'Über uns',
            'TENDABUY': 'Tendabuy',
            'TERMS_OF_USE': 'Nutzungsbedingungen',
            'PRIVACY_SECURITY': 'Datenschutz & Sicherheit',
            'LEGAL_NOTICE': 'Rechtliche Hinweise',
            'CONTACT_US': 'Kontaktieren Sie uns',
            'TRACK_INFO': 'Wie kann ich die Versandinformationen meines Pakets verfolgen？',
            'DELIVERY_AWARENESS': 'Worauf sollte ich achten, wenn mein Paket zugestellt wird？',
            'DAMAGED_ITEM': 'Was soll ich tun, wenn ein zerbrechlicher Artikel beschädigt ist？',
            'PROBLEMATIC_PRODUCT': 'Was sollte ich tun, wenn das Produkt eines Einkaufsagenten bei Erhalt problematisch ist？',
            'WAREHOUSE_PROBLEM': 'Was soll ich tun, wenn ich ein Problem mit dem Produkt feststelle, nachdem es in unserem Tendabuy-Lager angekommen ist？',
            '上面已经有了': '',
            'MULTIPLE_CHINA': 'Mehrere Einkaufsplattformen aus China',
            'COMPETITIVE_ITEMS': 'Bieten Sie wettbewerbsfähige Preise für Millionen von Online- und Offline-Artikeln',
            'COMBINE_DELIVER': 'Kombinieren Sie Artikel von verschiedenen Verkäufern in einem Paket und liefern Sie es Ihnen',
            'COVERING_SAFE': 'Weltweite Abdeckung in mehr als 200 Ländern, sicher und zuverlässig',
            'PURCHASE_TENDABUY': 'Kaufen Sie über Tendabuy',
            'CHECK_IN': 'Qualitätsprüfung & Lagerung',
            'SEND_PKG': 'Internationaler Versand leicht gemacht',
            'VIEW_MORE': 'Mehr Anzeigen',
            'MORE_NEWS': 'Mehr Nachrichten',
            '购物车支付界面': 'Zielort',
            'GOODS_INTER_CONTENT': 'Schreiben Sie nur, wenn Sie besondere Anforderungen für diese Bestellung haben',
            '收藏夹页面': 'Stichwortsuche in der Favoritenliste',
            'OFF_500': '50 Rabatt auf Bestellungen über ￥500',
            'COUPONS': 'Gutscheine',
            'NO-THRESHOLD_COUPON': '￥20 Gutschein ohne Mindestbestellwert',
            'OVER_500': 'Über ￥500',
            '20%_OFF': '20% Rabatt',
            'APPLICABLE_COUNTRY': 'Anwendbares Land',
            'APPLICABLE_LINE': 'Anwendbare Linie',
            'ALL_COUNTRIES': 'Alle Lände',
            'USAGE_RULES': 'Nutzungsregeln',
            'USE': 'Verwendung',
            'CONVERT': 'Konvertieren',
            'ENTER_REDEEM': 'Bitte geben Sie den Einlösecode ein',
            'AVAILABLE_COUPON': 'Verfügbarer Gutschein',
            'USED_COUPONS0': 'Verwendete Gutscheine',
            'EXPIRED_COUPON': 'Abgelaufener Gutschein',
            'ANY_COUPONS': 'Sie haben noch keine Gutscheine',
            'DO_YOU_DELETE': 'Bestätigen Sie die Löschung?',
            'COLLECTED_PRODUCTS': 'Sie haben noch keine Produkte gesammelt, gehen Sie, um sie zu sammeln',
            'COLLECTED_SHOP': 'Sie haben noch keine Geschäfte gesammelt, gehen Sie, um sie zu sammeln',
            'ORDER_DETAIL': 'Bestelldetails',
            'PACKAGE_NUMBER': 'Probe Nr',
            'SUBMIT_TIME': 'Eingereichte Zeit',
            'VALUE_ADD_SERVICE_FEE': 'Mehrwertdienstgebühr',
            '前面有': 'Bestellbetrag',
            'GOODS_QUANTITY': 'Warenmenge',
            'PARCEL_INFO2': 'Paketinfor',
            'ESTIMATED_FREIGHT': 'Geschätzte Fracht',
            'ESTIMATED_WEIGHT': 'Geschätztes Gewicht',
            'ESTIMATED_VOLUME': 'Geschätztes Volumen',
            'FINAL_FREIGHT': 'Endgültige Fracht',
            'EXACT_WEIGHT': 'Exaktes Gewicht',
            'EXACT_VOLUME': 'Exaktes Volumen',
            'DETAILED_COSTS': 'Detaillierte Kosten',
            'PRE-PACKAGING_OPERATION_FEE': 'Gebühr für Vorverpackungsbetrieb',
            'RECIPIENTS_INFO': 'Empfängerinformationen',
            'RECIPIENT2': 'Empfänger',
            'MOBILE_PHONE': 'Handy',
            'SHIPPING_ADDRESS2': 'Lieferadresse',
            'SHIPPING_LINE': 'Reederei',
            'SHIPPING_ID': 'Versand-ID',
            'SHIPPING_STATUS': 'Versandstatus',
            'SHIPPING_INFO': 'Versandinfo',
            'STATUS1': 'Status',
            'PARCEL_PAGE': 'Nachdem die Paketbestellung erfolgreich bezahlt wurde, wird die Bestellung zur "Paket"-Seite gehen und wir werden bald den Versand arrangieren.',
            'SELECT_LOGISTICS': 'Bitte wählen Sie die Logistikroute aus',
            'NO_SELECTED': 'Keine Route ausgewählt',
            'INTENTIONAL_ROUTE': 'Absichtliche Route',
            'SELECT_ROUTE1': 'Logistik auswählen',
            'SUCCESSFULLY_CANCELED': 'Erfolgreich storniert',
            'TOTAL0': 'Gesamtsumme',
            'ENQUIRY_PAGE': 'Willkommen auf der "Anfrage"-Seite, es gibt derzeit keine Anfragen.\nSie können jederzeit auf den Button "Kundenservice" rechts klicken, um uns eine Nachricht zu hinterlassen.',
            'UNDER_2MB': 'Nur Bilder unter 2MB!',
            'TRACK_PARCEL': 'Wie verfolge ich mein Paket?',
            'NOTICES_RECEIVING': 'Benachrichtigungen über den Paketempfang',
            'PRODUCTS_DAMAGED': 'Was passiert, wenn zerbrechliche Produkte beschädigt ankommen?',
            'ISSUE_PRODUCT': 'Was soll ich tun, wenn nach dem Erhalt ein Produktproblem vorliegt?',
            'APPLY_AFTERSALES': 'Wie beantrage ich den Kundendienst?',
            'LEAVE_INFO': 'Produktbeschreibung und Spezifikationen hinterlassen.',
            'AMOUNT_LESS': 'Der Betrag darf nicht kleiner als 0 sein',
            'AMOUNT_MORE': 'Der Betrag darf nicht mehr als 0 sein',
            'COMMON_BRAND': 'Gängige Marke',
            'INTERNATIONAL_BRAND': 'Internationale Marke',
            'COMMON_BATTERY': 'Gängige Marke (mit Batterie)',
            'COMMON_WITHOUT_BATT': 'Gängige Marke (ohne Batterie)',
            'REMOVE_ORDER': 'Bestellung entfernen',
            'COMBINE_PAYMENT': 'Zahlung kombinieren',
            'GO_PLACE': 'Bestellung aufgeben',
            'PLACE_AN_ORDER': 'Sie haben noch keine Bestellungen, gehen Sie bitte eine Bestellung aufgeben.',
            'ORDERS_UNPAID': 'Bitte überprüfen Sie, ob alle Bestellungen unbezahlt sind',
            'RESET_SUCCESSFUL': 'Passwort erfolgreich zurückgesetzt',
            'WRONG_PAYMENT': 'Etwas stimmt nicht mit der Zahlung',
            'WITHDRAWAL_AMOUNT': 'Abhebungsbetrag',
            'MODIFY_REMARK': 'Bemerkung ändern',
            'SELECT_DESTINATION': 'Bitte wählen Sie Ihr Ziel aus',
            'LESS_THAN': 'Der Betrag darf nicht weniger als 0 sein',
            'LESS_THAN2': 'Der Betrag darf nicht weniger als 0 sein',
            'PARCEL_NO': 'Paket Nr',
            'ORDER_SERVER_FEE': 'Dienstleistung',
            'NO_THRESHOLD': 'Kein Mindestbestellwert',
            'UP_20OFF': 'Bis zu ¥20,00 Rabatt',
            '50_OFF': '50% off，Bis zu ¥20,00 Rabatt',
            'ALL_LINES': 'Internationaler Versand - Alle Linien',
            'IN_PACKING': 'Eingelagert',
            'CONFIRM_REFUND': 'Bestätigen Sie die Löschung der Bestellung? Ihr Artikel wird nicht an den Verkäufer zurückgesendet und Sie erhalten keine Rückerstattung.',
            'REMINDER': 'Erinnerung',
            'QUALITY_PHOTOS': 'Qualitätsinspektionsfotos',
            'ENTER_PARCEL': 'Beachten Sie den Namen',
            'SHIPPING_COMPANY': 'Versandunternehmen',
            'SHIPPING_EXPRESS_NO': 'Versand-ID ist ',
            'NO_STATUS': 'Derzeit gibt es keinen Versandstatus für 1111.',
            'REFUND': 'Rückerstattung',
            'ORDER_ID': 'Bestellnummer',
            'REQUEST_REFUND': 'Rückerstattung beantragen',
            'REASON': 'Grund für die Anwendung',
            'SELECT_REASON': 'Bitte wählen Sie Ihren Grund aus',
            'DESCRIPTION': 'Beschreibung',
            'IN_DESCRIPTION': 'Bitte geben Sie eine Beschreibung ein',
            'REFUNDED': 'Bestellung erfolgreich erstattet',
            'REVIEWS': 'Rezensionen',
            'REDEMPTION': 'Gutschein erfolgreich eingelöst',
            'BLOG': 'Blog',
            'COMPLETED': 'Abgeschlossen',
            'AFTERSALES': 'In AfterSales',
            'REFUNDED2': 'Erstattet',
            'NO_LONGER': 'Ich möchte die Bestellung nicht mehr',
            'PRICE_CHANGE': 'Ich möchte eine Rückerstattung aufgrund von Preisänderungen',
            'OUT_OF_STOCK': 'Rückerstattung aufgrund von Nichtverfügbarkeit',
            'WRONG_SIZE': 'Falsche Farbe/Größe bestellt',
            'LATE_DELIVERY': 'Späte Lieferung',
            'QUALITY_ISSUES': 'Probleme mit der Produktqualität',
            'NOT_SAME': 'Unzufriedenheit mit dem Produkt',
            'INCORRECT': 'Falscher Artikel erhalten',
            'YES': 'Ja',
            'NO': 'Nein',
            'BUY_SHIP': 'Kaufen & versenden Sie beliebige chinesische Artikel',
            'SHOP_CHINA': 'China-Shop',
            'CHOOSE_TENDABUY': 'Wählen Sie Tendabuy',
            'NOT_FOUND': 'Artikel nicht gefunden',
            'PARTNERS': 'Partner',
            'VALUE_MONEY': 'Preis-Leistungs-Verhältnis',
            'ADDED_SERVICE': 'Mehrwertdienst',
            'LOGISTICS': 'Logistikanbieter',
            'DESTINATION': 'Bitte wählen Sie Ihr Ziel aus',
            'REFUND_RETURN': '1. Ihr Rückerstattungs-/Rückgabeantrag wird innerhalb von 24 Stunden vom Einkaufagenten akzeptiert und mit dem Verkäufer zur Verhandlung bearbeitet. (Bitte wählen Sie den Grund entsprechend der tatsächlichen Situation aus. Wenn der gewählte Grund und der tatsächliche Grund nicht übereinstimmen, gilt die endgültige Überprüfung durch den Händler.)',
            'RETURN_TIME': '2. Ob eine Rückgabe möglich ist und die Bearbeitungszeit der Rückerstattung hängt vom Verkäufer ab, ungefähr 7-15 Werktage.',
            'CLOSE_SERVICE': '3. der Verkäufer lehnt "kein Grund zur Rückgabe" ab, wenn die Ware länger als 5 Tage auf Lager ist, und der Verkäufer schließt das After-Sale-Portal, wenn die Ware länger als 10 Tage auf Lager ist, so dass es nicht möglich ist, einen Rückgabeantrag zu stellen.',
            'APPLYING_RETURN': '4. Bevor Sie eine Rückgabe beantragen, lesen Sie bitte sorgfältig → Klicken Sie hier',
            'REFUND_POLICY': '《Rückerstattungs-/Rückgabebedingungen》',
            'NOT_LOGGED': 'Sie sind nicht eingeloggt',
            'REPORT': 'Bitte geben Sie eine Beschreibung des Berichts ein',
            'STOCK_0': 'Dieser Artikel ist auf Lager 0 und kann nicht bestellt werden',
            'OK': 'Ok',
            'REMIND': 'Heute nicht mehr erinnern',
            'READ_ACCEPT': 'Ich habe den obigen Inhalt gelesen und akzeptiere ihn',
            'FORWARDING_SERVICE': 'Weiterleitung bedeutet, dass Ihre eigenen Einkäufe in China oder Waren, die Sie von Ihren Verwandten oder Freunden erhalten haben, in unser Lager gebracht und dann mit Hilfe bequemer internationaler Logistikdienste nach Übersee geschickt werden müssen.',
            'TRACKING': 'Bitte überprüfen Sie, ob die angegebene Kontrollnummer korrekt ist.',
            'TRANSFER': 'Der Transportauftrag wurde erfolgreich hinzugefügt',
            'LENGHTH': 'Exakte Länge',
            'PRE_PACKAGE': 'Vorverpackung Nein',
            'EXACT_WIDTH': 'Exakte Breite',
            'EXACT_HEIGHT': 'Exakte Höhe',
            'CANCEL_REASON': 'Bitte geben Sie den Grund für die Stornierung der Zahlung ein.',
            'CANCELLATION': 'Bestellungen können nach der Stornierung nicht wiederhergestellt werden, bitte wählen Sie den Grund für die Zahlungstornierung aus.',
            'REFUND_REASON': 'Bitte wählen Sie den Rückerstattungsgrund aus.',
            'NO_ORDER': 'Ich möchte die Bestellung nicht mehr',
            'INFO_WRONG': 'Informationen wurden falsch eingegeben.',
            'OUT': 'Verkäufer hat keinen Lagerbestand.',
            'UNABLE': 'Zahlung kann nicht durchgeführt werden.',
            'PAYMENT_PENDING_TIP_1': 'Die Bestellung wurde abgegeben und wartet auf Ihre Zahlung.',
            'PAYMENT_PENDING_TIP_2': 'Bitte vervollständigen Sie die Zahlung innerhalb von 72 Stunden nach Erstellung der Bestellung, sonst wird die Bestellung automatisch storniert.',
            'PAID_TIP_1': 'Ihre Bestellung wurde erfolgreich aufgegeben und wartet darauf, vom Agenten gekauft zu werden.',
            'PAID_TIP_2': 'Bestellungen, die zwischen 08:00 und 18:00 Uhr Pekinger Zeit eingereicht werden, werden innerhalb von 6 Stunden bearbeitet.',
            'PURCHASING_TIP_1': 'Die Beschaffungsmitarbeiter kaufen die von Ihnen ausgewählten Waren beim Händler.',
            'PURCHASING_TIP_2': 'Wir werden den Kauf für Sie innerhalb der vereinbarten Beschaffungszeit abschließen.',
            'PURCHASED_TIP_1': 'Die von Ihnen ausgewählten Waren wurden gekauft und warten darauf, dass der Händler sie versendet.',
            'PURCHASED_TIP_2': 'Händler versenden in der Regel innerhalb von 3-7 Tagen (ausgenommen einige maßgeschneiderte und vorverkaufte Artikel).',
            'SHIPPED_TIP_1': 'Der Händler hat den Versand der Waren veranlasst. Bitte warten Sie, bis die Waren im TENDABUY-Lager eintreffen.',
            'SHIPPED_TIP_2': 'Die Waren treffen normalerweise innerhalb von 3 Werktagen und spätestens nach 7 Werktagen in unserem Lager ein.',
            'IN_PACKING_CENTER_TIP_1': 'Die Waren wurden im Verpackungszentrum von Tendabuy gelagert. Sie können ins Lager gehen, um zu prüfen. ',
            'IN_PACKING_CENTER_TIP_2': 'Die Waren haben eine kostenlose Lagerzeit von 100 Tagen, und Sie können die Lieferung jederzeit während der Lagerzeit einreichen.',
            'IN_AFTERSALES_1': "Die Bestellung hat einen After-Sales-Antrag eingereicht, den Bearbeitungsstatus können Sie unter 'Rückerstattung/Nachverkauf' einsehen.",
            'IN_AFTERSALES_2': 'Nach Abschluss der Rückerstattungsabwicklung werden die Gelder auf Ihr Tendabuy-Konto zurücküberwiesen.',
            'CANCELED_TIP_1': 'Die Bestellungen werden von Ihnen manuell oder vom System automatisch zurückgezogen.',
            'CANCELED_TIP_2': 'Sie können es vor dem Kauf abheben.',
            'CLOSED_TIP_1': 'Die Bestellung, für die eine Rückerstattung angefordert wurde, nachdem die Zahlung abgeschlossen wurde.',
            'CLOSED_TIP_2': 'Sie können zur Startseite von Home gehen, auf Mein Guthaben klicken, um die Details der Rückerstattung einzusehen.',
            'OUTSIDE_SHIPPED_TIP_1': 'Ihr Paket wurde versendet',
            'OUTSIDE_SHIPPED_TIP_2': 'Sie können zu Paketdetails gehen, um die relevante Logistikspur zu sehen.',
            'REFUNDED_TIP_1': 'Die Bestellung, für die eine Rückerstattung angefordert wurde, nachdem die Zahlung abgeschlossen wurde.',
            'REFUNDED_TIP_2': 'Sie können zur Startseite von Home gehen, auf Mein Guthaben klicken, um die Details der Rückerstattung einzusehen.',
            'NO_PAID_72': 'Diese Bestellung wird storniert, wenn die Zahlung nicht innerhalb von 72 Stunden erfolgt.',
            'DECLARE': 'Wenn TENDABUY für Sie eine Erklärung abgeben soll, können Sie wählen',
            'DECLARATION': 'Betrauen Sie die Erklärung',
            'DECLARATION2': 'Sind Sie sicher, dass Sie dem TENDABUY Ihre Unterstützung bei der Bearbeitung der Erklärung anvertrauen wollen?',
            'INSTRUCTIONS': '《Anleitungen für die Betrauung mit der Erklärung》',
            'INSURED2': 'Versicherter Betrag',
            'PURCHASE': 'KAUFEN',
            'PASTE_ORDER': 'Artikel-Link einfügen, um zu bestellen ',
            'PAY_SHIPPING': 'bezahlen Sie den Artikelpreis & den Inlandsversand',
            'SHIP_ITEM': 'wir kaufen & versenden den Artikel in unser Lager',
            'QC_STORAGE': 'QC & LAGERUNG',
            'INSPECTION': 'Qualitätsprüfung im Lager',
            'FREEQC': '5 kostenlose QC-Fotos',
            'FREE_STORAGE': '90 Tage kostenloser Speicherdienst',
            'GLOBAL': 'WELTWEITE AUSLIEFERUNG',
            'ONE_PARCEL': 'verschiedene Aufträge zu einem Paket zusammenfassen',
            'SHIPPING_100': 'Kundenspezifische Verpackung, 100+ Versandlinien',
            'GLOBAL_FEE': 'Zahlung der globalen Versandgebühr',
            'EMPTY': 'Bitte geben Sie die Stadt ein',
            'ROUTE': 'Diese Route ist nicht vorhanden. Bitte wählen Sie einen neuen Bereich aus.',
            'GLOBAL_PLATFORM': 'Weltweit führende grenzübergreifende integrierte Serviceplattform',
            'EASILY': '',
            'STEPS': '',
            'GO_HOMEPAGE': '',
            'PAGE': '',
            'FREE2': '',
            'WELCOME': '',
            'ASSISTANT': '',
            'NO_NEED': '',
            'PLEASE_SELECT_PAYMENT_METHOD': 'Bitte wählen Sie eine Zahlungsmethode',
            'AUTHENTICATOR_LABEL': 'Authentifizierungsüberprüfung',
            'AUTHENTICATOR_DESCRIPTION': 'Die Verifizierung des virtuellen Authentifizierers wurde aktiviert, um das Konto sicherer zu machen. Bitte sichern Sie Ihren Schlüssel.',
            'OPEN': 'offen',
            'CLOSE': 'Schließen',
            'UNBIND': 'Abbinden',
            'LABEL_TRUST_CURRENT_DEVICE': 'Vertrauen Sie diesem Gerät',
            'LABEL_CURRENT_DEVICE': 'Dieses Gerät',
            'TITLE_GOOGLE_Authenticator': 'Google Authenticator-Verifizierung',
            'TITLE_OPEN_GOOGLE_AUTHENTICATOR': 'Verifizierung virtueller Authenticator-Geräte aktivieren',
            'TITLE_UNBIND_GOOGLE_Authenticator': 'Google Authenticator-Verifizierung aufheben',
            'TITLE_CLOSE_GOOGLE_AUTHENTICATOR': 'Verifizierung des virtuellen Authenticator-Geräts deaktivieren',
            'ALERT_OPEN_GOOGLE_AUTHENTICATOR': 'Ihr Konto ist zur Verifizierung an einen virtuellen Authenticator gebunden. Bitte geben Sie den dynamischen Code des virtuellen Authenticators ein, den Sie auf Ihrem Smart Device gebunden haben.',
            'PLACEHOLDER_AUTHENTICATOR_VERIFICATION_CODE_INPUT': 'Bitte geben Sie einen 6-stelligen Sicherheitscode ein',
            'BUTTON_SUBMIT_VERIFICATION_CODE': 'Überprüfung einreichen',
            'STEP_1_OPEN_GOOGLE_AUTHENTICATOR': 'Identität überprüfen',
            'STEP_2_OPEN_GOOGLE_AUTHENTICATOR': 'Anwendungen installieren',
            'STEP_3_OPEN_GOOGLE_AUTHENTICATOR': 'Bindung',
            'STEP_4_OPEN_GOOGLE_AUTHENTICATOR': 'komplett',
            'ALERT_CONFIRM_EMAIL_VERIFICATION_CODE': 'Um zu bestätigen, dass es sich um Ihren eigenen Betrieb handelt, führen Sie bitte die folgende Überprüfung durch. Wenn Sie den Verifizierungscode nicht erhalten haben, rufen Sie ihn bitte erneut ab oder versuchen Sie es später erneut.',
            'BUTTON_RESEND_EMAIL_VERIFICATION_CODE': 'Wieder abrufen',
            'ALERT_CONFIRM_EMAIL_ADDRESS': 'Um sicherzustellen, dass es sich um Ihren eigenen Betrieb handelt, bestätigen Sie bitte Ihre Bestätigungs-E-Mail, um die Identitätsprüfung abzuschließen.',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Bitte laden und installieren Sie Google Authenticator auf Ihrem Mobiltelefon',
            'TIP_IPHONE_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'IPhone: Suche nach Google Authenticator im AppStore',
            'TIP_ANDROID_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Android: Suche nach Google Authenticator im App-Markt',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR_NEXT_STEP': 'Nachdem die Installation abgeschlossen ist, klicken Sie auf den nächsten Schritt, um die Seite zu binden (wenn installiert, fahren Sie direkt mit dem nächsten Schritt fort)',
            'TIP_USE_GOOGLE_AUTHENTICATOR_TO_SCAN': 'Scannen Sie den folgenden QR-Code mit Google Authenticator, um einen 6-stelligen Verifizierungscode zu erhalten',
            'TIP_BIND_GOOGLE_AUTHENTICATOR_SUCCESS': 'Sie haben Google Authenticator erfolgreich gebunden!',
            'BUTTON_RETURN': 'zurück',
            'ALERT_CHECK_GOOGLE_AUTHENTICATOR_VERIFICATION_CODE': 'Um zu bestätigen, dass es sich um Ihren eigenen Betrieb handelt, geben Sie bitte den dynamischen Authenticator-Code ein, den Sie auf dem Smart Device gebunden haben.',
            'BUTTON_USE_EMAIL_TO_UNBIND': 'Binden per E-Mail aufheben',
            'BUTTON_USE_EMAIL_TO_CHECK': 'E-Mail-Verifizierung verwenden',
            'TITLE_MODIFY_DEVICE_NAME': 'Gerätename ändern',
            'TITLE_CURRENT_DEVICE': 'Aktuelles Gerät',
            'PLACEHOLDER_MODIFY_CURRENT_DEVICE_NAME': 'Bitte geben Sie den Gerätenamen ein, der geändert werden soll',
            'MESSAGE_OPEN_SUCCESS': 'Erfolgreich eröffnet',
            'MESSAGE_UNBIND_SUCCESS': 'Binden erfolgreich aufheben',
            'MESSAGE_CLOSE_SUCCESS': 'Erfolgreich geschlossen',
            'DELETE_BILLING_ADDRESS': 'Rechnungsadresse löschen',
            'EDIT_BILLING_ADDRESS': 'Rechnungsadresse ändern',
            'CLICK_VIEW': 'Klicken Sie, um alle Produkte in diesem Shop auf Tendabuy zu sehen',
            'TRANSIT': 'In Transit',
            'SERVICE_NOT': 'Servicegebühr zu zahlen',
            'SERVICE_PAID': 'Servicegebühr bezahlt',
            'AWAITING_PACK': 'Wartet auf Verpackung',
            'PACKED_2': 'Paket ist verpackt',
            'WAIT_SHIPMENT': 'Warte auf Lieferung',
            'PENDING_CLEAR': 'In Erwartung der Zollabfertigung',
            'TRANSIT_2': 'Internationaler Transport',
            'ARRIVED_COUNTRY': 'Ankunft in Zielland',
            'IN_COUNTRY': 'Inländische Transport',
            'DELIVERED_2': 'Zugestellt',
            'CANCEL_2': 'Abbrechen',
            'INTER_NOT': 'Internationaler Versand ausstehend Zahlung',
            'INTER_PAID': 'Internationale Versandgebühr bezahlt',
            'INTER_OPTIONS': 'Internationale Versandoptionen',
            'ESTIMATED_PAID': 'Geschätzte vollständige Gebühren bezahlt',
            'ESTIMATED_NOT': 'Geschätzte vollständige Gebühren nicht bezahlt',
            'NOTICES': 'Hinweise',
            'SHIPPING': 'Versandunternehmen',
            'SELECT_COUPON': 'Versandgutschein auswählen',
            'CURRENT_PAGE': 'Das aktuelle Produkt ist 1688 Produkte, und der Bestellbetrag unterliegt der tatsächlichen Transaktion. Wenn Sie spezielle Bedürfnisse haben, beachten Sie bitte.',
            'ITEM': 'Artikel',
            'WE_OFFER_5': 'Wir bieten 5 kostenlose HD-Inspektionsfotos',
            'AFTER_YOU_CONFIRM': 'Nachdem Sie die Waren besttigt haben, whlen Sie, sie mit internationalem Express zu versenden.',
            'NAVIGATION': 'Suchen Sie hier nach dem Artikel, den Sie kaufen möchten. TendaBuy unterstützt die Keyword-Suche sowie die Suche über einen Produktlink oder ein Produktbild.',
            'NAVIGATION_1': 'Klicken Sie hier, um die Versandkosten zu berechnen und mehr über die Versandroute zu erfahren.',
            'BACK': 'Zurück',
            'NEST': 'Nächste',
            'FINISH': 'Bestätigen',
            'INSURED_RANGE': 'Versicherungskostenbereich',
            'INSURANCE_FEE_WITHOUT': 'Die Versicherungsprämie fällt nicht in den angegebenen Kostenbereich',
            'EMAIL_NOT_VERIFIED': 'E-Mail nicht verifiziert',
            'TO_BE_SUPPLEMENTED': 'nachzuzahlen',
            'PARCEL_CLOSED': 'Paket geschlossen',
            'CONFIRM_RECEIPT': 'Ware erhalten',
            'SIZE_GUIDE_NOTES': 'Bitte beachten Sie, dass dies nur eine grobe Umrechnungstabelle ist, da die Größen von verschiedenen Verkäufern und Marken variieren können. Wir empfehlen Ihnen, vor dem Kauf die detaillierte Größentabelle des Produkts zu überprüfen und die passende Größe entsprechend Ihrer tatsächlichen Maße auszuwählen.',
            'SIZE_GUIDE': 'Größenratgeber',
            'SIZE_ASSISTANT': 'Größenassistent',
            'TOOL_MENU': 'Werkzeugmenü',
            'SWITCH_TO': 'wechseln zu',
            'PRODUCT_NOT_FOUND': 'Produkt nicht gefunden, zurück zu meinen Bestellungen.',
            'SELECT_COUPON_1': 'Gutschein auswählen',
            'PAID_1': 'bezahlt',
            'FIRST_WEIGHT_CHARGE': 'Erste Gewichtsgebühr',
            'RENEWAL_CHARGE': 'Verlängerungsgebühr',
            'ACTIVA': 'Senden Sie eine Aktivierungs-E-Mail',
            'ACTIVATE': 'Hast du deine E-Mail aktiviert?',
            'ACCEPT': 'Akzeptieren',
            'CANCEL0': 'Abbrechen'
        }, 'language4': {
            'HOME_PAGE': 'Inicio',
            'SHOPPING_CART': 'Carrito',
            'FAVORITE_LIST': 'Lista de deseos',
            'MY_ORDERS': 'Pedidos',
            'MY_WAREHOUSE': 'Mi Almacén',
            'MY_ASSETS': 'Mi Saldo',
            'MY_INQUIRY': 'Consulta',
            'MESSAGING_CENTER': 'Mensaje',
            'ACCOUNT_BALANCE': 'Saldo de cuenta(¥)',
            'CUSTOM_FEATURES': 'Personalizado',
            'AFFILIATE_MARKETING': 'Afiliados',
            'HELP_CENTER': 'Centro de Ayuda',
            'PRODUCT_INFORMATION': 'Información del producto',
            'ACCOUNT_BALANCE2': 'Equilibrio',
            'PRODUCT_NOTES': 'Notas del producto',
            'SPECIAL_PURCHASE_REQUIREMENT_MESSAGE': 'Por favor, indique sus requisitos de compra especiales',
            'ACCOUNT_SETTINGS': 'Cuenta',
            'ACCOUNT_SETTINGS2': 'Cuenta',
            'UNIT_PRICE_LABEL': 'Precio unitario',
            'QUANTITY_LABEL': 'Cantidad',
            'AMOUNT_LABEL': 'Cantidad',
            'ACTION_LABEL': 'Acción',
            'TAGS_LABEL': 'Etiquetas',
            'DELETE_BUTTON': 'Eliminar',
            'KIND_REMINDER': 'Recordatorio amable',
            'CONFIRM_DELETE_PRODUCT': '¿Confirma que desea eliminar',
            'CANCEL_1': 'Cancelar',
            'CONFIRM_1': 'Confirmar',
            'ADD_TO_CART': 'Agregar al carrito',
            'REMOVE_SELECTED_ITEMS': 'Eliminar seleccionado',
            'PRODUCT_SELECTED_1_NO_FREIGHT': 'Productos seleccionados 1 PCS (No incluido flete)',
            'ESTIMATE_SHIPPING_COST': 'Calculadora de flete',
            'PROCEED_TO_CHECKOUT': 'Enviar',
            'SHOP': 'Tienda',
            'PRODUCTS': 'Productos',
            'SEARCH_BY_NOTE_OR_STORE_NAME': 'Buscar por nombre de nota/nombre de tienda',
            'CHOOSE_PLATFORM': 'Seleccionar plataforma',
            'TAOBAO': 'Taobao',
            'TMALL': 'Tmall',
            'ALIBABA_1688': 1688.0,
            'ENTER_PRODUCT': 'Por favor, introduce el producto.',
            'FAVORITE_ITEM_KEYWORDS': 'Introduce palabras clave de tu producto favorito',
            'ADD_TO_CART2': 'Agregar al carrito',
            'VIEW_WAREHOUSE_IMAGES': 'Ver fotos del almacén',
            'NAVIGATE_TO': 'Ir a',
            'SHOW_ALL': 'Todos',
            'AWAITING_PAYMENT': 'Pago Pendiente',
            'SORT_BY_DATE': 'Ordenar por tiempo',
            'ORDER_RECYCLE_BIN': 'Papelera de reciclaje de pedidos',
            'ITEM_DETAILS_HEADER': 'Nombre/NºPI/NºPO',
            'SIMPLIFY_FILTERS': 'Plegar filtro',
            'ORDER_STATUS': 'Estado',
            'UNPAID_ORDERS': 'Pago Pendiente',
            'PAYMENT_COMPLETED': 'Pagado',
            'PENDING_ORDERS': 'Compras',
            'AWAITING_CONFIRMATION': 'Pendiente de confirmación',
            'SHIPPED_ORDERS': 'Enviado',
            'DELIVERED_ORDERS': 'Pedido entregado',
            'CANCEL_ORDER_ACTION': 'Cancelado',
            'START_DATE_RANGE': 'Fecha de inicio',
            'END_DATE_RANGE': 'Fecha de finalización',
            'SEARCH_ACTION': 'Buscar',
            'AFTER_SALES_SERVICE_1': 'Postventa',
            'UNIT_PRICE_FIELD': 'Precio unitario',
            'QUANTITY_FIELD': 'Cantidad',
            'STATUS_OF_ORDER': 'Estado del pedido',
            'ADDITIONAL_SERVICES': 'Servicio Adicional',
            'AFTER_SALES_SERVICE2': 'Posventa',
            'ORDER_TOTAL': 'Totalbetrag',
            'ADDITIONAL_ACTIONS': 'Andere',
            'ORDER_NUMBER_LABEL': 'Numéros de pedido',
            'CREATION_TIMESTAMP': 'Hora de creación',
            'WAREHOUSE_NAME_TENDBUY': 'Almacén: Almacén de Tendabuy',
            'UNPAID_STATUS': 'No pagado',
            'PROCEED_TO_PAYMENT': 'Pagar',
            'PRODUCT_NOTE_COLON': 'Nota del producto:',
            'SELECT_ALL_2': 'Seleccionar todo',
            'PAGE_NAVIGATION': 'Página',
            'ITEM_IDENTIFIER_HEADER': 'Nombre del producto/Número de pedido/Número de artículo',
            'FILTER_COLLAPSE': 'Filtrar por categorías',
            'DATE_RANGE_START': 'Fecha de inicio',
            'DATE_RANGE_END': 'Fecha de finalización',
            'SEARCH_QUERY': 'Buscar',
            'ORDER_SUCCESS_SHIPMENT_PROCESS': 'Después de realizar un pedido exitoso, el vendedor enviará la mercancía al almacén. Una vez que el almacén reciba la mercancía, podrá verlas y gestionarlas en esta página',
            'IDENTIFICATION_COLOR': 'Color de etiqueta',
            'PRODUCT_LABEL': 'Etiqueta de producto',
            'SELECT_ALL_ITEMS': 'Seleccionar todo',
            'TENDABUY_WAREHOUSE': 'Almacén de Tendabuy',
            'SHIPPING_COUNTRY': 'Destino',
            'ESTIMATED_DEPOSIT_WEIGHT': 'Depósito estimado (Peso real)',
            'SELECTED_ITEMS_COUNT': 'Seleccionado * artículo (Total * artículos)',
            'PREVIEW_PACKAGING': 'Embalaje estimado',
            'PREPACKING_SERVICE_INFO': 'El servicio de preempaquetado no es un empaquetado real, sino un servicio de preempaquetado. Si necesita empaquetar, por favor regrese al almacén y "envíe el envío',
            'SUBMIT_SHIPMENT': 'Enviar envío',
            'SEARCH_PR_PO_PI': 'Buscar por PR/PO/PI',
            'PACKAGE_PREVIEW_INFO': 'Ensayo Info',
            'INTENDED_ROUTE': 'Línea preferente',
            'WEIGHT_GRAMS': 'Peso',
            'DIMENSIONS_CM': 'Dimensiones ',
            'VALUE_ADDED': 'Valor añadido',
            'ALL_OPTIONS': 'Todos',
            'OPERATIONS': 'Operar',
            'UNPROCESSED_STATUS': 'No procesado',
            'PRINT_OUTBOUND_ORDER': 'Imprimir orden de salida',
            'SHIPPED_STATUS': 'Enviado',
            'WEIGHT_CALCULATED': 'Peso calculado',
            'SHIPPED_OUT': 'Enviado',
            'EXPIRED_STATUS': 'Enviado',
            'CANCELLED_STATUS': 'Peso calculado',
            'PACKAGING_WEIGHT_VOLUME_HINT': 'Antes de que sus artículos sean empacados, el sistema no puede obtener información precisa sobre el peso y el volumen. Por lo tanto, puede comprar un "paquete de vista previa" en la página "Mi almacén" para obtener el peso y volumen exactos de su paquete',
            'AFTER_SALES_SERVICE': 'Servicio Posventa',
            'RECHARGE': 'Recargar',
            'ARREARS': 'Deuda',
            'SETTLE_ARREARS': 'Repagar Atrasos',
            'AFTER_SALES_SERVICE_2': 'Servicio Posventa',
            'START_DATE': 'Fecha de inicio',
            'END_DATE': 'Fecha de finalización',
            'TRANSACTION_TYPE': 'Tipo',
            'WITHDRAWAL_DEDUCTION': 'Deducción por retiro',
            'ORDER_REFUND': 'Reembolso de pedido',
            'VIEW_BILL': 'Ver factura',
            'TXN_TIME': 'Hora de la transacción',
            'INCOME_EXPENSE': 'Ingreso/Gasto(¥)',
            'ALL_MESSAGES': 'Todos los mensajes',
            'TXN_ID': 'ID de transacción',
            'ALL_MESSAGES2': 'Todos los mensajes',
            'NO_DATA_AVAILABLE': 'Sin Datos',
            'INPUT_CONTENT': 'Nombre/Enlace de producto',
            'CONTENT_TYPE': 'Tipo',
            'AVAILABLE_BALANCE': 'Saldo disponible:',
            'ENQUIRY_CONTENT': 'Contenido de la consulta',
            'AVAILABLE_BALANCE2': 'Saldo disponible:',
            'BALANCE_DETAIL': 'Detalles del saldo',
            'ALL_INQUIRIES': 'Todos',
            'ORDER_INQUIRY': 'Consulta de pedido',
            'PACKAGE_INQUIRY': 'Consulta de paquete',
            'AFTER_SALE_SERVICE': 'Postventa de paquetes',
            'OTHER_QUERIES': 'Otras consultas',
            'PACKAGE_ORDER_NO': 'Número de paquete / Número de pedido',
            'ASSOCIATED_NO': 'Número asociado',
            'CONSULT_CONTENT': 'Tiempo',
            'ENQUIRY_SUBMIT_TIME': 'Tiempo',
            'ALL_ITEMS': 'Todos',
            'BALANCE_DETAIL2': '待删',
            'PACKAGE_ORDER_PRODUCT_NO': 'N° de Paq/N° de Ord/N° de Art',
            'TITLE': 'Título',
            'CREATION_TIME': 'Hora de creación',
            'OPERATION': 'Acción ',
            'EMPTY_DATA': 'Sin Datos',
            'SELECT_ALL_MESSAGES': 'Seleccionar todo',
            'BIRTHDAY': '待删',
            'SYSTEM_NOTIFICATION': 'Mensaje del sistema',
            'MANUAL_MESSAGE': 'Mensaje manual',
            'VIEW_DETAILS': 'Ver detalles',
            'PERSONAL_INFO': 'Información personal',
            'EDIT_PROFILE': 'Bearbeiten',
            'BIRTHDAY2': 'Fecha de nacimiento',
            'BUYING_GUIDELINES': 'Guía del Agente de Compras',
            'PHONE_NUMBER': 'teléfono',
            'BUYING_GUIDELINES2': 'Directrices del agente de compra',
            'VOICE': 'Idioma',
            'CANCEL_BUTTON': 'Cancelar',
            'GROWTH_POINTS': 'Valor de Crecimiento',
            'VIEW_GROWTH_HISTORY': 'Ver registro de nivel',
            'NEED_3000_MORE_POINTS_FOR_V1': 'Solo necesitas 3,000 puntos de Valor de Crecimiento más para alcanzar el nivel V1',
            'SHIPPING_ADDRESS': 'Dirección de Envío',
            'ADD_SHIPPING_ADDRESS': 'Agregar Dirección de Envío',
            'ZERO_ADDRESSES_CREATED_LEFT': 'Has creado',
            'ZERO_ADDRESSES_CREATED_RIGHT': 'Dirección de Envío',
            'PROMPT_ADD_ADDRESS': 'Todavía no has agregado ninguna dirección de envío. Por favor, añade una dirección de envío',
            'ACCOUNT_SECURITY': 'Seguridad de la cuenta',
            'USER_NAME': 'Nombre de usuario',
            'EMAIL_ADDRESS': 'Correo electrónico',
            'SECURITY_LEVEL': 'Nivel de seguridad',
            'LAST_LOGIN': 'Inicio de sesión reciente',
            'AUTHORIZED_BINDINGS': 'Inicio de sesión autorizado',
            'MODIFY_EMAIL_IF_ISSUE': 'Se recomienda modificar solo cuando haya un error o problema con la configuración del correo electrónico',
            'LOW_SECURITY_SET_PAYMENT_PW': "Tu nivel de seguridad es 'Bajo'. Para garantizar la seguridad de tu cuenta, por favor establece una contraseña de pago",
            'BINDING_ACTION': 'Vincular',
            'CHANGE_LOGIN_PW': 'Cambiar contraseña de inicio de sesión',
            'STRONG_PW_RECOMMENDED': 'Una contraseña altamente segura puede hacer que tu cuenta sea más segura. Recomendamos cambiar regularmente tu contraseña y establecer una que incluya tanto números como letras, con una longitud de al menos 6 caracteres',
            'PAYMENT_PASSWORD': 'Contraseña de pago',
            'SET_PW_FOR_SECURE_PAYMENTS': 'La contraseña necesaria al utilizar el saldo de Tendabuy para el pago, establece la contraseña de pago para garantizar la seguridad del pago',
            'UPDATE_PASSWORD': 'Cambiar contraseña',
            'CREDIT_CARD_BILLING_ADDRESS': 'Dirección de facturación de la tarjeta de crédito',
            'SET_CARD_BILLING_ADDRESS_FOR_PAYMENTS': 'Cuando uses una tarjeta de crédito para el pago, necesitas establecer la dirección de facturación de la tarjeta de crédito',
            'ADD_BILLING_ADDRESS': 'Agregar dirección de facturación',
            'TOP_UP_AUTHORIZATION': 'Autorización de Autopago',
            'ENABLE_SMALL_TOPUP_CONFIRMATION': 'Habilita esta función para omitir la confirmación de pequeñas recargas, mejorando así la eficiencia',
            'AUTHORIZATION_TYPE': 'Tipo de autorización',
            'CANCEL_BUTTON2': 'Cancelar',
            'DAILY_CAP': 'Límite diario',
            'REMAINING_LIMIT': 'Límite restante',
            'CONFIRM_BUTTON': 'Confirmar',
            'ACTION': 'Acción ',
            'DOMESTIC_SHIPPING_AUTH': 'Envío nacional',
            'EDIT_AUTH': 'Editar',
            'ORDER_TOPUP_AUTH': 'Autorización de pago de pedido',
            'INTERNATIONAL_SHIPPING_AUTH': 'Envío internacional',
            'ORDER_TAG_MANAGEMENT': 'Etiqueta de pedido',
            'ENABLE_CUSTOMER_MANAGEMENT': 'Habilite esta función para gestionar sus pedidos',
            'CREATE_TAG': 'Crear una etiqueta',
            'TAG_NAME': 'Nombre de la etiqueta',
            'TAG_COLOR': 'Color de la etiqueta',
            'TAG_REMARK': 'Nota de la etiqueta',
            'CONFIRM_BUTTON2': 'Confirmar',
            'COUNTRY': 'País',
            'TAG': 'Etiqueta',
            'NOTE': 'Comentario',
            'EDIT_TAG': 'Editar',
            'DELETE_TAG': 'Eliminar',
            'CUSTOM_PHOTO_TEMPLATE': 'Personalizar plantilla de foto',
            'LEARN_CUSTOM_PHOTO': 'Aprender a usar esta función facilitará completar el contenido de [Personalizar Foto]',
            'ADD_TEMPLATE': 'Agregar nueva plantilla',
            'TEMPLATE_NOT_FOUND': 'Todavía no has añadido una plantilla personalizada',
            'ADD_PHOTO_TEMPLATE': 'Ir a agregar una plantilla de foto detallada',
            'CUSTOM_PHOTO': 'Plantilla de Foto Personalizada',
            'TEMPLATE_NAME': 'Nombre de la Plantilla',
            'PHOTO_COUNT': 'Número de Fotos',
            'PHOTO_REQUIREMENTS': 'Requisitos de las Fotos',
            'TEMPLATE_NAME2 ': 'Por favor, introduzca el nombre de la plantilla',
            'ENTER_REMARKS': 'Por favor, introduzca un comentario',
            'PHOTO_REQUIREMENTS2': 'Por favor, especifique los requisitos de su foto, un requisito por foto, y puede subir una foto de muestra para referencia',
            'EMAIL_APP_NOTIFICATIONS': 'Configuraciones de notificación por correo electrónico',
            'SELECT_NOTIFICATIONS': 'Comprueba sobre qué deberíamos notificarte',
            'CUSTOMIZE_PHOTO': 'Personalizar fotos',
            'WATERMARK_ENABLED': 'La activación de la marca de agua añadirá el estilo de marca de agua seleccionado a las fotos que recibas',
            'ADD_FORWARDING_ORDER': 'Agregar una orden de reenvío',
            'WAREHOUSE_ADDRESS': 'Dirección de Tendabuy',
            'RETURN_ORDERS': 'Volver a Mi Pedido',
            'ORDER_REMARK2': 'Nota del pedido',
            'SEARCH_COURIER': 'Buscar un número de seguimiento',
            'HISTORY_FORWARDING': 'Historial de Reenvío',
            'RECIPIENT_ADDRESS': 'Dirección del almacén',
            'CONTACT_ADDRESS': 'Destinatario',
            'ADDDRESS': 'Dirección',
            'POST_CODE': 'Código postal',
            'CONTACT_PHONE': 'Móvil',
            'WARNING_PACKAGE_SECURITY': 'No compartas tu número de seguimiento con otros al enviar al almacén de Tendabuy. Es tu identificación única.',
            'SHIPPING_INFO_Tendabuy': 'Información del envío a Tendabuy',
            'TRACKING_NUMBER': 'Número de seguimiento',
            'TRACKING_NUMBER_REQUEST': 'Pide al remitente la información',
            'PARCEL_NAME': 'Nombre del producto',
            'ADD_TRACKING_NUMBER': 'Agregar seguimiento',
            'TRACK_PACKAGE_STATUS': 'Ver seguimiento',
            'CANCEL_ACTION': 'Cancelar',
            'SUBMIT_ACTION': 'Enviar',
            'DISCLAIMER': 'Renuncia',
            'SUBMIT_PRODUCT_INFO': 'Rellenar y enviar información del producto',
            'PAYMENT_SHIPPING_FEES': 'Pagar el producto y el flete de envío',
            'WAITING_MERCHANT_SHIPMENT': 'Esperando que el comerciante envíe',
            'COUNTRY2': 'País',
            'REQUIRED_FIELDS': 'Campos requeridos',
            'PRODUCT_LINK': 'Enlace del producto',
            'GENDER': 'Género',
            'GENDER2': 'Género',
            'INPUT_SHIPPING_COST': 'Por favor, ingresa el costo de envío desde el comerciante hasta el almacén de Tendabuy',
            'UPLOAD_PICTURE': 'Subir Imágenes',
            'PICTURE_SIZE_LIMIT': 'Solo imágenes de menos de 3MB (por favor, sube archivos en formato GIF, JPG y PNG)',
            'PRODUCT_REMARK': 'Notas del producto',
            'COST': 'Costo',
            'UNIT_PRICE': 'Precio',
            'FILL_IN_RMB_1_FOR_UNKNOWN_PRICE': 'Si el precio es desconocido, completa con 1CNY para realizar el pedido y espera a que nuestro agente confirme el importe insuficiente.',
            'DOMESTIC_SHIPPING_COST': 'Flete de llegada al almacén',
            'TOTAL_PRICE': 'Total',
            'INTERNATIONAL_SHIPPING_EXTRA': 'The international shipping cost is not included and will be charged separately',
            'ESTIMATE_SHIPPING': 'Calculadora de envíos',
            'THIRD_PARTY_LINK_DISCLAIMER': 'El enlace que has enviado en esta página pertenece a un enlace de producto de terceros (como Xianyu, Little Red Book, Bilibili, yupoo, etc.). Tendabuy solo puede ayudarte a encontrar al comerciante asociado con este enlace para consultas sobre productos, compras por proxy y organización de servicios logísticos de terceros para la entrega. Tendabuy no puede determinar la calidad o autenticidad de los productos. Debes asumir los riesgos relacionados antes de realizar un pedido',
            'BUYING_AGENT_GUIDE': 'Notas del Agente de Compras',
            'PRODUCT_DETAILS': 'Detalles del producto',
            'AGREE': 'Aceptado',
            'BUY_NOW': 'Comprar ahora',
            'CUSTOM_ORDER_REQUIRED': 'Pedido DIY (*Campo obligatorio)',
            'UPLOAD_DESCRIBE_PRODUCT_1': '1. Sube una imagen o describe el producto que deseas comprar.',
            'UPLOAD_DESCRIBE_PRODUCT_2': '2. Te ayudaremos a encontrar el producto(s) correspondiente y confirmaremos contigo dentro de las 24 horas posteriores a la recepción de tu solicitud.',
            'UPLOAD_DESCRIBE_PRODUCT_3': '3. La tarifa de servicio no es reembolsable si localizamos el producto(s) pero decides no comprarlo.',
            'PRODUCT_IMAGE': 'Imágenes del producto',
            'ORDER_REMARK': 'Observación',
            'PRICE_RANGE': 'Rango de precios',
            'ENTER_PROD_DESC': 'Dejar la descripción y especificación del producto',
            'QUANTITY': 'cantidad',
            'WAREHOUSE_SHIPPING_FEE_CNY_10': 'Envío al almacén de Tendabuy; tarifa de servicio de 10 CNY por pedido',
            'BUYER_QUALITY_DISCLAIMER': 'Descargo de responsabilidad: No podemos determinar la calidad o autenticidad de los productos. Si bien haremos todo lo posible para negociar con el vendedor en su nombre para el servicio posventa, no podemos garantizar el resultado. Si elige utilizar este servicio, debe asumir los riesgos asociados. De lo contrario, no podemos proceder con la compra en su nombre.',
            'CUSTOMIZE_NOW': 'Enviar ahora',
            'PRODUCT_IMAGE2': 'Imagen del producto',
            'CONFIRM_AGREE': 'De acuerdo',
            'PRODUCT_NAME': 'Nombre del producto',
            'PRODUCT_NAME2': 'Nombre del producto',
            'PRODUCT_SPECIFICATIONS': 'Detalles del producto',
            '': '',
            'PRODUCT_SPECIFICATIONS2': 'Especificaciones',
            'TRANSACTION_TIME': 'Hora de la transacción',
            'VIEW_STATEMENT': 'Verificación de Factura',
            'TRANSACTION_ID': 'ID de transacción',
            'REMARKS': 'Remáscara',
            'NO_DATA_FOUND': 'No hay datos',
            'PARCEL': 'in Spanish',
            'PRIORITY_SHIPPING': 'Envío Preferente" in Spanish',
            'RECHARGE_REQUIRED': 'Recargar',
            'IN_TRANSIT': 'En camino',
            'RECEIVED': 'Recibido',
            'CANCELLED': 'cancelado',
            'REVIEWABLE_PRODUCTS': 'Productos Evaluables',
            'POSTED_REVIEWS': 'Opiniones Publicadas',
            'EMPTY_FIELD': 'Vacío',
            'SEARCH_ORDER_NUMBER': 'Buscar Número de Orden',
            'RELATED_ORDER_NO': 'Número de pedido relacionado',
            'COUPONS_SECTION': 'Cupón',
            'AVAILABLE_COUPONS': 'Cupón Disponible',
            'USED_COUPONS': 'Cupón utilizado',
            'COUPON_EXPIRED': 'Cupón vencido',
            'NO_ACTIVE_COUPONS': 'No tienes ningún cupón aún',
            'MY_POINTS': 'Mis puntos',
            'POINTS_THIS_MONTH': 'Canje de puntos este mes',
            'REDEMPTION_DETAILS': 'Detalles del Canje',
            'REDEMPTION_RECORD': 'Registro de Canje',
            'RELATED_ORDER_NO2': 'Número de pedido relacionado',
            'RELATED_NUMBER': 'Número asociado',
            'REDEMPTION_METHOD': 'Método de Canje',
            'TENDABUY_POINTS': 'Puntos de Tendabuy',
            'STORAGE_FEES': 'Tarifas de Almacenamiento',
            'REDEMPTION_DATE': 'Fecha de Canje',
            'REMARK': 'Observación',
            'REDEMPTION_ACTION': 'Acción ',
            'REDEEMED_ITEMS': 'Canjear Artículos',
            'ORDER_INQUIRIES': 'Consulta de pedido',
            'PARCEL_INQUIRIES': 'Consulta de paquete',
            'AFTER_SALE_INQUIRIES': 'Paquete Postventa',
            'OTHER_ENQUIRY': 'Otra consulta',
            'QUERY_TYPE': 'Tipo',
            'REMARK2': 'Nota',
            'QUERY_CONTENT': 'Contenido de la consulta',
            'SELECT_ALL': 'Seleccionar todo',
            'QUERY_STATUS': 'Estado',
            'WELCOME_NO_QUERIES_MSG': 'Bienvenido/a a la página de "Consulta", actualmente no hay ninguna consulta,\n\npuedes hacer clic en el botón de "servicio al cliente" a la derecha para dejarnos un mensaje en cualquier momento.',
            'SELECT_ALL2': 'Seleccionar todo',
            'SYSTEM_MESSAGES': 'Mensaje del sistema',
            'MANUAL_MESSAGES': 'Mensaje manual',
            'STATUS': 'Estado',
            'NO_UNREAD_INBOX_MESSAGES': 'Todavía no has tenido ningún mensaje interno no leído',
            'STATUS2': 'Estado',
            'STATUS3': 'Estado',
            'PHONE': 'Móvil',
            'SUBMISSION_TIME': 'Hora de Envío',
            'LANGUAGE': 'Idioma',
            'SUBMISSION_TIME2': 'Hora de entrega',
            'VIEW_GROWTH_RECORDS': 'Ver registro de nivel',
            'POINTS_TO_LEVEL_UP': 'Obtén 3000 puntos más para ascender al nivel V1',
            'SIZE_INFORMATION': 'Información de mi talla',
            'MY_PRIVILEGES': 'Mi Privilegio',
            'LEVEL_BENEFITS': 'Nivel de Privilegio',
            'INTERNATIONAL_SHIPPING_DISCOUNT': 'Descuento en envío internacional',
            'INSURANCE_DISCOUNT': 'Descuento en seguro',
            'ADDED_SERVICE_DISCOUNT': 'Descuento en servicios de valor añadido',
            'RETURN_EXCHANGE': 'Tarifa Posventa Gratuita',
            'FEE_USED_AVAIL': 'Utilizado/disponible (mes)',
            'CUSTOMIZED_PHOTO_USED_MONTHLY': 'Foto Personalizada',
            'MY_RANK': 'Nivel',
            'LEVEL_VALUE': 'Valor de',
            'AUTO_PAY_AUTHORIZATIONS': 'Autorización de Pago Automático\nHabilitar el pago automático para pequeñas cantidades y el manejo de pedidos/paquetes.',
            'ENABLE_MICRO_PAYMENTS': 'Tipo de autorización',
            'AUTHORIZATION_TYPES': 'Envío nacional',
            'DOMESTIC_SHIPPING': 'Pago insuficiente',
            'UNPAID_TRANSPORT': 'Envío internacional',
            'TRANSACTION_LIMIT': 'Límite para un pago singular',
            'DAILY_LIMIT': 'Cantidad límite por día',
            'AVAILABLE_LIMIT': 'Cuota disponible',
            'STATUS_OPERATIONS': 'Estado',
            'ORDER_TAGGING': 'Acción',
            'TAGGING_FOR_EFFICIENCY': 'Cambiar',
            'TRANSACTION_LIMIT2': 'Cantidad límite por transacción',
            'TAG_NOT_FOUND': 'No se encontró la etiqueta que buscabas',
            'PRODUCT_TAGS_EXPLANATION': '¿Qué es una etiqueta de producto?\n\nEs una forma más flexible e interesante de categorizar artículos al agregar una o más etiquetas a cada artículo de tu pedido.',
            'BENEFITS_OF_TAGS': 'Los beneficios de agregar etiquetas\n\nPuedes filtrar y gestionar tus pedidos de forma más eficiente mediante etiquetas personalizadas en las páginas de "Carrito de compras", "Pedido" y "Almacén".',
            'HOW_TO_APPLY_TAGS': '¿Cómo añadir etiquetas?\n\nPuedes añadir etiquetas personalizadas en las páginas de "Carrito de compras", "Pedido" y "Almacén".',
            'HIGH_RES_TEMPLATES': 'Plantilla de fotos de alta definición\n\nguarda plantillas de fotos adicionales y aplica las plantillas con un simple clic.',
            'ADVANCED_PHOTO_TEMPLATES_USAGE': '¿Cómo funciona una plantilla detallada de fotos?\n\nAñade plantillas detalladas de fotos en las páginas de pago, Mi Pedido y Mi Almacén, luego el personal del almacén puede tomar fotos de tus productos según los requisitos de la plantilla después de que los productos lleguen al almacén.',
            'DETAILS_PHOTO_TEMPLATES_WORKFLOW': 'Información que se puede añadir a la plantilla detallada de fotos\n\nDespués de haber editado la plantilla de fotos, simplemente selecciona la plantilla y el personal del almacén seguirá la plantilla para tomar fotos de tu artículo, incluyendo la marca de agua, la cantidad de fotos, ángulos de fotografía y detalles, etc.',
            'INFO_IN_DETAIL_TEMPLATES': 'Cómo añadir una plantilla detallada de fotos?\n\nPuedes añadir etiquetas personalizadas en las páginas de "Carrito de compras", "Pedido" y "Almacén".',
            'ADDING_DETAIL_TEMPLATES': 'Gestión de notificaciones por correo electrónico y en la aplicación\n\nMarca lo que te gustaría que te notifiquemos.',
            'UNREAD_MESSAGES': 'No leído',
            'AUTO_PAY_ACTIVE': 'La función de pago automático ha sido habilitada',
            'COUPON_CLAIMED': 'Cupón recibido',
            'COUPON_EXPIRING_SOON': 'El cupón está a punto de caducar',
            'COUPON_USED': 'El cupón ha sido utilizado con éxito',
            'OPEN_BOX_REWARD_GRANTED': '¡Solicitud de recompensa por desempaquetado exitosa!',
            'OPEN_BOX_REJECTED': 'Solicitud de recompensa por desempaquetado rechazada',
            'FINANCIAL_OPERATIONS': 'Finanzas',
            'RECHARGE_COMPLETED': '¡Recarga exitosa!',
            'WITHDRAWAL_SUCCESS': '¡Retiro exitoso',
            'ORDER_AUTO_PAYMENTS': 'Pedido pagado automáticamente',
            'PARCELS': 'Paquetes',
            'PARCEL_SHORTAGE_REFUND': 'Reembolso por diferencia de paquete',
            'REHEARSAL_DONE': 'Ensayo completado',
            'PARCEL_SUBMITTED': '¡Paquete enviado!',
            'RISK_CONTROL': 'Paquete ingresado al control de riesgos',
            'PARCEL_CANCELLED': 'Paquete cancelado',
            'SHIPPED_PARCEL': 'Paquete enviado',
            'PARCEL_UNPAID': 'Paquete sin pagar',
            'PARCEL_PAID': 'El paquete ha sido pagado',
            'PARCEL_RECHARGED': 'Paquete recargado',
            'REHEARSAL_CANCELED': 'Ensayo cancelado',
            'INSURANCE_CLAIM_APPROVED': 'Reclamo de seguro aprobado',
            'INSURANCE_CLAIM_DENIED': 'Reclamo de seguro rechazado',
            'CLAIM_RECEIVED': 'Se ha recibido tu solicitud de reclamo de seguro',
            'ORDER_SUBMITTED': 'Pedidos',
            'ORDER_UNDER_REVIEW': 'Pedido ingresado al control de riesgos',
            'DOMESTIC_SHIPPING_UNPAID': 'Tasa de envío nacional sin pagar',
            'ORDER_PAYMENT_PENDING': 'Pedido sin pagar',
            'CONFIRMATION_NEEDED': 'Confirmación necesaria',
            'ORDER_STORED': '¡Pedido almacenado!',
            'PARTIAL_SHIPPING': 'Entrega por separado',
            'RETURN_REQUEST_ACCEPTED': 'La solicitud de devolución ha sido aceptada',
            'RETURN_REQUEST_CANCELED': 'La solicitud de devolución ha sido cancelada',
            'EXCHANGE_REQUEST_ACCEPTED': 'La solicitud de cambio ha sido aceptada',
            'EXCHANGE_REQUEST_CANCELED': 'La solicitud de cambio ha sido cancelada',
            'ORDER_RETURNED_SUCCESSFULLY': 'Pedido devuelto con éxito',
            'ORDER_EXPIRING': 'El pedido está a punto de expirar',
            'ORDER_OVERDUE': 'El pedido está vencido',
            'PAID_PHOTO_COMPLETE': 'Fotos pagadas terminadas',
            'ORDER_RECHARGED': 'Pedido recargado',
            'DOMESTIC_SHIPPING_RECHARGED': 'Recarga del envío nacional del pedido',
            'SHIPPING_REFUND_DONE': 'El envío del pedido ha sido reembolsado',
            'ORDER_RETRACTED': 'Pedido retirado',
            'ORDER_CANCELED': 'Pedido cancelado',
            'ORDER_PAID': 'El pedido ha sido pagado',
            'QUALITY_CHECK_ALERT': 'Recordatorio de inspección de calidad',
            'AGENT_ORDER_WARNING': 'Recordatorio de pedido del agente',
            'OVERDUE_ORDER_DELETION_IMMINENT': 'El pedido vencido está a punto de ser destruido',
            'ORDER_DELETED_OVERDUE': 'El pedido está vencido y ha sido destruido',
            'QUICK_WATERMARK_SETUP': 'Marca de agua en fotos de control de calidad\n\nconfigúralo ahora para que tus fotos de control de calidad tengan la marca de agua que seleccionaste.',
            'WATERMARK_SYSTEM': 'Marca de agua del sistema',
            'NO_WATERMARK': 'Sin marca de agua',
            'SOURCE_PRODUCT': 'Origen de este producto',
            'SOURCE_PLATFORM': 'plataforma Alibaba',
            'SOURCE_ALIBABA_TENDABUY': 'Los agentes de Tendabuy comprarán por ti',
            'CERTIFIED_WAREHOUSE': 'Almacén Tendabuy',
            'YOUR_LOCATION': 'Tu dirección',
            'INTERNATIONAL_SHIPPING_ESTIMATE': 'Estimación de entrega',
            'BUYING_HOURS_09_TO_18_UTC8': 'Los pedidos pagados entre las 18:00 y las 09:00 (hora de Beijing) serán procesados antes de las 14:00 del día siguiente.',
            'REPORT_ISSUE': 'Informe',
            'FORCE_REFRESH_PAGE': 'Forzar actualización',
            'MIN_ORDER_QUANTITY': 'La cantidad mínima de pedido es',
            'ORDER_FOLLOW_LINK': 'Tendabuy seguirá estrictamente el enlace del pedido para realizar compras en tu nombre. Si tienes alguna pregunta sobre tu pedido, no dudes en contactar a nuestro servicio al cliente en línea',
            'CONTACT_SUPPORT_ANYTIME': 'Todos los productos mostrados son de plataformas de terceros. Tendabuy solo proporciona servicios de búsqueda de información, agente de compra y envío. Tendabuy no puede verificar la calidad o autenticidad de los productos. Al utilizar el servicio de agente de compra de Tendabuy, aceptas automáticamente los riesgos mencionados anteriormente',
            'UNREAD_MESSAGES2': 'Unread',
            'SPECIFICATIONS': 'Especificaciones',
            'USERNAME': 'Username',
            'USERNAME2': 'Username',
            'FIND_SIMILAR': 'Encontrar similares',
            'affiLIATE_SHARE': 'Compartir afiliado',
            'FAVORITE': 'Favoritos',
            'MONTHLY_SALES': 'Ventas mensuales',
            'ENTER_STORE': 'Entrar a la tienda',
            'CATEGORY': 'Categorías',
            'MORE_SERVICES': 'Servicio',
            'ADDITIONAL_FEATURES': 'Servicio',
            'RESTRICTED_ITEMS_TITLE': 'Embargo',
            'RESTRICTED_ITEMS_CONTENT': '· Tabak, alcohol y artículos prohibidos no se transportan',
            'RESTRICTED_ITEMS': 'Los artículos prohibidos incluyen, pero no se limitan a lo siguiente: navajas suizas, juegos completos de cuchillos de cocina, cuchillos de carnicero, cortadores de papel/abre cartas, herramientas de jardinería/cuchillos/tijeras/sierras, descosedores, jugos puros de frutas y verduras, encendedores inflables, vino, espátulas, queso/quesos occidentales, cangrejos mitones chinos, espadas de bambú/espadas de madera, cuchillos de tarjeta multiusos, patines de hielo, otros cuchillos de exterior, componentes magnéticos, navajas suizas, fresas, cuchillos de talla, etiquetas de tabaco/tarjetas de cigarrillos, campanas de vajra/vajras/hachas, semillas, etc',
            'LIMITED_GOODS_NOTICE_TITLE': 'Restricciones de compra',
            'LIMITED_GOODS_NOTICE': 'Los medicamentos, líquidos, bienes en forma de pasta y polvos cosméticos son artículos de exportación restringidos por la aduana china. Por favor, consulte con [Servicio al Cliente] antes de realizar un pedido.',
            'CONSULT_BEFORE_ORDER_TITLE': 'Consejos del CEO',
            'CONSULT_BEFORE_ORDER': '[Sobre Aduanas y Logística] Debido a las variaciones en las políticas aduaneras de diferentes países, algunas naciones o regiones son particularmente sensibles a ciertos productos, lo que resulta en embargos, restricciones de compra, limitaciones en la ruta logística, y restricciones de volumen y peso.',
            'BEIAN_RIGHT': 'Copyright @ 2024 Tendabuy.com All rights reserved',
            'BEIAN': 'Proporcionamos especialmente productos de Taobao, 1688, Weidian y otras plataformas de compras chinas para usuarios globales, como libros, especialidades, cosméticos y otros tipos de productos, soportamos PayPal, Alipay y otros métodos de pago.',
            'GOODS_DETAIL_TIP1': 'Los artículos comprados serán enviados a nuestro almacén de TendaBuy para inspección y fotografía a su llegada.',
            'GOODS_DETAIL_TIP2': 'Puedes ver y gestionar tus artículos en [Mi Almacén].',
            'GOODS_DETAIL_TIP3': 'Si no estás satisfecho con los productos que llegan al almacén de TendaBuy, puedes solicitar una devolución o un cambio dentro de los 5 días posteriores a su llegada, y nuestro equipo de compras negociará con el vendedor en tu nombre para el servicio postventa.',
            'TIP2': 'Dejar un mensaje para el comprador. Si no hay requisitos especiales de compra, no escriba nada.',
            'SERVICE_ONE_WEBSITE': 'Servicios de Una Parada',
            'PURCHASE_ORDERS': 'Órdenes de compra',
            'PURCHASE_ORDERS_CONTENT': 'El equipo de agentes de Tendabuy puede ayudarte a comprar todos los productos en línea y fuera de línea en China. Con solo pegar un enlace de producto para hacer un pedido, y proporcionaremos una gama completa de servicios de primera clase después.',
            'SHIP_TO_WAREHOUSE': 'Enviar al almacén',
            'SHIP_TO_WAREHOUSE_CONTENT': 'Puedes comprar artículos de diferentes vendedores con nosotros, y estos productos se enviarán al almacén de Tendabuy en China.',
            'QUALITY_CHECK': 'Control de calidad',
            'QUALITY_CHECK_CONTENT': 'Después de que sus artículos lleguen al almacén de Tendabuy, se realizará un control de calidad de los artículos con defectos, tallas, colores, etc. Tendrá una garantía de servicio postventa en Tendabuy.',
            'GLOBAL_SHIPPING_CONTENT': 'Puede elegir libremente los artículos en su almacén y enviarlos en un solo paquete a su casa eligiendo un proveedor de logística confiable proporcionado por Tendabuy.',
            'HOT_LIST': 'Lista Caliente',
            'ESTIMATION': 'Estimación',
            'FORWARDING': 'Reenvío',
            'FILL_BUY': 'Llenar y Comprar',
            'FIND_BUY': 'Diy y Compra',
            'BEGINNERS_GUIDE': 'Guía',
            'HELP_CENTER_INDEX': 'Centro de Ayuda',
            'USER_CENTER_INDEX': 'Centro de Usuario',
            'LOGOUT_INDEX': 'Cerrar sesión',
            'GUIDE_USING_IMAGE_SEARCH': 'Función de búsqueda de imágenes',
            'MY_BALANCE': 'Mi saldo',
            'POINTS': 'Puntos',
            'My_COUPONS': 'Mis cupones',
            'MY_GROWTH_POINTS': 'Mis puntos de crecimiento',
            'WITHDRAW': 'Retirar',
            'PENDING_PAYMENT': 'Pago pendiente',
            'RECHARGE_AMOUNT': 'Cantidad de recarga',
            'ENTER_THE_AMOUNT': 'Por favor, introduzca la cantidad',
            'DELETE_SUCCESSFUL': 'Borrado exitoso',
            'SHOP_CART_NULL': 'Tu carrito está actualmente vacío. ¡Comencemos el viaje de compras!',
            'ORDER_DETAILS2': 'Detalles del pedido',
            'IN_PROGRESS': 'Procesando',
            'ORDERED': 'Comprado',
            'SENT': 'Enviado',
            'ARRIVED': 'Llegado',
            'STORAGE': 'Almacenamiento',
            'CONTACT': 'Contacto',
            'TOTAL_PRICE2': 'Precio total',
            'SEND': 'Enviar',
            'ORDER_CONSULT_TIP': 'Esta ventana solo puede manejar problemas relacionados con este pedido, ¡para otras preguntas por favor contacte el soporte en línea!',
            'ONLINE_CUSTOMER': 'Atención al cliente',
            'PRODUCT_AMOUNT': 'Cantidad de artículo',
            'WAREHOUSE_DELIVERY_FEE': 'Flete a nuestro almacén',
            'STORAGE_TIME': 'Almacenamiento',
            'PRODUCT_TYPE': 'Tipo de producto',
            'BUSINESS_TYPE': 'Tipo de negocio',
            'TRANSACTION_MODE': 'Método de pago',
            'Arrears_Info': 'Los atrasos se refieren a las tarifas de los paquetes, las tarifas de envío de devolución o cambio de pedido, o las tarifas contra reembolso que no ha pagado o complementado, pero priorizamos ayudarlo a procesarlos para evitar retrasos en la puntualidad. Por favor, pague los atrasos de manera oportuna, de lo contrario, no podrá comerciar la próxima vez',
            'Profile_Picture': 'Avatar',
            'Modify_User_Info': 'Editar perfil',
            'Female': 'Femenino',
            'Male': 'Masculino',
            'Currency': 'Moneda',
            'Shopping_Cart': 'Carrito',
            'Total_Order_Price': 'Precio total',
            'Proceed_To_Checkout': 'Caja',
            'Recipient': 'Destinatario',
            'First_Name': 'Nombre de pila',
            'Last_Name': 'Apellido',
            'Region': 'Regións',
            'Detailed_Address': 'Dirección Detallada',
            'ZIP_Code': 'Código Postal',
            'Address_Info': 'Por favor, ingresa tu número de apartamento, dirección de calle y otra información detallada de la dirección. (No dupliques el código postal, la ciudad, etc.)',
            'Phone_Number': 'Número de teléfono',
            'Address_Label': 'Etiqueta de dirección',
            'Home': 'Hogar',
            'Work': 'Empresa',
            'Default_Address': 'Dirección predeterminada',
            'Login_Password': 'Login Contraseña',
            'Enter_Old_Password': 'Por favor ingrese su contraseña de inicio de sesión original',
            'New_Password': 'Nueva contraseña',
            'Enter_Password': 'Por favor, introduce tu contraseña',
            'Repeat_Password': 'Repetir Contraseña',
            'Reenter_Password': 'Por favor, ingresa nuevamente tu contraseña',
            'Verification_Code': 'código de verificación',
            'Send_Code': 'Enviar Código de Verificación',
            'Submit': 'Enviar',
            'Custom_Template': 'Plantilla',
            'Popular_Searches': 'Introduce las palabras clave para buscar',
            'Homepage': 'Hogar',
            'FAQs': 'Preguntas frecuentes',
            'Service_Quality': 'Calidad del Servicio',
            'Service_Guarantee': 'Garantía de Servicio',
            'Sort_By_Comprehensive': 'Clasificación general',
            'Rebuy_Rate_30Days': 'Tasa de Repetición de Compra en los Últimos 30 Días',
            'Sort_By_Price': 'Ranking de precios',
            'Min': 'Mín',
            'Max': 'Máx',
            'Certified_Factory': 'Fábrica Certificada',
            'Experience_Score': 'Calificación general',
            'Five_Star': 'de 5 estrellas',
            'FourPointFive_Five_Stars': '4.5 estrellas - 5 estrellas',
            'Four_FourPointFive_Stars': '4 estrellas - 4.5 estrellas',
            'Below_Four_Stars': 'por debajo de 4 estrellas',
            'Pickup_Rate_24H': 'Tasa de envío de 24 horas',
            'Pickup_Rate_48H': 'Tasa de envío de 48 horas',
            'Same_Day_Shipping': 'Garantía de servicio',
            'Shipping_24H': 'Entrega en 24 horas',
            'Shipping_48H': 'Entrega en 48 horas',
            'Seven_Day_Return': '7-día devolución sin motivo',
            'Free_Shipping': 'Envío gratuito',
            'Reset': 'Restablecer',
            'Collapse': 'Ver menos',
            'More_Filters': 'Más opciones de filtrado',
            'Accepted': 'Aceptado',
            'Terms': '《Términos de búsqueda》',
            'Search_Service_Terms': 'Los productos buscados provienen todos de plataformas de terceros, y Tendabuy solo proporciona servicios como búsqueda de información, agente de compras y envío. Tendabuy no puede autenticar la calidad o autenticidad de los productos.',
            'Product_Info_Details': 'Acepte los "términos de búsqueda" para mostrar los resultados de búsqueda.',
            'Close_Page': 'Cerrar',
            'Merchant_Info': 'Comerciante',
            'Shipping_Fee': 'Flete Nacional',
            'Certified_Warehouse': 'Almacén Certificado de Tendabuy',
            'User_Message': 'Mensaje',
            'Sales_Volume_30Days': 'El volumen de ventas de este producto en los últimos 30 días. Algunos productos pueden tener errores. Estos datos son de una tercera parte y pueden usarse como referencia al comprar.',
            'Report_Reason': 'Motivo del Informe',
            'Infringement_Report': 'Informe de Infracción',
            'Report_Record': 'Registro de Informe',
            'Next_Step': 'Siguiente Paso',
            'Select_Report_Reason': 'Por favor, seleccione el motivo del informe',
            'Previous_Step': 'Paso Anterior',
            'Report_Description': 'Descripción del Informe',
            'Image_Evidence': 'Evidencia en Imagen',
            'Submit_Report': 'Enviar',
            'Select_Category': 'Por favor, seleccione una categoría',
            'Agree_And_Proceed': 'Por favor, lea y acepte antes de continuar',
            'TendaBuy_Warehouse': 'Almacén de TendaBuy',
            'Country_for_Shipping': 'Por favor, seleccione el país de destino, los agentes de Tendabuy sabrán si el producto puede ser enviado allí antes de comprar para usted.',
            'Shipping_Country': 'Seleccione País de Envío',
            'Order_Confirmation': 'Confirmar Pedido',
            'Store_Total': 'Total de la compra',
            'Payment_Total_Excl_SH_Fee': 'Total (envío internacional no incluido)',
            'Prohibited_Items': '《Declaración sobre artículos prohibidos》',
            'Service_Terms_Management': '《Términos de Servicios》',
            'Return_Exchange': '《Servicio de cambios y devoluciones》',
            'Disclaimer': '《Exención de responsabilidad》',
            'Read_and_Agree': 'He leido y acepto',
            'Shipping_Destination': 'Enviar a',
            'Sender_Information': 'Remitente',
            'Return_to_Cart_for_Modification': 'Regresar al carrito a modificar',
            'Product_Information_Confirmation': 'Confirmar la información del producto',
            'Subtotal': 'Subtotal',
            'Submit_Order': 'Enviar pedido',
            'Warehouse_Arrival_Notification': 'Por favor, tenga paciencia una vez que su pedido haya enviado y pagado. Una vez en stock, puede enviar un paquete internacional',
            'Payment_Due_By': 'Caja',
            'Checkout_Successful': 'Pedido enviado con éxito!',
            'Available_Payment_Methods': 'Por favor, pague dentro de las 72 horas, de lo contrario su pedido será cancelado automáticamente',
            'Current_Country': 'Seleccionar país',
            'Open_Payment_Channels': 'Métodos de pago',
            'Balance_Recharge_Needed': 'Su saldo es insuficiente. Por favor, recargue antes de realizar el pago',
            'Euro_Payment_Exchange': 'La moneda de liquidación para el pedido actual es el Euro. Se admite el pago en moneda local, y el banco de pago realizará el cambio de divisas según el tipo de cambio del banco',
            'Global_Currency_Support': 'Cubriendo 202 países o regiones, admitiendo 25 monedas. Se incurrirá en cambio de divisas y se cobrarán tarifas al pagar en monedas que no sean USD. Se aceptan tarjetas de débito y crédito de los bancos asociados locales.',
            'Total_Price': 'Precio Total',
            'Amount_Due': 'Importe Adeudado',
            'Handling_Fee': 'Cuota de Manejo',
            'Settlement_RMB_Only': 'La liquidación solo está disponible en moneda china (RMB)',
            'Purchase_Amount_Discount': 'Descuento por alcanzar una cierta cantidad de compra',
            'Negotiated_Price': 'Precio negociado con el comerciante',
            'Shipping_Fee_Discount': 'Descuento en la tarifa de envío',
            'Mobile_Exclusive_Price': 'Precio exclusivo para móviles',
            'Incorrect_Price_Displayed': 'Precio incorrecto mostrado en la página',
            'Others': 'Otros',
            'Login': 'o inicia sesión',
            'LOGIN_1': 'iniciar sesión',
            'Forgot_Password': '¿Olvidaste tu contraseña?',
            'Sign_Up': 'registrarse',
            'Email_Input': 'Por favor, introduce tu dirección de correo electrónico',
            'Password_Input': 'Por favor, ingresa tu contraseña',
            'Verification_Code_Input': 'Por favor, introduce el código de verificación',
            'TendaBuy_Reg_Agreement': '《Acuerdo de Registro de Usuario de TendaBuy》',
            'AND': 'y',
            'Privacy_Statement': '《Declaración de Privacidad》',
            'Account_Existence': 'Ya tienes una cuenta',
            'Password_Confirmation': 'Por favor, introduce la contraseña de nuevo',
            'Country_Selection': 'Por favor, seleccione un país',
            'Email_Address_Input': 'Correo electrónico (Por favor, proporcione una dirección de correo electrónico válida y utilizable)',
            'Invitation_Code_Optional': 'Por favor, introduzca el código de invitación (opcional)',
            'MY_PACKAGE': 'Paquetes',
            'PENDING': 'Pendiente',
            'PROCESSING': 'Procesando',
            'PROCESSED': 'Proceso terminado',
            'DAILY_GOODS': 'Artículos de uso diario',
            'SPECIAL_GOODS': 'Bienes especiales',
            'PAID': 'Pagado',
            'WAIT_PACK': 'Esperando el empaquetado',
            'PACKED': 'El paquete está empaquetado',
            'READY_SHIP': 'Listo para enviar',
            'PROVINCE': 'Provincia',
            'CITY': 'Ciudad',
            'ENTER_NAME': 'Por favor ingrese su nombre',
            'ENTER_AREA': 'Por favor ingrese la región',
            'ADDR_REQUIRED': 'La dirección no puede estar vacía',
            'ZIP_REQUIRED': 'El código postal no puede estar vacío',
            'PHONE_REQUIRED': 'El número de teléfono móvil no puede estar vacío',
            'PHONE_FMT_WRONG': 'El formato del número de teléfono móvil es incorrecto',
            'COUNTRY_ADDR_REQ': 'La dirección nacional no puede estar vacía',
            'FILES': 'Archivo',
            'PHOTO_TEMPLATE': 'Plantilla de foto',
            'ENTER_REMARK': 'Por favor, introduzca la descripción de la observación',
            'SINGLE_PHOTO_REQ': 'Solo se admite un requisito de foto, como: tomar una foto de cerca del logo, medir el tamaño de la pulsera, etc.',
            'PHOTO': 'Foto',
            'PHOTO_TIP': 'Recordatorio amistoso: Una foto solo proporciona un requisito de detalle para fotografiar un producto.',
            'FREE_PHOTOS_FEE': 'Fotos personalizadas gratuitas restantes este mes: 0. Tarifa de servicio:',
            'SERVICE': 'Servicio',
            'EDIT_SUCCESS': 'Modificación exitosa',
            'SHIPPING_FEE': 'Costo de transporte',
            'SHIP_TIME': 'Tiempo de transporte',
            'CHARGE_WEIGHT': 'Peso facturable',
            'FEATURES': 'Característica',
            'FEE_ITEM': 'Elemento de tarifa',
            'OPER_FEE': 'Tarifa de manejo',
            'SETTLE_STD': 'Estándar de liquidación',
            'TOTAL_FEE_DET': 'Detalles de las tarifas totales',
            'PAY_TOTAL': 'Monto total del pago',
            'MAIL_LIMIT': 'Restricciones de envío',
            'WEIGHT_LIMIT': 'Límite de peso',
            'MIN_LIMIT': 'Límite mínimo',
            'MAX_LENGTH_LIMIT': 'Límite de longitud máxima',
            'AFTERSALE_PERIOD': 'Período de consulta posventa',
            'COMP_BASIS': 'Base de compensación',
            'FUEL_SURCHARGE': 'Suplemento por combustible',
            'AFTERSALE_SUPPORT': 'Soporte para consultas posventa',
            'COMP_STD': 'Estándar de compensación',
            'COMP_PERIOD': 'Período de compensación',
            'NO_DATA': 'No hay datos disponibles',
            'ENTER_WEIGHT': 'Por favor, introduzca el peso',
            'NO_ROUTE_CFG': 'La ruta bajo esta configuración aún no está abierta',
            'PLEASE_SELECT': 'Categoría de Artículo',
            'HISTORY_SEARCH': 'Historial de búsqueda',
            'TRIGGER_WIDTH_IS': 'La anchura del desencadenante es',
            'IMG_COMPRESS_FAIL': 'La compresión de la imagen ha fallado',
            'NO_STORE_FOUND': 'No se encontró la tienda, por favor ingrese el enlace correcto de la tienda',
            'CREATE_NEW_TAG': 'Crear nueva pestaña',
            'DOMAIN_REQUIRED': 'El nombre de dominio no puede estar vacío',
            'ENTER_TAG_NAME': 'Por favor, introduzca el nombre de la etiqueta',
            'CART_EMPTY': '¡El carrito de compras está vacío, añade algunos productos ahora!',
            'SUCCESS': 'Éxito',
            'LIMIT_5_FILES': 'Actualmente limitado a seleccionar 5 archivos',
            'SHIPPING_NOW': 'Enviando',
            'AWAIT_CUSTOMS': 'A la espera de la inspección aduanera',
            'ENROUTE_INTL': 'En tránsito internacional',
            'ARRIVED_CNTRY': 'Llegó al país de destino',
            'DOMESTIC_TRNSP': 'Transporte doméstico en el país de destino',
            'DELIVERED': 'Entregado',
            'IMG_FMT_LIMIT': '¡Las imágenes cargadas solo pueden estar en formatos JPG, JPEG, gif, bmp, PNG!',
            'IMG_SIZE_10MB': '¡Las imágenes cargadas no deben exceder los 10MB de tamaño!',
            'PARAM_ERROR': 'Error de parámetro, por favor contacte al admin',
            'SYS_ERROR': 'Error del sistema, por favor contacte al admin',
            'ENTER_PHONE': 'Por favor, introduzca el número de teléfono correcto',
            'COPY': 'Copiar',
            'SET_DEFAULT': 'Establecer como predeterminado',
            'EDIT_ADDRESS': 'Modificar dirección',
            'PERMANENT_DELETE_ADDR': 'Esta operación eliminará permanentemente la dirección, ¿desea continuar?',
            'DEL_ADDR_CONF': 'Eliminar dirección de envío',
            'ADDR_DELETED': 'Eliminación cancelada',
            'V1_GROWTH_VAL': 'Solo -2- puntos de crecimiento para alcanzar V-1',
            'INT_FEE_DISCNT': 'Descuento en envío internacional',
            'INSURE_DIS_AMT': 'Monto de descuento de seguro',
            'VALUE_ADD_DISC': 'Descuento en servicios de valor agregado',
            'UPLOAD_DESPER': 'Subiendo intensamente',
            'SELECT_DATE': 'Seleccionar fecha',
            'IMG_FORMAT_PNG': '¡Las imágenes cargadas solo pueden estar en formato JPG/PNG!',
            'IMG_MAX_20MB': '¡Las imágenes cargadas no deben exceder los 20MB de tamaño!',
            'LIMIT_1_FILE': 'Actualmente limitado a seleccionar 1 archivo',
            'VIEW_RECORDS': 'Ver registros',
            'TOPUP_AMOUNT': 'Por favor, introduzca el monto de recarga',
            'BALANCE_TOPUP': 'Recarga de saldo',
            'MY_BILL': 'Mi factura',
            'SELLER_1': 'Vendedor',
            'COPY_ADDRESS': 'Copiar dirección',
            'ZHU_JIAXUAN': 'Zhu Jiaxuan',
            'PKG_NAME': 'Nombre del paquete',
            'COPY_SUCCESS': 'Copia exitosa',
            'COPY_FAIL': 'Copia fallida',
            'FUJIAN_ADDR': 'Fujian Sheng Quanzhou Shi Fengze Qu Qiyun Lu 152 Hao Huafeng Chuangyeyuan Sanlou Chu Dianti Youshoubian',
            'ADD_TRACK_NUM': 'Por favor, añada el número de seguimiento',
            'TRANSIT_ORDER': 'Orden de transferencia enviada con éxito',
            'SEARCH_TRACK': 'Buscar número de seguimiento',
            'SEARCH_KEYWORD': 'Palabra clave de búsqueda',
            'RESULT': 'Resultado',
            'READ': 'Leído',
            'SELECT_DATA': 'Por favor, seleccione datos',
            'PKG_DETAILS': 'Detalles del paquete',
            'RECV_INFO': 'Información de recepción',
            'DECLARE_NAME': 'Nombre declarado',
            'CURRENCY_1': 'Moneda',
            'DECLARED_PRICE': 'Precio unitario declarado',
            'DECLARED_QTY': 'Cantidad declarada',
            'TOTAL_DECLARED_VALUE': 'Valor total declarado',
            'TOTAL': 'Total',
            'TOTAL_VALUE': 'Valor total',
            'DECLARATION_INFO': 'Información de declaración (autodeclaración)',
            'LOGISTICS_INFO': 'Información logística',
            'PACKAGE_ID': 'Número de paquete',
            'DETAILS': 'Detalles',
            'SHIPPED': 'Enviado',
            'CHOOSE_RECEIVING_ADDR': 'Seleccionar dirección de entrega',
            'CHOOSE_LOGISTICS_ROUTE': 'Elegir ruta logística',
            'AUTH_DEDUCT_DIFF': 'Autorizar la deducción de la diferencia',
            'READ_AGREE_DISCLAIMER': 'He leído y estoy de acuerdo con el "Descargo de responsabilidad de Tendabuy"',
            'ADDRESS': 'Dirección',
            'SELECT': 'Seleccionar',
            'CHOOSE_SERVICE_ROUTE': 'Elegir servicio de paquete y ruta',
            'MAIL_LIMIT_1': 'Restricciones postales',
            'VOLUME': 'Volumen',
            'ADD_SERVICE': 'Añadir servicio',
            'PRODUCT_PRICE': 'Precio del producto',
            'CHOOSE_PACKAGE_SERVICE': 'Por favor, seleccione un servicio de paquetería',
            'DECLARATION_ADVICE': 'Dado que cada ruta logística tiene restricciones en los nombres de los artículos declarados y las cantidades, por favor seleccione y complete de acuerdo a las indicaciones del sistema. Si tiene alguna pregunta sobre la declaración, puede consultar al servicio de atención al cliente en línea.',
            'DECL_VAL_UPPER_LIMIT': 'Límite superior del valor total declarado',
            'DECL_VAL_LOWER_LIMIT': 'Límite inferior del valor total declarado',
            'DECL_ITEM_UPPER_LIMIT': 'Límite superior del ítem declarado',
            'DECL_ITEM_LOWER_LIMIT': 'Límite inferior del ítem declarado',
            'ADD_DECLARATION_ITEM': 'Añadir ítem declarado',
            'ENTER': 'Por favor ingrese',
            'TOTAL_QUANTITY': 'Cantidad total',
            'BUY_INSURANCE': 'Comprar valor asegurado',
            'INSURANCE_NOTICE': 'Notas del seguro',
            'INSURED_AMOUNT': 'Monto asegurado',
            'PREMIUM': 'Prima',
            'SERVICE_FEE': 'Tarifa de servicio',
            'CHECK_URL_OR_HOME': 'Por favor, compruebe si la URL que ha introducido es correcta, o haga clic en el botón de abajo para volver a la página principal.',
            'GO_HOME': 'Volver a la página de inicio',
            'PAGE_NOT_FOUND': 'Página no encontrada...',
            'BANNED_RESTRICTED_ITEMS': '[Artículos prohibidos y restringidos]',
            'TRACK_LOGISTICS': 'Rastrear la logística',
            'PRODUCT_ISSUE': 'Anomalía del producto',
            'PACKAGE_LOST': 'Paquete perdido',
            'REQUIRED_FIELD': '(*Campo obligatorio)',
            'UPLOAD_LIMIT_5_2MB': 'Se pueden subir un máximo de 5 imágenes, el tamaño de cada imagen está limitado a 2 MB como máximo (se admiten formatos  jpg, png).',
            'MIN_PRICE': 'Precio más bajo',
            'MAX_PRICE': 'Precio más alto',
            'SHIP_TO_TENDABUY': 'Por favor, enviar al almacén de Tendabuy; tarifa de servicio CNY 10 por pedido',
            'ENTER_MAX_PRICE': 'Por favor, introduzca el precio unitario máximo',
            'MAX_GT_MIN_PRICE': 'El precio unitario máximo no puede ser inferior al precio unitario mínimo',
            'ENTER_MIN_PRICE': 'Por favor, introduzca el precio unitario mínimo',
            'MIN_LT_MAX_PRICE': 'El precio unitario mínimo no puede ser superior al precio unitario máximo',
            'AVATAR_JPG_ONLY': '¡Las fotos de perfil subidas solo pueden estar en formato JPG!',
            'ADD_SUCCESS': 'Añadido con éxito',
            'HAVE_ACCOUNT': 'Ya tengo una cuenta,',
            'BACK_TO_TENDABUY': 'Volver a TENDABUY',
            'ACTIVATE_ACCOUNT': 'Activar cuenta',
            'EMAIL_ACTIVATED': 'Activación de correo electrónico exitosa',
            'PASSWORD_REQUIREMENT': '6-20 caracteres, debe incluir letra y número.',
            'PASSWORDS_NOT_MATCH': '¡Las dos entradas de contraseña no coinciden!',
            'VALID_EMAIL_REQUIRED': 'Por favor, introduzca la dirección de correo electrónico correcta',
            'CODE_REQUEST_FAST': 'Solicitud de código de verificación demasiado frecuente',
            'LOGIN_SUCCESS': 'Inicio de sesión exitoso',
            'PARTNER_LOW_COST': 'Unirse a muchas empresas de mensajería internacional, descuentos super bajos en envíos',
            'LENGTH': 'Longitud',
            'WIDTH': 'Ancho',
            'HEIGHT': 'Altura',
            'SEARCH': 'Consulta',
            'THIRD_PARTY_SHIPPING': 'Todos los servicios de logística para rutas no operadas por TendaBuy son proporcionados por proveedores de logística de terceros, y ajustarán el precio del envío de acuerdo con las condiciones del mercado. TendaBuy le notificará con anticipación si hay un cambio de precio. Para proporcionar una experiencia de servicio definitiva, si encuentra algún problema con los servicios de logística, TendaBuy puede negociar y comunicarse con proveedores de logística de terceros en su nombre.',
            'LOGISTICS_NOTICE': 'Explicación del servicio logístico',
            'QUERY_SHIPPING': '¡Por favor, introduzca los parámetros correspondientes para consultar la tarifa de envío!',
            'TO_TENDABUY_WAREHOUSE': 'De Tendabuy a',
            'SEARCHING': 'Buscando',
            'FOUND_PRODUCTS': 'encontró los siguientes productos para ti',
            'COMPREHENSIVE': 'Integral',
            'NO_PRODUCTS': 'No hay productos disponibles',
            'LOADING': 'Cargando...',
            'NO_MORE': 'No hay más...',
            'FINAL_PRICE': 'Por favor, rellene el precio final',
            'COUPON_RULES_EFFECT': 'Debido a las reglas de los cupones de compra, los descuentos de cupones o las reducciones totales pueden no estar disponibles, se recomienda que el precio que modifique coincida con el precio de venta real del vendedor.',
            'PRICE_CHANGE_ADVICE': 'Por favor, seleccione una razón para cambiar el precio',
            'PRODUCT_RISK_NOTICE': 'Recordatorio de riesgo del producto',
            'PRODUCT_RISK_ALERT': 'El producto que ha enviado puede tener -1- riesgo(s), se recomienda que lea cuidadosamente sobre el/los riesgo(s) antes de considerar si comprarlo~',
            'BRAND_PRODUCT': 'Producto de marca',
            'BRAND_AUTHENTICITY': 'Este producto es un producto de la marca -1-, por favor preste atención a la autenticidad',
            'UNDERSTOOD': 'Entiendo',
            'REPORT_DESC': 'Por favor, ingrese una descripción del informe',
            'MINIMUM_QUANTITY_ERROR': 'La cantidad de productos no puede ser menor que la cantidad mínima de lote',
            'FREE': 'Gratis',
            'CANCEL_SUCCESS': 'Producto cancelado con éxito',
            'CANCEL_FAIL': 'Falló la cancelación del producto',
            'SAVE_SUCCESS': 'Producto agregado a favoritos con éxito',
            'SAVE_FAIL': 'Falló al agregar producto a favoritos',
            'PASSWORD': 'Contraseña',
            'OR_CONTINUE': 'O continuar',
            'BACK_TO_PASSWORD_LOGIN': 'Volver al inicio de sesión con contraseña',
            'OPEN_APP_HOME': 'Abrir la página principal de la APP de Tendabuy',
            'CONFIRM_PASSWORD': 'Confirmar contraseña',
            'YOUR_EMAIL': 'Su correo electrónico',
            'EMAIL_INACTIVE': 'No activado, para continuar usando por favor haga clic en el botón de abajo para reenviar el correo electrónico de activación',
            'RESEND_ACTIVATION': 'Reenviar correo electrónico de activación',
            'CHECK_ACTIVATION_EMAIL': 'Por favor, vaya a su correo electrónico para ver el correo electrónico de activación y verificar su cuenta.',
            'NEW_LOCATION_LOGIN': 'Su cuenta está siendo accedida desde una nueva ubicación, por favor introduzca el código de verificación del correo electrónico para continuar.',
            'OPEN_LINK_ACTIVATE': 'Por favor, revise el correo y abra el enlace para activar su cuenta de Tendabuy',
            'REGISTER_SUCCESS': 'Registro exitoso',
            'WINDOW_FAIL': 'Falló la apertura de la ventana',
            'LOGIN_SUCCESSFUL': 'Inicio de sesión exitoso',
            'ACCOUNT_NOT_ACTIVE': 'Cuenta no activada',
            'READ_AGREE': 'Por favor, lea y acepte las instrucciones',
            'ORDER_IDENTIFICATION': 'Identificación del pedido',
            'DESCRIPTION_TEXT': 'Texto de descripción',
            'CUSTOM_PHOTO_SERV': 'La fotografía personalizada es un servicio con valor agregado de Tendabuy, con un costo de 1 CNY por foto. Permite tomar fotos de productos desde ángulos específicos según los requisitos personalizados, como solicitar la medición de la longitud de la plantilla, necesitar ver detalles del logo de una prenda de vestir o pedir las medidas del pecho de una sudadera',
            'REMAINING_FREE_PHOTO': 'Fotografía personalizada gratuita restante para este mes -1- fotos Tarifa de servicio:',
            'PHOTO_QUALITY_PROMISE': 'Fotografía personalizada - Compromiso de calidad',
            'TOTAL_PRODUCT_PRICE': 'Precio total de los productos',
            'VALUE_ADDED_FEE': 'Tarifa de servicio de valor añadido',
            'EDIT_REMARK': 'Modificar la información del comentario',
            'SEND_1': 'Enviar',
            'CANCEL_PAYMENT': 'Cancelar el pago',
            'CANCEL_PAYMENT_1': 'Cancelar pago?',
            'TRANSIT_SELF_SERVICE': 'Añadir un pedido de forwarding',
            'SERVICE_1': '[Servicio]',
            'FILE_SELECT': 'Seleccionar archivo',
            'HISTORY_MESSAGES': 'Mensajes históricos',
            'COLLAPSE_MESSAGES': 'Colapsar mensajes',
            'EDIT_LABEL': 'Editar etiqueta',
            'ENTER_LABEL_COLOR': 'Por favor, introduzca el color de la etiqueta',
            'MODIFY_LABEL': 'Modificar etiqueta',
            'DELETE_LABEL': 'Eliminar etiqueta',
            'CANCEL_EDIT': 'Cancelar edición',
            'SELF_CHECK_REMINDER': 'Recordatorio de autoverificación',
            'ORDER_REMINDER': 'Recordatorio de pedido',
            'SERVICE_2': 'Spanish: Servicio',
            'UPLOAD_FAIL': 'Fallo en la carga del archivo',
            'JPG_PNG_500KB_LIMIT': 'Solo se pueden subir archivos jpg/png, y no deben exceder los 500kb',
            'DELETE_LABEL_CONFIRM': '¿Confirmar la eliminación permanente de la etiqueta?',
            'WARNING': 'Advertencia',
            'CHOOSE_PRODUCT': 'Por favor, seleccione un producto',
            'CHOOSE_COLOR': 'Por favor, seleccione un color de etiqueta',
            'ADD_SERVICE_1': 'Añadir servicio',
            'STRENGTHEN_FRAME': 'Pie fijo+marco',
            'CALC_WEIGHT': 'Calcular peso (g)',
            'PACKAGE_DECLARATION_ALERT': 'Recordatorio de declaración de paquete',
            'NEW_DECLARATION_ITEM': 'Añadir nuevo ítem de declaración',
            'INSURANCE_PURCHASE': 'Comprar seguro',
            'INSURANCE_APPLICATION': 'Solicitud de seguro',
            'INSURANCE_SUGGESTION': 'Sugerencia: Monto asegurado = Valor total de los productos + Costo de envío',
            'SELECT_SERVICE': 'Seleccionar servicio',
            'BOX_STRENGTHENING': 'Embalaje de caja con pie',
            'ASSOCIATED_EMAIL': 'Por favor, ingrese el correo electrónico vinculado a su cuenta',
            'ENTER_ASSOCIATED_EMAIL': 'Por favor, ingrese su correo electrónico vinculado',
            'SENT_EMAIL_SUCCESS': 'Correo electrónico enviado con éxito, por favor revise su correo electrónico de inmediato',
            'PAYMENT_CANCEL': 'Pago cancelado',
            'CLOSE_1': 'Cerrar',
            'SHIPPING_PAYPAL': 'Por favor asegúrese de que la dirección de envío real de los productos coincida con la dirección de recepción de PayPal, de lo contrario la transacción será revisada, afectando el tiempo de procesamiento',
            'PAYMENT_TIP': 'Consejos útiles: Por favor, abra una nueva página para la operación de pago. El pago no está completo, por favor, no cierre esta ventana',
            'COMPLETED_PAYMENT': "Si ha pagado con éxito, por favor haga clic en 'Pago exitoso",
            'PAYMENT_PROBLEM': 'Si encuentra algún problema con el pago, puede intentar pagar de nuevo o hacer clic para dar su opinión',
            'PAYMENT_FAIL_FEEDBACK': 'Retroalimentación de fallo de pago',
            'PAYMENT_SUCCESS': 'Pagado',
            'PAYMENT_FAIL': 'El pago ha fallado',
            'ORDER_PROCESS_6_HOURS': 'Su pedido será procesado dentro de las 6 horas laborables (excepto en algunas plataformas de terceros)',
            'ATTENTION_ITEMS': 'Tenga en cuenta lo siguiente:',
            'PACKAGE_SUBMIT_SELF': 'Los bienes no serán enviados a usted; necesita enviar el paquete por sí mismo.',
            'AFTER_STORAGE_SUBMIT': 'Después de que los bienes estén almacenados, puede seleccionar los bienes en "Centro de Usuarios - Mi Almacén", enviar el paquete y elegir la logística para pagar el flete internacional.',
            'WATCH_INTERNAL_INFO': 'Siga la información dentro del sitio',
            'COMMUNICATE_ISSUES': 'Durante el procesamiento del pedido, si hay algún problema, nos comunicaremos y consultaremos con usted a través de mensajes en el sitio de manera oportuna.',
            'CONTINUE_SHOPPING': 'Continuar comprando',
            'VIEW_ORDER': 'Verificar pedidos',
            'RECHARGE_NOT_EMPTY': 'La cantidad de recarga no puede estar vacía',
            'SIMULATE_PACKAGE': 'Envío de Ensayo',
            'MY_PARCEL': 'Mi paquete',
            'TENDABUY_AUDIT_7DAY': 'Tendabuy revisará en un plazo de 7 días laborables, gracias por su retroalimentación',
            'REPORT_ID': 'Número de reporte',
            'REPORT_TIME': 'Tiempo de reporte',
            'REPORT_TYPE': 'Tipo de reporte',
            'REPORT_PRODUCT': 'Reportar producto',
            'REPORT_PROGRESS': 'Progreso del reporte',
            'STORE_FAVORITES': 'Tiendas favoritas',
            'SORT': 'Ordenar',
            'PRICE': 'Precio',
            'REORDER_RATE': 'Tasa de reposición',
            'FAVORITE_SUCCESS': 'Añadido a favoritos con éxito',
            'UNFAVORITE_SUCCESS': 'Eliminado de favoritos con éxito',
            'EXTEND_STORAGE': 'Extender el almacenamiento',
            'STORAGE_EXTRA_DAYS': 'El tiempo de almacenamiento de sus productos en el almacén se extenderá por (30*N) días, la fecha de vencimiento es',
            'CANCEL': 'Cancelar',
            'CONFIRM': 'Confirmar',
            'MONTHS': 'Meses',
            'SERVICES': 'Servicio',
            'SELECT_STORAGE_TIME': 'Por favor, seleccione el período de almacenamiento',
            'ENTER_PRODUCT_LINK': 'Por favor, introduzca el enlace del producto',
            'ENTER_PRODUCT_NAME': 'Por favor, introduzca el nombre del producto',
            'ENTER_PRODUCT_SPEC': 'Por favor, introduzca las especificaciones del producto',
            'IMAGE_LIMIT_2MB': 'El tamaño de una sola imagen está limitado a 2MB (soporta formatos jpg, png)',
            'PRICE_UNKNOWN_INPUT': 'Tendabuy no puede discernir el precio de los productos de plataformas de terceros, necesita ingresar el precio a continuación. Si también está claro sobre el precio del producto, puede hacer un pedido por 1 yuan. Después de que el comprador de Tendabuy reciba el pedido, se pondrá en contacto con el vendedor, y luego iniciará una solicitud de pago adicional del producto a usted.',
            'SELLER_SHIP_FEE': 'Por favor, rellene el costo de envío del vendedor al almacén de Tendabuy',
            'TOTAL_AMOUNT_RMB': 'Monto total = Costo del producto + Envío al almacén Estándar de conversión (basado en RMB)',
            'ENTER_UNIT_PRICE': 'Por favor, introduzca el precio unitario',
            'PRODUCT_NAME_100CHAR': 'Por favor, introduzca el nombre del producto (limitado a 100 caracteres)',
            'SELLER_FEE_TENDABUY': 'Por favor, rellene el costo de envío del vendedor al almacén de Tendabuy',
            'HTTP_LINK_REQUIRED': 'Por favor, introduzca el enlace del producto, comenzando con http:// o https://',
            'SPECIFY_COLOR_SIZE': 'Para comprar el producto más precisamente para usted, por favor deje aquí la información del color/tamaño y otras especificaciones',
            'CLICK_COUNT': 'Conteo de clics',
            'RECORD': 'Registro',
            'VIEW': 'Vista',
            'SIGNED': 'Recibido',
            'PAID1': 'Pagado',
            'ITEM_MISSING': 'No puedo encontrar este artículo',
            'SECURITY_SET_2FA': 'Su nivel de seguridad es "-1-" Por favor, establezca la verificación de dos pasos para asegurar su cuenta',
            'SECURITY_LOW': 'Su nivel de seguridad es "-1-"',
            'CHANGE_PWD': 'Cambiar contraseña de pago',
            'OLD_PWD': 'Contraseña de pago',
            'PWD_MIN_6': 'La contraseña debe tener al menos 6 caracteres',
            'ENTER_OLD_PWD': 'Contraseña inicial',
            'LOGIN_PWD_OK': 'Contraseña de inicio de sesión cambiada con éxito',
            'PAY_PWD_OK': 'Contraseña de pago cambiada con éxito',
            'VALUE_ADD': 'Servicios de valor',
            'ORDER_AMOUNT': 'Monto del pedido',
            'REAUTH': '',
            'ITEMS_QTY': 'Menge',
            'PARCEL_INFO': 'Información de paquetes',
            'EST_SHIPPING': 'Calculadora de envíos',
            'EST_WEIGHT': 'Peso estimado del paquete',
            'EST_VOLUME': 'Volumen estimado del paquete',
            'ACT_SHIPPING': 'Tarifa de envío real',
            'ACT_WEIGHT': 'Peso real del envío',
            'ACT_VOLUME': 'Volumen real del envío',
            'RECIPIENT': 'Destinatario',
            'CUBIC_CM': 'cm³',
            'TOTAL_WEIGHT': 'Peso total',
            'TOTAL_VOLUME': 'Volumen total',
            'LOG_ROUTE': 'ruta logística',
            'TRACK_NO': 'Versandnummer',
            'LOG_STATUS': 'estado de seguimiento',
            'ACT_AMOUNT': 'Cantidad final',
            'SELF_DECLAR': 'Declarar por mi cuenta',
            'PARCEL_SERVICE': 'Servicio de paquetería',
            'ORDER_SERVICE': 'Servicios de pedido',
            'AGREE_TNC': '',
            'DECLAR_EXCEED': 'Demasiados artículos declarados',
            'FILL_ITEMS': 'Por favor, declare sus artículos',
            'SELECT_ROUTE': 'Por favor, seleccione la ruta logística',
            'PRE_FEE': 'Tarifa de operación de preempaque',
            'IMG_2MB': 'Solo imágenes de menos de 2 MB',
            'MODIFY_PRICE': 'Editar precio',
            'STOCK': 'En stock',
            '待删除': '',
            'ACTIVATE_EMAIL': 'Activar',
            'EMAIL_SENT': 'Código de verificación de correo electrónico enviado con éxito',
            'TEMPLATE': 'Plantilla',
            'PENDING_CON': 'Pendiente de Confirmación',
            'WEIGHT_MAX': 'Límite de peso superior',
            'WEIGHT_MIN': 'Límite de peso inferior',
            'AFTER_SALES_DE': 'Descripción del servicio postventa',
            '与上面重复': 'Seguridad de la cuenta',
            'INVALID_PARAMS': 'Error de parámetro',
            'PWD_RESET_SU': 'Restablecimiento de contraseña exitoso',
            'PAY_BE_TIMEOUT': '',
            'CON_PAYMENT': 'Confirmar Pago',
            'PAY_ISSUE': 'Algo salió mal con el pago',
            'RE_DETAILS': 'Detalles de la denuncia',
            'LOGISTICS_LABEL': 'Esta etiqueta es utilizada por el sistema Tendabuy para identificar restricciones logísticas y no se adjuntará a los productos o al embalaje',
            'NEWBIE_GUIDE': 'Guía para nuevos usuarios',
            'MEMBER_GIFT': 'Cupón de Nuevo Miembro',
            '删掉': '',
            'TUTORIAL': 'Anleitung',
            'SERVICE_FEES': 'Servicio & Tarifas',
            'RETURNS_REFUNDS': 'Devoluciones & Reembolsos',
            'CUSTOMER_SERVICE': 'Servicio al Cliente',
            'PAYMENT_DEPOSIT': 'Pago y depósito',
            'STORAGE1': 'Almacenamiento',
            'INSPECTION_RULES': 'Reglas de Inspección',
            'INSURANCE_COM': 'Seguro y Compensación',
            'DELIVERY': 'Entrega',
            'CHARGES': 'Cargos',
            'PACKAGING': 'Embalaje',
            'CUSTOMS_TAX': 'Aduanas & Impuestos',
            'RECEIPT_INFORMATION': '',
            'LOGISTICS_TRACKING': 'Seguimiento Logístico',
            'ABOUT_US': 'Acerca de nosotros',
            'TENDABUY': 'Tendabuy',
            'TERMS_OF_USE': 'Términos de uso',
            'PRIVACY_SECURITY': 'Privacidad & Seguridad',
            'LEGAL_NOTICE': 'Aviso Legal',
            'CONTACT_US': 'Contáctenos',
            'TRACK_INFO': '¿Cómo puedo rastrear la información de envío de mi paquete？',
            'DELIVERY_AWARENESS': '¿Qué debo tener en cuenta cuando se entrega mi paquete？',
            'DAMAGED_ITEM': '¿Qué debo hacer si un artículo frágil está dañado？',
            'PROBLEMATIC_PRODUCT': '¿Qué debo hacer si el producto de un agente de compras es problemático al recibirlo？',
            'WAREHOUSE_PROBLEM': '¿Qué debo hacer si encuentro un problema con el producto después de que llegue a nuestro almacén de Tendabuy？',
            '上面已经有了': '',
            'MULTIPLE_CHINA': 'Plataformas de Compras Múltiples de China',
            'COMPETITIVE_ITEMS': 'Ofrecer precios competitivos en millones de artículos en línea y fuera de línea',
            'COMBINE_DELIVER': 'Combinar artículos de varios vendedores en un paquete y entregártelo',
            'COVERING_SAFE': 'Cobertura en más de 200 países en todo el mundo, seguro y confiable',
            'PURCHASE_TENDABUY': 'Compra a través de Tendabuy',
            'CHECK_IN': 'Inspección de Calidad y Almacenamiento',
            'SEND_PKG': 'Envío Internacional Fácil',
            'VIEW_MORE': 'Ver Más',
            'MORE_NEWS': 'Más Noticias',
            '购物车支付界面': 'Destino',
            'GOODS_INTER_CONTENT': 'Escriba solo si tiene requisitos especiales para este pedido',
            '收藏夹页面': 'Pegue el nombre del producto',
            'OFF_500': '50 de descuento en pedidos superiores a ￥500',
            'COUPONS': 'Cupones',
            'NO-THRESHOLD_COUPON': 'Cupón de ￥20 sin umbral míni',
            'OVER_500': 'Más de ￥500',
            '20%_OFF': '20% de descuento',
            'APPLICABLE_COUNTRY': 'País aplicable',
            'APPLICABLE_LINE': 'Línea aplicable',
            'ALL_COUNTRIES': 'Todos los países',
            'USAGE_RULES': 'Reglas de uso',
            'USE': 'Uso',
            'CONVERT': 'Convertir',
            'ENTER_REDEEM': 'Por favor, ingrese el código de canje',
            'AVAILABLE_COUPON': 'Cupón Disponible',
            'USED_COUPONS0': 'Cupón Usados',
            'EXPIRED_COUPON': 'Cupón Caducado',
            'ANY_COUPONS': 'Aún no tienes cupones',
            'DO_YOU_DELETE': '¿Confirma que desea eliminar',
            'COLLECTED_PRODUCTS': 'Aún no has recopilado ningún producto, ve a recopilarlos',
            'COLLECTED_SHOP': 'Aún no has recopilado ninguna tienda, ve a recopilarlas',
            'ORDER_DETAIL': 'Detalles del pedido',
            'PACKAGE_NUMBER': 'Num. Ensayo',
            'SUBMIT_TIME': 'Tiempo',
            'VALUE_ADD_SERVICE_FEE': 'Tarifa de servicio de valor añadido',
            '前面有': 'Monto del pedido',
            'GOODS_QUANTITY': 'Cantidad de Mercancías',
            'PARCEL_INFO2': 'Info del Paquete',
            'ESTIMATED_FREIGHT': 'Flete Estimado',
            'ESTIMATED_WEIGHT': 'Peso Estimado',
            'ESTIMATED_VOLUME': 'Volumen Estimado',
            'FINAL_FREIGHT': 'Flete Final',
            'EXACT_WEIGHT': 'Peso Exacto',
            'EXACT_VOLUME': 'Volumen Exacto',
            'DETAILED_COSTS': 'Costos Detallados',
            'PRE-PACKAGING_OPERATION_FEE': 'Tarifa de operación de preenvasado',
            'RECIPIENTS_INFO': 'Información del destinatario',
            'RECIPIENT2': 'recipiente',
            'MOBILE_PHONE': 'Teléfono móvil',
            'SHIPPING_ADDRESS2': 'Dirección de envío',
            'SHIPPING_LINE': 'Línea naviera',
            'SHIPPING_ID': 'ID de envío',
            'SHIPPING_STATUS': 'Estado del envío',
            'SHIPPING_INFO': 'Info de envío',
            'STATUS1': 'Estado',
            'PARCEL_PAGE': 'Después de que el pedido del paquete se haya pagado con éxito, el pedido pasará a la página "Paquete" y pronto organizaremos los envíos.',
            'SELECT_LOGISTICS': 'Por favor, seleccione la ruta logística',
            'NO_SELECTED': 'No se ha seleccionado ninguna ruta',
            'INTENTIONAL_ROUTE': 'Ruta intencionada',
            'SELECT_ROUTE1': 'Seleccionar logística',
            'SUCCESSFULLY_CANCELED': 'Cancelado con éxito',
            'TOTAL0': 'Total',
            'ENQUIRY_PAGE': 'Bienvenido a la página de "Consulta", no hay consultas en este momento.\nPuede hacer clic en el botón de "servicio al cliente" a la derecha para dejarnos un mensaje en cualquier momento.',
            'UNDER_2MB': '¡Solo imágenes menores de 2MB!',
            'TRACK_PARCEL': '¿Cómo rastreo mi paquete?',
            'NOTICES_RECEIVING': 'Notificaciones de recepción del paquete',
            'PRODUCTS_DAMAGED': '¿Qué pasa si los productos frágiles llegan dañados?',
            'ISSUE_PRODUCT': '¿Qué hago si hay un problema con el producto después de haberlo recibido?',
            'APPLY_AFTERSALES': '¿Cómo solicitar el servicio posventa?',
            'LEAVE_INFO': 'Dejar descripción y especificaciones del producto.',
            'AMOUNT_LESS': 'El monto no puede ser menos de 0',
            'AMOUNT_MORE': 'El monto no puede ser menos de 0',
            'COMMON_BRAND': 'Marca común',
            'INTERNATIONAL_BRAND': 'Marca internacional',
            'COMMON_BATTERY': 'Marca común (con batería)',
            'COMMON_WITHOUT_BATT': 'Marca común (sin batería)',
            'REMOVE_ORDER': 'Eliminar pedido',
            'COMBINE_PAYMENT': 'Combinar pago',
            'GO_PLACE': 'Ir a realizar un pedido',
            'PLACE_AN_ORDER': 'Todavía no tienes ningún pedido, ve a realizar un pedido.',
            'ORDERS_UNPAID': 'Por favor, compruebe si todos los pedidos no están pagados',
            'RESET_SUCCESSFUL': 'Restablecimiento de contraseña exitoso',
            'WRONG_PAYMENT': 'Algo anda mal con el pago',
            'WITHDRAWAL_AMOUNT': 'Cantidad de retiro',
            'MODIFY_REMARK': 'Modificar observación',
            'SELECT_DESTINATION': 'Por favor seleccione su destino',
            'LESS_THAN': 'El monto no puede ser menos de 0',
            'LESS_THAN2': 'El monto no puede ser menos de 0',
            'PARCEL_NO': 'Num. Paquete',
            'ORDER_SERVER_FEE': 'Servicio',
            'NO_THRESHOLD': 'Sin umbral',
            'UP_20OFF': 'Hasta ¥20,00 de descuento',
            '50_OFF': '50% off，Hasta ¥20,00 de descuento',
            'ALL_LINES': 'Envío Internacional - Todas las líneas',
            'IN_PACKING': 'Almacenado',
            'CONFIRM_REFUND': '¿Confirmar la eliminación del pedido? El producto no será devuelto al vendedor para obtener un reembolso.',
            'REMINDER': 'Recordatorio',
            'QUALITY_PHOTOS': 'Foto de inspección',
            'ENTER_PARCEL': 'Note el nombre',
            'SHIPPING_COMPANY': 'Empresa de envío',
            'SHIPPING_EXPRESS_NO': 'ID de envío es ',
            'NO_STATUS': 'No hay estado de envío para 1111 en este momento.',
            'REFUND': 'Reembolso',
            'ORDER_ID': 'ID de pedido',
            'REQUEST_REFUND': 'Solicitar un reembolso',
            'REASON': 'Razón de la solicitud',
            'SELECT_REASON': 'Por favor seleccione su razón',
            'DESCRIPTION': 'Descripción',
            'IN_DESCRIPTION': 'Por favor ingrese una descripción',
            'REFUNDED': 'Pedido reembolsado con éxito',
            'REVIEWS': 'Reseñas',
            'REDEMPTION': 'Cupón canjeado con éxito',
            'BLOG': 'Blog',
            'COMPLETED': 'Completado',
            'AFTERSALES': 'En Posventa',
            'REFUNDED2': 'Reembolsado',
            'NO_LONGER': 'Ya no deseo el pedido',
            'PRICE_CHANGE': 'Quiero un reembolso debido a cambios en el precio',
            'OUT_OF_STOCK': 'Reembolso debido a falta de stock',
            'WRONG_SIZE': 'Pedido de Talla/Color Incorrecto',
            'LATE_DELIVERY': 'Entrega tardía',
            'QUALITY_ISSUES': 'Problemas de calidad del producto',
            'NOT_SAME': 'Insatisfacción con el producto',
            'INCORRECT': 'Artículo incorrecto recibido',
            'YES': 'Sí',
            'NO': 'Sin',
            'BUY_SHIP': 'Compramos y enviamos cualquier artículo chino',
            'SHOP_CHINA': 'Tienda China',
            'CHOOSE_TENDABUY': 'Elija Tendabuy',
            'NOT_FOUND': 'Artículo no encontrado',
            'PARTNERS': 'Socios',
            'VALUE_MONEY': 'Relación calidad-precio',
            'ADDED_SERVICE': 'Servicio de valor añadido',
            'LOGISTICS': 'Proveedor de logística',
            'DESTINATION': 'Por favor seleccione su destino',
            'REFUND_RETURN': '1. Su solicitud de reembolso/devolución será aceptada por el agente de compras dentro de las 24 horas y se comunicará con el vendedor para negociar el procesamiento. (Por favor, elija el motivo según la situación real. Si elige un motivo que no coincide con el motivo real, prevalecerá la verificación final del comerciante.)',
            'RETURN_TIME': '2. Si una devolución es posible y el tiempo de procesamiento del reembolso depende del vendedor, aproximadamente 7-15 días hábiles.',
            'CLOSE_SERVICE': '3. el vendedor rechaza "no hay motivo de devolución" si la mercancía está en stock más de 5 días, y el vendedor cierra el portal de posventa si la mercancía está en stock más de 10 días, por lo que no es posible presentar una solicitud de devolución.',
            'APPLYING_RETURN': '4. Antes de solicitar una devolución, por favor lea detenidamente → Haga clic aquí',
            'REFUND_POLICY': '《Política de Reembolso/Devolución》',
            'NOT_LOGGED': 'No has iniciado sesión',
            'REPORT': 'Por favor, ingrese una descripción del informe',
            'STOCK_0': 'Este artículo está en stock 0 y no se puede pedir',
            'OK': 'Ok',
            'REMIND': 'No volver a recordarlo hoy',
            'READ_ACCEPT': 'He leído y acepto el contenido anterior',
            'FORWARDING_SERVICE': 'Reenvío significa que sus propias compras en China o las mercancías que le envíen sus familiares o amigos deben ser enviadas a nuestro almacén y luego enviadas al extranjero utilizando cómodos servicios de logística internacional.',
            'TRACKING': 'Compruebe que el número de seguimiento enviado es correcto.',
            'TRANSFER': 'La orden de transferencia se ha añadido correctamente',
            'LENGHTH': 'Longitud exacta',
            'PRE_PACKAGE': 'Preempaque No',
            'EXACT_WIDTH': 'Ancho exacto',
            'EXACT_HEIGHT': 'Altura exacta',
            'CANCEL_REASON': 'Por favor, ingrese el motivo de la cancelación del pago.',
            'CANCELLATION': 'Los pedidos no se pueden restaurar después de la cancelación, por favor seleccione el motivo de la cancelación del pago.',
            'REFUND_REASON': 'Por favor seleccione el motivo del reembolso.',
            'NO_ORDER': 'Ya no quiero el pedido.',
            'INFO_WRONG': 'Información ingresada incorrectamente.',
            'OUT': 'El vendedor no tiene stock.',
            'UNABLE': 'No se puede realizar el pago.',
            'PAYMENT_PENDING_TIP_1': 'El pedido ha sido enviado y está esperando\nsu pago.',
            'PAYMENT_PENDING_TIP_2': 'Por favor, complete el pago dentro de las 72 horas posteriores a la generación del pedido, de lo contrario el pedido ser¡ cancelado automaticamente.       ',
            'PAID_TIP_1': 'Su pedido ha sido realizado con éxito y está esperando a que el agente lo compre.',
            'PAID_TIP_2': 'Los pedidos enviados entre las 08:00 y las 18:00 horas, hora de Beijing, serán procesados dentro de las 6 horas.',
            'PURCHASING_TIP_1': 'El personal de adquisiciones está comprando los productos que ha seleccionado del comerciante.',
            'PURCHASING_TIP_2': 'Completaremos la compra para usted dentro del tiempo de adquisición acordado.',
            'PURCHASED_TIP_1': 'Los productos que ha seleccionado hansido comprados y están esperando a que elproveedor los  envíe.',
            'PURCHASED_TIP_2': 'Los comerciantes suelen enviar dentro de 3-7 días (excluyendo algunos artículos hechos a medida y en preventa).',
            'SHIPPED_TIP_1': 'El comerciante ha organizado el envío de los productos. Por favor, espere a que los productos lleguen al almacén de TENDABUY.',
            'SHIPPED_TIP_2': 'Los productos suelen llegar a nuestro almacén en un plazo de 3 días laborables y, a más tardar, en 7 días laborables.',
            'IN_PACKING_CENTER_TIP_1': 'Las mercancías han sido almacenadas en el centro de empaquetado de Tendabuy. Puedes ir al Almacén para verificar.',
            'IN_PACKING_CENTER_TIP_2': 'Las mercancías tienen un período de almacenamiento gratuito de 100 días, y puedes enviar el envío en cualquier momento durante el período de almacenamiento.',
            'IN_AFTERSALES_1': "Su pedido ha iniciado una solicitud de postventa, el estado de procesamiento se puede ver en 'Reembolsos/Postventa'.",
            'IN_AFTERSALES_2': 'Una vez que se complete el proceso de reembolso, los fondos se devolverán a su cuenta de Tendabuy.',
            'CANCELED_TIP_1': 'Las órdenes son retiradas por usted manualmente o por el sistema automaticamente.',
            'CANCELED_TIP_2': 'puede retirarla antes de que sea comprada.',
            'CLOSED_TIP_1': 'El pedido para el cual se solicitó un reembolso después de que se completó el pago.',
            'CLOSED_TIP_2': 'Puede ir a la página de inicio de Home, hacer clic en Mi saldo para ver los detalles del reembolso.',
            'OUTSIDE_SHIPPED_TIP_1': 'Su paquete ha sido enviado',
            'OUTSIDE_SHIPPED_TIP_2': 'Puede ir a Detalles del Paquete para ver la pista logística relevante.',
            'REFUNDED_TIP_1': 'El pedido para el cual se solicitó un reembolso después de que se completó el pago.',
            'REFUNDED_TIP_2': 'Puede ir a la página de inicio de Home, hacer clic en Mi saldo para ver los detalles del reembolso.',
            'NO_PAID_72': 'Este pedido se cancelará si el pago no se realiza en un plazo de 72 horas.',
            'DECLARE': 'Si necesita que SUGARGOO declare por usted, puede elegir',
            'DECLARATION': 'Confiar la declaración',
            'DECLARATION2': '¿Confirma que confiar SugarGoo lo ayudará a declarar, ',
            'INSTRUCTIONS': '《Instrucciones de declaración confiada》',
            'INSURED2': 'Monto asegurado',
            'PURCHASE': 'COMPRAR',
            'PASTE_ORDER': 'Insertar enlace de artículo para pedir ',
            'PAY_SHIPPING': 'pagar el precio del artículo y el envio domestico',
            'SHIP_ITEM': 'compramos y enviamos el artículo a nuestro almacén',
            'QC_STORAGE': 'QC & ALMACENAMIENTO',
            'INSPECTION': 'inspección de calidad en almacén',
            'FREEQC': '5 fotos de QC gratuitas',
            'FREE_STORAGE': '90 días de servicio de almacenamiento gratuito',
            'GLOBAL': 'ENTREGA GLOBAL',
            'ONE_PARCEL': 'envíe diferentes pedidos en un solo paquete',
            'SHIPPING_100': 'embalaje personalizado, 100+líneas marítimas',
            'GLOBAL_FEE': 'pagar la tarifa de envío global',
            'EMPTY': 'Por favor, introduzca la ciudad',
            'ROUTE': 'Esta ruta no existe. Por favor seleccione una nueva área.',
            'GLOBAL_PLATFORM': 'Plataforma de servicios integrados transfronterizos líder mundial',
            'EASILY': '',
            'STEPS': '',
            'GO_HOMEPAGE': '',
            'PAGE': '',
            'FREE2': '',
            'WELCOME': '',
            'ASSISTANT': '',
            'NO_NEED': '',
            'PLEASE_SELECT_PAYMENT_METHOD': 'Por favor elige un método de pago',
            'AUTHENTICATOR_LABEL': 'Verificación del Autentificador',
            'AUTHENTICATOR_DESCRIPTION': 'El Authenticator Verification está en uso para hacer la cuenta más segura. Por favor, haga una copia de seguridad de su clave.',
            'OPEN': 'Activar',
            'CLOSE': 'Cerrar',
            'UNBIND': 'Desenlazar',
            'LABEL_TRUST_CURRENT_DEVICE': 'Confíe en este dispositivo',
            'LABEL_CURRENT_DEVICE': 'Este dispositivo',
            'TITLE_GOOGLE_Authenticator': 'Verificación del authenticator de Google',
            'TITLE_OPEN_GOOGLE_AUTHENTICATOR': 'Activar la verificación del dispositivo de autenticación virtual',
            'TITLE_UNBIND_GOOGLE_Authenticator': 'Desatar la verificación de Google authenticator',
            'TITLE_CLOSE_GOOGLE_AUTHENTICATOR': 'Desactivar la verificación del dispositivo de autenticación virtual',
            'ALERT_OPEN_GOOGLE_AUTHENTICATOR': 'Su cuenta está vinculada a la verificación del autenticador virtual, introduzca el Código dinámico del autenticador virtual al que está vinculado en el dispositivo inteligente.',
            'PLACEHOLDER_AUTHENTICATOR_VERIFICATION_CODE_INPUT': 'Introduzca el Código de Seguridad digital de 6 dígitos',
            'BUTTON_SUBMIT_VERIFICATION_CODE': 'Presentar la verificación',
            'STEP_1_OPEN_GOOGLE_AUTHENTICATOR': 'Autenticación',
            'STEP_2_OPEN_GOOGLE_AUTHENTICATOR': 'Instalación y aplicación',
            'STEP_3_OPEN_GOOGLE_AUTHENTICATOR': 'Vinculación',
            'STEP_4_OPEN_GOOGLE_AUTHENTICATOR': 'Completado',
            'ALERT_CONFIRM_EMAIL_VERIFICATION_CODE': 'Para confirmar que es su propia operación, complete la siguiente verificación. Si no recibe el Código de verificación, vuelva a obtenerlo o vuelva a intentarlo más tarde.',
            'BUTTON_RESEND_EMAIL_VERIFICATION_CODE': 'Recuperar',
            'ALERT_CONFIRM_EMAIL_ADDRESS': 'Para asegurarse de que es usted quien opera, confirme su buzón de verificación para completar la autenticación.',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Descargue e instale Google authenticator en su teléfono móvil',
            'TIP_IPHONE_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'IPhone: buscando Google authenticator (verificador) en la Appstore',
            'TIP_ANDROID_DOWNLOAD_GOOGLE_AUTHENTICATOR': 'Android: buscando Google authenticator (verificador) en el mercado de aplicaciones',
            'TIP_DOWNLOAD_GOOGLE_AUTHENTICATOR_NEXT_STEP': 'Después de completar la instalación, haga clic en el siguiente paso para vincular la página (si está instalada, vaya directamente al siguiente paso)',
            'TIP_USE_GOOGLE_AUTHENTICATOR_TO_SCAN': 'Escanee el siguiente código QR con el verificador de Google para obtener el Código de verificación de 6 dígitos',
            'TIP_BIND_GOOGLE_AUTHENTICATOR_SUCCESS': '¡¡ ha vinculado con éxito al verificador de google!',
            'BUTTON_RETURN': 'Volver',
            'ALERT_CHECK_GOOGLE_AUTHENTICATOR_VERIFICATION_CODE': 'Para confirmar que es su propia operación, introduzca el Código dinámico del autenticador virtual al que está vinculado en el dispositivo inteligente.',
            'BUTTON_USE_EMAIL_TO_UNBIND': 'Desbloquear con buzón',
            'BUTTON_USE_EMAIL_TO_CHECK': 'Verificación con buzón',
            'TITLE_MODIFY_DEVICE_NAME': 'Modificar el nombre del dispositivo',
            'TITLE_CURRENT_DEVICE': 'Equipo actual',
            'PLACEHOLDER_MODIFY_CURRENT_DEVICE_NAME': 'Introduzca el nombre del dispositivo a modificar',
            'MESSAGE_OPEN_SUCCESS': 'Apertura exitosa',
            'MESSAGE_UNBIND_SUCCESS': 'Desatar con éxito',
            'MESSAGE_CLOSE_SUCCESS': 'Cierre exitoso',
            'DELETE_BILLING_ADDRESS': 'Eliminar la dirección de la factura',
            'EDIT_BILLING_ADDRESS': 'Modificar la dirección de la factura',
            'CLICK_VIEW': 'Haga clic para ver todos los productos de esta tienda en Tendabuy',
            'TRANSIT': 'En tránsito',
            'SERVICE_NOT': 'Tasa de servicio por pagar',
            'SERVICE_PAID': 'Tasa de servicio pagada',
            'AWAITING_PACK': 'En espera de embalaje',
            'PACKED_2': 'El paquete está empacado',
            'WAIT_SHIPMENT': 'En espera de envío',
            'PENDING_CLEAR': 'Pendiente de despacho aduanero',
            'TRANSIT_2': 'Tránsito internacional',
            'ARRIVED_COUNTRY': 'Llegado al país de destino',
            'IN_COUNTRY': 'transporte dentro del país',
            'DELIVERED_2': 'Entregado',
            'CANCEL_2': 'Cancelar',
            'INTER_NOT': 'Flete internacional pendiente de pago',
            'INTER_PAID': 'Tarifa de envío internacional pagada',
            'INTER_OPTIONS': 'Opciones de envío internacional',
            'ESTIMATED_PAID': 'Tasas completas estimadas pagadas',
            'ESTIMATED_NOT': 'Tasas completas estimadas no pagadas',
            'NOTICES': 'Anuncios',
            'SHIPPING': 'Compañía de envíos',
            'SELECT_COUPON': 'Seleccionar cupón de envío',
            'CURRENT_PAGE': 'La mercancía actual es de 1.688 productos, y el monto del pedido se basa en la transacción real. si tiene necesidades especiales, tome nota.',
            'ITEM': 'Item',
            'WE_OFFER_5': 'Ofrecemos 5 fotos de inspección HD gratuitas',
            'AFTER_YOU_CONFIRM': 'Después de confirmar la mercancía, elija International express.',
            'NAVIGATION': 'Puedes buscar los productos que quieres comprar aquí. TendaBuy ofrece búsqueda por palabras clave, búsqueda con un enlace o una imágen del producto.',
            'NAVIGATION_1': 'Haz clic aquí para calcular el costo de envío de tu paquete y ver los informaciones sobre las rutas de envío.',
            'BACK': 'Volver',
            'NEST': 'Siguiente',
            'FINISH': 'Confirmar',
            'INSURED_RANGE': 'Rango de gastos de seguro garantizado',
            'INSURANCE_FEE_WITHOUT': 'seguro de valor garantizado',
            'EMAIL_NOT_VERIFIED': 'Correo electrónico no verificado',
            'TO_BE_SUPPLEMENTED': 'por completa',
            'PARCEL_CLOSED': 'paquete cerrado',
            'CONFIRM_RECEIPT': 'confirmar recepción',
            'SIZE_GUIDE_NOTES': 'Por favor, tenga en cuenta que esta es solo una tabla de conversión aproximada, ya que las tallas pueden variar entre diferentes vendedores y marcas. Le recomendamos revisar la tabla de tallas detallada del producto antes de comprar y elegir la talla adecuada según sus medidas reales.',
            'SIZE_GUIDE': 'guía de tallas',
            'SIZE_ASSISTANT': 'asistente de tallas',
            'TOOL_MENU': 'menú de herramientas',
            'SWITCH_TO': 'cambiar a',
            'PRODUCT_NOT_FOUND': 'Producto no encontrado, volver a mis pedidos.',
            'SELECT_COUPON_1': 'seleccionar cupón',
            'PAID_1': 'pagado',
            'FIRST_WEIGHT_CHARGE': 'Cargo por primer peso',
            'RENEWAL_CHARGE': 'Cargo de renovación',
            'ACTIVA': 'Enviar correo electrónico de activación',
            'ACTIVATE': '¿Activaste tu correo electrónico?',
            'ACCEPT': 'Aceptar',
            'CANCEL0': 'Cancelar'
        }
    }


    return language['language' + (index * 1 + 1)]
}

import request from "../utils/request";
import axios from "axios";

var ossUrl = '/oss'

export function ossUploadImg(formData, params) {
    return axios.post('/main/oss/uploadImage', formData, {params}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(res => {
        //// console.log(res.data);
        return res
    })
    .catch(error => {
        console.error(error);
        return error
    });
    // return request({
    //     url: ossUrl + '/uploadImage',
    //     method: 'post',
    //     formData,
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // })
}

import request from "../utils/request";
var orderUrl = '/order'

export function orderAdd(data){
    var dataObj = {}
    dataObj.orderFormVOS = data
    dataObj.customPhotoAddCartVOS = data.customPhotoAddCartVOS
    //// console.log(666,data)
    ////// console.log(dataObj)
    return request({
        url: orderUrl + '/add',
        method: 'post',
        data:dataObj
    })
}

export function orderGetStorage(data){
    return request({
        url: orderUrl + '/get/storage',
        method: 'post',
        data
    })
}

export function orderGetUidStatus(){
    return request({
        url:orderUrl+'/get/uid/status',
        method:'get'
    })
}

export function orderGet(params){
    return request({
        url: orderUrl + '/get',
        method: 'get',
        params
    })
}

export function orderGetStatus(params){
    return request({
        url:orderUrl+'/get/status',
        method:'get',
        params
    })
}

//获取包裹服务
export function packageServiceApi(){
    return request({
        url:orderUrl+'/packageService',
        method:'get',
    })
}

export function orderServiceApi(){
    return request({
        url:orderUrl+'/orderService',
        method:'get',
    })
}

//查看订单所有包裹
export function pageageGetDetialApi(data){
    return request({
        url:orderUrl+'/package/getByUid',
        method:'get',
        params: data
    })
}

//根据订单查询详情
export function packageDetailApi(id){
    return request({
        url:orderUrl+'/package/getDetial',
        method:'get',
        params: {
            orderPackageId: id
        }
    })
}

//添加预演包裹
export function packageAddApi(data){
    return request({
        url:orderUrl+'/package/add',
        method:'post',
        data
    })
}

//添加包裹
export function packageAddAllApi(data){
    return request({
        url:orderUrl+'/package/add/all',
        method:'post',
        data
    })
}

//提交国际运费
export function freight(data){
    return request({
        url:orderUrl+'/package/topay/freight',
        method:'post',
        data
    })
}
// 取消支付
export function orderCancelPay(params){
    return request({
        url:orderUrl+'/cancel/pay',
        method:'post',
        params
    })
}

export function getServeType(params){
    return request({
        url:orderUrl+'/get/serveType',
        method:'get',
        params
    })
}

//获取订单ID
export function getOrderIdAPI(params){
    return request({
        url:orderUrl+'/package/getOrderId',
        method:'get',
        params
    })
}

// 修改订单备注
export function orderModifyRemark(params){
    return request({
        url:orderUrl+'/modify/remark',
        method:'post',
        params
    })
}

export function cancelAPI(params){
    return request({
        url:orderUrl+'/package/cancel',
        method:'post',
        params
    })
}

export function priviewApi(data){
    return request({
        url:orderUrl+'/package/get/package/volume',
        method:'post',
        data
    })
}

// 订单最大值
export function orderPackageGetNum(data){
    return request({
        url:orderUrl+'/package/getNum',
        method:'post',
        data
    })
}

// 获取对应服务类型
export function orderGetServeType(params){
    return request({
        url:orderUrl+'/get/serveType',
        method:'get',
        params
    })
}

// 删除订单
export function orderDelete(params){
    return request({
        url:orderUrl+'/delete',
        method:'post',
        params
    })
}

// 获取到仓数量
export function orderGetStorageNum(){
    return request({
        url:orderUrl+'/get/storage/num',
        method:'get'
    })
}

// 获取未付款订单数量
export function orderGetUnpaidNum(){
    return request({
        url:orderUrl+'/get/unpaid/num'
    })
}

// 带水印照片
export function orderGetStorageWatermark(data){
    return request({
        url:orderUrl+'/get/storage/watermark',
        method:'get',
        data,
        contentType: 'image/jpeg'
    })
}

// 获取包裹额外运费查询
export function orderPackageGetOrderIdFrightFee(data){
    return request({
        url:orderUrl+'/package/getOrderIdFrightFee',
        method:'post',
        data
    })
}


<template>
  <div class="container">
    <div class="header-container">
      <img class="header-logo" @click="$router.push('/')" src="../../public/images/tenda.png" alt="">
      <div class="login-header-remark">
        {{$store.state.language.GLOBAL_PLATFORM}}
      </div>
    </div>
    <div class="body-container">
      <div class="login-container">
        <img src="https://oss.tendabuy.com/static/%E7%99%BB%E5%BD%95.webp" style="height: 100%;width: 850px" alt="">
        <div class="login-box">
          <div class="login-box-item" style="position: relative;height: calc(100% - 50px)"
               v-show="isLoginStatus && twoCodeOrPassword">
            <!--            <div class="login-top-path" @click="twoCodeOrPassword = 0">-->
            <!--              <span>使用Tendabuy APP扫码登录</span>-->
            <!--              <img src="../../public/images/twoCode.png" style="width: 50px;height: 50px;" alt="">-->
            <!--            </div>-->
            <div class="login-box-title">{{ $store.state.language.LOGIN_1 }}</div>
            <el-form :model="loginValidateForm" :rules="loginRules" ref="loginValidateForm" label-width="100px"
                     class="demo-dynamic">
              <el-form-item prop="email" style="position: relative;">
                <el-input v-model="loginValidateForm.email"
                          :placeholder="$store.state.language.Email_Address_Input">
                    <i slot="prefix" class="el-icon-message input-icon"></i>
                </el-input>
                <div class="email-selector" :style="emailPositionStyle">
                  <div class="email-selector-item" v-for="(item, index) in emailOptions"
                       @mouseover="emailIndexChange(index)" :key="index"
                       :class="index == emailIndex ? 'email-selector-item-active' : ''" @click="changeEmail1(item)">
                    {{ item }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" v-model="loginValidateForm.password" show-password
                          :placeholder="$store.state.language.PASSWORD">
                  <i slot="prefix" class="el-icon-lock input-icon"></i>
                </el-input>
              </el-form-item>
              <div class="code-input">
                <el-form-item prop="verificationCode" :rules="[
              { required: true, message: this.$store.state.language.Verification_Code_Input, trigger: 'blur' }
            ]">
                  <el-input v-model="loginValidateForm.verificationCode"
                            :placeholder="$store.state.language.Verification_Code">

                  </el-input>
                </el-form-item>
                <img class="" :src="codeImage" style="cursor: pointer;" alt="" @click="changeLoginCode()">
              </div>
              <el-form-item>
                <el-button type="primary" :style="{opacity:loginLoading?'0.8':'1'}" @click="loginButton('loginValidateForm')">
                  <i class="el-icon-loading" v-show="loginLoading"></i>
                  {{
                    $store.state.language.LOGIN_1
                  }}
                </el-button>
              </el-form-item>
            </el-form>
            <div class="reset-register">
              <div style="cursor: pointer;" @click="passwordForget()">{{ $store.state.language.Forgot_Password }}</div>
              <div style="cursor: pointer;" @click="toLoginRegister(0)">{{ $store.state.language.Sign_Up }}</div>
            </div>
            <div class="login-bottom-container">
              <div class="login-bottom-diviced-line">
                <div style="background: #F2F2F2;width: 100%;height: 1px;"></div>
                <span
                    style="color: #999;font-size: 13px;text-wrap: nowrap;margin:0 10px;white-space: nowrap;">{{
                    $store.state.language.OR_CONTINUE
                  }}</span>
                <div style="background: #F2F2F2;width:  100%;height: 1px;"></div>
              </div>
              <div class="login-bottom-path">
                <div class="login-bottom-path-icon" @click="toLoginGoogle()"><img
                    src="../../public/images/googleIcon.png" alt="" style="cursor: pointer"></div>
<!--                <div class="login-bottom-path-icon"><img src="../../public/images/appleIcon.png" alt=""></div>-->
              </div>
            </div>
          </div>
          <div class="login-box-item" style="position: relative;height: 100%;" v-show="!twoCodeOrPassword">
            <div class="login-top-path" style="top: 0;" @click="twoCodeOrPassword = 1">
              <span
                  style="color: #999; background-color: #f2f2f2;">{{
                  $store.state.language.BACK_TO_PASSWORD_LOGIN
                }}</span>
              <img src="../../public/images/pc.png" style="width: 50px;height: 50px;" alt="">
            </div>
            <div class="login-body-path" style="">
              <div class="login-body-path-title">{{ $store.state.language.OPEN_APP_HOME }}</div>
              <div class="login-body-path-content">"扫一扫登录"</div>
              <img class="login-body-path-sweep" src="../../public/images/sweep.png" alt="">
              <img class="login-body-paht-code" src="../../public/images/loginTwoCode.png" alt="">
              <div class="login-path-register-button" @click="pathRegister()">
                {{ $store.state.language.Sign_Up }}
              </div>
            </div>
          </div>
          <div class="login-box-item" v-show="!isLoginStatus">
            <div class="login-box-title">{{ $store.state.language.Sign_Up }}</div>
            <el-form :rules="registerRules" :model="registerValidateForm" ref="registerValidateForm" label-width="100px"
               class="demo-dynamic">
              <el-form-item prop="country">
                <el-select v-model="registerValidateForm.country" filterable
                           :placeholder="$store.state.language.COUNTRY">
                  <i slot="prefix"   :class="registerValidateForm.country?'flag-icon flag-icon-'+registerValidateForm.country.split('-')[2]:'el-icon-location-outline input-icon'"></i>
                  <el-option
                      v-for="(item,index) in countryArr"
                      :key="index"
                      :label="item.label"
                      :value="item.country+'-'+item.countryEn+'-'+item.code">
                    <div><span :class="'flag-icon flag-icon-'+item.code"></span> {{ item.country }}-{{ item.countryEn }}-{{ item.code }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="email" style="position: relative;">
                <el-input v-model="registerValidateForm.email"
                          :placeholder="$store.state.language.Email_Address_Input">
                  <i slot="prefix" class="el-icon-message input-icon"></i>
                </el-input>
                <div class="email-selector" :style="emailPositionStyle">
                  <div class="email-selector-item" v-for="(item, index) in emailOptions"
                       @mouseover="emailIndexChange(index)" :key="index"
                       :class="index == emailIndex ? 'email-selector-item-active' : ''" @click="changeEmail(item)">
                    {{ item }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" show-password v-model="registerValidateForm.password"
                          :placeholder="$store.state.language.PASSWORD">
                  <i slot="prefix" class="el-icon-lock input-icon"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="confrimPassword">
                <el-input v-model="registerValidateForm.confrimPassword" type="password" show-password
                          :placeholder="$store.state.language.CONFIRM_PASSWORD">
                  <i slot="prefix" class="el-icon-lock input-icon"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="invitationCode" :rules="[
              { required: false, message: '', trigger: 'blur' }
            ]">
                <el-input v-model="registerValidateForm.invitationCode"
                          :placeholder="$store.state.language.Invitation_Code_Optional">
                  <i slot="prefix" class="el-icon-tickets input-icon"></i>
                </el-input>
              </el-form-item>
              <div class="code-input">
                <el-form-item prop="verificationCode">
                  <el-input v-model="registerValidateForm.verificationCode"
                            :placeholder="$store.state.language.Verification_Code"></el-input>
                </el-form-item>
                <img class="code-style" :src="codeImage" alt="" @click="changeRegisterCode()">
              </div>
              <div class="protocol">
                <div class="popover" v-show="visible">
                  {{ $store.state.language.Agree_And_Proceed }}
                </div>
                <el-checkbox v-model="checked"></el-checkbox>
                {{ $store.state.language.Read_and_Agree }}
                <span>{{ $store.state.language.TendaBuy_Reg_Agreement }}</span> {{ $store.state.language.AND }} <span> {{
                  $store.state.language.Privacy_Statement
                }}
                </span>
              </div>
              <el-form-item>
                <el-button type="primary" @click="registerSubmit('registerValidateForm')">
                  {{ $store.state.language.Sign_Up }}
                </el-button>
              </el-form-item>
            </el-form>
            <div class="reset-register" style="display: block; width: 100%;text-align: right;font-size: 14px;">
              <span style="font-size: 14px;color: #666">
                {{ $store.state.language.Account_Existence }}
              </span>
              <span style="color: #409EFF;cursor: pointer;margin-left: 5px;"
                    @click="toLoginRegister(1)">{{ $store.state.language.LOGIN_1 }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="big-login-bottom-container">
      <span class="login-bottom-right" >{{$store.state.language.BEIAN_RIGHT}}</span>
      <span class="login-bottom-help" @click="$router.push('/helpCenter/frequentQuestion')">帮助</span>
      <span class="login-bottom-help" @click="toHelpCenter({articleId:134})">隐私</span>
      <span class="login-bottom-help" @click="toHelpCenter({articleId:132})">条款</span>
    </div>
    <el-dialog
        :title="$store.state.language.ACTIVATE_EMAIL"
        :visible.sync="showActiveCodeDialog"
        width="480px">
      <div class="active-tip">{{ $store.state.language.YOUR_EMAIL }}{{
          loginValidateForm.email
        }}{{ $store.state.language.EMAIL_INACTIVE }}
      </div>
      <el-form :model="toActiveValidateForm" :rules="toActiveRules" ref="toActiveValidateForm" label-position="right" label-width="100px">
        <div class="code-input">
          <el-form-item prop="verificationCode">
            <el-input v-model="toActiveValidateForm.verificationCode"
                      :placeholder="$store.state.language.Verification_Code"></el-input>
          </el-form-item>
          <img class="code-style" :src="activeCodeImage" alt="" @click="changeActiveCode()">
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
       <div style="display: flex;width: 100%;justify-content: flex-end">
           <el-button size="small" round @click="showActiveCodeDialog=false"
                      style="background-color:white;border:1px #999 solid;color: #333;width: 60px;box-sizing:border-box ">{{
               $store.state.language.CANCEL
             }}</el-button>

          <el-button size="small" round @click="activeAcount('toActiveValidateForm')"
                     style="background-color:#333;border-color: #333;color: white;width: 123px; ">{{
              $store.state.language.RESEND_ACTIVATION
            }}</el-button>
       </div>
      </span>
    </el-dialog>
    <el-dialog
        :title="$store.state.language.EMAIL_SENT"
        :visible.sync="showActiveCodeConfirmDialog"
        width="480px">
      <div class="active-tip">{{ $store.state.language.CHECK_ACTIVATION_EMAIL }}
      </div>

      <span slot="footer" class="dialog-footer">
          <el-button size="small" round @click="showActiveCodeConfirmDialog=false"
                     style="background-color:#333;border-color: #333;color: white;width: 80px;">{{
              $store.state.language.CONFIRM
            }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="$store.state.language.CONSULT_BEFORE_ORDER_TITLE"
        :visible.sync="showSafeCodeDialog"
        width="480px">
      <div class="tip">{{ $store.state.language.NEW_LOCATION_LOGIN }}</div>
      <el-form label-position="right" label-width="100px">
        <el-form-item>
          <el-col span="4" style="text-align: right;padding-right: 10px;">
            <span>{{ $store.state.language.EMAIL_ADDRESS }}</span>
          </el-col>
          <el-col span="20">
            <el-input disabled v-model="userEmail"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col span="4" style="text-align: right;padding-right: 10px;">
            <span>{{ $store.state.language.Verification_Code }}</span>
          </el-col>
          <el-col span="10">
            <el-input :disabled="captchaDisable" v-model="captcha"></el-input>
          </el-col>
          <el-col span="10" style="text-align: right">
            <el-button type="primary" style="width: 120px;" @click="sendCaptcha" :disabled="sendCaptchaDisable">
              {{ sendCaptchaButtonText }}
            </el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" round @click="loginWithCaptcha"
                   style="background-color:#333;border-color: #333;color: white;width: 100px; ">{{
            $store.state.language.Sign_Up
          }}</el-button>
      </span>
    </el-dialog>
    <!--Authenticator验证弹窗-->
    <el-dialog
        width="860px"
        class="authenticator-dialog"
        :show-close="false"
        :visible.sync="authenticatorDialog">
      <div>
        <el-button type="primary" class="back-button" @click="authenticatorDialog = false">
          <i class="el-icon-arrow-left"></i>
        </el-button>
        <p class="big-title">
          {{ $store.state.language.ACCOUNT_SECURITY }}
        </p>
        <div class="content">
          <p class="title">{{ $store.state.language.TITLE_GOOGLE_Authenticator }}</p>
          <div v-if="!useEmail2LoginAuthenticator">
            <div role="alert" class="el-alert email-alert el-alert--warning is-light">
              <i class="el-alert__icon el-icon-warning"></i>
              <div class="el-alert__content"><span class="el-alert__title"><span
                  class="el-alert__title">{{ $store.state.language.EMAIL_ADDRESS }}<i>{{
                  userEmail
                }}</i>{{ $store.state.language.ALERT_CHECK_GOOGLE_AUTHENTICATOR_VERIFICATION_CODE }}</span></span>
              </div>
            </div>
            <el-input v-model="authenticatorVerificationCode"
                      class="emailVerify-inp"
                      :placeholder="$store.state.language.PLACEHOLDER_AUTHENTICATOR_VERIFICATION_CODE_INPUT"
            ></el-input>
            <el-button
                class="emailVerify-inp"
                type="primary"
                :loading="authenticatorBtnLoading"
                :disabled="!authenticatorVerificationCode || authenticatorVerificationCode.length !== 6"
                @click="onLoginAuthenticator"
            >
              {{ $store.state.language.BUTTON_SUBMIT_VERIFICATION_CODE }}
            </el-button>
            <br/>
            <el-checkbox :label="$store.state.language.LABEL_TRUST_CURRENT_DEVICE"
                         v-model="trustCurrentDevice"
                         class="trust-current-device-checkbox"
            ></el-checkbox>
            <p class="email-login-auth" @click="onUseEmail2LoginAuthenticator">
              {{ $store.state.language.BUTTON_USE_EMAIL_TO_CHECK }}</p>
          </div>
          <form class="el-form" v-else>
            <div role="alert" class="el-alert email-alert el-alert--warning is-light">
              <i class="el-alert__icon el-icon-warning"></i>
              <div class="el-alert__content"><span class="el-alert__title"><span
                  class="el-alert__title">{{ $store.state.language.EMAIL_ADDRESS }}<i>{{
                  userEmail
                }}</i>{{ $store.state.language.ALERT_CONFIRM_EMAIL_VERIFICATION_CODE }}</span></span>
              </div>
            </div>
            <div class="email-verification-code-inp el-input el-input--prefix">
              <el-input :placeholder="$store.state.language.Verification_Code_Input"
                        prefix-icon="el-icon-edit"
                        v-model="authenticatorEmailVerificationCode"
              ></el-input>
            </div>
            <button v-if="authenticatorSendEmailCountdown >= 0"
                    type="button" class="el-button email-verification-code-resend-btn el-button--info is-disabled"
                    disabled="disabled">
                    <span>
                      {{ authenticatorSendEmailCountdown }}
                    </span>
            </button>
            <el-button v-if="authenticatorSendEmailCountdown < 0" type="primary"
                       class="email-verification-code-resend-btn"
                       @click="onUseEmail2LoginAuthenticator">
              {{ $store.state.language.BUTTON_RESEND_EMAIL_VERIFICATION_CODE }}
            </el-button>
            <el-button
                class="emailVerify-inp"
                type="primary"
                :loading="authenticatorBtnLoading"
                :disabled="!authenticatorEmailVerificationCode"
                @click="onLoginAuthenticatorByEmail"
            >
              {{ $store.state.language.BUTTON_SUBMIT_VERIFICATION_CODE }}
            </el-button>
            <el-checkbox :label="$store.state.language.LABEL_TRUST_CURRENT_DEVICE"
                         v-model="trustCurrentDevice"
                         class="trust-current-device-checkbox"
            ></el-checkbox>
          </form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLoginCode,
  getRegisterCode,
  register,
  getPublicKey,
  login,
  sendCaptcha,
  loginWithCaptcha,
  getGoogleLogin, getGoogleLoginUserInfo, accountActiveCode, accountSendEmailActive
} from "../api/user";
import {getAllCountry} from "../api/resource"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {
  loginAuthenticator, loginAuthenticatorByEmail,
  sendVerificationCodeEmail2Account4LoginAuthenticator
} from "@/api/security";
import BottomContainer from '@/components/BottomContainer.vue'
import {toHelpCenter} from "@/utils/publicFunction";

export default {
  components: {BottomContainer},
  props: {},
  data() {
    var validatePass = (rule, value, callback) => {
      var pattern = /^(?=.*[A-Za-z])(?=.*\d).{6,20}$/;
      if (value === '') {
        callback(new Error(this.$store.state.language.Enter_Password));
      } else if (!pattern.test(this.registerValidateForm.password)) {
        callback(new Error(this.$store.state.language.PASSWORD_REQUIREMENT));
      } else {
        if (this.registerValidateForm.confrimPassword !== '') {
          this.$refs.registerValidateForm.validateField('confrimPassword');
        }
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      var pattern = /^(?=.*[A-Za-z])(?=.*\d).{6,20}$/;
      //// console.log(value);
      if (value === '') {
        callback(new Error(this.$store.state.language.Enter_Password));
      } else if (!pattern.test(this.loginValidateForm.password)) {
        callback(new Error(this.$store.state.language.PASSWORD_REQUIREMENT));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$store.state.language.Reenter_Password));
      } else if (value !== this.registerValidateForm.password) {
        callback(new Error(this.$store.state.language.PASSWORDS_NOT_MATCH));
      } else {
        callback();
      }
    };
    var validateEmail1 = (rule, value, callback) => {
      if (validateEmail(value)) {
        callback()
      } else {
        callback(new Error(this.$store.state.language.VALID_EMAIL_REQUIRED));
      }
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Example usage:
    function validateEmail(email) {
      return emailRegex.test(email);
    }

    return {
      userInfo: {},
      loginLoading:false,
      authenticatorDialog: false,
      useEmail2LoginAuthenticator: false,
      authenticatorEmailVerificationCode: null,
      authenticatorSendEmailCountdown: 0,
      authenticatorSendEmailCountdownTimer: null,
      authenticatorVerificationCode: null,
      trustCurrentDevice: false,
      authenticatorBtnLoading: false,
      showActiveCodeDialog: false,
      emailOptions: [],
      showActiveCodeConfirmDialog: false,
      registerValidateForm: {
        email: '',
        password: '',
        verificationCode: '',
        country: '',
        verification: '',
        invitationCode: '',
        confrimPassword: ''
      },
      activeCodeImage: '',
      loginValidateForm: {
        email: '',
        password: '',
        verificationCode: '',
        verification: ''
      },
      toActiveValidateForm: {
        verificationCode: '',
        verification: ''
      },
      registerRules: {
        email: [
          {required: true, message: this.$store.state.language.Email_Input, trigger: 'blur'},
          {type: 'email', validator: validateEmail1, trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, validator: validatePass, trigger: 'blur'}
        ],
        verificationCode: [
          {required: true, message: this.$store.state.language.Verification_Code_Input, trigger: 'blur'}
        ],
        country: [
          {required: true, message: this.$store.state.language.Country_Selection, trigger: 'change'}
        ],
        invitationCode: [{required: false}],
        confrimPassword: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ]
      },
      loginRules: {
        email: [
          {required: true, message: this.$store.state.language.Email_Input, trigger: 'blur'},
          {type: 'email', validator: validateEmail1, trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, validator: validatePass1, trigger: 'blur'}
        ],
        verificationCode: [
          {required: true, message: this.$store.state.language.Verification_Code_Input, trigger: 'blur'}
        ],
        verification: ''
      },
      toActiveRules: {
        verificationCode: [
          {required: true, message: this.$store.state.language.Verification_Code_Input, trigger: 'blur'}
        ],
        verification: ''
      },
      codeImage: '',
      isLoginStatus: '',
      visible: false,
      checked: false,
      countryArr: [],
      countryIcon: '',
      isEmail: true,
      emailIndex: 0,
      emailPositionIndex: 4,
      isChangeCode: true,
      emailPositionStyle: '',
      twoCodeOrPassword: 1,
      verification: '',
      isVerification: true,
      showSafeCodeDialog: false,
      fingerprint: '',
      captchaDisable: true,
      currentCount: 60,
      sendCaptchaDisable: false,
      sendCaptchaButtonText: this.$store.state.language.Send_Code,
      captcha: '',
      userEmail: '',
      selectCode:''
    };
  },

  computed: {},
  watch: {
    showActiveCodeDialog(newVal) {
      this.changeActiveCode()
    },
    checked(newValue, oldValue) {
      if (newValue) {
        this.visible = false
      }
    },
    'registerValidateForm.email'(newValue, oldValue) {
      if (newValue.includes('@') && newValue.split('@').slice(0)[0].length >= 1 && this.isEmail) {
        var newStr = '@' + newValue.split('@').slice('-1')
        var emailSelector = ['@gmail.com', '@icloud.com', '@yahoo.com', '@outlook.com', '@hotmail.com', '@yahoo.cn', '@yahoo.com.sg', '@yahoo.com.au', '@yahoo.com.ca', '@msn.com', '@163.com', '@126.com', '@qq.com', '@vip.qq.com', '@live.cn']
        this.emailOptions = emailSelector.filter(item => item.slice(0, newStr.length) == newStr && item.length != newStr.length)
      } else {
        this.emailOptions = []
      }
    },
    'loginValidateForm.email'(newValue, oldValue) {
      if (newValue.includes('@') && newValue.split('@').slice(0)[0].length >= 1 && this.isEmail) {
        var newStr = '@' + newValue.split('@').slice('-1')
        var emailSelector = ['@gmail.com', '@icloud.com', '@yahoo.com', '@outlook.com', '@hotmail.com', '@yahoo.cn', '@yahoo.com.sg', '@yahoo.com.au', '@yahoo.com.ca', '@msn.com', '@163.com', '@126.com', '@qq.com', '@vip.qq.com', '@live.cn']
        this.emailOptions = emailSelector.filter(item => item.slice(0, newStr.length) == newStr && item.length != newStr.length)
      } else {
        this.emailOptions = []
      }
    }
  },
  created() {
    var that = this;
    localStorage.removeItem('productList')
    this.changeActiveCode()
    const fpPromise = FingerprintJS.load();
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()
      that.fingerprint = result.visitorId;
    })()
  },
  mounted() {
    this.isLoginStatus = JSON.stringify(this.$route.params) == '{}' ? 1 : this.$route.params.isLoginStatus * 1;
    this.initCode()
    this.countryArr = getAllCountry()
  },
  methods: {
    toHelpCenter,
    onUseEmail2LoginAuthenticator() {
      this.useEmail2LoginAuthenticator = true;
      this.authenticatorSendEmailCountdown = 600;
      if (this.authenticatorSendEmailCountdownTimer) {
        clearInterval(this.authenticatorSendEmailCountdownTimer);
      }
      this.authenticatorSendEmailCountdownTimer = setInterval(() => {
        if (this.authenticatorSendEmailCountdown > 0) {
          this.authenticatorSendEmailCountdown--;
        } else {
          clearInterval(this.authenticatorSendEmailCountdownTimer);
          this.authenticatorSendEmailCountdownTimer = null;
        }
      }, 1000);
      sendVerificationCodeEmail2Account4LoginAuthenticator().then(() => {
      }).catch(() => {
        this.authenticatorSendEmailCountdown = -1;
        clearInterval(this.authenticatorSendEmailCountdownTimer);
        this.authenticatorSendEmailCountdownTimer = null;
      })
    },
    onLoginAuthenticator() {
      this.authenticatorBtnLoading = true;
      loginAuthenticator(this.authenticatorVerificationCode, this.trustCurrentDevice).then(response => {
        if (response && response.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.$router.push({
            path: '/'
          })
          this.openLogin()
        }
        this.authenticatorBtnLoading = false;
      }).catch(() => {
        this.authenticatorBtnLoading = false;
      })
    },
    onLoginAuthenticatorByEmail() {
      this.authenticatorBtnLoading = true;
      loginAuthenticatorByEmail(this.authenticatorEmailVerificationCode, this.trustCurrentDevice).then(response => {
        if (response && response.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.$router.push({
            path: '/'
          })
          this.openLogin()
        }
        this.authenticatorBtnLoading = false;
      }).catch(() => {
        this.authenticatorBtnLoading = false;
      })
    },
    activeAcount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = {
            email: this.loginValidateForm.email,
            verification: this.toActiveValidateForm.verification,
            verificationCode: this.toActiveValidateForm.verificationCode
          }
          accountSendEmailActive(data).then(res => {
            //// console.log(res)
            this.showActiveCodeDialog = false
            this.showActiveCodeConfirmDialog = true
          }).catch(() => {
            this.changeActiveCode();
          });
        } else {
          return false;
        }
      });
    },
    open3() {
      this.$message({
        message: this.$store.state.language.CODE_REQUEST_FAST,
        type: 'warning'
      });
    },
    openRegister() {
      this.$alert(this.$store.state.language.OPEN_LINK_ACTIVATE, this.$store.state.language.REGISTER_SUCCESS, {
        confirmButtonText: this.$store.state.language.CONFIRM,
        callback: action => {
         this.$router.push('/welcome')
        }
      });
    },
    initLoginCode() {
      getLoginCode().then(res => {
        this.codeImage = res.data.codeImage
        this.loginValidateForm.verification = res.data.verification
      })
    },
    initRegisterCode() {
      getRegisterCode().then(res => {
        this.codeImage = res.data.codeImage
        this.registerValidateForm.verification = res.data.verification
        this.isVerification = true
      })

    },
    initCode() {
      if (this.isVerification) {
        this.isVerification = false
        if (this.isLoginStatus) {
          this.initLoginCode()
        } else {
          this.initRegisterCode()
        }
      } else {
        this.open3()
      }
    },
    passwordForget() {
      this.$router.push({
        name: 'forget'
      })
    },
    pathRegister() {
      this.isLoginStatus = 0
      this.twoCodeOrPassword = 1
    },
    subEmailIndex() {
      this.emailIndex = (this.emailIndex == 0 ? this.emailOptions.length - 1 : --this.emailIndex)
      if (this.emailIndex > this.emailPositionIndex) {
        this.emailPositionStyle = {
          transiform: 'transilateY(' + this.emailIndex + 'px)'
        }
      }
    },
    addEmailIndex() {
      this.emailIndex = (this.emailIndex == this.emailOptions.length - 1 ? 0 : ++this.emailIndex)
    },
    emailIndexChange(index) {
    },
    changeEmail(str) {
      var emailStr = ''
      this.registerValidateForm.email.split('@').filter((item, index) => {
        var item1 = item + '@'
        if (index != this.registerValidateForm.email.split('@').length - 1) {
          emailStr = emailStr + item1
        }
      })
      this.registerValidateForm.email = emailStr.slice(0, -1) + str
      this.emailOptions = []
      this.isEmail = false
      setTimeout(() => {
        this.isEmail = true
      }, 1)
    },
    changeEmail1(str) {
      var emailStr = ''
      this.loginValidateForm.email.split('@').filter((item, index) => {
        var item1 = item + '@'
        if (index != this.loginValidateForm.email.split('@').length - 1) {
          emailStr = emailStr + item1
        }
      })
      this.loginValidateForm.email = emailStr.slice(0, -1) + str
      this.emailOptions = []
      this.isEmail = false
      setTimeout(() => {
        this.isEmail = true
      }, 1)
    },
    initChangeCode() {
      if (this.isVerification) {
        this.isVerification = false
        if (this.isLoginStatus) {
          getLoginCode().then(res => {
            this.codeImage = res.data.codeImage
            this.loginValidateForm.verification = res.data.verification
            this.isVerification = true
          })
        } else {
          getRegisterCode().then(res => {
            this.codeImage = res.data.codeImage
            this.registerValidateForm.verification = res.data.verification
            this.isVerification = true
          })
        }
      } else {
        this.open3()
      }
    },
    changeRegisterCode() {
      getRegisterCode().then(res => {
        this.codeImage = res.data.codeImage
        this.registerValidateForm.verification = res.data.verification
        this.isVerification = true
      })
    },
    changeActiveCode() {
      accountActiveCode().then(res => {
        this.activeCodeImage = res.data.codeImage
        this.toActiveValidateForm.verification = res.data.verification
        this.toActiveValidateForm.verificationCode = '';
        this.isVerification = true
      })
    },
    toLoginGoogle() {
      getGoogleLogin().then(res => {
        //// console.log(res.data)
        var leftPosition = (window.screen.width - 600) / 2; // 600是窗口的宽度
        var topPosition = (window.screen.height - 700) / 2; // 400是窗口的高度
        // window.open (res.data,"child.html", "newwindow", "height=100, width=400, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no")
        var popup = window.open(res.data, '_self');
        if (popup) {
          popup.onbeforeunload = popup.onunload = (event) => {
            //// console.log(res.data)
            getGoogleLoginUserInfo(res.data).then(res1 => {
              //// console.log(res1)
            })
            // 这里可以执行一些操作，比如关闭新窗口后的清理工作
          };
        } else {
          //// console.log(this.$store.state.language.WINDOW_FAIL);
        }
      })
    },
    changeLoginCode() {
      getLoginCode().then(res => {
        this.codeImage = res.data.codeImage
        this.loginValidateForm.verification = res.data.verification
        this.isVerification = true
        this.loginLoading = false
      })
    },

    toLoginRegister(status) {
      this.visible = false
      this.isLoginStatus = status
      this.$refs['registerValidateForm'].resetFields();
      this.$refs['loginValidateForm'].resetFields();
      if (status === 1) {
        this.changeLoginCode();
      } else if (status === 0) {
        this.changeRegisterCode();
      }
    },
    openLogin() {
      this.$message({
        message: this.$store.state.language.LOGIN_SUCCESS,
        type: 'success',
        duration: 1000
      });
    },
    loginButton(formName) {
      if(!this.loginLoading){
        this.loginLoading = true
        this.visible = !this.checked
        this.$refs[formName].validate((valid) => {
          if (valid) {
            getPublicKey().then(res => {
              //// console.log(this.loginValidateForm);
              let copiedRegisterForm = JSON.parse(JSON.stringify(this.loginValidateForm));
              copiedRegisterForm.fingerprint = this.fingerprint;
              login(copiedRegisterForm, res.data).then(res1 => {
                if (res1.code == 200) {
                  this.userInfo = res1.data
                  if (this.userInfo.needSecondAuth) {
                    this.userEmail = this.userInfo.email;
                    this.showSafeCodeDialog = true;
                  } else if (this.userInfo.authenticatorEnable) {
                    this.userEmail = this.userInfo.email;
                    this.useEmail2LoginAuthenticator = false;
                    this.authenticatorDialog = true;
                  } else {
                    localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
                    this.$router.push({
                      path: '/'
                    })

                    this.openLogin()
                  }
                }
                this.loginLoading = false
              }).catch(error => {
                // console.log(888)
                if (error.message == this.$store.state.language.ACCOUNT_NOT_ACTIVE) {
                  this.showActiveCodeDialog = true
                }
                // this.$message.error('请求失败：' + error.message);
                this.changeLoginCode();
                this.loginLoading = false
              });

            })
          } else {
            this.loginLoading = false
            return false;
          }
        });
      }

    },
    registerSubmit(formName) {
      if (!this.checked) {
        this.$notify({
          title: this.$store.state.language.READ_AGREE,
          type: 'warning'
        });
        this.visible = !this.checked
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.registerValidateForm.verification = this.verification
            getPublicKey().then(res => {
              let copiedRegisterForm = JSON.parse(JSON.stringify(this.registerValidateForm));
              register(copiedRegisterForm, res.data).then(res => {
                this.openRegister()
                this.isLoginStatus = 1
                this.changeLoginCode()
              }).catch(error => {
                this.changeRegisterCode();
                // this.$message.error('请求失败：' + error.message);
              });
            })
          } else {
            return false;
          }
        });
      }
    },
    sendCaptcha() {
      var that = this;
      this.sendCaptchaDisable = true;
      this.captchaDisable = false;
      sendCaptcha();
      var intervalId = setInterval(function () {
        that.currentCount--;
        that.sendCaptchaButtonText = this.$store.state.language.Send_Code + '(' + that.currentCount + ")";
        if (that.currentCount <= 0) {
          clearInterval(intervalId); // 清除倒计时
          that.sendCaptchaDisable = false;
          that.currentCount = 60;
          that.sendCaptchaButtonText = this.$store.state.language.Send_Code;
        }
      }, 1000);
    },

    loginWithCaptcha() {
      loginWithCaptcha(this.captcha).then(res => {
        if (res.code == 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.$router.push({
            path: '/'
          })
          this.openLogin()
        }
      })
    }
  },
}
;
</script>
<style lang="scss" scoped>
.input-icon{
  font-size: 20px;
  color: #c0c4c0;
  margin-top: 10px !important;
}
.login-bottom-right{
  margin: 30px;
}
.login-bottom-help{
  margin: 8px;
  cursor: pointer;
}
.login-bottom-help:hover{
  color: #0071dc;
}
.big-login-bottom-container{
  text-align: center;
  font-size: 12px;
  width: 100%;
  margin: auto;
  color: #999;
  margin: 20px 0;
}
.login-header-remark{
  border-left: 1px solid #ccc;
  color: #666;
  font-size: 20px;
  height: 28px;
  line-height: 28px;
  margin-left: 20px;
  padding-left: 20px;
  vertical-align: middle;
}
.trust-current-device-checkbox {
  font-size: 12px;
  width: 500px;
  margin-left: -15px;
}

.email-login-auth {
  cursor: pointer;
  color: #409eff;
  text-align: left;
  width: 500px;
  font-size: 12px;
  margin: -15px auto 0;
  margin-top: 0px;
}

.authenticator-dialog .email-alert /deep/ .el-alert__icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.authenticator-dialog .email-alert {
  position: relative;
  width: 500px;
  margin: 0 auto 20px;
}

.authenticator-dialog .email-alert .el-alert__title {
  color: #4f4f4f !important;
  text-align: left;
}

.authenticator-dialog .email-alert /deep/ .el-alert__title i {
  color: red;
  font-style: normal;
}

.authenticator-dialog .email-alert /deep/ .el-alert__content {
  padding-left: 20px;
  text-align: left;
}

.authenticator-dialog .email-alert {
  width: 500px;
  margin: 0 auto 35px;
}

.authenticator-dialog .email-verification-code-inp {
  width: 395px;
  margin-bottom: 35px;
}

.authenticator-dialog .email-verification-code-resend-btn {
  width: 100px;
  margin-left: 5px;
}


.authenticator-dialog .open-step-content {
  width: 650px;
  height: 400px;
  margin: 20px auto 0;
}

.authenticator-dialog .content {
  width: 650px;
  height: 400px;
  margin: 0 auto;
  text-align: center;
}

.authenticator-dialog .content .email-alert {
  position: relative;
  width: 500px;
  margin: 0 auto 20px;
  text-align: left;
}

.authenticator-dialog .emailVerify-inp {
  width: 500px;
  margin-bottom: 20px;
  margin-left: 0;
}

.authenticator-dialog .content .title {
  font-size: 24px;
  color: #4f4f4f;
  font-weight: 700;
  line-height: 100px;
  margin-top: 18px;
  text-align: center;
}

.authenticator-dialog .back-button {
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
}

.authenticator-dialog .el-icon-arrow-left {
  font-size: xxx-large;
}

.authenticator-dialog /deep/ .el-dialog {
  border-radius: 50px;
  overflow: hidden;
}

.authenticator-dialog /deep/ .el-dialog__header {
  padding: 0;
}

.authenticator-dialog /deep/ .el-dialog__body {
  padding: 0;
}

.authenticator-dialog .big-title {
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 80px;
  background-color: #0071DC;
}

.active-tip {
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-path-register-button {
  position: absolute;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #606266;
}

.login-path-register-button:hover {
  color: #0071DC;
}

.login-body-path-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  line-height: 22px;
  margin-top: 100px;

}

.login-body-path-content {
  font-size: 12px;
  color: #999;
  line-height: 17px;
  margin-top: 4px;
}

.login-body-path-sweep {
  width: 58px;
  height: 58px;
  margin-top: 10px;
}

.login-body-paht-code {
  width: 200px;
  height: 200px;
  margin: 30px auto 0;
  position: relative;
}

.login-body-path {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-top-path span::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -6px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 6px solid rgba(17, 186, 102, .1);
  border-bottom: 6px solid transparent;
}

.login-top-path span {
  font-size: 12px;
  display: inline-block;
  border-radius: 4px;
  padding: 2px 4px;
  height: 17px;
  line-height: 17px;
  position: relative;
  color: #0071DC;
  background: rgba(17, 186, 102, .1);
  margin-top: 10px;
}

.login-top-path {
  display: flex;
  // align-items: center;
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;

}

.login-bottom-path-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #F2F2F2;
  margin: 5px;
}

.login-bottom-path-icon img {
  width: 30px;
  height: 30px;
}

.login-bottom-path {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.login-bottom-diviced-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.login-bottom-container {
  position: absolute;
  bottom: 0;
  width: 100%;

}

.code-style {
  cursor: pointer;
}

.email-selector {
  position: absolute;
  width: 100%;
  z-index: 111;
  box-sizing: border-box;
  overflow-y: scroll;
  border-radius: 6px;
  background: white;
  max-height: 200px;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, .1);
}

.email-selector-item {
  padding: 0 20px;
  cursor: pointer;
}

.email-selector-item-active,
.email-selector-item:hover {

  background: #F5F7FA;
}

.country-option {
  font-size: 15px;
}

::v-deep .el-select {
  width: 100% !important;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: -150px !important;
}

.popover {
  position: absolute;
  top: -10;
  padding: 12px 10px;
  box-sizing: border-box;
  background: rgba($color: #000000, $alpha: 0.7);
  color: white;
  transform: translateX(-44%);
  top: -50px;
  border-radius: 5px;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border: 1px solid #0071DC;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0071DC;
}

::v-deep .el-checkbox__inner:hover {
  border: 1px solid #0071DC;
}

.protocol span {
  // font-size: 12.5px;
  color: #409EFF;

}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border: 1px solid #0071DC;
}

.protocol {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  position: relative;
}

.reset-register div:hover {
  color: #0071DC;
}

.el-input--suffix .el-input__inner {
  width: 100% !important;
  padding-right: 154px;
}

.el-select-dropdown__item.selected {
  color: #41C885;
}

.reset-register {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  color: #606266;
  font-size: 12px;
}

.code-input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  display: flex;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
  // margin-top: 10px;

}

.el-button {
  border: none;
  width: 100%;
}

.el-input {
  border: none !important;
  background: #F8F8F8 !important;

}

.el-button:focus {
  background: #0071DC;
}

::v-deep .el-input__inner {
  background: #F8F8F8 !important;
  border: none;
}

::v-deep .pd-input-custom .el-input__inner {
  width: 100%;
  background: #0071DC;
  border: none;
}

.el-button--primary {
  background: #0071DC;
}

::v-deep .el-button--primary:hover {
  background: #41C885;
}

.login-box-title {
  font-size: 25px;
  margin-top: 40px;
  font-weight: 600;
  margin-bottom: 15px;
}


.container {
  min-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .body-container {
    background-color: #060D18;
    height: 732px;
    display: flex;
    justify-content: center;
    width: 100%;

    .login-container {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .login-box {
        background: white;
        height: 680px;
        width: 350px;
        border-radius: 5px;
        padding: 15px;
        box-sizing: border-box;
        position: relative;
      }
    }
  }

  .header-container {
    width: 1200px;
    height: 80px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .header-logo {
      height: 80px;
      max-width: 91px;
      padding: 10px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  .tip {
    margin-bottom: 20px;
    padding: 6px 10px;
    border-radius: 2px;
    color: #d61;
    background-color: #fcf8d9;
  }

  ::v-deep .el-dialog__body {
    padding-bottom: 10px !important;
  }
}
</style>

import {ossUploadImg} from "@/api/oss";
import {consultAdd, consultGet} from "@/api/consult";
import router from '@/router'
import store from "@/store";
import {exchangeRateConvert, exchangeRateQuery} from "@/api/exchangeRate";
import {Message} from "element-ui";
import {orderStatusArr} from "@/utils/enums";
import {languageMore} from "@/api/language";

export function beforeUpload(file) {
    console.log(file,123)
    let types = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/png'];
    const isImage = types.includes(file.type);
    const isLtSize = file.size / 1024 / 1024 < 10;
    if (!isImage) {
        this.$message.error(this.$store.state.language.IMG_FMT_LIMIT);
        return false;
    } else if (!isLtSize) {
        this.$message.error(this.$store.state.language.IMG_SIZE_10MB);
        return false;
    } else {
        // this.$message.error('上传图片格式不对!');
    }

    return true;
}

export function filterTimestamp(timestamp, type) {
    if (timestamp) {
        var dateTime = ''
        var date = new Date(timestamp);
        let year = date.getFullYear();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let seconds = ("0" + date.getSeconds()).slice(-2);
        if (type == 'day') {
            dateTime = year + "-" + month + "-" + day
        } else {
            dateTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
        }
        return dateTime;
    }
}

export function changePackageStatus(num) {
    if (num == 0) {
        return languageMore(localStorage.getItem('languageIndex')).SERVICE_NOT
    } else if (num == 1) {
        return languageMore(localStorage.getItem('languageIndex')).SERVICE_PAID
    } else if (num == 2) {
        return languageMore(localStorage.getItem('languageIndex')).AWAITING_PACK
    } else if (num == 3) {
        return languageMore(localStorage.getItem('languageIndex')).PACKED_2
    } else if (num == 4) {
        return languageMore(localStorage.getItem('languageIndex')).WAIT_SHIPMENT
    } else if (num == 5) {
        return languageMore(localStorage.getItem('languageIndex')).TRANSIT
    } else if (num == 6) {
        return languageMore(localStorage.getItem('languageIndex')).PENDING_CLEAR
    } else if (num == 7) {
        return languageMore(localStorage.getItem('languageIndex')).TRANSIT_2
    } else if (num == 8) {
        return languageMore(localStorage.getItem('languageIndex')).ARRIVED_COUNTRY
    } else if (num == 9) {
        return languageMore(localStorage.getItem('languageIndex')).IN_COUNTRY
    } else if (num == 10) {
        return languageMore(localStorage.getItem('languageIndex')).DELIVERED_2
    } else if (num == 11) {
        return languageMore(localStorage.getItem('languageIndex')).CANCEL_2
    } else if (num == 12) {
        return languageMore(localStorage.getItem('languageIndex')).TO_BE_SUPPLEMENTED

    } else if (num == 13) {
        return languageMore(localStorage.getItem('languageIndex')).INTER_NOT
    } else if (num == 14) {
        return languageMore(localStorage.getItem('languageIndex')).INTER_PAID
    } else if (num == 15) {
        return languageMore(localStorage.getItem('languageIndex')).INTER_OPTIONS
    } else if (num == 16) {
        return languageMore(localStorage.getItem('languageIndex')).ESTIMATED_PAID
    } else if (num == 17) {
        return languageMore(localStorage.getItem('languageIndex')).ESTIMATED_NOT
    } else if (num == 18) {
        return languageMore(localStorage.getItem('languageIndex')).PARCEL_CLOSED

    } else if (num == 19) {
        return languageMore(localStorage.getItem('languageIndex')).CONFIRM_RECEIPT
    }
}

export function mergeExtension(consultImageFileArr) {
    var extension = ''
    var isRun = false
    //// console.log(12333, consultImageFileArr)
    if (consultImageFileArr?.length != 0) {
        consultImageFileArr?.filter((item1, index1) => {
            const formData = new FormData();
            formData.append('file', item1.raw);
            ossUploadImg(formData).then(res => {
                if (consultImageFileArr.length == index1 + 1) {
                    extension += res.data.data.url
                    //// console.log(extension)
                    return extension
                } else {
                    extension += res.data.data.url + ','
                }
            })
        })
    } else {
        return ''
    }
}

// 谷歌分析事件记录
export function behavior(action, label, value) {
    if (value === undefined) {
        window.ga && window.ga('send', 'event', 'category', action, label)
    } else {
        window.ga && window.ga('send', 'event', 'category', action, label, value)
    }
}

export function filterGoodsUrl(url) {
    var baseUrl = url
    baseUrl = baseUrl ? baseUrl : '../../images/forwarding.png'
    return baseUrl.split(',')[0]
}

export function filterCountryCode() {
    var code = ''
    if (localStorage.getItem('languageIndex') == 1) {
        code = 'En'
    } else if (localStorage.getItem('languageIndex') == 2) {
        code = 'En'
    } else if (localStorage.getItem('languageIndex') == 3) {
        code = 'Es'
    }
    return code
}

export function filterCountryCode1() {
    var code = 'Zh'
    if (localStorage.getItem('languageIndex') == 1) {
        code = 'En'
    } else if (localStorage.getItem('languageIndex') == 2) {
        code = 'De'
    } else if (localStorage.getItem('languageIndex') == 3) {
        code = 'Es'
    }
    return code
}

export function filterPrice(amount) {
    return (Math.round(amount * 100 * store.state.currency.rate) / 100).toFixed(2)
}

export function filterPriceReverse(amount,currency) {
    return (Math.round(amount * 100 / filterCurrency(currency)[0].rate) / 100).toFixed(2)
}

export function filterPriceByCurrency(amount, currency) {
    return (Math.round(amount * 100 * filterCurrency(currency)[0].rate) / 100).toFixed(2)
}

export function filterCurrency(currency) {
    // console.log(store.state.currencyArr.filter(item => item.name == currency),currency,56)
    return store.state.currencyArr.filter(item => item.name == currency)
}

export function toHelpCenter(query) {
    var path = ''
    if (query.articleId == -1) {
        path = router.resolve({
            path: '/guidance',
            query: {
                id: 2
            }
        }).href
    } else {
        path = router.resolve({
            path: '/helpCenter/frequentQuestion',
            query
        }).href
    }
    window.open(path, '_blank')
}

export function localstorageLanguageIndex() {
    return localStorage.getItem('languageIndex')
}

export function exchangeRate(callback) {
    exchangeRateQuery().then(res => {
        // console.log()
        var currencyArr = res.data.map(item => {
            return {
                nameZn: item.currencyName,
                name: item.currency,
                rate: item.rate
            }
        })
        store.commit('changeCurrencyArr', currencyArr)
        res?.data?.filter(item => {
            if (item.currency == store.state.currency.name) {
                store.commit('changeCurrency', {
                    symbol: store.state.currency.symbol,
                    name: store.state.currency.name,
                    rate: item.rate
                });
                callback()
            }
        })
    })
}

export function toGoodsDetail(goodsId, searchPath) {
    if (goodsId) {
        var path = router.resolve({
            path: '/goods/detail',
            query: {
                goodsId,
                searchPath
            }
        }).href
        window.open(path, '_blank')
    } else {
        Message.warning(store.state.language.ITEM_MISSING)
    }
}

export function toPaymentPaycenter(payPalOrderVOS, price, type, tradeNo) {
    var order = {
        payPalOrderVOS,
        price,
        type
    }
    localStorage.setItem('order', JSON.stringify(order))
    router.push({
        path: '/payment/paycenter'
    })
}

export function filterCountryCodeLowerCase() {
    var code = ''
    if (localStorage.getItem('languageIndex') == 1) {
        code = 'en'
    } else if (localStorage.getItem('languageIndex') == 2) {
        code = 'en'
    } else if (localStorage.getItem('languageIndex') == 3) {
        code = 'es'
    }
    return code
}


export function isTranslate(path) {
    return localStorage.getItem('languageIndex') == 0 || path == 'taobao' || path == 'weidian' ? '' : 'Trans'
}

export function splitExtension(extension) {
    return extension ? extension.split(',') : []
}

export function toShopCar() {
    router.push({
        path: '/person/shop/cart'
    })
}

export function taobaoTranslate(text, path, translateArr, translate) {
    var str = text
    if (path != 1688 && filterCountryCodeLowerCase() != '' && !translate) {
        str = translateArr[text]
    }
    return str
}

export function googleTranslate(text, translateArr) {
    var str = text
    if (filterCountryCodeLowerCase() != '') {
        str = translateArr[text]
    }
    return str
}

export function filterCouponTitle1(title, item) {
    var str = title
    if (item.limitMax) {
        str = str.replace('${limitMax}', store.state.currency.symbol + (filterPrice(item.limitMax) * 1).toFixed(2))
    }
    if (item.limitMax) {
        str = str.replace('${conditionsOfUse}', store.state.currency.symbol + (filterPrice(item.conditionsOfUse) * 1).toFixed(2))
    }
    return str
}

export function filterCouponTitle(params1, params2, type) {
    var str = ''
    if (type == 1) {
        if (params1 == 0 || !params1) {
            if (localstorageLanguageIndex() == 0) {
                str = store.state.currency.symbol + filterPrice(params2) + '无门槛'
            } else if (localstorageLanguageIndex() == 1) {
                str = store.state.currency.symbol + filterPrice(params2) + ' No-threshold coupon'
            } else if (localstorageLanguageIndex() == 2) {
                str = store.state.currency.symbol + filterPrice(params2) + ' Gutschein ohne Mindestbestellwert'
            } else if (localstorageLanguageIndex() == 3) {
                str = 'Cupón de' + store.state.currency.symbol + filterPrice(params2) + 'sin umbral míni'
            }
        } else {
            if (localstorageLanguageIndex() == 0) {
                str = '满' + store.state.currency.symbol + filterPrice(params1) + '减' + store.state.currency.symbol + filterPrice(params2)
            } else if (localstorageLanguageIndex() == 1) {
                str = store.state.currency.symbol + filterPrice(params2) + ' off orders over' + store.state.currency.symbol + filterPrice(params1)
            } else if (localstorageLanguageIndex() == 2) {
                str = store.state.currency.symbol + filterPrice(params2) + ' Rabatt auf Bestellungen über ' + store.state.currency.symbol + filterPrice(params1)
            } else if (localstorageLanguageIndex() == 3) {
                str = store.state.currency.symbol + filterPrice(params2) + ' de descuento en pedidos superiores a ' + store.state.currency.symbol + filterPrice(params1)
            }
        }
    } else {
        var par = 100 - ((params1 * 10) + '').slice(0, 2) * 1
        if (localstorageLanguageIndex() == 0) {
            str = params1 + '折券,最多可优惠' + store.state.currency.symbol + filterPrice(params2)
        } else if (localstorageLanguageIndex() == 1) {
            str = par + '% off, up to ' + store.state.currency.symbol + filterPrice(params2) + ' off'
        } else if (localstorageLanguageIndex() == 2) {
            str = par + '% off, Bis zu ' + store.state.currency.symbol + filterPrice(params2) + ' Rabatt'
        } else if (localstorageLanguageIndex() == 3) {
            str = par + '% off, Hasta ' + store.state.currency.symbol + filterPrice(params2) + ' de descuento'
        }
    }
    return str
}

export function filterDateTime(datetime) {
    datetime = datetime.map(item => parseStringToTimestamp(item))
    return datetime
}

export function parseStringToTimestamp(dateString) {
    // 移除时区信息和括号内的内容，以便使用 Date.parse
    // const cleanDateString = JSON.stringify(dateString).replace(/ GMT.*$/, '');
    // 使用 Date.parse 解析日期字符串（这会使用本地时区）
    const localDate = new Date(Date.parse(dateString));
    // 获取本地时区偏移量（以分钟为单位）
    const localOffset = localDate.getTimezoneOffset();
    // 转换为 UTC 时间（通过减去本地时区偏移量）
    const utcDate = new Date(localDate.getTime() - localOffset * 60000);
    // 获取 UTC 时间戳
    const timestamp = utcDate.getTime();
    return timestamp;
}

export function filterCouponDegree(params, type) {
    var str = ''
    if (type != 1) {
        var par = 100 - ((params * 10) + '').slice(0, 2) * 1
        if (localstorageLanguageIndex() == 0) {
            str = params + '折'
        } else if (localstorageLanguageIndex() == 1) {
            str = par + '% off'
        } else if (localstorageLanguageIndex() == 2) {
            str = par + '% Rabatt'
        } else if (localstorageLanguageIndex() == 3) {
            str = par + '% de descuento'
        }
    }
    // console.log(params)
    return type == 1 ? store.state.currency.symbol + filterPrice(params) : str
}

export function filterCouponCondition(params, type) {
    var str = ''
    if (type == 1) {
        if (params == 0 || !params) {
            str = languageMore(localStorage.getItem('languageIndex')).NO_THRESHOLD
        } else {
            if (localstorageLanguageIndex() == 0) {
                str = '满' + store.state.currency.symbol + filterPrice(params) + '可用'
            } else if (localstorageLanguageIndex() == 1) {
                str = 'Over ' + store.state.currency.symbol + filterPrice(params)
            } else if (localstorageLanguageIndex() == 2) {
                str = 'Über ' + store.state.currency.symbol + filterPrice(params)
            } else if (localstorageLanguageIndex() == 3) {
                str = 'Más de ' + store.state.currency.symbol + filterPrice(params)
            }
        }
    } else {
        if (localstorageLanguageIndex() == 0) {
            str = '最多可优惠' + store.state.currency.symbol + filterPrice(params)
        } else if (localstorageLanguageIndex() == 1) {
            str = 'Over ' + store.state.currency.symbol + filterPrice(params)
        } else if (localstorageLanguageIndex() == 2) {
            str = 'Über ' + store.state.currency.symbol + filterPrice(params)
        } else if (localstorageLanguageIndex() == 3) {
            str = 'Más de ' + store.state.currency.symbol + filterPrice(params)
        }
    }
    return str
}

export function filterConsultStatus(type, status) {
    var str = ''
    if (type == 2)
        str = orderStatusArr.filter(item => item.code == status)[0].status
    else
        str = orderStatusArr.filter(item => item.code == status)[0].status
    return str
}

// 跳转公告详情页
export function toAnnouncementDetail(id) {
    var path = router.resolve({
        path: '/announcement/detail',
        query: {
            announcementId: id
        }
    }).href
    window.open(path, '_blank')
}

//添加水印
export function generateWatermarkImage(originalImageUrl, watermarkImageUrl) {
    // 创建一个Image对象用于加载原始图片和水印图片
    const originalImage = new Image();
    originalImage.setAttribute("crossOrigin", "anonymous"); //关键，防止canvas跨域污染
    const watermarkImage = new Image();
    watermarkImage.setAttribute("crossOrigin", "anonymous"); //关键
    // 监听原始图片加载完成事件
    originalImage.onload = () => {
        // 创建一个canvas元素用于绘制图片
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // 设置canvas的大小为原始图片的大小
        canvas.width = originalImage.width;
        canvas.height = originalImage.height;

        // 绘制原始图片
        context.drawImage(originalImage, 0, 0);

        // 绘制水印图片在左下角
        context.drawImage(
            watermarkImage,
            0,
            0
        );
        context.font = '24px Arial';
        context.fillStyle = 'rgba(0, 0, 0, 0.3)';
        context.fillText('水印是123', 20, 40); // 调整水印位置
        // 将canvas转换为DataURL
        const watermarkImageUrl = canvas.toDataURL("image/png");

        // 生成新的图片链接
        const newImageUrl = URL.createObjectURL(
            this.dataURLtoBlob(watermarkImageUrl)
        );
        // 在控制台打印新的图片链接
        // console.log("newImageUrl", newImageUrl);
    };
    // 加载原始图片和水印图片
    originalImage.src = originalImageUrl;
    watermarkImage.src = watermarkImageUrl;
    // console.log(originalImage.src, watermarkImageUrl.src, 777);
}

// 将DataURL转换为Blob对象
export function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

export function filterProductIcon(type){
    var url = ''
    if(type==1688){
        url = 'https://oss.tendabuy.com/static/logo/1688.webp'
    }else if(type=='taobao'){
        url = 'https://oss.tendabuy.com/static/logo/taobao.webp'
    }else if(type=='weidian'){
        url = 'https://oss.tendabuy.com/static/logo/%E5%BE%AE%E5%BA%97.png'
    }else if(type=='topup'){
        url = 'https://oss.tendabuy.com/static/topup.webp'
    }else{
        url = 'https://oss.tendabuy.com/static/topuplogo.webp'
    }
    return url
}
import request from "../utils/request";

var inboxUrl = '/inbox'

//站内信查询
export function query(data) {
    return request({
        url: inboxUrl + '/query',
        method: 'post',
        data
    })
}


//站内信详情
export function detail(id) {
    return request({
        url: inboxUrl + '/detail',
        method: 'get',
        param: {
            id: id
        }
    })
}

//已读未读数量
export function inboxStatus() {
    return request({
        url: inboxUrl + '/status',
        method: 'get'
    })
}

//设置已读
export function inboxRead(data) {
    return request({
        url: inboxUrl + '/read',
        method: 'post',
        data
    })
}

//删除站内信
export function deleteInbox(data) {
    return request({
        url: inboxUrl + '/delete',
        method: 'post',
        data
    })
}


import {encrypt} from "../utils/jsenscrypt";
import request from "../utils/request";
import axios from 'axios';

var securityUrl = '/security'

// 修改登录密码
export function userSecurityInfo() {
    return request({
        url: securityUrl + '/securityInfo',
        method: 'get'
    })
}

// 修改登录密码
export function changeLoginPassword(data) {
    return request({
        url: securityUrl + '/changeLoginPassword',
        method: 'post',
        data
    })
}

// 修改支付密码
export function changePayPassword(data) {
    return request({
        url: securityUrl + '/changePayPassword',
        method: 'post',
        data
    })
}

// 登录认证器-发送验证码邮件
export function sendVerificationCodeEmail2Account4LoginAuthenticator() {
    return request({
        url: securityUrl + '/authenticator/login/email',
        method: 'get'
    })
}

// 登录认证器-校验
export function loginAuthenticator(code, trustCurrentDevice) {
    return request({
        url: securityUrl + '/authenticator/login',
        method: 'get',
        params: {
            code: code,
            trustCurrentDevice: trustCurrentDevice
        }
    })
}

// 登录认证器-校验
export function loginAuthenticatorByEmail(code, trustCurrentDevice) {
    return request({
        url: securityUrl + '/authenticator/loginByEmail',
        method: 'get',
        params: {
            code: code,
            trustCurrentDevice: trustCurrentDevice
        }
    })
}

// 绑定认证器-发送验证码邮件
export function sendVerificationCodeEmail2Account4BindAuthenticator() {
    return request({
        url: securityUrl + '/authenticator/bind/email',
        method: 'get'
    })
}

// 开启认证器前验证邮件
export function bindAuthenticatorPreVerifyEmail(code) {
    return request({
        url: securityUrl + '/authenticator/bind/email/check',
        method: 'get',
        params: {
            code: code
        }
    })
}

// 认证器二维码
export function qrcode() {
    return request({
        url: securityUrl + '/authenticator/qrcode',
        method: 'get'
    })
}

// 绑定认证器
export function openAndBindAuthenticator(code) {
    return request({
        url: securityUrl + '/authenticator/bind',
        method: 'get',
        params: {
            code: code
        }
    })
}

// 关闭认证器
export function closeAuthenticator(code) {
    return request({
        url: securityUrl + '/authenticator/close',
        method: 'get',
        params: {
            code: code
        }
    })
}

// 解绑认证器
export function unbindAuthenticator(code) {
    return request({
        url: securityUrl + '/authenticator/unbind',
        method: 'get',
        params: {
            code: code
        }
    })
}

// 通过邮件解绑认证器
export function unbindAuthenticatorByEmail(code) {
    return request({
        url: securityUrl + '/authenticator/unbindByEmail',
        method: 'get',
        params: {
            code: code
        }
    })
}

// 解绑认证器-发送验证码邮件
export function sendVerificationCodeEmail2Account4UnbindAuthenticator() {
    return request({
        url: securityUrl + '/authenticator/unbind/email',
        method: 'get'
    })
}

// 获取受信任的设备列表
export function getTrustedDevices() {
    return request({
        url: securityUrl + '/trusted-devices',
        method: 'get'
    })
}

// 根据ip受信任的设备列表
export function deleteTrustedDeviceByIp(ip) {
    return request({
        url: securityUrl + '/trusted-devices/delete',
        method: 'get',
        params: {
            ip: ip
        }
    })
}


// 编辑受信任的设备名称
export function editTrustedDeviceName(ip, name) {
    return request({
        url: securityUrl + '/trusted-devices/edit',
        method: 'get',
        params: {
            ip: ip,
            name: name
        }
    })
}

<template>
  <div class="app-container">
    <right-menu v-if="$route.path!='/guidance'&&$route.path!='/novice/guide'&&$route.path!='/welcome/next'&&$route.path!='/welcome'&&$route.path!='/login'&&$route.path!='/email/active'&&$route.path&&$route.path!='/forget'&&$route.path!='/password/reset'"></right-menu>
    <router-view></router-view>
    <BottomContainer v-show="isShowBottomContainer()"></BottomContainer>
  </div>
</template>

<script>
import BottomContainer from '@/components/BottomContainer.vue'
import RightMenu from "@/components/RightMenu.vue";
import {getAccount} from "@/api/user";
export default {
  components: {RightMenu, BottomContainer},
  props: {},
  data() {
    return {
      pathArr:['/helpCenter/frequentQuestion','/forwarding','/uniorder','/customize','/helpCenter/hotQuestion','/goods/detail','/order/confirm','/payment/paycenter','/payment/success'],
      language:localStorage.getItem('languageIndex'),
      introOption: { // 参数对象
        prevLabel: '上一步',
        nextLabel: '下一步',
        // skipLabel: '跳过',
        doneLabel: '完成',
        tooltipClass: 'intro-tooltip', /* 引导说明文本框的样式 */
        // highlightClass: 'intro-highlight', /* 说明高亮区域的样式 */
        // exitOnEsc: true, /* 是否使用键盘Esc退出 */
        exitOnOverlayClick: false, /* 是否允许点击空白处退出 */
        keyboardNavigation: true, /* 是否允许键盘来操作 */
        showBullets: false, /* 是否使用点显示进度 */
        showProgress: false, /* 是否显示进度条 */
        scrollToElement: true, /* 是否滑动到高亮的区域 */
        overlayOpacity: 0.5, // 遮罩层的透明度 0-1之间
        positionPrecedence: ['bottom', 'top', 'right', 'left'], /* 当位置选择自动的时候，位置排列的优先级 */
        disableInteraction: false, /* 是否禁止与元素的相互关联 */
        hidePrev: true, /* 是否在第一步隐藏上一步 */
        // hideNext: true, /* 是否在最后一步隐藏下一步 */
        steps: [], /* steps步骤，可以写个工具类保存起来 */
      }
    };
  },
  computed:{
    languageIndex(){
      return this.$store.state.language;
    }
  },
  watch: {
    languageIndex(newVal){
      //// console.log(newVal)

      // window.location.reload();
    }
  },
  created() {

    this.$behavior(this.$store.state.language.CLICK_COUNT, this.$store.state.language.RECORD, '+1')

  },
  mounted() {
    // this.$nextTick(() => {
    //   // if (localStorage.getItem('isFirst') === null || localStorage.getItem('isFirst') !== '1') {
    //   this.initGuide()
    //   //   localStorage.setItem('isFirst', 1)
    //   // }
    // })
  },
  methods: {


    isShowBottomContainer(){
      return this.pathArr.some(obj=>obj==this.$route.path)
    }
  },
};
</script>
<style lang="scss">
.right-menu-quantity[data-v-4e7c2168] .el-input-number .el-input__inner{
  border: none;
}
.goods-container .el-input__inner {
  background: white !important;
}
.goods-container .el-pagination__jump{
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}
.goods-container .el-pagination__editor.el-input .el-input__inner{
  border: 1px solid #aaa !important;
}
.driver-popover-description{
  color: #333 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.driver-popover-next-btn{
  background: #0071dc !important;
  color: white !important;
  text-shadow: none !important;
  border-color: #0071dc !important;
}
.driver-popover-next-btn:hover{
  opacity: 0.8 !important;
}
.driver-popover-footer .driver-popover-btn-disabled{
  display: none !important;
}
*{
  word-break: break-word;
}
.el-popconfirm{
  padding: 10px;
  box-sizing: border-box;
}
.el-empty__description p{
  color: #333;
}
.app-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;

}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border: 1px solid #0071DC !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0071DC !important;
}

.el-checkbox__inner:hover {
  border: 1px solid #0071DC !important;
}

.protocol span {
  // font-size: 12.5px;
  color: #409EFF;
}
 .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #0071DC !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border: 1px solid #0071DC !important;
}

.el-popper{
  padding: 0;
  z-index: 2048 !important;
  min-width: 0;
}
.custom-photography{
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

.photo-template{
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
 .order-popover-input input{
   width: 125px;
 }
 .el-button--primary{
   background-color: #0071DC !important;
   border-color: #0071DC !important;
 }

 .el-button--primary.is-disabled{
   color: white !important;
   background-color: #a0cfff !important;
   border-color: #a0cfff !important;
 }
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;

}
.container-box{
  width: 1200px;
}
 .el-button--primary:hover{
   opacity: 0.8;
 }
 .order-box-bottom{
   width: 1005px !important;
 }
 .children-container{
   width: 1005px !important;
 }
//.right-menu

* {
  padding: 0;
  margin: 0;
}
.driver-popover{
  max-width: 600px !important;
  padding: 15px 32px !important;
}
</style>

import request from "../utils/request";

var cartgoodsUrl = '/cartgoods'
// 添加购物车
export function cartgoodsAdd(data){
    var dataArr = []
    dataArr.push(data)
    var dataObj = {}
    dataObj.orderFormVOS = dataArr
    return request({
        url: cartgoodsUrl + '/add',
        method: 'post',
        data
    })
}

// 获取购物车
export function cartgoodsGet(){
    return request({
        url: cartgoodsUrl + '/get',
        method: 'get',
        http2:true
    })
}

// 获取购物车id
export function cartgoodsGetListId(params){
    // const queryString = data
    var data = params
    ////// console.log(params)
    return request({
        url: cartgoodsUrl + '/getListId',
        method: 'post',
        data
    })
}



// 批量移除购物车
export function cartgoodsDeleteList(data){
    return request({
        url: cartgoodsUrl + '/deleteList',
        method: 'post',
        data
    })
}

// 修改购物车数量
export function cartgoodsModify(params){
    return request({
        url: cartgoodsUrl + '/modify',
        method: 'post',
        params
    })
}


// 修改购物车备注
export function cartgoodsModifyRemark(params){
    return request({
        url: cartgoodsUrl + '/modify/remark',
        method: 'post',
        params
    })
}

// 移除购物车
export function cartgoodsDelete(params){
    return request({
        url: cartgoodsUrl + '/delete',
        method: 'post',
        params
    })
}

// 获取购物车数量
export function cartgoodsGetNum(){
    return request({
        url:cartgoodsUrl+'/get/num'
    })
}
import request from "../utils/request";
var consultUrl = '/consult'
export function consultAdd(data){
    return request({
        url:consultUrl+'/add',
        method:'post',
        data
    })
}

export function consultGet(params){
    return request({
        url:consultUrl+'/get',
        method:'get',
        params
    })
}

export function consultGetByUid(params){
    return request({
        url:consultUrl+'/getByUid',
        method:'get',
        params
    })
}

export function consultGetByOrderId(params){
    return request({
        url:consultUrl+'/getByOrderId',
        method:'get',
        params
    })
}
import Vue from 'vue'
import Vuex from 'vuex'
import {getUserProfile} from "@/common/common";
import {getAccount} from "@/api/user";
import {cartgoodsGet, cartgoodsGetNum} from "@/api/cargoods";
import {orderGet, orderGetStorageNum, orderGetUnpaidNum} from "@/api/order";
import {languageMore} from "@/api/language";
import {inboxStatus} from "@/api/inbox";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        country: '',
        consultInput: '',
        userInfo: '',
        avatar: '',
        currency: {
            symbol: '',
            name: '',
            rate:''
        },
        cartQuantity: 0,
        unpaidOrderQuantity:0,
        storageOrderQuantity:0,
        language:languageMore(localStorage.getItem('languageIndex')?localStorage.getItem('languageIndex'):1),
        collect:'',
        inboxUnreadNum:0,
        currencyArr:[]
    },
    getters: {},
    mutations: {
        changeLanguage(state,index){
            state.language = languageMore(index)
        },
        changeCurrencyArr(state,currencyArr){
          state.currencyArr = currencyArr;
        },
        changeCollect(state,collect){
            state.collect = collect
        },
        changeCurrency(state, currency) {
            state.currency = currency
        },
        addUnpaidOrderQuantity(state){
            state.unpaidOrderQuantity++
        },
        subUnpaidOrderQuantity(state){
            state.unpaidOrderQuantity--
        },
        changeCountry(state, amount) {
            state.country = amount
        },
        changeUserInfo(state,userInfo) {
            state.userInfo = userInfo
            // //// console.log()
        },
        changeAvatar(state) {
            var userProfile = getUserProfile();
            if (userProfile && userProfile.attributes.avatar) {
                state.avatar = userProfile.attributes.avatar;
            }
        },
        changeCartQuantity(state, count) {
            state.cartQuantity = count
        },
        changeUnpaidOrderQuantity(state,count){
            state.unpaidOrderQuantity = count
        },
        changeStorageOrderQuantity(state,count){
            state.storageOrderQuantity = count
        },
        changeInboxUnreadNum(state,num){
            state.inboxUnreadNum = num
        }
    },
    actions: {
        changeInboxUnreadNumAsync(mutations){
            inboxStatus().then(res=>{
                var num = res.data.unRead
                mutations.commit('changeInboxUnreadNum',num)
            })
        },
        changeCartQuantityAsync(context) {
            cartgoodsGetNum().then(res => {
                var count = res.data
                context.commit('changeCartQuantity', count)
            })
        },
        changeUnpaidOrderQuantityAsync(mutations){
            orderGetUnpaidNum().then(res=>{
                var count = res.data
                mutations.commit('changeUnpaidOrderQuantity',count)
            })

            orderGetStorageNum().then(res=>{
                var count = res.data
                mutations.commit('changeStorageOrderQuantity',count)
            })
        }

    },
    modules: {}
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/Login.vue'
import {getUserProfile} from "@/common/common";
import {Message} from "element-ui";
import {languageMore} from "@/api/language";
import store from "@/store";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',

    // redirect: '/index',
    component: () => import('../views/Index.vue'),
    meta:{
      auth:true,
      title:'Tendabuy -The Best Taobao Agent Help You Shop,Shipping From China',
      name:'google-site-verification',
      content:'nwqhXbNxJMEDhNEBaoFcRkLdSXplgOCGHt3ryfTbx_w'
    }
  },
  {
    path:'/welcome',
    name:'Welcome',
    meta:{
      auth:true
    },
    component:() => import('../views/Welcome.vue'),
  },
  {
    path:'/guidance',
    name:'NoviceGuide',
    meta:{
      auth:true
    },
    component:() => import('../views/NoviceGuide.vue'),
  },

  {
    path:'/welcome/next',
    name:'WelcomeNext',
    meta:{
      auth:true
    },
    component:() => import('../views/WelcomeNextPage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      auth:true
    },
    component: LoginView
  },
  {
    path:'/Announcement/detail',
    name:'announcementDetail',
    meta:{
      auth:true
    },
    component:()=>import('../views/AnnouncementDetail.vue')
  },
  {
    path:'/email/active',
    name:'EmailActive',
    meta:{
      auth:true
    },
    component:()=>import('../views/EmailActive.vue')
  },
  {
    path:'/report/records',
    name:'reportRecords',
    component:()=>import('../views/ReportRecords.vue')
  },
  {
    path:'/cart/estimation/freight',
    name:'cartEstimationFreight',
    meta:{
      auth:true
    },
    component:()=>import('../views/CartEstimationFreight.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta:{
      auth:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/forget',
    name: 'forget',
    meta:{
      auth:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordForget.vue')
  },
  {
    path:'/password/reset',
    name:'passwordReset',
    meta:{
      auth:true
    },
    component:()=>import('../views/PasswordReset.vue')
  },
  {
    path:'/about/that',
    name:'aboutThat',
    meta:{
      auth:true
    },
    component:()=>import('../views/AboutThat.vue')
  },
  {
    path:'/goods',
    name:'goods',
    meta:{
      title: '商品',
      auth:true
    },
    component:()=>import('../views/Goods')
  },
  {
    path:'/goods/detail',
    name:'goodsDetail',
    meta:{
      auth:true
    },
    component:()=>import('../views/GoodsDetail.vue')
  },
  {
    path:'/order/confirm',
    name:'orderConfirm',
    component:()=>import('../views/OrderConfirm.vue')
  },

  {
    path:'/customize',
    name:'customize',
    component:()=>import('../views/Customize.vue')

  },
  {
    path:'/payment/paycenter',
    name:'paymentPaycenter',
    component:()=>import('../views/PaymentPaycenter.vue')
  },
  {
    path:'/uniorder',
    name:'uniorder',
    component:()=>import('../views/Uniorder.vue')
  },

  {
    path:'/payment/fail',
    name:'paymentFail',
    component:()=>import('../views/PaymentFail.vue')
  },
  {
    path:'/payment/success',
    name:'paymentSuccess',
    component:()=>import('../views/PaymentSuccess.vue')
  },
  {
    path:'/estimation',
    name:'estimation',
    component:()=>import('../views/Estimation.vue')
  },
  {
    path:'/shop/detail',
    name:'shopDetail',
    component:()=>import('../views/ShopDetail.vue')
  },
  {
    path:'/forwarding',
    name:'transport',
    component:()=>import('../views/forwarding/forwarding.vue')
  },
  {
    path:'/forwarding/history',
    name:'forwardingHistory',
    component:()=>import('../views/forwarding/forwardingHistory.vue')
  },
  {
    path: '/helpCenter',
    meta:{
      auth:true
    },
    name: 'helpCenter',
    redirect: 'helpCenter/hotQuestion',
    component: ()=>import('../views/helpcenter/front/helpCenter'),
    children: [
      {
        path: 'frequentQuestion',
        name: 'frequentQuestion',
        meta:{
          auth:true
        },
        component: ()=>import('../views/helpcenter/front/frequentQuestion')
      },
      {
        path: 'hotQuestion',
        name: 'hotQuestion',
        meta:{
          auth:true
        },
        component: ()=>import('../views/helpcenter/front/hotQuestion')
      }
    ]
  },
  {
    path:'/person',
    name:'person',
    redirect:'/person/pecenter',
    component:()=>import('../views/Person.vue'),
    children:[
      {
        path:'wallet/withdraw',
        name:'walletWithdraw',
        component:()=>import('../views/balance/WalletWithdraw.vue'),
      },
      {
        path:'coupon',
        name:'coupon',
        component:()=>import('../views/Coupon.vue')
      },
      {
        path:'pecenter',
        name:'pecenter',
        component:()=>import('../views/Pecenter.vue')
      },
      {
        path:'shop/cart',
        name:'shopCart',
        component:()=>import('../views/ShopCar.vue')
      },
      {
        path:'collection',
        name:'collection',
        redirect: 'collection/store',
        component:()=>import('../views/Collection.vue'),
        children: [{
          path: 'store',
          name: 'store',
          component: ()=>import('../views/collection/store')
        },
        {
          path: 'goods',
          name: 'goods',
            component: ()=>import('../views/collection/goods')
        }
      ]
      },
      {
        path:'order/list',
        name:'orderList',
        component:()=>import('../views/OrderList.vue')
      },
      {
        path:'order/detail',
        name:'orderDetail',
        component:()=>import('../views/OrderDetail.vue')
      },
      {
        path:'storage/list',
        name:'storageList',
        component:()=>import('../views/StorageList.vue')
      },
      {
        path:'parcel/preview',
        name:'parcelPreview',
        component:()=>import('../views/ParcelPreview.vue')
      },
      {
        path:'previewParcel/list',
        name:'parcelList',
        component:()=>import('../views/package/previewParcel.vue')
      },
      {
        path:'parcel/list',
        name:'parcelList',
        component:()=>import('../views/package/ParcelList.vue')
      },
      {
        path: 'packageDetail',
        name: 'packageDetail',
        component: ()=>import('../views/package/packageDetail')
      },
      {
        path: 'previewPackageDetail',
        name: 'previewPackageDetail',
        component: ()=>import('../views/package/previewPackageDetail')
      },
      {
        path: 'servicePickUp',
        name: 'servicePickUp',
        component: () => import('../views/package/servicePickUp')
      },
      {
        path: 'pickUp',
        name: 'pickUp',
        component: () => import('../views/package/pickUp')
      },
      {
        path: 'routePickUp',
        name: 'routePickUp',
        component: () => import('../views/package/routePickUp')
      },
      {
        path:'comments',
        name:'comments',
        component:()=>import('../views/Comments.vue')
      },
      {
        path:'wallet/balance',
        name:'walletBalance',
        component:()=>import('../views/balance/balance.vue')
      },
      {
        path:'balance/history',
        name:'walletBalanceHistory',
        component:()=>import('../views/balance/balanceHistory.vue')
      },
      {
        path:'points',
        name:'points',
        component:()=>import('../views/Points.vue')
      },
      {
        path:'inbox',
        name:'inbox',
        component:()=>import('../views/inbox/inbox.vue')
      },
      {
        path:'consult/list',
        name:'consultList',
        component:()=>import('../views/ConsultList.vue')
      },
      {
        path:'site/message',
        name:'siteMessage',
        component:()=>import('../views/SiteMessage.vue')
      },
      {
        path:'info',
        redirect:'udetail',
        name:'infoUdetail',
        component:()=>import('../views/InfoUdetail.vue'),
        children:[
          {
            path: 'udetail',
            name: 'udetail',
            component:()=>import('../views/accountComponents/udetail')
          }
          ,  {
            path: 'uaddress',
            name: 'uaddress',
            component:()=>import('../views/accountComponents/uaddress')
          }
          ,
          {
            path: 'security',
            name: 'security',
            component:()=>import('../views/accountComponents/security')
          }
          ,
          {
            path: 'accountAddress',
            name: 'accountAddress',
            component:()=>import('../views/accountComponents/accountAddress')
          }
        ]
      },
      {
        path:'manager/mcenter',
        name:'managerMcenter',
        component:()=>import('../views/ManagerMcenter.vue')
      },
      {
        path:'proxy/prhome',
        name:'proxyPrhome',
        component:()=>import('../views/ProxyPrhome.vue')
      },
      {
        path: 'adminHelpCenter',
        name: 'adminHelpCenter',
        redirect: "adminHelpCenter/articleCategory",
        component: ()=>import('../views/helpcenter/admin/helpCenterAdmin'),
        children: [
            {
          path: 'articleList',
          name: 'articleList',
          component: ()=>import('../views/helpcenter/admin/articleList')
        }, {
            path: 'articleList/articleEdit',
            name: 'articleEdit',
            component: ()=>import('../views/helpcenter/admin/articleEdit')
          },
          {
            path: 'articleCategory',
            name: 'articleCategory',
            component: ()=>import('../views/helpcenter/admin/articleCategory')
          }]
      }
    ]
  },
  {
    path: "/login/fail",
    name: 'loginFail',
    meta: {
      title: '未找到页面',
      auth:true
    },
    component: () => import('@/views/404.vue'),
    hidden: true
  },
  {
    path:"/login/success",
    name:'loginSuccess',
    meta:{
      auth:true
    },
    component:()=>import('@/views/LoginSuccess.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base:'/',
  routes
})
router.beforeEach((to, from, next) => {
  if (typeof gtag !== 'undefined') {
    gtag('config', 'G-YT2HTRYWP8', {
      'page_path': to.path
    })
  }
  next()
})
window._axiosPromiseArr = []
router.beforeEach((to, from, next) => {
  if(to.query.searchPath=="mouldKing"||to.query.searchPath=="MouldKing"||(to.query.search&&to.query.search.toLowerCase()=="mouldking")){
      store.commit('changeLanguage',1);
  }else{
    store.commit('changeLanguage',localStorage.getItem('languageIndex')?localStorage.getItem('languageIndex'):1);
  }
  if(to.meta.title) {
    document.title = to.meta.title;
  }
  if(to.meta.content){
    document.name = to.meta.content
  }
  if(to.path!='/helpCenter/frequentQuestion'){
    localStorage.removeItem('OutterHelpCenterId')
    localStorage.removeItem('OutterHelpCenterChar')
    localStorage.removeItem('InnerHelpCenterId')
    localStorage.removeItem('InnerHelpCenterName')
  }
  if(to.meta.name){
    document.name = to.meta.name
  }
  // 检查元信息中的 requiresAuth 字段
  if (!to.meta.auth) {
    // 该路由需要验证，执行验证逻辑
    if (getUserProfile()) {
      window._axiosPromiseArr.forEach((ele, index) => {
        ele.cancel()
        delete window._axiosPromiseArr[index]
      })
        next(); // 用户已登录，继续导航
    } else {
      // console.log(99666)
      Message.error(languageMore(localStorage.getItem('languageIndex')).NOT_LOGGED)
      next('/login'); // 用户未登录，重定向到登录页
    }
  } else {
    if(to.path=='/login'||getUserProfile()){
      window._axiosPromiseArr.forEach((ele, index) => {
        ele.cancel()
        delete window._axiosPromiseArr[index]
      })
      next()
    }
    else{
      window._axiosPromiseArr.forEach((ele, index) => {
        ele.cancel()
        delete window._axiosPromiseArr[index]
      })
      next()
    }
    // 不需要验证，继续导航
  }
});
export default router
